import React, { ReactNode, useEffect, useRef } from 'react'

export const PriceChange = ({
  children,
  isUp
}: {
  children: ReactNode
  isUp: boolean
}) => {
  const animatedTextRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (animatedTextRef.current) {
      animatedTextRef.current.classList.add(
        isUp ? 'animate-greenpulse' : 'animate-redpulse'
      )

      setTimeout(() => {
        if (animatedTextRef.current) {
          animatedTextRef.current.classList.remove('animate-greenpulse')
          animatedTextRef.current.classList.remove('animate-redpulse')
        }
      }, 1000)
    }
  }, [children, isUp])

  return <div ref={animatedTextRef}>{children}</div>
}

export const PnLChange = ({
  children,
  value
}: {
  children: ReactNode
  value: number
}) => {
  const animatedTextRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (animatedTextRef.current) {
      animatedTextRef.current.classList.add(
        value >= 0 ? 'animate-greenpulse' : 'animate-redpulse'
      )

      setTimeout(() => {
        if (animatedTextRef.current) {
          animatedTextRef.current.classList.remove('animate-greenpulse')
          animatedTextRef.current.classList.remove('animate-redpulse')
        }
      }, 1000)
    }
  }, [value])

  return (
    <span
      ref={animatedTextRef}
      className={`${value >= 0 ? 'text-green' : 'text-red'}`}
    >
      {children}
    </span>
  )
}
