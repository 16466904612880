import { useEffect, useState } from 'react'
import { DEFAULT_CHAIN, SUPPORTED_CHAIN_IDS } from '../constants'
import { useAccount } from 'wagmi'

export function useChainId() {
  const { chain } = useAccount()

  if (chain) {
    return chain.id
  }

  return DEFAULT_CHAIN
}

export function useIsSupportedChain() {
  const [supported, setSupported] = useState(true)
  const chainId = useChainId()

  useEffect(() => {
    if (chainId) {
      setSupported(SUPPORTED_CHAIN_IDS.includes(chainId))
    }
  }, [chainId])

  return supported
}
