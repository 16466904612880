import { useMemo } from 'react'
import { Address, GammaOrderBuilder, Q96 } from '@predy/js-sdk'
import { ADDRESS_MAP, PairInfo } from '../../constants'
import { useBlockTimestamp, useDeadline } from '../useBlockTimestamp'
import { usePermit2Nonce } from '../query/usePermit2Nonce'
import { toScaledPrice } from '../../utils'
import { sqrt } from '../../utils/bn'
import { getSqrtPriceTrigger } from '../../utils/trigger'

function createOrderBuilder(chainId: number) {
  return new GammaOrderBuilder(chainId).market(
    ADDRESS_MAP[chainId].GammaTradeMarket
  )
}

export function useGammaOrder(
  chainId: number,
  pairInfo: PairInfo,
  trader: Address,
  quantity: bigint,
  quantitySqrt: bigint,
  marginAmount: bigint,
  leverage: number,
  basePrice: number,
  {
    positionId = 0n,
    isEnabled,
    duration,
    hedgeInterval,
    priceTrigger
  }: {
    positionId?: bigint
    isEnabled: boolean
    duration?: number
    hedgeInterval?: number
    priceTrigger?: number
  }
) {
  const deadline = useDeadline(false)
  const now = useBlockTimestamp(chainId)
  const nonce = usePermit2Nonce(
    chainId,
    trader,
    ADDRESS_MAP[chainId].GammaTradeMarket
  )

  return useMemo(() => {
    let finalDeadline = 0n

    if (nonce.isSuccess) {
      finalDeadline = deadline
    }

    const baseSqrtPrice = sqrt(toScaledPrice(pairInfo, basePrice) * Q96)

    const expiration = duration === undefined ? 0n : now + BigInt(duration)

    const sqrtPriceTrigger = priceTrigger
      ? getSqrtPriceTrigger(priceTrigger)
      : 0

    return createOrderBuilder(chainId)
      .trader(trader)
      .nonce(nonce.data || 0n)
      .deadline(finalDeadline)
      .pairId(BigInt(pairInfo.pairId || 0))
      .entryTokenAddress(pairInfo.quote.address)
      .positionId(positionId)
      .quantity(quantity)
      .quantitySqrt(quantitySqrt)
      .marginAmount(marginAmount)
      .leverage(leverage)
      .baseSqrtPrice(baseSqrtPrice)
      .slippageTolerance(1000000 + 3000) // 0.3%
      .isEnabled(isEnabled)
      .expiration(expiration)
      .hedgeInterval(hedgeInterval === undefined ? 0 : hedgeInterval)
      .sqrtPriceTrigger(sqrtPriceTrigger)
      .auctionPeriod(3 * 60)
      .auctionRange(8000) // 0.8%
      .minSlippageTolerance(1000000 + 2800) // 0.28%
      .maxSlippageTolerance(1000000 + 5800) // 0.58%
      .build()
  }, [
    chainId,
    pairInfo,
    positionId,
    basePrice,
    deadline,
    nonce,
    duration,
    isEnabled,
    now,
    trader,
    quantity,
    quantitySqrt,
    marginAmount,
    leverage,
    hedgeInterval,
    priceTrigger
  ])
}
