import React from 'react'
import { PrimaryButton } from '../common/Button'
import { ADDRESS_MAP } from '../../constants/addresses'
import { PAIR_INFOS, PairInfo } from '../../constants/assets'
import { Address } from '@predy/js-sdk/dist/types'
import { useUpdateQuoteTokenMap } from '../../hooks/contracts/useExecuteOrder'
import { useWeth9Deposit } from '../../hooks/contracts/useTransfer'
import { useUpdateWhitelistFiller } from '../../hooks/contracts/useUpdateFiller'
import { SupplyPoolPanel, WithdrawPoolPanel } from './PoolPanel'
import { useAccount, useWriteContract } from 'wagmi'
import { LendingHistory } from './LendingHistory'

export const MintWethButton = ({
  pairInfo,
  account,
  amount
}: {
  pairInfo: PairInfo
  account: Address
  amount: bigint
}) => {
  const deposit = useWeth9Deposit(pairInfo.base.address, account)

  return (
    <PrimaryButton
      disabled={false}
      onClick={() => {
        if (deposit.writeContract)
          deposit.writeContract({
            value: amount
          } as any)
      }}
    >
      Mint WETH
    </PrimaryButton>
  )
}

export const UpdateQuoteTokenMapButton = ({ chainId }: { chainId: number }) => {
  const update = useUpdateQuoteTokenMap(chainId, 1)
  const write = useWriteContract()

  return (
    <PrimaryButton
      disabled={false}
      onClick={() => {
        if (update.data) {
          write.writeContract(update.data.request)
        }
      }}
    >
      Update
    </PrimaryButton>
  )
}

export const FillerButton = ({
  chainId,
  filler
}: {
  chainId: number
  filler: Address
}) => {
  const update = useUpdateWhitelistFiller(
    ADDRESS_MAP[chainId].PerpMarket,
    filler
  )
  const write = useWriteContract()

  return (
    <PrimaryButton
      disabled={false}
      onClick={() => {
        if (update.data) {
          write.writeContract(update.data.request)
        }
      }}
    >
      Filler
    </PrimaryButton>
  )
}

export const LendingTradeForm = ({ chainId }: { chainId: number }) => {
  const { address } = useAccount()

  if (address === undefined) {
    return <div>Connect Wallet</div>
  }

  return (
    <div>
      <div className="my-4 p-4 bg-panel border-[1px] border-panelborder space-y-2 w-[580px]">
        <div className="mb-4">Your Supplies</div>

        <div className="px-4 flex justify-between text-xs text-fourth">
          <div className="basis-2/6">Asset</div>
          <div className="basis-2/6 flex justify-end">Supplied Balance</div>
          <div className="basis-1/6 flex justify-end">APY</div>
          <div className="basis-1/6"></div>
        </div>

        <WithdrawPoolPanel
          chainId={chainId}
          pairInfo={PAIR_INFOS[chainId]['eth-usdc']}
          isQuote={false}
        />
        <WithdrawPoolPanel
          chainId={chainId}
          pairInfo={PAIR_INFOS[chainId]['eth-usdc']}
          isQuote={true}
        />
      </div>
      <div className="my-4 p-4 bg-panel border-[1px] border-panelborder space-y-2 w-[580px]">
        <div className="mb-4">Assets to Supply</div>

        <div className="px-4 flex justify-between text-xs text-fourth">
          <div className="basis-2/6">Asset</div>
          <div className="basis-2/6 flex justify-end">Wallet Balance</div>
          <div className="basis-1/6 flex justify-end">APY</div>
          <div className="basis-1/6"></div>
        </div>

        <SupplyPoolPanel
          chainId={chainId}
          trader={address}
          pairInfo={PAIR_INFOS[chainId]['eth-usdc']}
          isQuote={false}
        />
        <SupplyPoolPanel
          chainId={chainId}
          trader={address}
          pairInfo={PAIR_INFOS[chainId]['eth-usdc']}
          isQuote={true}
        />
      </div>

      <LendingHistory chainId={chainId} address={address} />
    </div>
  )
}
