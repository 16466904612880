import React from 'react'

export enum OrderType {
  Market,
  Limit,
  Stop
}

interface Props {
  orderType: OrderType
  setOrderType: (orderType: OrderType) => void
}

export const OrderTypeSelector = ({ orderType, setOrderType }: Props) => {
  return (
    <div className="py-1 rounded-md flex justify-start items-center space-x-4 text-base">
      <div
        onClick={() => {
          setOrderType(OrderType.Market)
        }}
        className={`${
          orderType === OrderType.Market
            ? 'text-accent'
            : 'text-third hover:text-accent'
        } flex justify-center items-center cursor-pointer`}
      >
        Market
      </div>
      <div
        onClick={() => {
          setOrderType(OrderType.Limit)
        }}
        className={`${
          orderType === OrderType.Limit
            ? 'text-accent'
            : 'text-third hover:text-accent'
        } flex justify-center items-center cursor-pointer`}
      >
        Limit
      </div>
    </div>
  )
}
