import React, { ReactNode } from 'react'
import { Tooltip } from '@material-tailwind/react'
import infoSvg from '../../assets/info.svg'
import { AiFillInfoCircle } from 'react-icons/ai'

const InfoTooltip = ({
  children,
  placement
}: {
  children: ReactNode
  placement: string
}) => {
  return (
    <Tooltip
      className="z-50 bg-panel border-panelborder border-[1px]"
      content={
        <div className="p-1 bg-panel rounded-xl">
          <div className="font-normal bg-panel text-white text-center text-xs">
            {children}
          </div>
        </div>
      }
      placement={placement}
    >
      <div>
        <AiFillInfoCircle />
      </div>
    </Tooltip>
  )
}

export const AlertTooltip = ({
  children,
  placement
}: {
  children: ReactNode
  placement: string
}) => {
  return (
    <Tooltip
      className="z-50 bg-panel border-panelborder border-[1px]"
      content={
        <div className="w-60 p-1 rounded-xl font-normal text-red text-center text-xs">
          {children}
        </div>
      }
      placement={placement}
    >
      <img className="ml-1 mb-2" src={infoSvg} alt="alert" width={12} />
    </Tooltip>
  )
}

export const InfoTooltip2 = ({
  children,
  placement,
  description
}: {
  children: ReactNode
  placement: string
  description: string
}) => {
  return (
    <Tooltip
      className="z-50 bg-panel border-panelborder border-[1px]"
      content={
        <div className="w-60 p-1 rounded-xl font-normal text-white text-center text-xs">
          {description}
        </div>
      }
      placement={placement}
    >
      {children}
    </Tooltip>
  )
}

export default InfoTooltip
