import { useSimulateContract } from 'wagmi'
import { erc20ABI } from '../../abis/ERC20'

export function useApprove(
  address: `0x${string}`,
  spender: `0x${string}`,
  amount: bigint
) {
  return useSimulateContract({
    address,
    abi: erc20ABI,
    functionName: 'approve',
    args: [spender, amount]
  })
}
