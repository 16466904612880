import { useSimulateContract } from 'wagmi'
import { PredyPoolQuoterABI } from '@predy/js-sdk'
import { ADDRESS_MAP } from '../../constants/addresses'
import { Address } from 'viem'
import { IRMParams } from '../../utils/irm'

export type ScaledAssetStatus = {
  totalCompoundDeposited: bigint
  totalNormalDeposited: bigint
  totalNormalBorrowed: bigint
  assetScaler: bigint
  assetGrowth: bigint
  debtGrowth: bigint
}

export type AssetStatus = {
  token: Address
  supplyTokenAddress: Address
  tokenStatus: ScaledAssetStatus
  irmParams: IRMParams
  accumulatedProtocolRevenue: bigint
  accumulatedCreatorRevenue: bigint
}

export type SqrtAssetStatus = {
  uniswapPool: Address
  tickLower: number
  tickUpper: number
  numRebalance: bigint
  totalAmount: bigint
  borrowedAmount: bigint
  lastRebalanceTotalSquartAmount: bigint
  lastFee0Growth: bigint
  lastFee1Growth: bigint
  borrowPremium0Growth: bigint
  borrowPremium1Growth: bigint
  fee0Growth: bigint
  fee1Growth: bigint
  rebalancePositionBase: {
    positionAmount: bigint
    lastFeeGrowth: bigint
  }
  rebalancePositionQuote: {
    positionAmount: bigint
    lastFeeGrowth: bigint
  }
  rebalanceInterestGrowthBase: bigint
  rebalanceInterestGrowthQuote: bigint
}

export type PairStatus = {
  id: bigint
  isQuoteZero: boolean
  marginId: Address
  poolOwner: Address
  priceFeed: Address
  quotePool: AssetStatus
  basePool: AssetStatus
  sqrtAssetStatus: SqrtAssetStatus
  lastUpdateTimestamp: bigint
}

export function usePairStatus(chainId: number, pairId: number) {
  return useSimulateContract({
    address: ADDRESS_MAP[chainId].PredyPoolQuoter,
    abi: PredyPoolQuoterABI,
    functionName: 'quotePairStatus',
    args: [BigInt(pairId)],
    chainId,
    account: '0x0000000000000000000000000000000000000000'
  })
}
