import React, { useState } from 'react'
import { PriceChart } from '../chart/TradingViewWidget'
import { TradeFormSM } from './trade/TradeFormSM'
import { TradeForm } from './trade/TradeForm'
import { PositionTab } from './position/PositionTab'
import { ChartTop } from './center/ChartTop'
import { PairInfo } from '../../constants/assets'

interface Props {
  chainId: number
  pairInfo: PairInfo
}

export const SwapMarket = ({ chainId, pairInfo }: Props) => {
  const mediaQuery = window.matchMedia('(width < 768px)')

  const [isChartOpen, setIsChartOpen] = useState(!mediaQuery.matches)

  return (
    <div>
      <ChartTop
        chainId={chainId}
        pairInfo={pairInfo}
        isChartOpen={isChartOpen}
        setIsChartOpen={setIsChartOpen}
      />
      <div className="h-full flex justify-center flex-col md:flex-row">
        <div className="h-full grow flex flex-col">
          <div className="z-0 border-panelborder">
            <PriceChart isChartOpen={isChartOpen} />
          </div>
          <div className="w-full block md:hidden">
            <TradeFormSM chainId={chainId} pairInfo={pairInfo} />
          </div>
        </div>
        <div className="w-[480px] hidden md:block">
          <div className="h-[420px] bg-panel border-[1px] border-panelborder">
            <TradeForm chainId={chainId} pairInfo={pairInfo} />
          </div>
        </div>
      </div>
      <div className="p-2 border-[1px] border-panelborder">
        <PositionTab chainId={chainId} />
      </div>
    </div>
  )
}
