import React, { useCallback, useRef, useState } from 'react'
import { PairInfo } from '../../../constants'
import {
  AccentButton,
  AmountForm,
  BaseModal,
  CancelButton,
  LoadingIndicator,
  PrimaryButton,
  Slider
} from '../../common'
import { GammaPnLChart } from '../GammaPnLChart'
import { toScaled, toUnscaled } from '../../../utils/bn'
import { useAvbl } from '../../../hooks/perp/useAvbl'
import { Address } from 'viem'
import { AiFillEdit } from 'react-icons/ai'
import { useGammaOrder } from '../../../hooks/gamma/useGammaOrder'
import { useSendGammaOrder } from '../../../hooks/gamma/useSendGammaOrder'
import { useGammaPosition } from '../../../hooks/gamma/useGammaPositions'
import { useQuoteGammaTrade } from '../../../hooks/gamma/useQuoteGammaTrade'
import dayjs from 'dayjs'
import { StrongTitle, Title } from '../common/Title'
import { getPriceTrigger } from '../../../utils/trigger'
import { DurationComponent } from '../common/DurationComponent'
import { getStrategyName } from '../../../utils/gamma'

interface Props {
  chainId: number
  pairInfo: PairInfo
  price: number
  trader: Address
  templateId: number
  positionId: number
  onCancel?: () => void
}

export const CloseAfter = ({ expiration }: { expiration: number }) => {
  const closeTime = dayjs(expiration * 1000)
  const now = dayjs()

  const closeIn = closeTime.diff(now)

  return <DurationComponent time={closeIn} />
}

export const NextHedge = ({
  lastHedgeTime,
  hedgeInterval
}: {
  lastHedgeTime: number
  hedgeInterval: number
}) => {
  const nextHedgeTime = dayjs((lastHedgeTime + hedgeInterval) * 1000)
  const now = dayjs()

  const nextHedgeIn = nextHedgeTime.diff(now)

  return <DurationComponent time={nextHedgeIn} />
}

export const GammaModifyForm = ({
  chainId,
  pairInfo,
  price,
  trader,
  positionId,
  onCancel
}: Props) => {
  const animatedTextRef = useRef<HTMLDivElement | null>(null)

  const gammaPosition = useGammaPosition(
    chainId,
    trader,
    pairInfo,
    BigInt(positionId)
  )

  if (gammaPosition == null) {
    return <LoadingIndicator />
  }

  const historyItem = gammaPosition.historyItem
  const totalDepositBn = historyItem
    ? historyItem.totalDeposit - historyItem.totalWithdraw
    : gammaPosition.vault.margin

  const valueBn = gammaPosition.valueBn

  const value = valueBn ? toUnscaled(valueBn, 6, 2) : undefined

  const pnl = valueBn ? toUnscaled(valueBn - totalDepositBn, 6, 2) : undefined

  const totalDeposit = toUnscaled(totalDepositBn, 6, 2)

  const lastHedgePrice = gammaPosition.lastHedgePrice

  const priceTrigger =
    gammaPosition.userPosition.sqrtPriceTrigger > 0n
      ? getPriceTrigger(Number(gammaPosition.userPosition.sqrtPriceTrigger))
      : 0

  return (
    <div className="space-y-3 animate-slide-up" ref={animatedTextRef}>
      <div className="p-2 space-y-3 bg-panel border-panelborder border-[1px] rounded">
        <div className="flex justify-between items-center space-x-2">
          <div className="flex items-center space-x-1">
            <StrongTitle>
              {getStrategyName({
                leverage: gammaPosition.userPosition.leverage
              })}
            </StrongTitle>
            <div className="flex items-center text-xs text-fourth">
              ETH/USDC
            </div>
            <div className="p-1 bg-secondary rounded flex items-center text-xs">
              {gammaPosition.userPosition.leverage}x
            </div>
          </div>
        </div>

        <div className="flex justify-between space-x-2">
          <div className="space-y-1">
            <div className="flex justify-start text-xs text-white5 font-sm underline decoration-dotted">
              Value
            </div>
            <div className="flex items-center text-sm">
              <div>{value} USDC</div>
              <ModifyAmountForm
                chainId={chainId}
                pairInfo={pairInfo}
                trader={trader}
                initialAmount={Math.floor(
                  toUnscaled(
                    gammaPosition.vaultStatus.vaultValue,
                    pairInfo.quote.decimals
                  )
                )}
                quantity={gammaPosition.vault.openPosition.perp.amount}
                quantitySqrt={gammaPosition.vault.openPosition.sqrtPerp.amount}
                leverage={gammaPosition.userPosition.leverage}
                price={price}
                positionId={BigInt(positionId)}
              />
            </div>
          </div>
          <div className="space-y-1">
            <div className="flex justify-end text-xs text-white5 font-sm underline decoration-dotted">
              PNL(ROI)
            </div>
            <div className="flex items-center text-sm">
              {pnl ? (
                <div className={pnl > 0 ? 'text-high-green' : 'text-high-red'}>
                  {pnl > 0 ? '+' : '-'}
                  {Math.abs(pnl).toFixed(pairInfo.quote.precision)} USDC (
                  {pnl > 0 ? '+' : '-'}
                  {Math.abs((pnl * 100) / totalDeposit).toFixed(2)}%)
                </div>
              ) : (
                <LoadingIndicator />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="p-2 space-y-3 bg-panel border-panelborder border-[1px] rounded">
        <div>
          <div className="mb-2 ">
            <Title>Auto Close</Title>
          </div>
          <div className="flex justify-between space-x-2 text-xs">
            <div className="text-white5">Close after</div>
            <CloseAfter
              expiration={Number(gammaPosition.userPosition.expiration)}
            />
          </div>
        </div>

        {gammaPosition.userPosition.hedgeInterval > 0 && priceTrigger > 0 ? (
          <div>
            <div className="mb-2">
              <Title>Auto Delta Hedge</Title>
            </div>

            <div className="flex justify-between space-x-2 text-xs">
              <div className="text-white5">Next hedge in</div>
              <NextHedge
                lastHedgeTime={Number(
                  gammaPosition.userPosition.lastHedgedTime
                )}
                hedgeInterval={Number(gammaPosition.userPosition.hedgeInterval)}
              />
            </div>

            <div className="flex justify-between space-x-2 text-xs">
              <div className="text-white5">Last hedge price</div>
              <div className="text-xs text-white">
                {lastHedgePrice.toFixed(pairInfo.pricePrecision)}
              </div>
            </div>

            <div className="flex justify-between items-center space-x-2 text-xs">
              <div className="text-white5">Time Interval</div>
              <div className="flex">
                <div>
                  {Number(
                    gammaPosition.userPosition.hedgeInterval / (60n * 60n)
                  )}{' '}
                  H
                </div>
              </div>
            </div>

            <div className="flex justify-between space-x-2 text-xs">
              <div className="text-white5">Price trigger</div>
              <div>{priceTrigger.toFixed(3)}%</div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      {gammaPosition.position ? (
        <div className="p-2 w-full h-[340px] bg-panel border-panelborder border-[1px] rounded">
          <div className="mb-2">
            <StrongTitle>PnL Simulation</StrongTitle>
          </div>
          <div className="w-full h-full">
            <GammaPnLChart
              pair={pairInfo}
              price={price}
              position={gammaPosition.position}
              lastPrice={lastHedgePrice}
              priceTrigger={priceTrigger}
            />
          </div>
        </div>
      ) : (
        <LoadingIndicator />
      )}

      <TradeButtonArea
        chainId={chainId}
        pairInfo={pairInfo}
        trader={trader}
        price={price}
        positionId={gammaPosition.userPosition.vaultId}
        quantity={-gammaPosition.vault.openPosition.perp.amount}
        quantitySqrt={-gammaPosition.vault.openPosition.sqrtPerp.amount}
        leverage={gammaPosition.userPosition.leverage}
        onSuccess={onCancel}
        onCancel={() => {
          if (animatedTextRef.current) {
            animatedTextRef.current.classList.remove('animate-slide-up')
            animatedTextRef.current.classList.add('animate-slide-down')
          }
          setTimeout(() => {
            if (onCancel) onCancel()
          }, 100)
        }}
      />
    </div>
  )
}

const TradeButtonArea = ({
  chainId,
  pairInfo,
  trader,
  price,
  positionId,
  quantity,
  quantitySqrt,
  leverage,
  onSuccess,
  onCancel
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  price: number
  positionId: bigint
  quantity: bigint
  quantitySqrt: bigint
  leverage: number
  onSuccess?: () => void
  onCancel?: () => void
}) => {
  const order = useGammaOrder(
    chainId,
    pairInfo,
    trader,
    quantity,
    quantitySqrt,
    0n,
    leverage,
    price,
    {
      positionId,
      isEnabled: false,
      duration: 0
    }
  )
  const quote = useQuoteGammaTrade(chainId, order)

  const routeIndex = quote.data ? quote.data.routeIndex : -1

  const { signTypedData, variables, error } = useSendGammaOrder(
    order,
    routeIndex,
    error => {
      if (error === null && onSuccess) onSuccess()
    }
  )

  const closePositionHandler = useCallback(() => {
    signTypedData({
      domain: variables.domain,
      types: variables.types,
      message: variables.message,
      primaryType: 'PermitWitnessTransferFrom'
    })
  }, [signTypedData, variables])

  return (
    <div className="z-10 sticky bottom-6 left-0 p-2 bg-background border-panelborder border-[1px]">
      {error ? <div className="pb-1 text-red text-xs">{error}</div> : <></>}
      <div className="h-10 flex space-x-2">
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <AccentButton
          size="lg"
          disabled={routeIndex < 0}
          onClick={closePositionHandler}
        >
          Close Position
        </AccentButton>
      </div>
    </div>
  )
}

const ModifyAmountForm = ({
  chainId,
  pairInfo,
  trader,
  initialAmount,
  quantity,
  quantitySqrt,
  leverage,
  price,
  positionId
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  initialAmount: number
  quantity: bigint
  quantitySqrt: bigint
  leverage: number
  price: number
  positionId: bigint
}) => {
  const available = useAvbl(chainId, pairInfo, trader)

  const [isOpen, setIsOpen] = useState(false)
  const [amount, setAmount] = useState(initialAmount)

  const updateAmount = Math.floor(amount - initialAmount)

  const order = useGammaOrder(
    chainId,
    pairInfo,
    trader,
    (quantity * BigInt(updateAmount)) / BigInt(initialAmount),
    (quantitySqrt * BigInt(updateAmount)) / BigInt(initialAmount),
    toScaled(updateAmount, pairInfo.quote.decimals),
    leverage,
    price,
    {
      positionId,
      isEnabled: false
    }
  )
  const quote = useQuoteGammaTrade(chainId, order)

  const routeIndex = quote.data ? quote.data.routeIndex : -1

  const { signTypedData, variables } = useSendGammaOrder(
    order,
    routeIndex,
    () => {}
  )

  const modifyPositionHandler = useCallback(() => {
    console.log(1, signTypedData)
    signTypedData({
      domain: variables.domain,
      types: variables.types,
      message: variables.message,
      primaryType: 'PermitWitnessTransferFrom'
    })
  }, [signTypedData, variables])

  const max = initialAmount * 2

  return (
    <div className="space-y-2">
      <AiFillEdit className="cursor-pointer" onClick={() => setIsOpen(true)} />
      <BaseModal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false)
        }}
        height={312}
      >
        <div className="flex flex-col space-y-6">
          <Title>Amount</Title>
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="text-xs text-white5">
                {toUnscaled(available || 0n, pairInfo.quote.decimals)} USDC
              </div>
              <AmountForm
                title="Amount"
                unit={pairInfo.quote.symbol}
                amount={amount}
                step={1}
                max={max}
                isAlert={amount < 10}
                alertText={amount < 10 ? 'Amount must be greater than 0' : ''}
                onChange={setAmount}
              />
            </div>
            <Slider
              value={amount}
              step={10}
              min={0}
              max={max}
              onValueChanged={setAmount}
            />
          </div>
          <div className="space-y-2">
            <div className="flex justify-between">
              <div className="text-xs text-fourth">After Margin</div>
              <div>
                {amount.toFixed(pairInfo.quote.precision)}{' '}
                {pairInfo.quote.symbol}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-xs text-fourth">
                {updateAmount > 0 ? 'Cost' : 'Receive'}
              </div>
              <div>
                {updateAmount.toFixed(pairInfo.quote.precision)}{' '}
                {pairInfo.quote.symbol}
              </div>
            </div>
          </div>
          <div className="h-10">
            <PrimaryButton
              disabled={amount <= 10 || routeIndex < 0}
              onClick={modifyPositionHandler}
            >
              Modify Position
            </PrimaryButton>
          </div>
        </div>
      </BaseModal>
    </div>
  )
}
