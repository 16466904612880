import { useMemo } from 'react'
import {
  Address,
  PerpOrderV3,
  PerpOrderV3Builder,
  AuctionParams
} from '@predy/js-sdk'
import { ADDRESS_MAP, PairInfo } from '../../constants'
import { useDeadline } from '../useBlockTimestamp'
import { applySlippage } from '../../utils/price'
import { usePermit2Nonce } from '../query/usePermit2Nonce'
import { usePerpPosition } from './usePosition'
import { calculateRequiredMargin } from '../../utils/im'
import { max } from '../../utils/bn'

function getAuctionParams(isLong: boolean, price: bigint, slippage: number) {
  return new AuctionParams(
    applySlippage(isLong, price, slippage),
    applySlippage(isLong, price, slippage),
    0n,
    0n
  )
}

function getAuctionParamsForStopOrder() {
  // 0.27% - 0.58% (0-1%)
  return new AuctionParams(1002700n, 1005800n, 0n, 10000n)
}

function createOrderBuilder(chainId: number) {
  return new PerpOrderV3Builder(chainId).market(ADDRESS_MAP[chainId].PerpMarket)
}

export function usePerpOrder(
  chainId: number,
  pairInfo: PairInfo,
  trader: Address,
  isLong: boolean,
  amount: bigint,
  leverage: number,
  {
    price,
    slippage,
    limitPrice = 0n,
    stopPrice = 0n,
    reduceOnly = false,
    closePosition = false
  }: {
    price?: bigint
    slippage?: number
    limitPrice?: bigint
    stopPrice?: bigint
    reduceOnly?: boolean
    closePosition?: boolean
  }
) {
  const deadline = useDeadline(limitPrice > 0n || stopPrice > 0n)
  const nonce = usePermit2Nonce(
    chainId,
    trader,
    ADDRESS_MAP[chainId].PerpMarket
  )

  const position = usePerpPosition(
    chainId,
    trader,
    BigInt(pairInfo.pairId || 0)
  )

  return useMemo(() => {
    let finalDeadline = 0n

    if (position.isSuccess && nonce.isSuccess) {
      finalDeadline = deadline
    }

    let requiredMargin = 0n

    if (position) {
      requiredMargin = max(
        [price, limitPrice, stopPrice].map(p => {
          return calculateRequiredMargin(
            position.data.perpAmount,
            position.data.entryValue,
            amount * (isLong ? 1n : -1n),
            p || 0n,
            position.data.unrealizedFee.feeAmountQuote,
            position.data.unrealizedFee.feeAmountBase,
            position.data.marginBn,
            leverage
          )
        })
      )
    }

    const marginAmount = closePosition || reduceOnly ? 0n : requiredMargin

    return createOrderBuilder(chainId)
      .trader(trader)
      .nonce(nonce.data || 0n)
      .deadline(finalDeadline)
      .pairId(BigInt(pairInfo.pairId || 0))
      .entryTokenAddress(pairInfo.quote.address)
      .side(isLong ? 'Buy' : 'Sell')
      .quantity(amount)
      .marginAmount(marginAmount > 0n ? marginAmount : 0n)
      .leverage(leverage)
      .limitPrice(limitPrice)
      .stopPrice(stopPrice)
      .closePosition(closePosition)
      .reduceOnly(reduceOnly)
      .auctionData(
        stopPrice > 0n
          ? getAuctionParamsForStopOrder().serialize()
          : price && slippage && limitPrice === 0n
          ? getAuctionParams(isLong, price, slippage).serialize()
          : '0x'
      )
      .build()
  }, [
    position,
    chainId,
    pairInfo,
    deadline,
    isLong,
    amount,
    slippage,
    nonce,
    trader,
    price,
    leverage,
    limitPrice,
    stopPrice,
    reduceOnly,
    closePosition
  ])
}

export function usePerpOrderWithSlippage(
  order: PerpOrderV3,
  estPrice: bigint,
  slippage: number
) {
  return useMemo(() => {
    const isLong = order.perpOrder.side === 'Buy'

    if (order.perpOrder.limitPrice == 0n && order.perpOrder.stopPrice == 0n) {
      return new PerpOrderV3Builder(order.chainId)
        .market(order.perpOrder.info.market)
        .trader(order.perpOrder.info.trader)
        .nonce(order.perpOrder.info.nonce)
        .deadline(order.perpOrder.info.deadline)
        .pairId(order.perpOrder.pairId)
        .entryTokenAddress(order.perpOrder.entryTokenAddress)
        .side(order.perpOrder.side)
        .quantity(order.perpOrder.quantity)
        .marginAmount(order.perpOrder.marginAmount)
        .leverage(order.perpOrder.leverage)
        .limitPrice(0n)
        .stopPrice(0n)
        .closePosition(order.perpOrder.closePosition)
        .reduceOnly(order.perpOrder.reduceOnly)
        .auctionData(getAuctionParams(isLong, estPrice, slippage).serialize())
        .build()
    } else {
      return order
    }
  }, [order, estPrice, slippage])
}

export function useModifiedPerpOrder(
  order: PerpOrderV3,
  quantity: bigint,
  limitPrice: bigint,
  stopPrice: bigint
) {
  const position = usePerpPosition(
    order.chainId,
    order.perpOrder.info.trader,
    order.perpOrder.pairId
  )

  return useMemo(() => {
    const requiredMargin = max(
      [limitPrice, stopPrice].map(p => {
        return calculateRequiredMargin(
          position.data.perpAmount,
          position.data.entryValue,
          quantity * (order.perpOrder.side === 'Buy' ? 1n : -1n),
          p || 0n,
          position.data.unrealizedFee.feeAmountQuote,
          position.data.unrealizedFee.feeAmountBase,
          position.data.marginBn,
          order.perpOrder.leverage
        )
      })
    )

    const marginAmount =
      order.perpOrder.closePosition || order.perpOrder.reduceOnly
        ? 0n
        : requiredMargin

    const params = {
      ...order.perpOrder,
      quantity,
      limitPrice,
      stopPrice,
      marginAmount
    }

    return new PerpOrderV3(params, order.chainId)
  }, [order, quantity, limitPrice, stopPrice, position.data])
}
