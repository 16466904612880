export function getSqrtPriceTrigger(priceTrigger: number) {
  const sqrtPriceTrigger = priceTrigger
    ? Math.floor(Math.sqrt(100 + priceTrigger) * 100000)
    : 0

  return sqrtPriceTrigger
}

export const getPriceTrigger = (sqrtPriceTrigger: number) => {
  const sqrtPriceTrigger2 = sqrtPriceTrigger / 100000

  return sqrtPriceTrigger2 * sqrtPriceTrigger2 - 100
}
