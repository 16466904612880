export function applySlippage(
  isLong: boolean,
  price: bigint,
  slippage: number
) {
  if (isLong) {
    return (price * (10000n + BigInt(slippage))) / 10000n
  } else {
    return (price * 10000n) / (10000n + BigInt(slippage))
  }
}
