import { FILLER_API_ENDPOINT } from '../constants'

export async function apiGet(path: string, query: URLSearchParams) {
  const res = await fetch(`${FILLER_API_ENDPOINT}${path}?${query}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return await res.json()
}
