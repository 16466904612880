import { useMemo } from 'react'
import { Address } from 'viem'
import { AuctionParams, SpotOrderV3, SpotOrderV3Builder } from '@predy/js-sdk'
import { ADDRESS_MAP } from '../../constants/addresses'
import { PairInfo } from '../../constants/assets'
import { useDeadline } from '../useBlockTimestamp'
import { applySlippage } from '../../utils/price'
import { usePermit2Nonce } from '../query/usePermit2Nonce'

function getAuctionParams(endAmount: bigint) {
  return new AuctionParams(endAmount, endAmount, 0n, 0n)
}

export function useSpotMarketOrder(
  chainId: number,
  pairInfo: PairInfo,
  trader: Address,
  isLong: boolean,
  amount: bigint,
  quoteAmount: bigint,
  slippage: number,
  isBaseAssetQty: boolean
) {
  const deadline = useDeadline(false)
  const nonce = usePermit2Nonce(
    chainId,
    trader,
    ADDRESS_MAP[chainId].SpotMarket
  )

  return useMemo(() => {
    if (nonce.data) {
      const baseAmount =
        amount * (isLong ? 1n : -1n) * (isBaseAssetQty ? 1n : -1n)

      const isBuyBase = isBaseAssetQty ? isLong : !isLong

      const endAmount = applySlippage(isBuyBase, quoteAmount, slippage)

      const maxQuoteAmount = endAmount

      return new SpotOrderV3Builder(chainId)
        .market(ADDRESS_MAP[chainId].SpotMarket)
        .trader(trader)
        .nonce(nonce.data)
        .deadline(deadline)
        .baseToken(
          isBaseAssetQty ? pairInfo.base.address : pairInfo.quote.address
        )
        .quoteToken(
          isBaseAssetQty ? pairInfo.quote.address : pairInfo.base.address
        )
        .baseTokenAmount(baseAmount)
        .quoteTokenAmount(maxQuoteAmount)
        .limitQuoteTokenAmount(0n)
        .auctionData(getAuctionParams(endAmount).serialize())
        .build()
    } else {
      return null
    }
  }, [
    chainId,
    pairInfo,
    deadline,
    isLong,
    amount,
    quoteAmount,
    nonce,
    slippage,
    trader,
    isBaseAssetQty
  ])
}

export function useSpotLimitOrder(
  chainId: number,
  pairInfo: PairInfo,
  trader: Address,
  isLong: boolean,
  amount: bigint,
  limitQuoteTokenAmount: bigint
) {
  const deadline = useDeadline(true)
  const nonce = usePermit2Nonce(
    chainId,
    trader,
    ADDRESS_MAP[chainId].SpotMarket
  )

  return useMemo(() => {
    if (nonce.data) {
      const baseAmount = amount * (isLong ? 1n : -1n)

      return new SpotOrderV3Builder(chainId)
        .market(ADDRESS_MAP[chainId].SpotMarket)
        .trader(trader)
        .nonce(nonce.data)
        .deadline(deadline)
        .baseToken(pairInfo.base.address)
        .quoteToken(pairInfo.quote.address)
        .baseTokenAmount(baseAmount)
        .quoteTokenAmount(limitQuoteTokenAmount)
        .limitQuoteTokenAmount(limitQuoteTokenAmount)
        .auctionData('0x')
        .build()
    } else {
      return null
    }
  }, [
    chainId,
    pairInfo,
    deadline,
    isLong,
    amount,
    nonce,
    trader,
    limitQuoteTokenAmount
  ])
}

export function useSpotOrderWithSlippage(
  order: SpotOrderV3,
  quoteTokenAmount: bigint,
  slippage: number
) {
  return useMemo(() => {
    const isBuy = order.spotOrder.baseTokenAmount > 0n

    if (order.spotOrder.limitQuoteTokenAmount > 0n) {
      return order
    }

    const params = {
      ...order.spotOrder,
      auctionData: getAuctionParams(
        applySlippage(isBuy, quoteTokenAmount, slippage)
      ).serialize()
    }

    return new SpotOrderV3(params, order.chainId)
  }, [order, quoteTokenAmount, slippage])
}

export function useSpotOrderWithLimitPrice(
  order: SpotOrderV3,
  baseTokenAmount: bigint,
  quoteTokenAmount: bigint
) {
  return useMemo(() => {
    const params = {
      ...order.spotOrder,
      baseTokenAmount: baseTokenAmount,
      quoteTokenAmount: quoteTokenAmount,
      limitQuoteTokenAmount: quoteTokenAmount
    }

    return new SpotOrderV3(params, order.chainId)
  }, [order, baseTokenAmount, quoteTokenAmount])
}
