import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
  LENDING_HISTORY_ITEM_QUERY,
  LendingUserHistoryItems
} from '../../queries/lendingHistoryItemQuery'
import { toUnscaled } from '../../utils/bn'
import { PredyClient } from '../../utils/apollo-client'

export type LendingHistoryItem = {
  id: string
  address: string
  assetId: number
  symbol: string
  isStable: boolean
  account: string
  action: string
  quantity: number
  timestamp: number
}

export const NUM_ONE_PAGE_ITEMS = 500

export function useLendingHistory(chainId: number, account: string) {
  const [skip, setSkip] = useState(0)
  const [lendingHistory, setLendingHistory] = useState<LendingHistoryItem[]>([])

  const { data } = useQuery<LendingUserHistoryItems>(
    LENDING_HISTORY_ITEM_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        owner: account.toLowerCase(),
        skip: skip,
        first: NUM_ONE_PAGE_ITEMS
      },
      pollInterval: 30000,
      client: PredyClient[chainId]
    }
  )

  useEffect(() => {
    if (data) {
      const newItems = data.lendingUserHistoryItems.map(entity => {
        const assetAmount = BigInt(entity.assetAmount)

        return {
          id: entity.id,
          address: entity.address.toLowerCase(),
          assetId: Number(entity.pairId),
          isStable: entity.isStable,
          symbol: entity.isStable ? 'USDC' : 'WETH',
          account: entity.account,
          action: entity.action === 'DEPOSIT' ? 'Supply' : 'Withdraw',
          quantity: toUnscaled(assetAmount, entity.isStable ? 6 : 18, 2),
          timestamp: Number(entity.createdAt)
        }
      })

      setLendingHistory(history => {
        return history
          .concat(newItems)
          .map(item => Object.assign({}, item))
          .sort((a, b) => b.timestamp - a.timestamp)
          .filter(val => val.account === account.toLowerCase())
          .filter(
            (val, ind, self) =>
              ind === self.findIndex(item => item.id === val.id)
          )
      })
    }
  }, [chainId, account, data])

  return {
    lendingHistory,
    skip,
    setSkip
  }
}
