import React from 'react'
import { StrongTitle } from './common/Title'
import { CloseAfter, NextHedge } from './sm/ModifyForm'
import { getStrategyName } from '../../utils/gamma'
import { PairInfo } from '../../constants'
import { AccentStrokeButton, LoadingIndicator } from '../common'
import { Address } from 'viem'

interface Props {
  positionId: bigint
  chainId: number
  pairInfo: PairInfo
  trader: Address
  leverage: number
  price: number
  value?: number
  pnl?: number
  totalDeposit: number
  expiration: number
  lastHedgePrice: number
  lastHedgeTime: number
  hedgeInterval: number
  isAutoHedge: boolean
  onClick: () => void
}

export const PositionCardSM = ({
  pairInfo,
  leverage,
  value,
  pnl,
  totalDeposit,
  expiration,
  lastHedgePrice,
  lastHedgeTime,
  hedgeInterval,
  isAutoHedge,
  onClick
}: Props) => {
  const isPlus = pnl ? pnl >= 0 : true

  return (
    <div className="p-2 bg-panel border-panelborder border-[1px] space-y-3 rounded">
      <div className="flex justify-start items-center space-x-2">
        <div className="flex space-x-1">
          <StrongTitle>
            {getStrategyName({
              leverage
            })}
          </StrongTitle>
        </div>
        <div className="flex space-x-1">
          <div className="text-xs text-fourth">
            {pairInfo.base.symbol}/{pairInfo.quote.symbol}
          </div>
          <div className="px-1 bg-secondary rounded flex items-center text-xs">
            {leverage}x
          </div>
        </div>
      </div>

      <div className="flex justify-between space-x-2">
        <div className="space-y-1">
          <div className="flex justify-start text-xs text-white5 font-sm underline decoration-dotted">
            Value
          </div>
          {value !== undefined ? (
            <div className="text-base">{value} USDC</div>
          ) : (
            <LoadingIndicator />
          )}
        </div>
        <div className="space-y-1">
          <div className="flex justify-end text-xs text-white5 font-sm underline decoration-dotted">
            PNL(ROI)
          </div>
          {pnl ? (
            <div
              className={`text-base ${
                pnl
                  ? pnl > 0
                    ? 'text-high-green'
                    : 'text-high-red'
                  : 'text-white5'
              }`}
            >
              {isPlus ? '+' : '-'}
              {Math.abs(pnl)} USDC ({isPlus ? '+' : '-'}
              {Math.abs((pnl * 100) / totalDeposit).toFixed(2)}%)
            </div>
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>

      <div className="flex justify-between space-x-2">
        <div className="space-y-1">
          <div className="flex justify-start text-xs text-white5 font-sm underline decoration-dotted">
            Last hedge price
          </div>
          <div className="text-xs text-fourth">
            {isAutoHedge
              ? lastHedgePrice.toFixed(pairInfo.pricePrecision)
              : '-'}
          </div>
        </div>
        <div className="space-y-1">
          <div className="flex justify-start text-xs text-white5 font-sm underline decoration-dotted">
            Next hedge in
          </div>
          <div className="text-fourth">
            {isAutoHedge ? (
              <NextHedge
                lastHedgeTime={lastHedgeTime}
                hedgeInterval={hedgeInterval}
              />
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className="space-y-1">
          <div className="flex justify-end text-xs text-white5 font-sm underline decoration-dotted">
            Close in
          </div>
          <div className="text-fourth">
            {expiration === 0 ? '-' : <CloseAfter expiration={expiration} />}
          </div>
        </div>
      </div>

      <div className="md:basis-1/3 h-9 flex justify-between space-x-4">
        <AccentStrokeButton onClick={onClick}>More Detail</AccentStrokeButton>
      </div>
    </div>
  )
}

export const PositionCardPC = ({
  pairInfo,
  leverage,
  value,
  pnl,
  totalDeposit,
  expiration,
  lastHedgePrice,
  lastHedgeTime,
  hedgeInterval,
  isAutoHedge,
  onClick
}: Props) => {
  const isPlus = pnl ? pnl >= 0 : true

  return (
    <tr>
      <td className="p-3 flex flex-col justify-start items-start space-y-1">
        <div className="flex justify-start space-x-1">
          <StrongTitle>
            {getStrategyName({
              leverage
            })}
          </StrongTitle>
        </div>
        <div className="flex space-x-1">
          <div className="text-xs text-fourth">
            {pairInfo.base.symbol}/{pairInfo.quote.symbol}
          </div>
          <div className="px-1 bg-secondary rounded flex items-center text-xs">
            {leverage}x
          </div>
        </div>
      </td>

      <td className="p-2 text-end align-middle">
        {value !== undefined ? (
          <div className="text-sm">{value} USDC</div>
        ) : (
          <LoadingIndicator />
        )}
      </td>
      <td className="p-2 text-end align-middle">
        {pnl ? (
          <div
            className={`text-sm ${
              pnl
                ? pnl > 0
                  ? 'text-high-green'
                  : 'text-high-red'
                : 'text-white5'
            }`}
          >
            {isPlus ? '+' : '-'}
            {Math.abs(pnl)} USDC ({isPlus ? '+' : '-'}
            {Math.abs((pnl * 100) / totalDeposit).toFixed(2)}%)
          </div>
        ) : (
          <LoadingIndicator />
        )}
      </td>
      <td className="p-2 text-end align-middle">
        <div className="text-xs text-fourth">
          {isAutoHedge ? lastHedgePrice.toFixed(pairInfo.pricePrecision) : '-'}
        </div>
      </td>

      <td className="p-2 text-end align-middle text-fourth">
        {isAutoHedge ? (
          <NextHedge
            lastHedgeTime={lastHedgeTime}
            hedgeInterval={hedgeInterval}
          />
        ) : (
          '-'
        )}
      </td>
      <td className="p-2 text-end align-middle text-fourth">
        {expiration === 0 ? '-' : <CloseAfter expiration={expiration} />}
      </td>

      <td className="px-2 align-middle">
        <div className="w-32 h-9 flex justify-between space-x-4">
          <AccentStrokeButton onClick={onClick}>More Detail</AccentStrokeButton>
        </div>
      </td>
    </tr>
  )
}
