import { useReadContracts } from 'wagmi'
import { PredyPoolABI } from '@predy/js-sdk'
import { ADDRESS_MAP } from '../../constants/addresses'

export interface PriceResult {
  price: bigint
  sqrtPrice: bigint
  oraclePrice: bigint
  sqrtIndexPrice: bigint
}

export function usePrice(chainId: number, pairId: number) {
  const result = useReadContracts({
    query: {
      refetchInterval: 30000
    },
    contracts: [
      {
        address: ADDRESS_MAP[chainId].PredyPool,
        abi: PredyPoolABI,
        functionName: 'getSqrtPrice',
        args: [BigInt(pairId)],
        chainId
      },
      {
        address: ADDRESS_MAP[chainId].PredyPool,
        abi: PredyPoolABI,
        functionName: 'getSqrtIndexPrice',
        args: [BigInt(pairId)],
        chainId
      }
    ]
  })

  if (result.data === undefined) {
    return {
      isSuccess: false
    }
  }

  const dataList = result.data

  if (dataList[0].result !== undefined && dataList[1].result !== undefined) {
    const sqrtPrice = dataList[0].result
    const sqrtIndexPrice = dataList[1].result

    const price = (sqrtPrice * sqrtPrice) / 2n ** 96n
    const indexPrice = (sqrtIndexPrice * sqrtIndexPrice) / 2n ** 96n

    return {
      isSuccess: true,
      data: {
        price,
        sqrtPrice,
        oraclePrice: indexPrice,
        sqrtIndexPrice
      } as PriceResult
    }
  }

  return {
    isSuccess: false
  }
}
