import React from 'react'
import { ADDRESS_MAP, DEFAULT_CHAIN, PairInfo, Q96 } from '../../../constants'
import { Address, isAddressEqual } from 'viem'
import { Direction } from './DirectionSelector'
import { LoadingIndicator, PrimaryButton } from '../../common'
import { SpotOrderV3 } from '@predy/js-sdk'
import { useBalanceQuery } from '../../../hooks/erc20/balance'
import { usePermitV1 } from '../../../hooks/contracts/usePermitV1'
import { useSpotSendOrder } from '../../../hooks/spot/useSpotSendOrder'
import { useQuoteSpotTrade } from '../../../hooks/spot/useQuoteSpotTrade'
import { abs, toUnscaled } from '../../../utils/bn'
import { toUnscaledPrice } from '../../../utils'
import { useSpotOrderWithSlippage } from '../../../hooks/spot/useSpotOrder'

export function TradeResult({
  chainId,
  pairInfo,
  trader,
  direction,
  maxAmount,
  order,
  isLimitOrder,
  disabled
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  direction: Direction
  maxAmount: number
  order: SpotOrderV3
  isLimitOrder: boolean
  disabled: boolean
}) {
  const quoteResult = useQuoteSpotTrade(chainId, order)

  const isReverse = isAddressEqual(
    order.spotOrder.baseToken,
    pairInfo.quote.address
  )

  const quoteTokenAmount = quoteResult.data ? quoteResult.data.quoteAmount : 0n

  const estPrice =
    order.spotOrder.baseTokenAmount !== 0n
      ? abs((quoteTokenAmount * Q96) / order.spotOrder.baseTokenAmount)
      : 0n

  const totalAmount = quoteResult.data?.quoteAmount || 0n

  return (
    <div className="space-y-3">
      <div className="flex justify-start space-x-2 text-xs">
        <div className="text-fourth">
          {direction === Direction.Long ? 'Max Buy' : 'Max Sell'}
        </div>
        <div>
          {maxAmount}{' '}
          {direction === Direction.Long
            ? pairInfo.base.symbol
            : pairInfo.quote.symbol}
        </div>
      </div>
      {isLimitOrder ? (
        <></>
      ) : (
        <>
          <div className="flex justify-start space-x-2 text-xs">
            <div className="text-fourth">Est. Price</div>
            <div>
              {estPrice > 0n
                ? toUnscaledPrice(
                    pairInfo,
                    isReverse ? (Q96 * Q96) / estPrice : estPrice
                  ) +
                  ' ' +
                  pairInfo.quote.symbol
                : ''}
            </div>
          </div>

          <div className="flex justify-start space-x-2 text-xs">
            <div className="text-fourth">Total Amount</div>
            <div>
              {totalAmount > 0n
                ? isReverse
                  ? toUnscaled(
                      totalAmount,
                      pairInfo.base.decimals,
                      pairInfo.base.precision
                    ) +
                    ' ' +
                    pairInfo.base.symbol
                  : toUnscaled(
                      totalAmount,
                      pairInfo.quote.decimals,
                      pairInfo.quote.precision
                    ) +
                    ' ' +
                    pairInfo.quote.symbol
                : ''}
            </div>
          </div>
        </>
      )}
      <div className="h-10">
        {quoteResult.data ? (
          <TradeButton
            chainId={chainId}
            pairInfo={pairInfo}
            trader={trader}
            direction={direction}
            order={order}
            routeIndex={quoteResult.data?.routeIndex}
            quoteTokenAmount={quoteTokenAmount}
            disabled={disabled}
          />
        ) : (
          <PrimaryButton
            type={direction === Direction.Long ? 'green' : 'red'}
            size="md"
            disabled={true}
          >
            {(direction === Direction.Long ? 'Buy' : 'Sell') +
              ' ' +
              pairInfo.base.symbol}
          </PrimaryButton>
        )}
      </div>
    </div>
  )
}

const TradeButton = ({
  chainId,
  pairInfo,
  trader,
  direction,
  order,
  routeIndex,
  quoteTokenAmount,
  disabled
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  direction: Direction
  order: SpotOrderV3
  routeIndex: number
  quoteTokenAmount: bigint
  disabled: boolean
}) => {
  const order2 = useSpotOrderWithSlippage(
    order,
    quoteTokenAmount,
    pairInfo.slippageTolerance
  )

  const isReverse = isAddressEqual(
    order.spotOrder.baseToken,
    pairInfo.quote.address
  )

  const requiredAmount =
    direction === Direction.Long
      ? isReverse
        ? -order2.spotOrder.baseTokenAmount
        : order2.spotOrder.quoteTokenAmount
      : isReverse
      ? order2.spotOrder.quoteTokenAmount
      : -order2.spotOrder.baseTokenAmount

  const allowance = useBalanceQuery(
    chainId,
    direction === Direction.Long
      ? pairInfo.quote.address
      : pairInfo.base.address,
    trader,
    ADDRESS_MAP[chainId].Permit2
  )
  const permitV1 = usePermitV1(
    chainId,
    trader,
    direction === Direction.Long
      ? pairInfo.quote.address
      : pairInfo.base.address,
    requiredAmount
  )
  const { signTypedData, error, isLoading, variables } = useSpotSendOrder(
    order2,
    routeIndex,
    () => {}
  )

  /*
  if (
    isAddressEqual(
      order2.spotOrder.validatorAddress,
      SPOT_DUTCH_ORDER_VALIDATOR_MAPPING[chainId]
    )
  ) {
    const a = SpotDutchOrderValidationData.deserialize(
      order2.spotOrder.validationData
    )


    console.log(
      a.startAmount,
      a.endAmount,
    )
  }
  */

  if (isLoading) {
    return (
      <PrimaryButton
        type={direction === Direction.Long ? 'green' : 'red'}
        disabled={true}
      >
        <LoadingIndicator />
      </PrimaryButton>
    )
  }

  return (
    <>
      <PrimaryButton
        type={direction === Direction.Long ? 'green' : 'red'}
        size="md"
        disabled={disabled}
        onClick={() => {
          if (allowance.data && allowance.data.isLoaded) {
            if (allowance.data.allowance >= requiredAmount) {
              signTypedData({
                domain: variables.domain,
                types: variables.types,
                message: variables.message,
                primaryType: 'PermitWitnessTransferFrom'
              })
            } else {
              permitV1.signTypedData({
                domain: permitV1.variables.domain,
                types: permitV1.variables.types,
                message: permitV1.variables.message,
                primaryType: 'Permit'
              })
            }
          }
        }}
      >
        {chainId === DEFAULT_CHAIN
          ? (
              allowance.data && allowance.data.isLoaded
                ? allowance.data.allowance >= requiredAmount
                : false
            )
            ? direction === Direction.Long
              ? `Buy ${pairInfo.base.symbol}`
              : `Sell ${pairInfo.base.symbol}`
            : direction === Direction.Long
            ? `Approve ${pairInfo.quote.symbol}`
            : `Approve ${pairInfo.base.symbol}`
          : 'Connect Wallet'}
      </PrimaryButton>
      {permitV1.error ? (
        <div className="mx-2 text-xs text-red">{permitV1.error}</div>
      ) : (
        <></>
      )}
      {error ? <div className="mx-2 text-xs text-red">{error}</div> : <></>}
    </>
  )
}
