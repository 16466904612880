import { useQuery } from '@apollo/client'
import {
  SPOT_HISTORY_QUERY,
  SpotHistoryItemEntities
} from '../../queries/spotHistoryQuery'
import { PredyClient } from '../../utils/apollo-client'
import { Address } from 'viem'
import { useMemo } from 'react'
import { baseTokenAddressToPair } from '../../utils/pairs'
import { abs, toUnscaled } from '../../utils/bn'

export function useSpotTradeHistory(chainId: number, trader: Address) {
  const { data, loading } = useQuery<SpotHistoryItemEntities>(
    SPOT_HISTORY_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        address: trader.toLowerCase()
      },
      client: PredyClient[chainId]
    }
  )

  const result = useMemo(() => {
    if (data) {
      return data.spotTradeHistoryItems
        .map(item => {
          const canonicalPair = baseTokenAddressToPair(
            chainId,
            item.baseToken as Address
          )
          const reversePair = baseTokenAddressToPair(
            chainId,
            item.quoteToken as Address
          )

          const pair = canonicalPair || reversePair

          if (pair === null) throw new Error('pair not found')

          const baseAmount = BigInt(item.baseAmount)
          const quoteAmount = BigInt(item.quoteAmount)

          const price = canonicalPair
            ? abs(
                (quoteAmount * 10n ** BigInt(pair.base.decimals)) / baseAmount
              )
            : abs(
                (baseAmount * 10n ** BigInt(pair.base.decimals)) / quoteAmount
              )

          const isBuy = !!canonicalPair === baseAmount > 0n

          const quantity = toUnscaled(
            canonicalPair ? abs(baseAmount) : abs(quoteAmount),
            pair.base.decimals,
            pair.base.precision
          )

          return {
            id: item.id,
            pair,
            trader: item.trader,
            baseAmount: baseAmount,
            baseToken: item.baseToken,
            isBuy,
            quoteAmount,
            quoteToken: item.quoteToken,
            price: toUnscaled(price, pair.quote.decimals, pair.pricePrecision),
            quantity,
            txHash: item.txHash,
            link: `https://arbiscan.io/tx/${item.txHash}`,
            createdAt: Number(item.createdAt),
            validatorAddress: item.validatorAddress
          }
        })
        .filter(item => item !== null)
    }

    return null
  }, [chainId, data])

  return {
    data: result,
    loading
  }
}
