import React, { useState } from 'react'
import {
  BaseModal,
  ModalTitle,
  HorizontalRule,
  PrimaryButton,
  Slider,
  AmountForm
} from '../common'

export const LeverageModal = ({
  leverage,
  setLeverage
}: {
  leverage: number
  setLeverage: (leverage: number) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)

  // The handler called when user try to close modal
  const onCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <div className="w-20 md:w-24 h-6 text-sm md:text-xs">
      <button
        className="flex justify-center items-center w-20 bg-white3 rounded px-2 py-1"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        {leverage}x
      </button>

      <BaseModal isOpen={isOpen} onRequestClose={onCloseModal} height={300}>
        <LeverageModalContent
          leverage={leverage}
          setLeverage={setLeverage}
          onCloseModal={onCloseModal}
        />
      </BaseModal>
    </div>
  )
}

const LeverageModalContent = ({
  leverage,
  setLeverage,
  onCloseModal
}: {
  leverage: number
  setLeverage: (leverage: number) => void
  onCloseModal: () => void
}) => {
  const [innerLeverage, setInnerLeverage] = useState(leverage)

  return (
    <div className="space-y-6">
      <ModalTitle>Adjust Leverage</ModalTitle>

      <HorizontalRule />

      <div className="mt-3 mb-8">
        <p className="my-4 text-xs">
          Adjust the leverage used when opening positions.
        </p>
        <div className="flex flex-col justify-between items-center space-y-3">
          <AmountForm
            title="Leverage"
            amount={innerLeverage}
            onChange={setInnerLeverage}
          />
          <Slider
            value={innerLeverage}
            min={1}
            max={40}
            step={1}
            onValueChanged={setInnerLeverage}
          />
        </div>
      </div>

      <div className="h-10">
        <PrimaryButton
          disabled={false}
          onClick={() => {
            setLeverage(innerLeverage)
            onCloseModal()
          }}
        >
          Confirm
        </PrimaryButton>
      </div>
    </div>
  )
}
