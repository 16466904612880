import React from 'react'
import discordIcon from '../../assets/links/discord.svg'
import twitterIcon from '../../assets/links/twitter.svg'
import mediumIcon from '../../assets/links/medium.svg'

const Footer = () => {
  return (
    <div className="fixed bottom-0 z-999 w-full p-1 bg-background border-t-[1px] border-panelborder">
      <ul className="flex flex-row justify-center items-center space-x-4">
        <li>
          <a href="https://discord.gg/predy" target="_blank" rel="noreferrer">
            <img width={20} src={discordIcon} alt={'Discord'} />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/predyfinance"
            target="_blank"
            rel="noreferrer"
          >
            <img width={20} src={twitterIcon} alt={'twitter'} />
          </a>
        </li>
        <li>
          <a
            href="https://predyfinance.medium.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img width={20} src={mediumIcon} alt={'Medium'} />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Footer
