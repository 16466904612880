import { GammaOrder } from '@predy/js-sdk'
import { useQuery } from '@tanstack/react-query'
import { DEFAULT_STALE_TIME } from '../../constants'
import { useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { apiGet } from '../../utils/fetch'

interface GammaQuote {
  // if long averagePrice is negative
  averagePrice: bigint
  fee: bigint
}

interface BestGammaQuote {
  quote: GammaQuote
  routeIndex: number
}

export function updateOrderAmount(
  order: GammaOrder,
  newQuantity: bigint,
  newQuantitySqrt: bigint
) {
  const witnessInfo = order.witnessInfo()

  witnessInfo.quantity = newQuantity
  witnessInfo.quantitySqrt = newQuantitySqrt

  return new GammaOrder(witnessInfo, order.chainId)
}

export function useQuoteGammaTrade(chainId: number, order: GammaOrder) {
  const currentQuantity = order.gammaOrder.quantity
  const currentQuantitySqrt = order.gammaOrder.quantitySqrt

  const [quantity, setQuantity] = useState(0n)
  const [quantitySqrt, setQuantitySqrt] = useState(0n)

  const [,] = useDebounce(
    () => {
      setQuantity(currentQuantity)
      setQuantitySqrt(currentQuantitySqrt)
    },
    500,
    [currentQuantity, currentQuantitySqrt]
  )

  const query = useQuery<
    { error: null; result: BestGammaQuote } | { error: string; result: null }
  >({
    queryKey: [
      'quote_gamma',
      chainId,
      order.gammaOrder.pairId.toString(),
      quantity.toString(),
      quantitySqrt.toString()
    ],
    queryFn: async () => {
      const query = new URLSearchParams({
        order: updateOrderAmount(order, quantity, quantitySqrt).serialize(),
        chainId: chainId.toString()
      })

      const result = await apiGet('order/v2/gamma/quote', query)

      if (result.message && result.message.length > 0) {
        return {
          error: result.message,
          result: null
        }
      }

      const quoteResult = deserializeGammaQuote(result)

      return {
        error: null,
        result: quoteResult
      }
    },
    enabled: quantity != 0n && quantitySqrt != 0n,
    // 20s
    refetchInterval: 60 * 1000,
    staleTime: DEFAULT_STALE_TIME
  })

  return useMemo(() => {
    if (query.data) {
      if (quantity === 0n && quantitySqrt === 0n) {
        return {
          isLoading: false,
          error: null,
          data: {
            routeIndex: -1,
            averagePrice: 0n
          }
        }
      }
      if (query.data.error || query.data.result === null) {
        return {
          isLoading: false,
          error: query.data.error,
          data: null
        }
      }

      const averagePrice = query.data
        ? BigInt(query.data.result.quote.averagePrice)
        : 0n

      return {
        isLoading: false,
        error: null,
        data: {
          routeIndex: query.data.result.routeIndex,
          averagePrice
        }
      }
    }

    return {
      isLoading: true,
      error: null,
      data: null
    }
  }, [query.data, quantity, quantitySqrt])
}

function deserializeGammaQuote(perpQuote: {
  quote: {
    averagePrice: string
    fee: string
  }
  routeIndex: number
}) {
  return {
    quote: {
      averagePrice: BigInt(perpQuote.quote.averagePrice),
      fee: BigInt(perpQuote.quote.fee)
    },
    routeIndex: perpQuote.routeIndex
  }
}
