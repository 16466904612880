import { useSimulateContract } from 'wagmi'
import { ADDRESS_MAP } from '../../constants/addresses'
import { PredyPoolABI } from '@predy/js-sdk'
import { Address, isAddressEqual, zeroAddress } from 'viem'

export function useAddPair(
  chainId: number,
  marginId: Address,
  uniswapPool: Address,
  poolOwner: Address,
  priceFeed: Address,
  whitelistEnabled: boolean,
  fee: number,
  assetRiskParams: {
    riskRatio: bigint
    debtRiskRatio: bigint
    rangeSize: number
    rebalanceThreshold: number
    minSlippage: bigint
    maxSlippage: bigint
  },
  baseIrmParams: {
    baseRate: bigint
    kinkRate: bigint
    slope1: bigint
    slope2: bigint
  },
  quoteIrmParams: {
    baseRate: bigint
    kinkRate: bigint
    slope1: bigint
    slope2: bigint
  }
) {
  return useSimulateContract({
    chainId,
    address: ADDRESS_MAP[chainId].PredyPool,
    abi: PredyPoolABI,
    functionName: 'registerPair',
    args: [
      {
        marginId,
        uniswapPool,
        poolOwner,
        priceFeed,
        whitelistEnabled,
        fee,
        assetRiskParams,
        baseIrmParams,
        quoteIrmParams
      }
    ],
    query: {
      enabled: !isAddressEqual(marginId, zeroAddress)
    }
  })
}
