import React from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export const DurationComponent = ({ time }: { time: number }) => {
  const duration = dayjs.duration(time >= 0 ? time : 0)

  const y = duration.years()
  const m = duration.months()
  const d = duration.days() + y * 365 + m * 30
  const h = duration.hours()
  const hours = Math.floor(h)
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  const dText = d === 0 ? '' : ` ${d} d `
  const sText = seconds === 0 ? '' : ` ${seconds} s`

  return (
    <div className="text-xs">
      {dText}
      {hours} h {minutes} m{sText}
    </div>
  )
}
