import { Address, isAddressEqual } from 'viem'
import { PAIR_INFOS } from '../constants'

export function pairTitleToPairId(title: string | null) {
  if (title === 'eth-usdce') {
    return 1
  } else if (title === 'eth-usdc') {
    return 2
  } else if (title === 'arb-usdc') {
    return 3
  } else if (title === 'wbtc-usdc') {
    return 4
  } else {
    return 1
  }
}

export function pairIdToSymbol(pairId: number | null) {
  if (pairId === 1) {
    return 'eth-usdce'
  } else if (pairId === 2) {
    return 'eth-usdc'
  } else if (pairId === 3) {
    return 'arb-usdc'
  } else if (pairId === 4) {
    return 'wbtc-usdc'
  } else {
    return 'eth-usdce'
  }
}

export function baseTokenAddressToPair(chainId: number, base: Address) {
  for (let i = 2; i <= 4; i++) {
    const symbol = pairIdToSymbol(i)
    if (isAddressEqual(PAIR_INFOS[chainId][symbol].base.address, base)) {
      return PAIR_INFOS[chainId][symbol]
    }
  }

  return null
}
