import React from 'react'
import { DEFAULT_CHAIN } from '../constants'
import { LendingTradeForm } from '../components/lending/LendingTradeForm'
import { useRoute } from 'wouter'

const LendingView = () => {
  const route = useRoute('/:chain/lending')

  console.log(route)

  return (
    <div className="flex justify-center flex-col md:flex-row">
      <LendingTradeForm chainId={DEFAULT_CHAIN} />
    </div>
  )
}

export default LendingView
