export enum Direction {
  Long,
  Short
}

export enum Action {
  TRADE = 'TRADE',
  DEPOSIT = 'DEPOSIT_MARGIN',
  WITHDRAW = 'WITHDRAW_MARGIN',
  LIQUIDATE = 'LIQUIDATE'
}

export enum Product {
  PERP = 'PERP',
  SQRT = 'SQRT'
}

export enum OrderType {
  MARKET = 'market',
  LIMIT = 'limit',
  STOP = 'stop',
  STOP_LIMIT = 'stop_limit',
  TPSL = 'tpsl'
}

export enum OrderStatus {
  NOT_ACTIVATED = 'not_activated',
  PENDING = 'pending',
  FILLED = 'filled',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  FAILED = 'failed'
}

export enum ChartRange {
  Hour,
  Day,
  Week,
  Month,
  Year
}

export enum MyPositionTab {
  Positions = 0,
  TradeHistory = 1
}

export enum PositionStyle {
  Bearish25,
  Neutral,
  Bullish25
}

export type TimeFilter = 'd' | 'w' | 'm' | 'all'
