import { ONE } from '../constants'

export type IRMParams = {
  baseRate: bigint
  kinkRate: bigint
  slope1: bigint
  slope2: bigint
}

export function calculateInterestRate(params: IRMParams, ur: bigint) {
  if (ur < 0n) {
    throw new Error('utilization ratio must be positive value.')
  }

  let ir = params.baseRate

  if (ur <= params.kinkRate) {
    ir = ir + (ur * params.slope1) / ONE
  } else {
    ir = ir + (params.kinkRate * params.slope1) / ONE
    ir = ir + (params.slope2 * (ur - params.kinkRate)) / ONE
  }

  return ir
}

const SQRT_KINK_UR = 10n ** 16n * 10n

/**
 * Calculates premium curve
 * 0 {ur <= 0.1}
 * 1.6 * (UR-0.1)^2 {0.1 < ur}
 * @param _utilization utilization ratio scaled by 1e18
 * @return spread parameter scaled by 1e3
 */
export function calculatePremiumCurve(ur: bigint) {
  if (ur <= SQRT_KINK_UR) {
    return 0n
  }

  const b = ur - SQRT_KINK_UR

  return (b * b * 1600n) / ONE / ONE
}
