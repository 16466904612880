import { useQuery } from '@tanstack/react-query'
import { sliceLastItems, trimArray } from '../../utils/arr'
import { useUniFeeGrowthHourly } from './useFeeGrowth'

const NUM_ITEMS = 24

// calculate 24h trade fee for token 0 and token 1
export function useUniswapTradeFee24H(
  chainId: number,
  contractAddress: string,
  trimmedMean: boolean
) {
  const uniFeeGrowth = useUniFeeGrowthHourly(chainId, contractAddress)

  return useQuery({
    queryKey: ['uniswap_trade_fee_24h', contractAddress, trimmedMean],
    queryFn: async () => {
      if (!uniFeeGrowth) throw new Error('uniFeeGrowth not set')

      const hourlyFees0 = convertToHourlyFee(
        uniFeeGrowth.map(i => i.feeGrowthGlobal0X128)
      )
      const hourlyFees1 = convertToHourlyFee(
        uniFeeGrowth.map(i => i.feeGrowthGlobal1X128)
      )

      if (trimmedMean) {
        return {
          fee0: calculateTrimmedMean(sliceLastItems(hourlyFees0, NUM_ITEMS)),
          fee1: calculateTrimmedMean(sliceLastItems(hourlyFees1, NUM_ITEMS))
        }
      } else {
        return {
          fee0: sumArray(sliceLastItems(hourlyFees0, NUM_ITEMS)),
          fee1: sumArray(sliceLastItems(hourlyFees1, NUM_ITEMS))
        }
      }
    },
    enabled: !!uniFeeGrowth
  })
}

function convertToHourlyFee(growthDataSet: bigint[]) {
  return growthDataSet.map((growthData, i) => {
    if (i === 0) {
      return 0n
    }
    return growthData - growthDataSet[i - 1]
  })
}

function calculateTrimmedMean(data: bigint[]) {
  if (data.length != NUM_ITEMS) {
    console.warn(`data length is not ${NUM_ITEMS}`)
  }

  /// copy
  const dataCopied = [...data]

  dataCopied.sort((a, b) => (a > b ? 1 : -1))

  return (
    (trimArray(dataCopied, 1).reduce((acc, item) => acc + item, 0n) * 24n) / 22n
  )
}

function sumArray(data: bigint[]) {
  if (data.length != NUM_ITEMS) {
    console.warn(`data length is not ${NUM_ITEMS}`)
  }

  /// copy
  const dataCopied = [...data]

  return dataCopied.reduce((acc, item) => acc + item, 0n)
}
