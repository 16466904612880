import { useWriteContract, useSimulateContract } from 'wagmi'
import { ADDRESS_MAP } from '../../constants/addresses'
import { PredyPoolABI } from '@predy/js-sdk'
import { Address } from 'viem'

export function useUpdateWhitelistAddress(
  chainId: number,
  pairId: number,
  trader: Address
) {
  const { data } = useSimulateContract({
    chainId,
    address: ADDRESS_MAP[chainId].PredyPool,
    abi: PredyPoolABI,
    functionName: 'updateWhitelistAddress',
    args: [BigInt(pairId), trader, true]
  })

  return {
    ...useWriteContract(),
    data
  }
}
