import { useBorrowingFee } from '../pool/useBorrowingFee'
import { useQuery } from '@tanstack/react-query'
import { PairInfo, Q96 } from '../../constants'
import { Address } from 'viem'
import { usePrice } from '../query/usePrice'
import { useAvbl } from './useAvbl'
import { mulLev } from '../../utils/im'
import { toScaledPrice } from '../../utils'
import { min } from '../../utils/bn'
import { usePerpPosition } from './usePosition'

function calculateMaxTradableSize(
  marginAmount: bigint,
  vaultValue: bigint,
  leverage: number,
  price: bigint
) {
  // K = 1 + leverage * 0.01
  // max_size = margin * leverage / (price * K)
  return (
    ((marginAmount + vaultValue) * BigInt(leverage) * Q96) /
    mulLev(price, leverage)
  )

  // margin := im * K - value
  // im := (size * price) / leverage
  // then
  // size = (margin + value) * leverage / K / price
}

function calculateMaxTradableSize2(
  marginAmount: bigint,
  vaultValue: bigint,
  leverage: number,
  price: bigint,
  limitPrice: bigint
) {
  const tradableFromOraclePrice = calculateMaxTradableSize(
    marginAmount,
    vaultValue,
    leverage,
    price
  )

  if (limitPrice === 0n) return tradableFromOraclePrice

  const tradableFromLimitPrice = calculateMaxTradableSize(
    marginAmount,
    vaultValue,
    leverage,
    limitPrice
  )

  return min([tradableFromOraclePrice, tradableFromLimitPrice])
}

export function useMaxTradable(
  chainId: number,
  pairInfo: PairInfo,
  trader: Address,
  currentPositionAmount: bigint,
  leverage: number,
  limitPrice: number = 0
) {
  const pairStatus = useBorrowingFee(chainId, pairInfo.pairId || 0)
  const balance = useAvbl(chainId, pairInfo, trader)
  const price = usePrice(chainId, pairInfo.pairId || 0)
  const perpPosition = usePerpPosition(
    chainId,
    trader,
    BigInt(pairInfo.pairId || 0)
  )

  return useQuery({
    queryKey: [
      'max-tradable',
      chainId,
      pairInfo.pairId,
      trader,
      leverage,
      limitPrice,
      balance?.toString()
    ],
    queryFn: () => {
      if (!pairStatus.isSuccess) throw new Error('pairStatus not loaded')
      if (balance === null) throw new Error('balance not loaded')
      if (!price.isSuccess || price.data === undefined)
        throw new Error('price not loaded')
      if (!perpPosition.isSuccess) throw new Error('perpPosition not loaded')

      const limitPriceX96 = toScaledPrice(pairInfo, limitPrice)

      const tradableAmountWithBalance = calculateMaxTradableSize2(
        balance,
        0n,
        leverage,
        price.data.price,
        limitPriceX96
      )
      const tradableAmountWithReverse = calculateMaxTradableSize2(
        balance,
        perpPosition.data.vaultValue,
        leverage,
        price.data.price,
        limitPriceX96
      )

      let maxBuy =
        currentPositionAmount >= 0n
          ? tradableAmountWithBalance
          : tradableAmountWithReverse - currentPositionAmount

      let maxSell =
        currentPositionAmount <= 0n
          ? tradableAmountWithBalance
          : tradableAmountWithReverse + currentPositionAmount

      if (pairStatus.data.availableBaseAmount < maxSell) {
        maxSell = pairStatus.data.availableBaseAmount
      }

      const availableBuyAmount =
        (pairStatus.data.availableQuoteAmount * Q96) / price.data.price

      if (availableBuyAmount < maxBuy) {
        maxBuy = availableBuyAmount
      }

      return {
        maxBuy,
        maxSell
      }
    },
    enabled:
      pairStatus.isSuccess &&
      balance !== null &&
      price.isSuccess &&
      perpPosition.isSuccess
  })
}
