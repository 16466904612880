import { Address, zeroAddress } from 'viem'
import { PairInfo } from '../../constants'
import { useBalanceQuery } from '../erc20/balance'
import { useQuoteAmountsInPerpOrders } from './usePerpOrders'

export function useAvbl(chainId: number, pairInfo: PairInfo, trader: Address) {
  const balance = useBalanceQuery(
    chainId,
    pairInfo.quote.address,
    trader,
    zeroAddress
  )
  const usedQuoteAmountInPerp = useQuoteAmountsInPerpOrders(chainId, trader)

  if (balance.data) {
    return balance.data.balance - usedQuoteAmountInPerp
  }

  return null
}
