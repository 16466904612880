import { Address, isAddressEqual, zeroAddress } from 'viem'
import { useSimulateContract } from 'wagmi'
import { GammaTradeMarketL2ABI, Position, Q96 } from '@predy/js-sdk'
import { ADDRESS_MAP, PairInfo } from '../../constants'
import { useMemo } from 'react'
import { useGammaPositionHistoryFromGraph } from './useGammaPositionHistory'
import { toUnscaledPrice } from '../../utils'
import { useRealtimePrice } from '../price/useRealtimePrice'

export function useMockGammaPositions(
  chainId: number,
  account: Address,
  pairInfo: PairInfo
) {
  console.log('useMockGammaPositions', chainId, account, pairInfo)
  return {
    data: [
      {
        userPosition: {
          vaultId: 0,
          leverage: 8,
          expiration: 0,
          hedgeInterval: 60 * 60,
          sqrtPriceTrigger: 200,
          lastHedgedTime: 100
        },
        vault: {
          openPosition: {
            perp: {
              entryValue: 0n,
              amount: 0n
            },
            sqrtPerp: {
              entryValue: 0n,
              amount: 0n
            }
          },
          margin: 0n
        },
        position: {
          underlying: 0n,
          stable: 0n,
          squart: 0n
        },
        historyItem: {
          totalDeposit: 0n,
          totalWithdraw: 0n
        },
        valueBn: 0n,
        lastHedgePrice: 1000
      },
      {
        userPosition: {
          vaultId: 0,
          leverage: 8,
          expiration: 0,
          hedgeInterval: 60 * 60,
          sqrtPriceTrigger: 200,
          lastHedgedTime: 100
        },
        vault: {
          openPosition: {
            perp: {
              entryValue: 0n,
              amount: 0n
            },
            sqrtPerp: {
              entryValue: 0n,
              amount: 0n
            }
          },
          margin: 0n
        },
        position: {
          underlying: 0n,
          stable: 0n,
          squart: 0n
        },
        historyItem: {
          totalDeposit: 0n,
          totalWithdraw: 0n
        },
        valueBn: 0n,
        lastHedgePrice: 1000
      }
    ]
  }
}

export function useGammaPositions(
  chainId: number,
  account: Address,
  pairInfo: PairInfo
) {
  const realtimePrice = useRealtimePrice(pairInfo)

  const result = useSimulateContract({
    address: ADDRESS_MAP[chainId].GammaTradeMarket,
    abi: GammaTradeMarketL2ABI,
    functionName: 'getUserPositions',
    args: [account],
    chainId,
    query: {
      refetchInterval: 30000,
      enabled:
        !!pairInfo.pairId &&
        pairInfo.pairId >= 1 &&
        !isAddressEqual(account, zeroAddress)
    }
  })

  const { data, loading } = useGammaPositionHistoryFromGraph(
    chainId,
    account,
    'all'
  )

  const resultWithHistoryItem = useMemo(() => {
    if (result.data === undefined) {
      return null
    }

    return result.data.result.map(item => {
      const vaultId = item.userPosition.vaultId

      if (data && realtimePrice && realtimePrice.oraclePrice) {
        const historyItem = data.find(item2 => item2.positionId === vaultId)

        const openPosition = item.vault.openPosition

        const position = new Position(
          openPosition.perp.entryValue +
            openPosition.sqrtPerp.entryValue +
            item.vault.margin +
            item.vaultStatus.feeAmount.feeAmountQuote,
          openPosition.sqrtPerp.amount,
          openPosition.perp.amount + item.vaultStatus.feeAmount.feeAmountBase
        )

        const sqrtPriceX96 =
          (BigInt(Math.floor(Math.sqrt(realtimePrice.oraclePrice * 1000000))) *
            Q96) /
          10n ** 9n

        const valueBn1 = position.calculateValue(
          (sqrtPriceX96 * 10004n) / 10000n
        )
        const valueBn2 = position.calculateValue(
          (sqrtPriceX96 * 10000n) / 10004n
        )
        const valueBn = valueBn1 > valueBn2 ? valueBn2 : valueBn1

        const lastHedgedSqrtPrice = item.userPosition.lastHedgedSqrtPrice

        const lastPriceX96 = (lastHedgedSqrtPrice * lastHedgedSqrtPrice) / Q96

        const lastHedgePrice = toUnscaledPrice(pairInfo, lastPriceX96)

        return {
          ...item,
          position,
          historyItem,
          valueBn,
          lastHedgePrice
        }
      }

      return {
        ...item,
        position: undefined,
        historyItem: undefined,
        valueBn: undefined,
        lastHedgePrice: 0
      }
    })
  }, [pairInfo, realtimePrice, result, data])

  return {
    queryKey: result.queryKey,
    data: resultWithHistoryItem,
    loading
  }
}

export function useGammaPosition(
  chainId: number,
  account: Address,
  pairInfo: PairInfo,
  positionId: bigint
) {
  const result = useGammaPositions(chainId, account, pairInfo)

  return useMemo(() => {
    if (result.data === null) {
      return null
    }

    const filtered = result.data.filter(
      position => position.userPosition.vaultId === positionId
    )

    if (filtered === undefined || filtered.length === 0) {
      return null
    }

    return filtered[0]
  }, [positionId, result])
}
