import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink
} from '@apollo/client'

export const goerliArbitrum = {
  Predy: {
    http: new HttpLink({
      uri: 'https://api.thegraph.com/subgraphs/name/predy-dev/predy-v5-arbitrum-goerli'
    })
  },
  Uniswap: {
    http: new HttpLink({
      uri: 'https://api.thegraph.com/subgraphs/name/predy-dev/predy-v5-uni-arbitrum-goerli'
    })
  }
}

export const arbitrum = {
  Predy: {
    http: new HttpLink({
      uri: 'https://api.studio.thegraph.com/query/47113/predyv6arbitrum/version/latest'
      //      uri: 'https://gateway-arbitrum.network.thegraph.com/api/8a5b8a52f2a8383c47be7e623c1c296d/subgraphs/id/EatVrC9TSKYBKn5gGbRNDSVdvDs99BsvcYSn4K3wNnDc'
    })
  },
  Uniswap: {
    http: new HttpLink({
      // uri: 'https://api.studio.thegraph.com/query/47113/predy-v5-fee-subgraph/version/latest'
      uri: 'https://gateway-arbitrum.network.thegraph.com/api/8a5b8a52f2a8383c47be7e623c1c296d/subgraphs/id/FMeMvxUPEANaZRnm1GPJea24LEXPvPjDsZQutfAuJDfS'
    })
  }
}

export const base = {
  Predy: {
    http: new HttpLink({
      uri: 'https://api.studio.thegraph.com/query/47113/predy-v5-base-mainnet/version/latest'
    })
  },
  Uniswap: {
    http: new HttpLink({
      uri: 'https://api.studio.thegraph.com/query/47113/predy-v5-fee-base-mainnet/version/latest'
    })
  }
}

const predyArbitrumGoerli = new ApolloClient({
  link: goerliArbitrum.Predy.http,
  cache: new InMemoryCache()
})

const predyArbitrum = new ApolloClient({
  link: arbitrum.Predy.http,
  cache: new InMemoryCache()
})

const predyBase = new ApolloClient({
  link: base.Predy.http,
  cache: new InMemoryCache()
})

const uniswapArbitrumGoerli = new ApolloClient({
  link: goerliArbitrum.Uniswap.http,
  cache: new InMemoryCache()
})

const uniswapArbitrum = new ApolloClient({
  link: arbitrum.Uniswap.http,
  cache: new InMemoryCache()
})

const uniswapBase = new ApolloClient({
  link: base.Uniswap.http,
  cache: new InMemoryCache()
})

export const PredyClient: {
  [key: number]: ApolloClient<NormalizedCacheObject>
} = {
  1337: predyArbitrum,
  8453: predyBase,
  42161: predyArbitrum,
  421613: predyArbitrumGoerli
}

export const UniswapClient: {
  [key: number]: ApolloClient<NormalizedCacheObject>
} = {
  1337: uniswapArbitrum,
  8453: uniswapBase,
  42161: uniswapArbitrum,
  421613: uniswapArbitrumGoerli
}
