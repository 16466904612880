import React, { useMemo } from 'react'
import { useAccount } from 'wagmi'
import { Address } from 'viem'
import { PerpOrderV3 } from '@predy/js-sdk'
import { PrimaryButton, ErrorMessage, LoadingIndicator } from '../../common'
import { abs, toUnscaled } from '../../../utils/bn'
import {
  usePerpOrder,
  usePerpOrderWithSlippage,
  useAfterPosition,
  useQuotePerpTrade,
  usePerpSendOrder,
  useMaxTradable
} from '../../../hooks/perp'
import { toScaledPrice, toUnscaledPrice } from '../../../utils'
import { SLIPPAGE_TOLERANCE_FROM_ORACLE, PairInfo } from '../../../constants'
import { usePermit } from '../../../hooks/usePermit'

export function TradeResult({
  chainId,
  pairInfo,
  trader,
  isLong,
  price,
  leverage,
  currentPositionAmount,
  tradeAmount,
  limitPrice
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  isLong: boolean
  price: bigint
  leverage: number
  currentPositionAmount: bigint
  tradeAmount: bigint
  limitPrice?: number
}) {
  const limitPriceX96 = limitPrice
    ? toScaledPrice(pairInfo, limitPrice)
    : undefined

  const order = usePerpOrder(
    chainId,
    pairInfo,
    trader,
    isLong,
    tradeAmount,
    leverage,
    {
      price,
      slippage: SLIPPAGE_TOLERANCE_FROM_ORACLE,
      limitPrice: limitPriceX96
    }
  )

  const quoter = useQuotePerpTrade(chainId, order, 20000)

  const executionPrice = quoter.data ? quoter.data.averagePrice : price

  const estPrice = limitPriceX96
    ? isLong
      ? limitPriceX96 < executionPrice
        ? limitPriceX96
        : executionPrice
      : limitPriceX96 > executionPrice
      ? limitPriceX96
      : executionPrice
    : executionPrice

  const afterPosition = useAfterPosition(
    chainId,
    trader,
    BigInt(pairInfo.pairId || 0),
    estPrice,
    tradeAmount * (isLong ? 1n : -1n),
    leverage,
    limitPriceX96
  )

  const liquidationPrice = useMemo(() => {
    if (afterPosition) {
      const newPosition = afterPosition.position

      if (afterPosition.nextPerpPositionAmount === 0n) {
        return 0
      }

      const liquidationPrice =
        afterPosition.nextPerpPositionAmount > 0n
          ? newPosition.calculateLiquidationPrice1(
              afterPosition.nextRequiredMargin
            )
          : newPosition.calculateLiquidationPrice2(
              afterPosition.nextRequiredMargin
            )

      return toUnscaledPrice(pairInfo, liquidationPrice)
    }
  }, [pairInfo, afterPosition])

  const availableSize = useMaxTradable(
    chainId,
    pairInfo,
    trader,
    currentPositionAmount,
    leverage,
    limitPrice
  )

  const maxBuy = availableSize.isSuccess ? availableSize.data.maxBuy : 0n
  const maxSell = availableSize.isSuccess ? availableSize.data.maxSell : 0n

  const estMarginUpdate = afterPosition?.nextMarginUpdate || 0n

  return (
    <div className="w-full text-xs space-y-1">
      <div className="h-10 my-3">
        {quoter.data ? (
          <TradeButton
            chainId={chainId}
            trader={trader}
            isLong={isLong}
            pairInfo={pairInfo}
            order={order}
            estPrice={estPrice}
            routeIndex={quoter.data.routeIndex}
            tradeAmount={toUnscaled(tradeAmount, pairInfo.base.decimals, 3)}
            disabled={
              order.perpOrder.quantity === 0n ||
              (isLong
                ? order.perpOrder.quantity > maxBuy
                : order.perpOrder.quantity > maxSell)
            }
          />
        ) : (
          <PrimaryButton
            size="md"
            type={isLong ? 'green' : 'red'}
            disabled={true}
          >
            {tradeAmount !== 0n ? (
              <LoadingIndicator />
            ) : isLong ? (
              'Long'
            ) : (
              'Short'
            )}
          </PrimaryButton>
        )}
      </div>

      <div className="flex justify-between">
        <div className="text-white5">
          {estMarginUpdate >= 0n ? 'Cost' : 'Receive'}
        </div>
        <div>
          {toUnscaled(abs(estMarginUpdate), pairInfo.quote.decimals, 2)} USDC
        </div>
      </div>

      <div className="flex justify-between">
        <div className="text-white5">{isLong ? 'Max Buy' : 'Max Sell'}</div>
        <div>
          {isLong
            ? toUnscaled(
                maxBuy,
                pairInfo.base.decimals,
                pairInfo.base.precision
              )
            : toUnscaled(
                maxSell,
                pairInfo.base.decimals,
                pairInfo.base.precision
              )}{' '}
          {pairInfo.base.symbol}
        </div>
      </div>

      <div className="flex justify-between">
        <div className="text-white5">Est. Price</div>
        <div>
          {limitPrice === undefined && quoter.error ? (
            <ErrorMessage message={'quote error'} />
          ) : estPrice ? (
            toUnscaledPrice(pairInfo, estPrice)
          ) : (
            '-'
          )}
        </div>
      </div>

      <div className="flex justify-between">
        <div className="text-white5">Liq. Price</div>
        <div>{liquidationPrice}</div>
      </div>
    </div>
  )
}

const TradeButton = ({
  chainId,
  trader,
  isLong,
  tradeAmount,
  pairInfo,
  order,
  estPrice,
  disabled,
  routeIndex
}: {
  chainId: number
  trader: Address
  isLong: boolean
  tradeAmount: number
  pairInfo: PairInfo
  order: PerpOrderV3
  estPrice: bigint
  disabled: boolean
  routeIndex: number
}) => {
  const requiredCollateral = order.perpOrder.marginAmount

  const { isConnected } = useAccount()

  const permitV1 = usePermit(chainId, pairInfo, trader, requiredCollateral)

  // Adds slippage tolerance from estimated price
  const orderWithSlippage = usePerpOrderWithSlippage(
    order,
    estPrice,
    pairInfo.slippageTolerance
  )

  const { variables, signTypedData, isLoading, error } = usePerpSendOrder(
    orderWithSlippage,
    routeIndex,
    (tradeResult: any) => {
      console.log(tradeResult)
    }
  )

  const isLimitOrder = orderWithSlippage.perpOrder.limitPrice !== 0n

  if (isLoading) {
    return (
      <PrimaryButton size="md" type={isLong ? 'green' : 'red'} disabled={true}>
        <LoadingIndicator />
      </PrimaryButton>
    )
  }

  return (
    <>
      <PrimaryButton
        size="md"
        type={isLong ? 'green' : 'red'}
        disabled={disabled || (!isLimitOrder && routeIndex === undefined)}
        onClick={() => {
          if (permitV1.isEnoughAllowance) {
            signTypedData({
              domain: variables.domain,
              types: variables.types,
              message: variables.message,
              primaryType: 'PermitWitnessTransferFrom'
            })
          } else {
            permitV1.approve()
          }
        }}
      >
        {isConnected ? (
          permitV1.isEnoughAllowance ? (
            isLong ? (
              <div>
                <div className="leading-4">Long</div>
                <div className="font-medium text-xs">
                  {tradeAmount} {pairInfo.base.symbol}
                </div>
              </div>
            ) : (
              <div>
                <div className="leading-4">Short</div>
                <div className="font-medium text-xs">
                  {tradeAmount} {pairInfo.base.symbol}
                </div>
              </div>
            )
          ) : (
            'Approve USDC'
          )
        ) : (
          'Connect Wallet'
        )}
      </PrimaryButton>
      {permitV1.error ? (
        <span className="mx-2 text-xs text-red">{permitV1.error}</span>
      ) : (
        <></>
      )}
      {error ? <span className="mx-2 text-xs text-red">{error}</span> : <></>}
    </>
  )
}
