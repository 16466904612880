import React from 'react'
import { PairInfo } from '../../constants'
import { GammaMarketSM } from './sm/GammaMarketSM'
import { useRealtimePrice } from '../../hooks/price/useRealtimePrice'
import { LoadingIndicator } from '../common'
import { useIsMobile } from '../../hooks/useIsMobile'

interface Props {
  chainId: number
  pairInfo: PairInfo
}

export const GammaMarket = ({ chainId, pairInfo }: Props) => {
  const isMobile = useIsMobile()

  const realtimePrice = useRealtimePrice(pairInfo)

  if (realtimePrice && realtimePrice.oraclePrice) {
    if (isMobile) {
      return (
        <GammaMarketSM
          chainId={chainId}
          pairInfo={pairInfo}
          price={realtimePrice.oraclePrice}
        />
      )
    } else {
      return (
        <GammaMarketSM
          chainId={chainId}
          pairInfo={pairInfo}
          price={realtimePrice.oraclePrice}
        />
      )
    }
  } else {
    return <LoadingIndicator />
  }
}
