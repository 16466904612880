import React, { useState } from 'react'
import { Address } from '@predy/js-sdk'
import { useWriteContract } from 'wagmi'
import { useAddPair } from '../../hooks/contracts/useAddPair'
import { PrimaryButton } from '../common/Button'
import { AssetRiskParams, IrmParams } from '../../types'
import { useDetails } from '../../hooks/erc20/details'

const AddNewPairButton = ({
  chainId,
  marginId,
  uniswapPool,
  poolOwner,
  priceFeed,
  assetRiskParams,
  underlyingIrmParams,
  stableIrmParams
}: {
  chainId: number
  marginId: Address
  uniswapPool: Address
  poolOwner: Address
  priceFeed: Address
  assetRiskParams: AssetRiskParams
  underlyingIrmParams: IrmParams
  stableIrmParams: IrmParams
}) => {
  const addPair = useAddPair(
    chainId,
    marginId,
    uniswapPool,
    poolOwner,
    priceFeed,
    true,
    0,
    assetRiskParams,
    underlyingIrmParams,
    stableIrmParams
  )
  const write = useWriteContract()

  return (
    <PrimaryButton
      disabled={!addPair.data}
      onClick={() => {
        if (addPair.data) write.writeContract(addPair.data.request)
      }}
    >
      Add
    </PrimaryButton>
  )
}

export const AddNewPairForm = ({ chainId }: { chainId: number }) => {
  const [marginId, setMarginId] = useState(
    '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'
  )
  const [uniswapPool, setUniswapPool] = useState(
    '0xC6962004f452bE9203591991D15f6b388e09E8D0'
  )
  const [poolOwner, setPoolOwner] = useState(
    '0x4b748969Ab77C9d09AE07b4282A151B82F96311B'
  )
  const [priceFeed, setPriceFeed] = useState(
    '0xef299c799ccb9b322078148636683dde5c50ca96'
  )
  const [assetRiskParams] = useState({
    riskRatio: 100995049n,
    debtRiskRatio: 1000n,
    rangeSize: 600,
    rebalanceThreshold: 300,
    minSlippage: 1005000n,
    maxSlippage: 1016000n
  })
  const [baseIrmParams] = useState({
    baseRate: 15000000000000000n,
    kinkRate: 880000000000000000n,
    slope1: 50000000000000000n,
    slope2: 1500000000000000000n
  })
  const [quoteIrmParams] = useState({
    baseRate: 15000000000000000n,
    kinkRate: 880000000000000000n,
    slope1: 50000000000000000n,
    slope2: 1500000000000000000n
  })

  return (
    <div className="p-2 bg-panel rounded-lg border-[1px] border-third">
      <div>Add New Pair</div>
      <div className="w-[400px] flex justify-between items-center space-x-1">
        <div>Margin Token</div>
        <input
          className="w-full p-1 rounded-md bg-black"
          value={marginId}
          onChange={e => {
            setMarginId(e.target.value)
          }}
        />
        <TokenName chainId={chainId} address={marginId as Address} />
      </div>
      <div>
        <input
          className="w-full p-1 rounded-md bg-black"
          value={uniswapPool}
          onChange={e => {
            setUniswapPool(e.target.value)
          }}
        />
      </div>
      <div>
        <input
          className="p-1 rounded-md bg-black"
          value={poolOwner}
          onChange={e => {
            setPoolOwner(e.target.value)
          }}
        />
      </div>
      <div>
        <input
          className="p-1 rounded-md bg-black"
          value={priceFeed}
          onChange={e => {
            setPriceFeed(e.target.value)
          }}
        />
      </div>
      <div>
        <AddNewPairButton
          chainId={chainId}
          marginId={marginId as `0x${string}`}
          uniswapPool={uniswapPool as `0x${string}`}
          poolOwner={poolOwner as `0x${string}`}
          priceFeed={priceFeed as `0x${string}`}
          assetRiskParams={assetRiskParams}
          underlyingIrmParams={baseIrmParams}
          stableIrmParams={quoteIrmParams}
        />
      </div>
    </div>
  )
}

const TokenName = ({
  chainId,
  address
}: {
  chainId: number
  address: Address
}) => {
  const name = useDetails(chainId, address)

  return <td>{name.data}</td>
}
