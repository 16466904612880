import React, { ReactNode, useState } from 'react'

type Props = {
  titleContent: ReactNode
  children: ReactNode
  isSmall: boolean
}

export const BasePopup = ({ titleContent, children, isSmall }: Props) => {
  const [isEnteringTitle, setIsEnteringTitle] = useState(false)

  return (
    <div>
      <button
        onClick={() => {
          setIsEnteringTitle(!isEnteringTitle)
        }}
        onBlur={() => {
          setTimeout(() => setIsEnteringTitle(false), 1)
        }}
      >
        {titleContent}
      </button>
      {isSmall ? (
        <BasePopupContentSm isShowing={isEnteringTitle}>
          {children}
        </BasePopupContentSm>
      ) : (
        <BasePopupContent isShowing={isEnteringTitle}>
          {children}
        </BasePopupContent>
      )}
    </div>
  )
}

const BasePopupContent = ({
  children,
  isShowing
}: {
  children: ReactNode
  isShowing: boolean
}) => {
  return (
    <div
      className={`left-0 md:left-auto w-full md:w-80 absolute mt-[4px] z-80 rounded-lg border-[1px] border-white1 bg-panel text-sm font-light pointer-cursor ${
        isShowing ? 'visible' : 'invisible'
      }`}
    >
      {children}
    </div>
  )
}

const BasePopupContentSm = ({
  children,
  isShowing
}: {
  children: ReactNode
  isShowing: boolean
}) => {
  return (
    <div
      className={`left-0 md:left-auto w-full md:w-24 absolute mt-[4px] z-80 rounded-lg border-[1px] border-white1 bg-panel text-sm font-light pointer-cursor ${
        isShowing ? 'visible' : 'invisible'
      }`}
    >
      {children}
    </div>
  )
}
