import { useReadContract } from 'wagmi'
import { predyPoolAbi } from '../../abis/PredyPool'
import { ADDRESS_MAP } from '../../constants/addresses'

export function useGlobalData(chainId: number) {
  return useReadContract({
    address: ADDRESS_MAP[chainId].PredyPool,
    abi: predyPoolAbi,
    functionName: 'globalData',
    chainId
  })
}
