import { Q96 } from '@predy/js-sdk'
import { useMemo } from 'react'
import {
  calculateAmount0Offset,
  calculateAmount1Offset
} from '../../utils/uniswap'
import { ScaledAssetStatus, usePairStatus } from '../pool/usePairStatus'
import { toUnscaled } from '../../utils/bn'
import { ONE } from '../../constants'
import { calculateGammaPosition } from '../../utils/gamma'

export const useDepositLimit = (
  chainId: number,
  pairId: number,
  leverage: number,
  price: number,
  value: number = 100,
  isGammaLong = false
) => {
  const pairStatus = usePairStatus(chainId, pairId)

  return useMemo(() => {
    if (pairStatus.data) {
      const position = calculateGammaPosition(
        value,
        price,
        leverage,
        isGammaLong
      )

      const sqrtPriceX96 =
        (BigInt(Math.floor(Math.sqrt(price * 1000000))) * Q96) / 10n ** 9n
      const priceX96 = (sqrtPriceX96 * sqrtPriceX96) / Q96

      if (position.squart < 0n) {
        const sqrtAssetStatus = pairStatus.data.result.sqrtAssetStatus

        const ur = toUnscaled(
          (sqrtAssetStatus.borrowedAmount * ONE) / sqrtAssetStatus.totalAmount,
          16,
          2
        )
        const unitUr = Math.abs(
          toUnscaled(
            (position.squart * ONE) / sqrtAssetStatus.totalAmount,
            16,
            2
          )
        )
        return {
          currentValue: (ur * value) / unitUr,
          maxValue: (100 * value) / unitUr
        }
      }

      const quoteAmount =
        calculateAmount1Offset(
          pairStatus.data.result.sqrtAssetStatus.tickLower,
          position.squart
        ) -
        (position.underlying * priceX96) / Q96 -
        (2n * position.squart * sqrtPriceX96) / Q96

      const baseAmount =
        calculateAmount0Offset(
          pairStatus.data.result.sqrtAssetStatus.tickUpper,
          position.squart
        ) + position.underlying

      const quoteUr = getUR(pairStatus.data.result.quotePool.tokenStatus)
      const baseUr = getUR(pairStatus.data.result.basePool.tokenStatus)

      const quoteUnitUr = getUR2(
        -1n * quoteAmount,
        pairStatus.data.result.quotePool.tokenStatus
      )
      const baseUnitUr = getUR2(
        -1n * baseAmount,
        pairStatus.data.result.basePool.tokenStatus
      )

      const ur = toUnscaled(quoteUr > baseUr ? quoteUr : baseUr, 16, 2)
      const unitUr = toUnscaled(
        quoteUnitUr > baseUnitUr ? quoteUnitUr : baseUnitUr,
        16,
        2
      )

      return {
        currentValue: (ur * value) / unitUr,
        maxValue: (100 * value) / unitUr
      }
    }
  }, [pairStatus.data, leverage, value, price, isGammaLong])
}

const getUR = (pool: ScaledAssetStatus) => {
  return getUR2(pool.totalNormalBorrowed, pool)
}

const getUR2 = (borrowAmount: bigint, pool: ScaledAssetStatus) => {
  const ur =
    (borrowAmount * ONE) /
    ((pool.assetScaler * pool.totalCompoundDeposited) / ONE +
      pool.totalNormalDeposited)

  return ur
}
