import React, { ReactNode, createContext } from 'react'
import {
  WebSocketContextAction,
  WebSocketContextState,
  useWebSocketCore
} from './useWebSocketCore'

export const WebSocketStateContext =
  createContext<WebSocketContextState | null>(null)
export const WebSocketActionContext =
  createContext<WebSocketContextAction | null>(null)

export const WebSocketProvider = ({
  children,
  endpoint,
  initialMessage
}: {
  children: ReactNode
  endpoint: string
  initialMessage: any
}) => {
  const [states, actions] = useWebSocketCore(endpoint, initialMessage)

  return (
    <WebSocketStateContext.Provider value={states}>
      <WebSocketActionContext.Provider value={actions}>
        {children}
      </WebSocketActionContext.Provider>
    </WebSocketStateContext.Provider>
  )
}
