import React from 'react'
import { Address } from 'viem'
import { useLendingHistory } from '../../hooks/lending/useLendingHistory'
import { toTimeString } from '../../utils/string'

export const LendingHistory = ({
  chainId,
  address
}: {
  chainId: number
  address: Address
}) => {
  const history = useLendingHistory(chainId, address)

  return (
    <div className="mx-4 mt-4 mb-12">
      <div className="text-md my-2 font-bold">History</div>
      <div className="my-2 flex justify-between text-xs text-fourth">
        <div className="basis-1/4">Time</div>
        <div className="basis-1/4">Symbol</div>
        <div className="basis-1/4">Action</div>
        <div className="basis-1/4">Quantity</div>
      </div>
      {history.lendingHistory.length > 0 ? (
        history.lendingHistory.map((item, index) => {
          return (
            <div key={index} className="my-1 flex justify-between text-xs">
              <div className="basis-1/4">{toTimeString(item.timestamp)}</div>
              <div className="basis-1/4">{item.symbol}</div>
              <div className="basis-1/4">{item.action}</div>
              <div className="basis-1/4">
                {item.quantity} {item.symbol}
              </div>
            </div>
          )
        })
      ) : (
        <div className="my-1 flex justify-center text-xs">No History</div>
      )}
    </div>
  )
}
