import React from 'react'
import { Direction } from './DirectionSelector'
import { OrderType } from './OrderTypeSelector'
import { PairInfo } from '../../../constants/assets'
import { Address } from '@predy/js-sdk'
import { MarketOrderForm } from './MarketOrderForm'
import { LimitOrderForm } from './LimitOrderForm'
import { useUniswapPrice } from '../../../hooks/useUniswapPrice'
import { LoadingIndicator } from '../../common'

export function TradeDirectionForm({
  chainId,
  pairInfo,
  trader,
  direction,
  orderType
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  direction: Direction
  orderType: OrderType
}) {
  /*
  const [amount,] = useState(0)
  const order = useSpotOrder(
    chainId,
    pairInfo,
    trader,
    direction === Direction.Long,
    SLIPPAGE_TOLERANCE_FROM_ORACLE,
    toScaled(amount, pairInfo.base.decimals),
    true
  )
  */

  return (
    <div className="space-y-4">
      <TradeDirectionFormInner
        chainId={chainId}
        pairInfo={pairInfo}
        trader={trader}
        direction={direction}
        orderType={orderType}
      />
    </div>
  )
}

function TradeDirectionFormInner({
  chainId,
  pairInfo,
  trader,
  direction,
  orderType
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  direction: Direction
  orderType: OrderType
}) {
  const price = useUniswapPrice(chainId, pairInfo.id)

  if (orderType === OrderType.Market) {
    return (
      <MarketOrderForm
        chainId={chainId}
        pairInfo={pairInfo}
        trader={trader}
        direction={direction}
      />
    )
  } else if (orderType === OrderType.Limit) {
    if (price.data) {
      return (
        <LimitOrderForm
          chainId={chainId}
          pairInfo={pairInfo}
          trader={trader}
          direction={direction}
          price={price.data}
        />
      )
    } else {
      return <LoadingIndicator />
    }
  } else {
    return <div>Unimplemented order type</div>
  }
}
