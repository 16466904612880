import { useBlock } from 'wagmi'
import { useQuery } from '@tanstack/react-query'
import { useChainId } from './network'
import { useEffect, useState } from 'react'

export function useBlockTimestamp(chainId: number) {
  const block = useBlock({
    query: {
      refetchInterval: 15000
    }
  })

  const [blockTime, setBlockTime] = useState(0n)

  const query = useQuery({
    queryKey: ['timestamp', chainId, block.data?.number.toString()],
    queryFn: async () => {
      if (!block.isSuccess) throw new Error('Block not loaded')

      return block.data.timestamp
    },
    enabled: block.isSuccess
  })

  useEffect(() => {
    if (query.data) {
      setBlockTime(query.data)
    }
  }, [query.data])

  return blockTime
}

// default deadline is 10 minutes
export const DEFAULT_DEADLINE = BigInt(20 * 60)
// default limit order deadline is 10 year
export const DEFAULT_LIMIT_ORDER_DEADLINE = BigInt(10 * 365 * 24 * 60 * 60)

export function useDeadline(isLimitOrder = false) {
  const chainId = useChainId()
  const blockTime = useBlockTimestamp(chainId)

  return (
    blockTime + (isLimitOrder ? DEFAULT_LIMIT_ORDER_DEADLINE : DEFAULT_DEADLINE)
  )
}
