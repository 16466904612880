import React from 'react'
import { useGlobalData } from '../../hooks/pool/useGlobalData'
import { usePairStatus } from '../../hooks/pool/usePairStatus'
import { toUnscaled } from '../../utils/bn'
import { useDetails } from '../../hooks/erc20/details'
import { Address } from '@predy/js-sdk'
import { useUpdateWhitelistAddress } from '../../hooks/contracts/useAddWhitelistAddress'
import { ADDRESS_MAP, PAIR_INFOS, PairInfo } from '../../constants'
import { PrimaryButton } from '../common/Button'
import { useUpdateQuoteTokenMap } from '../../hooks/contracts/useExecuteOrder'
import { useCreatePriceFeed } from '../../hooks/contracts/useCreatePriceFeed'
import { useUpdatePriceOracle } from '../../hooks/contracts/useUpdatePriceOracle'
import { useAccount, useWriteContract } from 'wagmi'
import { useUpdateFeeRatio } from '../../hooks/pool/useUpdateFeeRatio'
import {
  useWithdrawCreatorFee,
  useWithdrawProtocolFee
} from '../../hooks/pool/useWithdrawFee'
import { pairIdToSymbol } from '../../utils/pairs'

const PairName = ({
  chainId,
  address
}: {
  chainId: number
  address: Address
}) => {
  const name = useDetails(chainId, address)

  return <td>{name.data}</td>
}

const PairComponent = ({
  chainId,
  account,
  pairId,
  pairInfo
}: {
  chainId: number
  account: Address
  pairId: number
  pairInfo: PairInfo
}) => {
  const pairStatus = usePairStatus(chainId, pairId)
  const updateWhitelistAddress = useUpdateWhitelistAddress(
    chainId,
    pairId,
    ADDRESS_MAP[chainId].PerpMarket
  )
  const updateQuoteTokenMap = useUpdateQuoteTokenMap(chainId, pairId)
  const updateFeeRatio = useUpdateFeeRatio(chainId, BigInt(pairId), 1)
  const withdrawQuoteCreatorFee = useWithdrawCreatorFee(
    chainId,
    account,
    BigInt(pairId),
    true
  )
  const withdrawQuoteProtocolFee = useWithdrawProtocolFee(
    chainId,
    account,
    BigInt(pairId),
    true
  )

  const write = useWriteContract()

  if (pairStatus.data) {
    const result = pairStatus.data.result
    return (
      <tr className="h-8">
        <td className="my-2">{pairId}</td>
        <td>
          <PairName chainId={chainId} address={result.marginId} />
        </td>
        <td>
          <PairName chainId={chainId} address={result.basePool.token} />
        </td>
        <td>{result.poolOwner}</td>
        <td>
          {toUnscaled(
            result.riskParams.riskRatio * result.riskParams.riskRatio -
              10n ** 16n,
            14,
            2
          )}{' '}
          %
        </td>
        <td>
          {toUnscaled(
            result.basePool.accumulatedProtocolRevenue,
            pairInfo.base.decimals,
            6
          )}{' '}
          {pairInfo.base.symbol},
          {toUnscaled(
            result.quotePool.accumulatedProtocolRevenue,
            pairInfo.quote.decimals,
            6
          )}{' '}
          {pairInfo.quote.symbol},
        </td>
        <td>
          {toUnscaled(
            result.basePool.accumulatedCreatorRevenue,
            pairInfo.base.decimals,
            6
          )}{' '}
          {pairInfo.base.symbol},
          {toUnscaled(
            result.quotePool.accumulatedCreatorRevenue,
            pairInfo.quote.decimals,
            6
          )}{' '}
          {pairInfo.quote.symbol},
        </td>
        <td className="my-2">
          <div className="w-48 m-2">
            <PrimaryButton
              size="sm"
              onClick={() => {
                if (updateWhitelistAddress.data) {
                  write.writeContract(updateWhitelistAddress.data.request)
                }
              }}
            >
              Set Perp Market
            </PrimaryButton>
          </div>
        </td>
        <td>
          <PrimaryButton
            size="sm"
            onClick={() => {
              if (updateQuoteTokenMap.data) {
                write.writeContract(updateQuoteTokenMap.data.request)
              }
            }}
          >
            Update QTM
          </PrimaryButton>
        </td>
        <td>
          <div className="w-48 m-2">
            <PrimaryButton
              size="sm"
              onClick={() => {
                if (updateFeeRatio.data) {
                  write.writeContract(updateFeeRatio.data.request)
                }
              }}
            >
              Update Fee Ratio
            </PrimaryButton>
          </div>
        </td>
        <td>
          <div className="w-48 m-2">
            <PrimaryButton
              size="sm"
              onClick={() => {
                if (withdrawQuoteCreatorFee.data) {
                  write.writeContract(withdrawQuoteCreatorFee.data.request)
                }
              }}
            >
              Withdraw creator fee
            </PrimaryButton>
          </div>
        </td>
        <td>
          <div className="w-48 m-2">
            <PrimaryButton
              size="sm"
              onClick={() => {
                if (withdrawQuoteProtocolFee.data) {
                  write.writeContract(withdrawQuoteProtocolFee.data.request)
                }
              }}
            >
              Withdraw protocol fee
            </PrimaryButton>
          </div>
        </td>
      </tr>
    )
  } else {
    return <tr></tr>
  }
}

export const PairListComponent = ({ chainId }: { chainId: number }) => {
  const globalData = useGlobalData(chainId)
  const createPriceFeed = useCreatePriceFeed(chainId)
  const updatePriceOracle = useUpdatePriceOracle(
    chainId,
    2,
    '0xF0Dc8188FCbCaB732628b69dDDBFcc2a803afeDA'
  )
  const write = useWriteContract()

  const { address } = useAccount()

  const pairCount = globalData.data ? Number(globalData.data[0]) : 0

  return (
    <div>
      <table>
        <thead>
          <th>ID</th>
        </thead>
        <tbody>
          {address ? (
            Array.from(Array(pairCount).keys())
              .filter(pairId => pairId > 0)
              .map(pairId => (
                <PairComponent
                  key={pairId}
                  chainId={chainId}
                  pairId={pairId}
                  pairInfo={PAIR_INFOS[chainId][pairIdToSymbol(pairId)]}
                  account={address}
                />
              ))
          ) : (
            <tr></tr>
          )}
        </tbody>
      </table>
      <PrimaryButton
        onClick={() => {
          if (createPriceFeed.data) {
            write.writeContract(createPriceFeed.data.request)
          }
        }}
      >
        Create Price Feed
      </PrimaryButton>
      <PrimaryButton
        onClick={() => {
          if (updatePriceOracle.data) {
            write.writeContract(updatePriceOracle.data.request)
          }
        }}
      >
        Update Price Oracle
      </PrimaryButton>
    </div>
  )
}
