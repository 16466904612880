import React, { useEffect } from 'react'
import { PairSelector } from '../../PairSelector'
import { PairInfo } from '../../../constants'
import { toUnscaled } from '../../../utils/bn'
import { useBorrowingFee } from '../../../hooks/pool/useBorrowingFee'
import { usePairSelectorItems } from '../../../hooks/query/usePairSelectorItems'
import { useRealtimePrice } from '../../../hooks/price/useRealtimePrice'
import { InfoTooltip2, LoadingIndicator } from '../../common'
import { formatPrice } from '../../../utils'
import { usePrice } from '../../../hooks/query/usePrice'
import { PnLChange, PriceChange } from '../../common/PriceChange'
import { AiOutlineLineChart } from 'react-icons/ai'
import { use24HPriceSummary } from '../../../hooks/price/use24HPriceSummary'

export const ChartTop = ({
  chainId,
  pairInfo,
  isChartOpen,
  setIsChartOpen
}: {
  chainId: number
  pairInfo: PairInfo
  isChartOpen: boolean
  setIsChartOpen: (isOpen: boolean) => void
}) => {
  const items = usePairSelectorItems(false)
  const price = useRealtimePrice(pairInfo)
  const priceFromContract = usePrice(chainId, pairInfo.pairId || 0)
  const borrowingFee = useBorrowingFee(chainId, pairInfo.pairId || 0)
  const price24h = use24HPriceSummary(chainId, pairInfo)

  useEffect(() => {
    if (price) {
      const priceStr = price.now.fmtPrice

      const symbol = `${pairInfo.base.symbol}/${pairInfo.quote.symbol}`

      document.title = `${priceStr} | ${symbol} | Predy V6`
    }
  }, [chainId, pairInfo, price])

  if (!price || priceFromContract.data === undefined) {
    return (
      <div className="h-12 bg-background border-[1px] border-panelborder flex justify-center items-center">
        <LoadingIndicator />
      </div>
    )
  }

  const isUp = price?.now.isUp || false

  return (
    <div className="z-10 sticky top-0 left-0 px-2 md:px-6 py-2 bg-background border-[1px] border-panelborder flex justify-center md:justify-between items-center space-x-0 md:space-x-2">
      <div className="flex justify-end items-center space-x-6 md:space-x-8">
        <PairSelector chainId={chainId} items={items} />
        <div className="flex justify-between items-center space-x-2">
          <div
            className={`flex justify-start items-center text-xl font-bold ${
              isUp ? 'text-green' : 'text-red'
            }`}
          >
            <PriceChange isUp={isUp}>
              {price ? price.now.fmtPrice : '-'}
            </PriceChange>
          </div>
          <AiOutlineLineChart
            className="md:hidden w-[18px] h-[18px]"
            color={isChartOpen ? '#FFF' : '#777'}
            onClick={() => {
              setIsChartOpen(!isChartOpen)
            }}
          />
        </div>
      </div>
      <div className="w-[352px] lg:w-[456px] xl:w-[608px] hidden md:block md:flex justify-between items-center mr-6 space-x-4 text-xs overflow-x-scroll">
        <div className="flex justify-end items-center space-x-4 text-xs whitespace-nowrap">
          <div className="space-y-1">
            <div className="text-fourth">24h Change</div>
            <div>
              <PnLChange value={price24h ? price24h.change : 0}>
                <div>{price24h ? price24h.change : '...'}%</div>
              </PnLChange>
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-fourth">24h High</div>
            <div>{price24h ? formatPrice(pairInfo, price24h.high) : '...'}</div>
          </div>
          <div className="space-y-1">
            <div className="text-fourth">24h Low</div>
            <div>{price24h ? formatPrice(pairInfo, price24h.low) : '...'}</div>
          </div>
          <div className="space-y-1">
            <div className="text-fourth">
              <InfoTooltip2
                placement="top"
                description="The Oracle Price is the price used to determine liquidation conditions. It may differ from the trade price because Pyth Network is used to prevent price manipulation."
              >
                <p className="text-fourth cursor-pointer underline">
                  Oracle Price
                </p>
              </InfoTooltip2>
            </div>
            <div>{formatPrice(pairInfo, price.oraclePrice || 0)}</div>
          </div>
          <div className="flex flex-col justify-start space-y-1">
            <div className="text-third">
              <InfoTooltip2
                description={`The total number of outstanding derivative contracts.`}
                placement="top"
              >
                <p className="text-third cursor-pointer underline whitespace-nowrap">
                  Open Interest
                </p>
              </InfoTooltip2>
            </div>
            <div className="text-xs whitespace-nowrap">
              {borrowingFee.data
                ? `Long ${toUnscaled(
                    borrowingFee.data.openLong,
                    pairInfo.base.decimals,
                    pairInfo.base.precision
                  )}, Short ${toUnscaled(
                    borrowingFee.data.openShort,
                    pairInfo.base.decimals,
                    pairInfo.base.precision
                  )}`
                : '-'}
            </div>
          </div>
          <div className="flex flex-col justify-start space-y-1">
            <div>
              <InfoTooltip2
                description="The interest rate that longs pay over a 24-hour."
                placement="top"
              >
                <p className="text-third cursor-pointer underline">
                  Borrowing(L)
                </p>
              </InfoTooltip2>
            </div>
            <div className="text-xs">
              {borrowingFee.data ? -borrowingFee.data.borrowingFeeLong : 0} %
            </div>
          </div>
          <div className="flex flex-col justify-start space-y-1">
            <div>
              <InfoTooltip2
                description="The interest rate that shorts pay over a 24-hour."
                placement="top"
              >
                <p className="text-third cursor-pointer underline">
                  Borrowing(S)
                </p>
              </InfoTooltip2>
            </div>
            <div className="text-xs">
              {borrowingFee.data ? -borrowingFee.data.borrowingFeeShort : 0} %
            </div>
          </div>
        </div>

        {/*
        <div className="hidden xl:block flex flex-col justify-start space-y-1">
          <div className="text-third">
            <InfoTooltip2
              description={`The total number of outstanding derivative contracts.
              ${borrowingFee.data ? toUnscaled(
                borrowingFee.data.availableBaseAmount,
                pairInfo.base.decimals
              ) : 0} ${pairInfo.base.symbol}, ${borrowingFee.data ? toUnscaled(
                borrowingFee.data.availableQuoteAmount,
                pairInfo.quote.decimals
              ) : 0} ${pairInfo.quote.symbol}
              `}
              placement="top"
            >
              <p className="text-third cursor-pointer underline">
                Available
              </p>
            </InfoTooltip2>
          </div>
        <div className="text-xs">
          {borrowingFee.data
            ? `${convertNotionalToString(toUnscaled(
              borrowingFee.data.availableQuoteAmount,
              pairInfo.quote.decimals
            ))} ${pairInfo.quote.symbol} ${convertNotionalToString(toUnscaled(
              borrowingFee.data.availableBaseAmount,
              pairInfo.base.decimals
            ))} ${pairInfo.base.symbol}`
            : '-'}
        </div>
      </div>
          */}
      </div>
    </div>
  )
}
