import { useContext } from 'react'
import {
  WebSocketStateContext,
  WebSocketActionContext
} from './WebSocketProvider'

export const useWebSocketState = () => {
  const context = useContext(WebSocketStateContext)
  if (context === null) {
    throw new Error('useWebSocketState works under websocket provider')
  }
  return context
}

export const useWebSocketAction = () => {
  const context = useContext(WebSocketActionContext)
  if (context === null) {
    throw new Error('useWebSocketAction works under websocket provider')
  }
  return context
}
