import { useQuery } from '@apollo/client'
import {
  GAMMA_POSITION_HISTORY_QUERY,
  GammaPositionHistoryEntities
} from '../../queries/gammaPositionHistoryQuery'
import { PredyClient } from '../../utils/apollo-client'
import { Address } from 'viem'
import { useEffect, useMemo, useState } from 'react'
import { toUnscaled } from '../../utils/bn'
import { PAIR_INFOS, TimeFilter } from '../../constants'
import { pairIdToSymbol } from '../../utils/pairs'
import { useDeltaTimestamps } from '../../utils/time'

export function useGammaPositionHistoryFromGraph(
  chainId: number,
  trader: Address,
  filterTime: TimeFilter
) {
  const [, dayAgo, weekAgo, monthAgo] = useDeltaTimestamps()

  const [history, setHistory] = useState<
    GammaPositionHistoryEntities | undefined
  >(undefined)

  const { data, loading } = useQuery<GammaPositionHistoryEntities>(
    GAMMA_POSITION_HISTORY_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        address: trader.toLowerCase(),
        startTime:
          filterTime === 'd'
            ? dayAgo
            : filterTime === 'w'
            ? weekAgo
            : filterTime === 'm'
            ? monthAgo
            : 0
      },
      client: PredyClient[chainId],
      pollInterval: 30000
    }
  )

  useEffect(() => {
    if (data) {
      setHistory(data)
    }
  }, [data])

  const result = useMemo(() => {
    if (history) {
      return history.gammaPositionEntities.map(item => {
        const pair = PAIR_INFOS[chainId][pairIdToSymbol(Number(item.pair.id))]

        const positionId = BigInt(item.vault.id)
        const totalDeposit = BigInt(item.totalDeposit)
        const totalWithdraw = BigInt(item.totalWithdraw)
        const expiration = BigInt(item.expiration)
        const hedgeInterval = Number(item.hedgeInterval)
        const sqrtPriceTrigger = BigInt(item.sqrtPriceTrigger)
        const isClosed = item.isClosed
        const lastAction = item.lastAction
        const closeType =
          item.lastAction === 'LIQUIDATION'
            ? 'Liquidated'
            : item.lastAction === 'CLOSE_BY_TIME' ||
              item.lastAction === 'CLOSE_BY_PRICE'
            ? 'Auto Close'
            : 'Manual Close'
        const leverage = Number(item.leverage)
        const createdAt = Number(item.createdAt)
        const updatedAt = Number(item.updatedAt)
        const txHash = item.history.length > 0 ? item.history[0].txHash : null

        return {
          positionId,
          pair,
          isClosed,
          closeType,
          lastAction,
          totalDeposit,
          totalWithdraw,
          expiration,
          hedgeInterval,
          sqrtPriceTrigger,
          leverage,
          createdAt,
          updatedAt,
          txHash
        }
      })
    }

    return null
  }, [chainId, history])

  return {
    data: result,
    loading
  }
}

export function useGammaPositionHistory(
  chainId: number,
  trader: Address,
  filterTime: TimeFilter
) {
  const { data, loading } = useGammaPositionHistoryFromGraph(
    chainId,
    trader,
    filterTime
  )

  const result = useMemo(() => {
    if (data) {
      return data
        .filter(item => item.isClosed)
        .map(item => {
          const pair = item.pair
          return {
            ...item,
            totalDeposit: toUnscaled(
              item.totalDeposit,
              pair.quote.decimals,
              pair.quote.precision
            ),
            totalWithdraw: toUnscaled(
              item.totalWithdraw,
              pair.quote.decimals,
              pair.quote.precision
            )
          }
        })
    }

    return null
  }, [data])

  return {
    data: result,
    loading
  }
}
