import React, { useCallback, useEffect, useState } from 'react'
import { PrimaryButton } from '../common/Button'
import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract
} from 'wagmi'
import { useBalanceQuery } from '../../hooks/erc20/balance'
import { ADDRESS_MAP } from '../../constants/addresses'
import { PairInfo } from '../../constants/assets'
import { useSupply } from '../../hooks/lending/useSupply'
import { useApprove } from '../../hooks/contracts/useApprove'
import { Address } from '@predy/js-sdk/dist/types'
import { AmountForm } from '../common/form/AmountForm'
import { AmountSlider } from '../swap/trade/AmountSlider'
import { toScaled, toUnscaled } from '../../utils/bn'
import { ConnectLink } from '../header/ConnectButton'
import { useQueryClient } from '@tanstack/react-query'
import { DEFAULT_CONFIRMATION } from '../../constants'

export const SupplyAmountForm = ({
  amount,
  symbol,
  maxAmount,
  setAmount
}: {
  symbol: string
  amount: number
  maxAmount: number
  setAmount: (amount: number) => void
}) => {
  const [innerValue, setInnerValue] = useState<number>(amount)
  const [sizeRatio, setSizeRatio] = useState(0)

  useEffect(() => {
    const amount = parseFloat(((maxAmount * sizeRatio) / 100).toFixed(2))

    setInnerValue(amount)
    setAmount(amount)
  }, [maxAmount, sizeRatio, setAmount, setInnerValue])

  useEffect(() => {
    setAmount(innerValue)
  }, [innerValue, setAmount])

  return (
    <div className="space-y-2">
      <AmountForm
        title={'Size'}
        unit={symbol}
        amount={innerValue}
        onChange={setInnerValue}
      />

      <AmountSlider
        amount={sizeRatio}
        maxAmount={100}
        setAmount={setSizeRatio}
        precision={2}
      />
    </div>
  )
}

export const SupplyForm = ({
  chainId,
  pairInfo,
  account,
  isQuote
}: {
  chainId: number
  pairInfo: PairInfo
  account: Address
  isQuote: boolean
}) => {
  const queryClient = useQueryClient()
  const [amount, serAmount] = useState<number>(0)

  const { isConnected } = useAccount()
  const balance = useBalanceQuery(
    chainId,
    isQuote ? pairInfo.quote.address : pairInfo.base.address,
    account,
    ADDRESS_MAP[chainId].PredyPool
  )

  const amountBn = toScaled(
    amount,
    isQuote ? pairInfo.quote.decimals : pairInfo.base.decimals
  )

  const approve = useApprove(
    isQuote ? pairInfo.quote.address : pairInfo.base.address,
    ADDRESS_MAP[chainId].PredyPool,
    amountBn
  )
  const supply = useSupply(chainId, pairInfo.pairId || 0, amountBn, isQuote)
  const { writeContractAsync, data: hash } = useWriteContract()
  const { isSuccess } = useWaitForTransactionReceipt({
    hash,
    confirmations: DEFAULT_CONFIRMATION[chainId]
  })

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries({ queryKey: balance.queryKey })
    }
  }, [isSuccess, queryClient, balance.queryKey])

  const SupplyButton = useCallback(() => {
    const balanceAmount =
      balance.data && balance.data.isLoaded ? balance.data.balance : null
    const allowanceAmount =
      balance.data && balance.data.isLoaded ? balance.data.allowance : null

    if (balanceAmount === null || allowanceAmount === null) {
      return <PrimaryButton disabled={true}>...</PrimaryButton>
    }

    if (balanceAmount < amountBn) {
      return <PrimaryButton disabled={true}>Insufficient Balance</PrimaryButton>
    }

    if (isConnected) {
      if (allowanceAmount >= amountBn) {
        // enough allowance
        return (
          <PrimaryButton
            disabled={!supply.data}
            onClick={async () => {
              if (supply.data) {
                await writeContractAsync(supply.data.request)
              }
            }}
          >
            {'Supply' +
              (isQuote
                ? ' ' + pairInfo.quote.symbol
                : ' ' + pairInfo.base.symbol)}
          </PrimaryButton>
        )
      } else {
        return (
          <PrimaryButton
            disabled={!approve.data}
            onClick={async () => {
              if (approve.data) {
                await writeContractAsync(approve.data.request)
              }
            }}
          >
            {'Approve' +
              (isQuote
                ? ' ' + pairInfo.quote.symbol
                : ' ' + pairInfo.base.symbol)}
          </PrimaryButton>
        )
      }
    } else {
      return <ConnectLink>Connect Wallet</ConnectLink>
    }
  }, [
    amountBn,
    balance,
    pairInfo,
    isConnected,
    isQuote,
    approve.data,
    supply.data,
    writeContractAsync
  ])

  return (
    <div className="space-y-2">
      <div className="flex justify-between text-xs">
        <div className="text-fourth">Avbl</div>
        <div>
          {toUnscaled(
            balance.data?.balance || 0n,
            isQuote ? pairInfo.quote.decimals : pairInfo.base.decimals
          )}
        </div>
      </div>
      <SupplyAmountForm
        symbol={isQuote ? pairInfo.quote.symbol : pairInfo.base.symbol}
        maxAmount={toUnscaled(
          balance.data?.balance || 0n,
          isQuote ? pairInfo.quote.decimals : pairInfo.base.decimals
        )}
        amount={amount}
        setAmount={serAmount}
      />

      <div className="my-10 h-10">
        <SupplyButton />
      </div>
    </div>
  )
}
