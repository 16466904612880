import { useCallback } from 'react'
import { useLocation, useRoute } from 'wouter'

export interface TradeRouteResult {
  isNotFound: boolean
  chainId: number | null
  chainName: string | null
  pairTitle: string | null
  templateId: number | null
  positionId: number | null
  setPairTitle: (pairTitle: string) => void
  setTemplateId: (templateId: number) => void
  setPositionId: (positionId: number) => void
}

export function useTradeRoute(): TradeRouteResult {
  const route = useRoute('/:chain/:title/:pairTitle')
  const createRoute = useRoute('/:chain/:title/:pairTitle/create/:templateId')
  const positionRoute = useRoute(
    '/:chain/:title/:pairTitle/positions/:positionId'
  )

  const params = route[1] || createRoute[1] || positionRoute[1]

  const [, setLocation] = useLocation()

  const chainId = params ? chainNameToId(params.chain) : null

  const pairTitle = params ? params.pairTitle : null

  const setPairTitle = useCallback(
    (pairTitle: string) => {
      if (params) {
        setLocation(`/${params.chain}/${params.title}/${pairTitle}`)
      }
    },
    [setLocation, params]
  )

  const setTemplateId = useCallback(
    (templateId: number) => {
      if (params) {
        if (templateId) {
          setLocation(
            `/${params.chain}/${params.title}/${pairTitle}/create/${templateId}`
          )
        } else {
          setLocation(`/${params.chain}/${params.title}/${pairTitle}`)
        }
      }
    },
    [setLocation, params, pairTitle]
  )

  const setPositionId = useCallback(
    (positionId: number) => {
      if (params) {
        if (positionId) {
          setLocation(
            `/${params.chain}/${params.title}/${pairTitle}/positions/${positionId}`
          )
        } else {
          setLocation(`/${params.chain}/${params.title}/${pairTitle}`)
        }
      }
    },
    [setLocation, params, pairTitle]
  )
  const isNotFound = !chainId || !pairTitle

  const templateId = createRoute[0] ? Number(createRoute[1].templateId) : null
  const positionId = positionRoute[0]
    ? Number(positionRoute[1].positionId)
    : null

  return {
    isNotFound,
    chainId,
    chainName: chainId ? chainIdToName(chainId) : null,
    pairTitle,
    templateId,
    positionId,
    setPairTitle,
    setTemplateId,
    setPositionId
  }
}

export function chainIdToName(chainId: number) {
  if (chainId === 42161) {
    return 'arbitrum'
  } else if (chainId === 8453) {
    return 'base'
  } else if (chainId === 1337) {
    return 'local'
  } else {
    return null
  }
}

export function chainNameToId(chainName: string) {
  if (chainName === 'arbitrum') {
    return 42161
  } else if (chainName === 'base') {
    return 8453
  } else if (chainName === 'local') {
    return 1337
  } else {
    return null
  }
}
