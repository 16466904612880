import React, { useCallback, useEffect, useState } from 'react'
import { Slider } from '../../common'
import { roundDigit } from '../../../utils/number'

interface Props {
  amount: number
  maxAmount: number
  setAmount: (amount: number) => void
  precision: number
}

export const AmountSlider = ({
  amount,
  maxAmount,
  setAmount,
  precision
}: Props) => {
  const [value, setValue] = useState(
    maxAmount > 0 ? Math.floor((amount * 100) / maxAmount) : 0
  )

  const handler = useCallback(
    (value: number) => {
      const amount = (maxAmount * Number(value)) / 100

      setAmount(roundDigit(amount, precision))
      setValue(value)
    },
    [setAmount, setValue, maxAmount, precision]
  )

  useEffect(() => {
    const value = maxAmount > 0 ? amount / maxAmount : 0

    setValue(Math.floor(value * 100))
  }, [amount, maxAmount, setValue])

  return (
    <Slider min={0} max={100} step={1} value={value} onValueChanged={handler} />
  )
}
