import { useQuery } from '@apollo/client'
import {
  PERP_HISTORY_QUERY,
  PerpHistoryItemEntities
} from '../../queries/perpHistoryQuery'
import { PredyClient } from '../../utils/apollo-client'
import { Address } from 'viem'
import { useMemo } from 'react'
import { abs, toUnscaled } from '../../utils/bn'
import { PAIR_INFOS } from '../../constants'
import { pairIdToSymbol } from '../../utils/pairs'

export function usePerpTradeHistory(chainId: number, trader: Address) {
  const { data, loading } = useQuery<PerpHistoryItemEntities>(
    PERP_HISTORY_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        address: trader.toLowerCase()
      },
      client: PredyClient[chainId],
      pollInterval: 30000
    }
  )

  const result = useMemo(() => {
    if (data) {
      return data.perpTradeHistoryItems
        .filter(item => item.size !== '0')
        .map(item => {
          const pair = PAIR_INFOS[chainId][pairIdToSymbol(Number(item.pair.id))]

          const size = BigInt(item.size)
          const payoff = BigInt(item.payoff)
          const fee = BigInt(item.fee)

          const price = abs(
            ((BigInt(item.entryValue) + payoff) *
              10n ** BigInt(pair.base.decimals)) /
              size
          )

          const fmtPayoff = toUnscaled(
            payoff,
            pair.quote.decimals,
            pair.quote.precision,
            payoff < 0n
          )
          const fmtFee = toUnscaled(
            fee,
            pair.quote.decimals,
            pair.quote.precision,
            fee < 0n
          )

          return [
            {
              id: item.id,
              pair,
              trader: item.trader,
              action: item.action === 'POSITION' ? 'Trade' : 'Liquidation',
              side: size >= 0n ? 'Long' : 'Short',
              price: toUnscaled(price, pair.quote.decimals, 2).toFixed(
                pair.quote.precision
              ),
              quantity: toUnscaled(abs(size), pair.base.decimals, 3).toFixed(
                pair.base.precision
              ),
              fmtPayoff,
              payoff: (fmtPayoff > 0 ? '+' : '') + fmtPayoff.toString(),
              txHash: item.txHash,
              link: `https://arbiscan.io/tx/${item.txHash}`,
              createdAt: Number(item.createdAt)
            },
            {
              id: item.id,
              pair,
              trader: item.trader,
              action: 'Fee',
              side: '',
              price: '',
              quantity: '',
              isGreen: fee >= 0n,
              fmtPayoff: fmtFee,
              payoff: (fmtFee > 0 ? '+' : '') + fmtFee.toString(),
              txHash: item.txHash,
              link: `https://arbiscan.io/tx/${item.txHash}`,
              createdAt: Number(item.createdAt)
            }
          ]
        })
        .flat()
        .filter(item => item !== null)
    }

    return null
  }, [chainId, data])

  return {
    data: result,
    loading
  }
}
