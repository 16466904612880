import React, { useEffect } from 'react'
import { DEFAULT_CHAIN } from '../../../constants'
import { HorizontalPairSelector, PairSelector } from '../../PairSelector'
import { usePairSelectorItems } from '../../../hooks/query/usePairSelectorItems'
import { PairInfo } from '../../../constants/assets'
import { useRealtimePrice } from '../../../hooks/price/useRealtimePrice'
import { LoadingIndicator } from '../../common/LoadingIndicator'
import { formatPrice } from '../../../utils'
import { AiOutlineLineChart } from 'react-icons/ai'
import { use24HPriceSummary } from '../../../hooks/price/use24HPriceSummary'

export const ChartTop = ({
  chainId,
  pairInfo,
  isChartOpen,
  setIsChartOpen
}: {
  chainId: number
  pairInfo: PairInfo
  isChartOpen: boolean
  setIsChartOpen: (isOpen: boolean) => void
}) => {
  return (
    <div className="h-14 z-10 sticky top-0 left-0 py-2 bg-background border-[1px] border-panelborder flex justify-between items-center">
      <div className="mx-8 flex justify-between items-center space-x-20">
        <ChartTopPriceInfo
          chainId={chainId}
          pairInfo={pairInfo}
          isChartOpen={isChartOpen}
          setIsChartOpen={setIsChartOpen}
        />
      </div>
      <div className="mx-4 hidden md:block">
        <HorizontalPairSelector chainId={DEFAULT_CHAIN} />
      </div>
    </div>
  )
}

const ChartTopPriceInfo = ({
  chainId,
  pairInfo,
  isChartOpen,
  setIsChartOpen
}: {
  chainId: number
  pairInfo: PairInfo
  isChartOpen: boolean
  setIsChartOpen: (isOpen: boolean) => void
}) => {
  const items = usePairSelectorItems()
  const price = useRealtimePrice(pairInfo)
  const price24h = use24HPriceSummary(chainId, pairInfo)
  const isUp = price?.now.isUp || false

  useEffect(() => {
    if (price) {
      const priceStr = price.now.fmtPrice

      const symbol = `${pairInfo.base.symbol}/${pairInfo.quote.symbol}`

      document.title = `${priceStr} | ${symbol} | Predy V6`
    }
  }, [pairInfo, price])

  if (!price) {
    return <LoadingIndicator />
  }

  return (
    <>
      <div className="flex justify-between items-center space-x-8">
        <PairSelector chainId={DEFAULT_CHAIN} items={items} />
        <div
          className={`text-xl font-bold ${isUp ? 'text-green' : 'text-red'}`}
        >
          {price.day.fmtPrice}
        </div>
        <AiOutlineLineChart
          className="md:hidden w-[20px] h-[20px]"
          color={isChartOpen ? '#FFF' : '#777'}
          onClick={() => {
            setIsChartOpen(!isChartOpen)
          }}
        />
      </div>
      <div className="hidden md:block md:flex justify-between items-center space-x-4 text-sm">
        <div>
          <div className="text-fourth">24h Change</div>
          {price24h ? (
            price24h.change > 0 ? (
              <div className="text-green">{price24h.change}%</div>
            ) : (
              <div className="text-red">{price24h.change}%</div>
            )
          ) : (
            '...'
          )}
        </div>
        <div>
          <div className="text-fourth">24h High</div>
          <div>{price24h ? formatPrice(pairInfo, price24h.high) : '...'}</div>
        </div>
        <div>
          <div className="text-fourth">24h Low</div>
          <div>{price24h ? formatPrice(pairInfo, price24h.low) : '...'}</div>
        </div>
      </div>
    </>
  )
}
