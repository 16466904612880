export const STRATEGY_TEMPLATE = [
  {
    id: 1,
    title: 'Low Risk',
    leverage: 3,
    autoHedge: true,
    duration: 24,
    timeInterval: 8,
    priceTrigger: 2.7,
    isGammaLong: false
  },
  {
    id: 2,
    title: 'High APR',
    leverage: 6,
    autoHedge: true,
    duration: 12,
    timeInterval: 4,
    priceTrigger: 1.6,
    isGammaLong: false
  },
  {
    id: 3,
    title: 'Super APR',
    leverage: 12,
    autoHedge: false,
    duration: 1,
    timeInterval: 0,
    priceTrigger: 0,
    isGammaLong: false
  } /*,
  {
    id: 4,
    title: 'Gamma Long',
    leverage: 40,
    autoHedge: false,
    duration: 1,
    timeInterval: 0,
    priceTrigger: 0,
    isGammaLong: true
  }*/
]
