import React from 'react'
import { AmountForm } from '../../common'

interface Props {
  value: number
  isLoading: boolean
  availableAmount: number
  availableInPool: number
  setValue: (value: number) => void
}

export const DepositAmountForm = ({
  value,
  setValue,
  availableAmount,
  availableInPool,
  isLoading
}: Props) => {
  return (
    <>
      <AmountForm
        title="Amount"
        unit="USDC"
        amount={value}
        isAlert={
          !isLoading &&
          (value > availableAmount || value >= availableInPool || value < 10)
        }
        alertText={
          value < 10
            ? 'amount must be greater than 10'
            : value >= availableInPool
            ? 'No available liquidity in pool'
            : 'Insufficient balance'
        }
        step={10}
        onChange={setValue}
      />
      <div className="flex justify-between items-center space-x-2 text-sm">
        <button
          className={`basis-1/4 h-10 p-1 rounded ${
            value === 100 ? 'bg-accent' : 'bg-secondary'
          }`}
          onClick={() => {
            setValue(100)
          }}
        >
          100
        </button>
        <button
          className={`basis-1/4 h-10 p-1 rounded ${
            value === 200 ? 'bg-accent' : 'bg-secondary'
          }`}
          onClick={() => {
            setValue(200)
          }}
        >
          200
        </button>
        <button
          className={`basis-1/4 h-10 p-1 rounded ${
            value === 500 ? 'bg-accent' : 'bg-secondary'
          }`}
          onClick={() => {
            setValue(500)
          }}
        >
          500
        </button>
        <button
          className={`basis-1/4 h-10 p-1 rounded ${
            value === 1000 ? 'bg-accent' : 'bg-secondary'
          }`}
          onClick={() => {
            setValue(1000)
          }}
        >
          1000
        </button>
      </div>
    </>
  )
}
