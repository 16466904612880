import React, { useCallback, useState } from 'react'
import { PairInfo } from '../../../constants'
import { Address, zeroAddress } from 'viem'
import { Direction } from './DirectionSelector'
import { AmountForm, LoadingIndicator } from '../../common'
import { AmountSlider } from './AmountSlider'
import { toUnscaledPrice } from '../../../utils'
import { useBalanceQuery } from '../../../hooks/erc20/balance'
import { toScaled, toUnscaled } from '../../../utils/bn'
import { TradeResult } from './TradeResult'
import { useSpotLimitOrder } from '../../../hooks/spot/useSpotOrder'
import { roundDigit } from '../../../utils/number'

export function LimitOrderForm({
  chainId,
  pairInfo,
  trader,
  direction,
  price
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  direction: Direction
  price: bigint
}) {
  const [limitPrice, setLimitPrice] = useState(toUnscaledPrice(pairInfo, price))
  const [amount, setAmount] = useState<number | null>(null)
  const [total, setTotal] = useState<number | null>(null)
  const quoteBalance = useBalanceQuery(
    chainId,
    pairInfo.quote.address,
    trader,
    zeroAddress
  )
  const baseBalance = useBalanceQuery(
    chainId,
    pairInfo.base.address,
    trader,
    zeroAddress
  )

  const maxAmountBuy = quoteBalance.data
    ? roundDigit(
        toUnscaled(
          quoteBalance.data?.balance,
          pairInfo.quote.decimals,
          pairInfo.quote.precision
        ) / limitPrice,
        pairInfo.base.precision
      )
    : 0
  const maxTotalBuy = maxAmountBuy * limitPrice

  const maxAmountSell = baseBalance.data
    ? toUnscaled(
        baseBalance.data?.balance,
        pairInfo.base.decimals,
        pairInfo.base.precision
      )
    : 0
  const maxTotalSell = maxAmountSell * limitPrice

  const maxAmount = roundDigit(
    direction === Direction.Long ? maxAmountBuy : maxAmountSell,
    pairInfo.base.precision
  )
  const maxTotal = roundDigit(
    direction === Direction.Long ? maxTotalBuy : maxTotalSell,
    pairInfo.quote.precision
  )

  const onAmountChanged = useCallback(
    (amount: number) => {
      setAmount(amount)
      setTotal(roundDigit(amount * limitPrice, pairInfo.quote.precision))
    },
    [setAmount, setTotal, limitPrice, pairInfo.quote.precision]
  )

  const onTotalChanged = useCallback(
    (amount: number) => {
      setTotal(amount)
      setAmount(roundDigit(amount / limitPrice, pairInfo.base.precision))
    },
    [setTotal, setAmount, limitPrice, pairInfo.base.precision]
  )

  const order = useSpotLimitOrder(
    chainId,
    pairInfo,
    trader,
    direction === Direction.Long,
    toScaled(amount || 0, pairInfo.base.decimals),
    toScaled(total || 0, pairInfo.quote.decimals)
  )

  return (
    <div>
      <div className="space-y-3">
        {direction === Direction.Long ? (
          <AvblBalance
            balance={
              (quoteBalance.data
                ? toUnscaled(quoteBalance.data.balance, pairInfo.quote.decimals)
                : 0) +
              ' ' +
              pairInfo.quote.symbol
            }
          />
        ) : (
          <AvblBalance
            balance={
              (baseBalance.data
                ? toUnscaled(baseBalance.data.balance, pairInfo.base.decimals)
                : 0) +
              ' ' +
              pairInfo.base.symbol
            }
          />
        )}
        <div>
          <LimitPriceForm
            limitPrice={limitPrice}
            setLimitPrice={setLimitPrice}
            pairInfo={pairInfo}
          />
        </div>
        <div className="space-y-4">
          <AmountForm
            title="Amount"
            amount={amount}
            max={maxAmount}
            onChange={onAmountChanged}
            unit={pairInfo.base.symbol}
            isAlert={amount === null ? false : amount > maxAmount}
          />
          <AmountSlider
            amount={amount || 0}
            maxAmount={maxAmount}
            setAmount={onAmountChanged}
            precision={pairInfo.base.precision}
          />
        </div>
      </div>
      <div className="mt-6">
        <AmountForm
          title="Total"
          amount={total}
          max={maxTotal}
          onChange={onTotalChanged}
          unit={pairInfo.quote.symbol}
        />
      </div>
      <div className="mt-6">
        {order ? (
          <TradeResult
            chainId={chainId}
            pairInfo={pairInfo}
            trader={trader}
            direction={direction}
            maxAmount={direction === Direction.Long ? maxAmount : maxTotal}
            order={order}
            isLimitOrder={true}
            disabled={amount === null ? true : amount > maxAmount}
          />
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </div>
  )
}

function LimitPriceForm({
  limitPrice,
  setLimitPrice,
  pairInfo
}: {
  limitPrice: number
  setLimitPrice: (limitPrice: number) => void
  pairInfo: PairInfo
}) {
  return (
    <AmountForm
      title="Price"
      amount={limitPrice}
      onChange={setLimitPrice}
      unit={pairInfo.quote.symbol}
    />
  )
}

const AvblBalance = ({ balance }: { balance: string }) => {
  return (
    <div className="flex justify-start text-xs space-x-2">
      <div className="text-white5">Avbl</div>
      <div>{balance}</div>
    </div>
  )
}
