import React from 'react'
import { useTradeRoute } from '../hooks/useTradeRoute'
import NotFound from '../components/NotFound'
import { PAIR_INFOS } from '../constants/assets'
import { PerpMarket } from '../components/perp/PerpMarket'

const PerpView = () => {
  const tradeRoute = useTradeRoute()

  if (tradeRoute.chainId === null || tradeRoute.pairTitle === null) {
    return <NotFound />
  }

  const pairInfo = PAIR_INFOS[tradeRoute.chainId][tradeRoute.pairTitle]

  return <PerpMarket chainId={tradeRoute.chainId} pairInfo={pairInfo} />
}

export default PerpView
