import { useSimulateContract } from 'wagmi'
import { ADDRESS_MAP } from '../../constants/addresses'
import { predyPoolAbi } from '../../abis/PredyPool'

export function useSupply(
  chainId: number,
  pairId: number,
  supplyAmount: bigint,
  isStable: boolean
) {
  return useSimulateContract({
    chainId,
    address: ADDRESS_MAP[chainId].PredyPool,
    abi: predyPoolAbi,
    functionName: 'supply',
    args: [BigInt(pairId), isStable, supplyAmount],
    query: {
      enabled: pairId >= 1 && supplyAmount > 0n
    }
  })
}
