import React, { ReactNode } from 'react'

type Props = {
  type?: 'primary' | 'red' | 'green' | 'accent'
  children?: ReactNode
  size?: 'sm' | 'md' | 'lg' | 'xs'
  disabled?: boolean
  onClick?: () => void
}

export const PrimaryButton = ({
  type = 'primary',
  size = 'lg',
  children,
  disabled = false,
  onClick = () => {}
}: Props) => {
  const getColor = (type: string) => {
    switch (type) {
      case 'primary':
        return 'bg-primary hover:bg-high-primary'
      case 'red':
        return 'bg-red hover:bg-high-red'
      case 'green':
        return 'bg-green hover:bg-high-green'
      default:
        return 'bg-accent hover:bg-accent'
    }
  }
  return (
    <button
      className={`w-full h-full leading-6 rounded-lg text-${size} ${getColor(
        type
      )} text-white font-semibold ${disabled ? 'cursor-not-allowed' : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const AccentButton = ({
  size = 'md',
  children,
  disabled = false,
  onClick = () => {}
}: Props) => {
  return (
    <button
      className={`w-full h-full leading-6 rounded-lg text-${size} bg-accent hover:bg-high-accent text-white font-semibold ${
        disabled ? 'cursor-not-allowed' : ''
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const AccentStrokeButton = ({
  children,
  disabled = false,
  onClick = () => {}
}: Props) => {
  return (
    <button
      className={`w-full h-full leading-6 rounded-lg text-md border-[1px] hover:bg-secondary border-accent hover:border-high-accent text-white font-semibold ${
        disabled ? 'cursor-not-allowed' : ''
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const CancelButton = ({
  children,
  size = 'lg',
  disabled = false,
  onClick = () => {}
}: Props) => {
  return (
    <button
      className={`w-full h-full leading-6 rounded-lg text-${size} text-white7 bg-secondary hover:bg-secondary7 text-white font-semibold`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
