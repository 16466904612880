import React from 'react'
import { useSpotTradeHistory } from '../../../hooks/spot/useSpotTradeHistory'
import { Address } from 'viem'
import { toTimeString } from '../../../utils/string'
import { LoadingIndicator } from '../../common/LoadingIndicator'
import { HorizontalRule } from '../../common/HorizontalRule'

export const TradeHistory = ({
  chainId,
  trader
}: {
  chainId: number
  trader: Address
}) => {
  const tradeHistory = useSpotTradeHistory(chainId, trader)

  if (tradeHistory.loading) {
    return <LoadingIndicator />
  }

  return (
    <div className="px-4 text-xs">
      {tradeHistory.data === null || tradeHistory.data.length === 0 ? (
        <div className="flex justify-center items-center text-third">
          No History
        </div>
      ) : (
        <div className="text-fourth flex justify-around">
          <div className="basis-[18%]">Time</div>
          <div className="basis-[21%]">Symbol</div>
          <div className="basis-[20%]">Side</div>
          <div className="basis-[20%]">Price</div>
          <div className="basis-[21%]">Quantity</div>
        </div>
      )}
      <HorizontalRule />
      {tradeHistory.data ? (
        tradeHistory.data.map((item, index) => (
          <div className="my-2 text-white flex justify-around" key={index}>
            <a
              className="basis-[18%] hover:underline"
              target="_blank"
              rel="noreferrer"
              href={item.link}
            >
              {toTimeString(item.createdAt)}
            </a>
            <div className="basis-[21%]">{item.pair?.base.symbol}</div>
            <div className="basis-[20%]">{item.isBuy ? 'Buy' : 'Sell'}</div>
            <div className="basis-[20%]">{item.price}</div>
            <div className="basis-[21%]">{item.quantity}</div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  )
}
