import React from 'react'
import { InputForm } from './InputForm'
import { roundDigit } from '../../../utils/number'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'

export type AmountFormProps = {
  title: string | React.ReactNode
  unit?: string
  disabled?: boolean
  isAlert?: boolean
  disabledText?: string
  alertText?: string
  isRatio?: boolean
  max?: number
  step?: number
  digits?: number
  amount: number | null
  onChange?: (amount: number) => void
  onFocus?: () => void
}

export const AmountForm = ({
  title,
  unit,
  amount,
  max,
  step = 1,
  digits = 2,
  disabled = false,
  isRatio = false,
  isAlert = false,
  alertText = 'Your balance is not enough',
  disabledText,
  onChange,
  onFocus
}: AmountFormProps) => {
  return (
    <div
      className={`px-3 w-full h-12 md:h-10 text-sm font-normal rounded bg-secondary select-none flex justify-between items-center ${
        disabled || !!disabledText
          ? 'bg-secondary7'
          : (isAlert ? 'border-red' : 'border-secondary hover:border-accent') +
            ' border-[1px] bg-secondary'
      }`}
    >
      {isAlert ? (
        <div className="relative">
          <div className="absolute -top-16 whitespace-nowrap z-10 inline-block px-3 py-2 text-xs font-medium text-white bg-panel border-panelborder border-[1px] rounded-lg shadow-sm dark:bg-gray-700 before:w-4 before:h-4 before:rotate-45 before:bg-panel before:absolute before:z-[-1] before:-bottom-1 before:left-0 before:right-0 before:mx-auto">
            {alertText}
          </div>
        </div>
      ) : (
        <></>
      )}

      {disabledText ? (
        <></>
      ) : (
        <AiOutlineMinus
          className="w-6 text-center block md:hidden text-fourth text-lg cursor-pointer"
          onClick={() => {
            if (onChange) {
              if (isRatio) {
                onChange(amount ? amount - 1 : 0)
              } else {
                onChange(roundDigit(amount ? amount - step : step, digits || 2))
              }
            }
          }}
        >
          -
        </AiOutlineMinus>
      )}

      <span className="hidden md:block text-fourth whitespace-nowrap">
        {title}
      </span>

      <div className="w-full md:w-7/8">
        <div className="block md:hidden text-center text-fourth text-xs flex justify-center space-x-1">
          {title}
          <div>{unit ? '(' + unit + ')' : ''}</div>
        </div>

        {disabledText ? (
          <InputForm
            value={disabledText}
            type={'text'}
            className={`w-full h-full bg-secondary7 text-center md:text-right md:pr-2`}
            disabled={true}
          />
        ) : (
          <InputForm
            type={isRatio ? 'text' : 'number'}
            value={isRatio ? amount + '%' : amount ? amount.toString() : ''}
            disabled={disabled}
            step={step}
            max={max}
            onChange={(value: string) => {
              if (onFocus) onFocus()

              const numValue = Number(value)

              if (onChange && !isNaN(numValue))
                onChange(roundDigit(numValue, digits))
            }}
            onFocus={onFocus}
            className={`w-full h-full bg-secondary text-center md:text-right md:pr-2`}
          />
        )}
      </div>

      {unit ? (
        <span className="hidden md:block text-white">{unit}</span>
      ) : (
        <div />
      )}

      {disabledText ? (
        <></>
      ) : (
        <AiOutlinePlus
          className="w-6 text-center block md:hidden text-fourth text-lg cursor-pointer"
          onClick={() => {
            if (onChange)
              if (isRatio) {
                onChange(amount ? amount + 1 : 1)
              } else {
                onChange(roundDigit(amount ? amount + step : step, digits || 2))
              }
          }}
        ></AiOutlinePlus>
      )}
    </div>
  )
}
