import React, { ReactNode } from 'react'

type Props = {
  children?: ReactNode
  onSelect: (tabId: number) => void
}

type TabProps = {
  children?: ReactNode
  tabId: number
  className?: string
  selected?: boolean
}

export const TabList = ({ children, onSelect }: Props) => {
  return (
    <ul
      className="flex items-center justify-start space-x-3 md:space-x-4 text-sm font-semibold"
      onClick={(e: any) => {
        if (e.target.tabIndex >= 0) {
          onSelect(e.target.tabIndex)
        }
      }}
    >
      {children}
    </ul>
  )
}

export const Tab = ({ children, tabId, selected, className }: TabProps) => {
  return (
    <li
      className={`rounded-xl my-2 py-2 px-4 cursor-pointer ${
        selected ? 'text-accent' : 'text-third hover:text-accent'
      } ${className}`}
      tabIndex={tabId}
    >
      {children}
    </li>
  )
}
