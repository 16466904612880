import create from 'zustand'

interface State {
  currentPair: string
  isQuote: boolean
  selectPair: (pair: string) => void
  setIsQuote: (isQuote: boolean) => void
}

const useStore = create<State>(set => ({
  currentPair: 'eth-usdc',
  isQuote: false,
  selectPair: (currentPair: string) => set({ currentPair }),
  setIsQuote: (isQuote: boolean) => set({ isQuote })
}))

export default useStore
