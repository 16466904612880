import '@rainbow-me/rainbowkit/styles.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import App from './App'
import './index.css'
import { WagmiProvider, createConfig, http } from 'wagmi'
import { arbitrum } from 'wagmi/chains'
import { Buffer } from 'buffer'
import { TrackJS } from 'trackjs'
import {
  RainbowKitProvider,
  connectorsForWallets
} from '@rainbow-me/rainbowkit'
import {
  metaMaskWallet,
  coinbaseWallet,
  rabbyWallet,
  okxWallet,
  walletConnectWallet
} from '@rainbow-me/rainbowkit/wallets'

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        metaMaskWallet,
        coinbaseWallet,
        rabbyWallet,
        okxWallet,
        walletConnectWallet
      ]
    }
  ],
  {
    appName: 'Predy V6',
    projectId: 'fe2d9d4986f43c69cbe41422c9801ec3'
  }
)

const config = createConfig({
  chains: [arbitrum],
  transports: {
    [arbitrum.id]: http()
  },
  connectors
})

TrackJS.install({
  token: '1154f0357e9347018bdf5f56c8b658cb',
  application: 'predyv6',
  network: {
    enabled: false
  }
})

// @ts-expect-error avoid Buffer is not defined
window.Buffer = Buffer

/*
const config = createConfig({
  chains: [arbitrum, localhost],
  transports: {
    [arbitrum.id]: http(),
    [localhost.id]: http()
  },
  connectors: [
    injected({ target: 'metaMask' }),
    injected({ target: 'rabby' }),
    coinbaseWallet({
      appName: 'wagmi'
    })
    walletConnect({
      projectId: 'fe2d9d4986f43c69cbe41422c9801ec3'
    })
  ]
})
*/

const queryClient = new QueryClient()

const root = createRoot(document.getElementById('root')!) // eslint-disable-line

root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <App />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
)
