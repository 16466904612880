import React, { useState } from 'react'
import { PriceChart } from '../chart/TradingViewWidget'
import { TradeForm } from './trade/TradeForm'
import { PositionTab } from './position/PositionTab'
import { ChartTop } from './center/ChartTop'
import { DEFAULT_CHAIN, PairInfo } from '../../constants'

interface Props {
  chainId: number
  pairInfo: PairInfo
}

export const PerpMarket = ({ chainId, pairInfo }: Props) => {
  const mediaQuery = window.matchMedia('(width < 768px)')

  const [isChartOpen, setIsChartOpen] = useState(!mediaQuery.matches)

  return (
    <div className="flex justify-center flex-col md:flex-row">
      <div className="grow">
        <ChartTop
          chainId={chainId || DEFAULT_CHAIN}
          pairInfo={pairInfo}
          isChartOpen={isChartOpen}
          setIsChartOpen={setIsChartOpen}
        />
        <div className="z-0 border-b-[0px] border-panelborder">
          <PriceChart isChartOpen={isChartOpen} />
        </div>
        <div className="block md:hidden">
          <TradeForm chainId={chainId} pairInfo={pairInfo} />
        </div>
        <div>
          <div className="p-2 border-[1px] border-panelborder">
            <PositionTab chainId={chainId} pairInfo={pairInfo} />
          </div>
        </div>
      </div>
      <div className="w-[340px] min-w-[300px] hidden md:block">
        <TradeForm chainId={chainId} pairInfo={pairInfo} />
      </div>
    </div>
  )
}
