import { abs } from './bn'

function calLog(x: bigint) {
  let result = 0n
  while (x > 1n) {
    result += 1n
    x = x / 2n
  }
  return result
}

export function calculateLogStep(x: bigint) {
  if (x === 0n) return 0n

  const n = calLog(abs(x)) + 1n

  if (x > 0n) {
    return 2n ** n
  } else {
    return -(2n ** n)
  }
}
