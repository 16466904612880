import React, { useCallback, useMemo, useState } from 'react'
import { PairInfo } from '../../constants'
import { calculateGammaPosition } from '../../utils/gamma'
import { StrongTitle } from './common/Title'
import { APRComponent } from './common/APRComponent'
import { AccentButton, BaseModal, CancelButton } from '../common'
import { Address } from 'viem'
import { useAvbl } from '../../hooks/perp/useAvbl'
import { useGammaOrder } from '../../hooks/gamma/useGammaOrder'
import { toScaled, toUnscaled } from '../../utils/bn'
import { useQuoteGammaTrade } from '../../hooks/gamma/useQuoteGammaTrade'
import { useSendGammaOrder } from '../../hooks/gamma/useSendGammaOrder'
import { DepositAmountForm } from './common/DepositAmountForm'
import { useAPR } from '../../hooks/interest/useAPR'
import { usePermit } from '../../hooks/usePermit'

export const TemplateCardSM = ({
  chainId,
  pairInfo,
  price,
  symbol,
  title,
  leverage,
  templateId,
  isAutoClose = false,
  isAutoHedge = false,
  onClick
}: {
  chainId: number
  pairInfo: PairInfo
  price: number
  symbol: string
  title: string
  leverage: number
  templateId: number
  isAutoHedge?: boolean
  isAutoClose?: boolean
  onClick: (templateId: number) => void
}) => {
  return (
    <div
      className={`p-3 md:flex md:justify-between items-center bg-panel border-panelborder border-[1px] md:space-x-3 space-y-3 rounded`}
    >
      <div className="md:basis-2/3 space-y-3">
        <div className="flex justify-between items-center space-x-2">
          <div className="flex items-center space-x-1">
            <StrongTitle>{title}</StrongTitle>
          </div>
          <div className="flex space-x-1">
            <div className="font-semibold text-high-green">Fee APR</div>
            <APRComponent
              chainId={chainId}
              pairInfo={pairInfo}
              price={price}
              leverage={leverage}
            />
          </div>
        </div>

        <div className="flex space-x-2 flex justify-between items-center">
          <div className="flex items-center space-x-1">
            <div className="text-xs text-fourth">{symbol}</div>
            <div className="px-1 bg-secondary rounded flex items-center text-xs">
              {leverage}x
            </div>
          </div>

          <div className="flex items-center space-x-1">
            {isAutoClose ? (
              <div className="p-[2px] w-[88px] text-xs font-light bg-secondary rounded text-center">
                Auto Close
              </div>
            ) : null}

            {isAutoHedge ? (
              <div className="p-[2px] w-[88px] text-xs font-light bg-secondary rounded text-center">
                Auto Hedge
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="md:basis-1/3 h-9">
        <AccentButton
          onClick={() => {
            onClick(templateId)
          }}
        >
          Create Position
        </AccentButton>
      </div>
    </div>
  )
}

export const TemplateCardPC = ({
  chainId,
  pairInfo,
  price,
  symbol,
  title,
  leverage,
  templateId,
  isAutoClose = false,
  isAutoHedge = false,
  onClick
}: {
  chainId: number
  pairInfo: PairInfo
  price: number
  symbol: string
  title: string
  leverage: number
  templateId: number
  isAutoHedge?: boolean
  isAutoClose?: boolean
  onClick: (templateId: number) => void
}) => {
  return (
    <tr className="p-2">
      <td className="p-3 flex flex-col justify-start items-start space-y-1">
        <StrongTitle>{title}</StrongTitle>
        <div className="flex items-center space-x-1">
          <div className="text-xs text-fourth">{symbol}</div>
          <div className="px-1 bg-secondary rounded flex items-center text-xs">
            {leverage}x
          </div>
        </div>
      </td>
      <td className="p-2 text-end align-middle">
        <APRComponent
          chainId={chainId}
          pairInfo={pairInfo}
          price={price}
          leverage={leverage}
        />
      </td>

      <td className="p-2 text-end align-middle">
        <div className="flex justify-end space-x-2">
          {isAutoHedge ? (
            <div className="p-1 text-xs font-light bg-secondary rounded text-center">
              Auto Hedge
            </div>
          ) : null}
          {isAutoClose ? (
            <div className="p-1 text-xs font-light bg-secondary rounded text-center">
              Auto Close
            </div>
          ) : null}
        </div>
      </td>

      <td className="p-2 align-middle">
        <div className="flex justify-center">
          <div className="w-[128px] h-9">
            <AccentButton
              onClick={() => {
                onClick(templateId)
              }}
            >
              Create Position
            </AccentButton>
          </div>
        </div>
      </td>
    </tr>
  )
}

export const DepositForm = ({
  chainId,
  pairInfo,
  trader,
  leverage,
  price,
  availableInPool,
  isGammaLong
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  leverage: number
  price: number
  availableInPool: number
  isGammaLong: boolean
}) => {
  const available = useAvbl(chainId, pairInfo, trader)

  const [isOpen, setIsOpen] = useState(false)
  const [amount, setAmount] = useState(100)

  const position = useMemo(() => {
    return calculateGammaPosition(amount, price, leverage, isGammaLong)
  }, [price, amount, leverage, isGammaLong])

  const apr = useAPR(chainId, pairInfo, 2, price, leverage)

  const order = useGammaOrder(
    chainId,
    pairInfo,
    trader,
    position.underlying,
    position.squart,
    toScaled(amount, pairInfo.quote.decimals),
    leverage,
    price,
    {
      isEnabled: false
    }
  )
  const quote = useQuoteGammaTrade(chainId, order)

  const { signTypedData, variables } = useSendGammaOrder(
    order,
    quote.data?.routeIndex || 0,
    () => {}
  )

  const { isEnoughAllowance, approve } = usePermit(
    chainId,
    pairInfo,
    trader,
    toScaled(amount, pairInfo.quote.decimals)
  )

  const modifyPositionHandler = useCallback(() => {
    if (isEnoughAllowance) {
      signTypedData({
        domain: variables.domain,
        types: variables.types,
        message: variables.message,
        primaryType: 'PermitWitnessTransferFrom'
      })
    } else {
      approve()
    }
  }, [isEnoughAllowance, approve, signTypedData, variables])

  const availableAmount =
    available === null
      ? null
      : toUnscaled(available, pairInfo.quote.decimals, pairInfo.quote.precision)

  return (
    <>
      <AccentButton onClick={() => setIsOpen(true)}>Create</AccentButton>
      <BaseModal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false)
        }}
        height={300}
        maxWidth={400}
      >
        <div className="flex flex-col space-y-3">
          <h1 className="text-base font-bold">Quick Create Form</h1>

          <div className="flex text-xs space-x-1">
            <div className="text-white5">Avbl</div>
            <div className="text-fourth">{availableAmount} USDC</div>
          </div>

          <DepositAmountForm
            value={amount}
            setValue={setAmount}
            availableAmount={availableAmount || 0}
            availableInPool={availableInPool}
            isLoading={availableAmount === null}
          />

          <div className="flex justify-between items-center">
            <div className="text-white5 text-xs">Est. daily income</div>
            <div className="text-white text-xs">
              {apr
                ? ((apr * amount) / 36500).toFixed(pairInfo.quote.precision)
                : '-'}{' '}
              {pairInfo.quote.symbol}
            </div>
          </div>

          <div className="flex justify-between space-x-3 h-10">
            <CancelButton size="md" onClick={() => setIsOpen(false)}>
              Cancel
            </CancelButton>
            <AccentButton onClick={modifyPositionHandler}>
              {isEnoughAllowance ? 'Create Position' : 'Approve'}
            </AccentButton>
          </div>
        </div>
      </BaseModal>
    </>
  )
}
