import React from 'react'
import { PairInfo } from '../../../constants'
import { useAPR } from '../../../hooks/interest/useAPR'
import { LoadingIndicator } from '../../common'

export const APRComponent = ({
  chainId,
  pairInfo,
  price,
  leverage
}: {
  chainId: number
  pairInfo: PairInfo
  price: number
  leverage: number
}) => {
  const apr = useAPR(chainId, pairInfo, 2, price, leverage)

  if (apr === null) {
    return <LoadingIndicator />
  }

  return (
    <>
      {apr > 0 ? (
        <div className="font-semibold text-high-green">
          {apr.toLocaleString(undefined, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
          })}{' '}
          %
        </div>
      ) : (
        <div className="font-semibold text-red">
          Daily Cost {(Math.abs(apr) / 365).toFixed(1)} %
        </div>
      )}
    </>
  )
}
