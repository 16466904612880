import { useQuery } from '@tanstack/react-query'
import { ONE, Q128, Q96 } from '../../constants'
import { usePrice } from '../query/usePrice'
import {
  calculateAssetInterest,
  calculateSquartInterest
} from './useAssetInterest'
import { useUniswapTradeFee24H } from './useUniswapTradeFee'
import { AssetStatus, PairStatus, usePairStatus } from '../pool/usePairStatus'
import {
  calculateAmount0Offset,
  calculateAmount1Offset
} from '../../utils/uniswap'
import { PairInfo } from '../../constants/assets'

export function useFeeEst(
  chainId: number,
  pairInfo: PairInfo,
  pairId: number,
  tradeAmountPerp: bigint,
  tradeAmount2Sqrt: bigint
) {
  const pairStatus = usePairStatus(chainId, pairId)
  const price = usePrice(chainId, pairId)
  const tradeFeeIn24h = useUniswapTradeFee24H(
    chainId,
    pairInfo.uniswapPoolAddress,
    true
  )

  return useQuery({
    queryKey: [
      'fee_est',
      chainId,
      pairId,
      tradeAmountPerp.toString(),
      tradeAmount2Sqrt.toString()
    ],
    queryFn: async () => {
      if (!pairStatus.isSuccess) throw new Error('asset not set')
      if (!price.data) throw new Error('price not set')
      if (!tradeFeeIn24h.isSuccess) throw new Error('tradeFeeIn24h not set')

      const tradeAmountStable =
        (price.data.price * tradeAmountPerp * -1n) / Q96 +
        (price.data.sqrtPrice * tradeAmount2Sqrt * -2n) / Q96 +
        calculateAmount1Offset(
          pairStatus.data.result.sqrtAssetStatus.tickLower,
          tradeAmount2Sqrt
        )

      const tradeAmountUnderlying =
        tradeAmountPerp +
        calculateAmount0Offset(
          pairStatus.data.result.sqrtAssetStatus.tickUpper,
          tradeAmount2Sqrt
        )

      return estimateFee(
        chainId,
        pairStatus.data.result,
        price.data.price,
        {
          stableAmount: tradeAmountStable,
          underlyingAmount: tradeAmountUnderlying,
          sqrt2Amount: tradeAmount2Sqrt
        },
        {
          fee0: tradeFeeIn24h.data.fee0,
          fee1: tradeFeeIn24h.data.fee1
        }
      )
    },
    enabled: pairStatus.isSuccess && price.isSuccess && tradeFeeIn24h.isSuccess
  })
}

export function estimateFee(
  chainId: number,
  pair: PairStatus,
  price: bigint,
  position: {
    stableAmount: bigint
    underlyingAmount: bigint
    sqrt2Amount: bigint
  },
  uniswapTradeFee: {
    fee0: bigint
    fee1: bigint
  }
) {
  const squartInterest = calculateSquartInterest(pair, position.sqrt2Amount, 0n)

  let tradeFeeStable = 0n
  let tradeFeeUnderlying = 0n
  if (pair.isQuoteZero) {
    tradeFeeStable = (uniswapTradeFee.fee0 * position.sqrt2Amount) / Q128
    tradeFeeUnderlying = (uniswapTradeFee.fee1 * position.sqrt2Amount) / Q128
  } else {
    tradeFeeUnderlying = (uniswapTradeFee.fee0 * position.sqrt2Amount) / Q128
    tradeFeeStable = (uniswapTradeFee.fee1 * position.sqrt2Amount) / Q128
  }

  let tradePremiumStable = 0n
  let tradePremiumUnderlying = 0n

  if (position.sqrt2Amount > 0n) {
    if (squartInterest.supply == 0n) {
      tradePremiumUnderlying = tradeFeeUnderlying
      tradePremiumStable = tradeFeeStable
    } else {
      tradePremiumUnderlying =
        (tradeFeeUnderlying *
          (squartInterest.supply +
            (squartInterest.borrow * squartInterest.spread) / 1000n)) /
        squartInterest.supply
      tradePremiumStable =
        (tradeFeeStable *
          (squartInterest.supply +
            (squartInterest.borrow * squartInterest.spread) / 1000n)) /
        squartInterest.supply
    }
  } else {
    tradePremiumUnderlying =
      (tradeFeeUnderlying * (squartInterest.spread + 1000n)) / 1000n
    tradePremiumStable =
      (tradeFeeStable * (squartInterest.spread + 1000n)) / 1000n
  }

  // Calculates Asset interest
  const interestStable = calculateDailyAssetInterest(
    pair.quotePool,
    position.stableAmount,
    0n
  )

  const interestUnderlying = calculateDailyAssetInterest(
    pair.basePool,
    position.underlyingAmount,
    0n
  )

  const totalInterestEst = interestStable + (interestUnderlying * price) / Q96
  const totalPremiumEst =
    tradePremiumStable + (tradePremiumUnderlying * price) / Q96

  const totalFeeEst = totalInterestEst + totalPremiumEst

  return {
    total: totalFeeEst,
    totalInterestEst,
    totalPremiumEst
  }
}

function calculateDailyAssetInterest(
  assetPool: AssetStatus,
  position: bigint,
  trade: bigint
) {
  const stableInterest = calculateAssetInterest(
    assetPool.tokenStatus,
    assetPool.irmParams,
    position - trade,
    trade
  )

  if (position > 0n) {
    return (position * stableInterest.supplyInterest) / ONE / 365n
  } else {
    return (position * stableInterest.borrowInterest) / ONE / 365n
  }
}
