import React from 'react'
import { Address } from 'viem'
import { PairInfo, TimeFilter } from '../../constants'
import { useGammaPositionHistory } from '../../hooks/gamma/useGammaPositionHistory'
import { InfoTooltip2, LoadingIndicator } from '../common'
import {
  PositionHistoryCardSM,
  PositionHistoryCardPC
} from './PositionHistoryCard'
import { useIsMobile } from '../../hooks/useIsMobile'

export const PositionHistoryList = ({
  chainId,
  pairInfo,
  address,
  filterTime
}: {
  chainId: number
  pairInfo: PairInfo
  address: Address
  filterTime: TimeFilter
}) => {
  const isMobile = useIsMobile()

  const positions = useGammaPositionHistory(chainId, address, filterTime)

  if (positions.data === null) {
    return <LoadingIndicator />
  }

  if (isMobile) {
    return (
      <>
        {positions.data.map((position, index) => {
          return (
            <PositionHistoryCardSM
              key={index}
              pairInfo={pairInfo}
              totalDeposit={position.totalDeposit}
              totalWithdraw={position.totalWithdraw}
              hedgeInterval={position.hedgeInterval}
              leverage={position.leverage}
              isAutoClose={position.expiration !== 0n}
              isAutoHedge={
                position.hedgeInterval > 0n || position.sqrtPriceTrigger > 0n
              }
              closeType={position.closeType}
              openedAt={position.createdAt}
              closedAt={position.updatedAt}
            />
          )
        })}
      </>
    )
  } else {
    if (!positions.data || positions.data.length === 0)
      return <div>No history</div>

    return (
      <div className="border-collapse w-full bg-panel border-panelborder border-[1px] rounded">
        <table className="w-[99%] m-auto">
          <thead className="m-1 border-b-[1px] border-white1">
            <tr className="text-xs text-white5 font-sm underline decoration-dotted">
              <th className="p-3 text-start">Close time</th>
              <th className="p-3 text-start">Symbol</th>
              <th className="p-3 text-end">Value</th>
              <th className="p-3 text-end">PNL(ROI)</th>
              <th className="p-3 text-end">
                <InfoTooltip2
                  placement="bottom"
                  description="The total time a position was open, from initiation to closure."
                >
                  Runtime
                </InfoTooltip2>
              </th>
              <th className="p-3 text-end">Hedge interval</th>
              <th className="p-3 text-end">Closed by</th>
            </tr>
          </thead>
          <tbody>
            {positions.data.map((position, index) => {
              return (
                <PositionHistoryCardPC
                  key={index}
                  pairInfo={pairInfo}
                  totalDeposit={position.totalDeposit}
                  totalWithdraw={position.totalWithdraw}
                  hedgeInterval={position.hedgeInterval}
                  leverage={position.leverage}
                  isAutoClose={position.expiration !== 0n}
                  isAutoHedge={
                    position.hedgeInterval > 0n ||
                    position.sqrtPriceTrigger > 0n
                  }
                  closeType={position.closeType}
                  openedAt={position.createdAt}
                  closedAt={position.updatedAt}
                  txHash={position.txHash}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}
