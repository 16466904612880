import { useSimulateContract } from 'wagmi'
import { ADDRESS_MAP } from '../../constants/addresses'
import { priceFeedFactoryAbi } from '../../abis/PriceFeedFactory'

export function useCreatePriceFeed(chainId: number) {
  return useSimulateContract({
    chainId,
    address: ADDRESS_MAP[chainId].PriceFeedFactory,
    abi: priceFeedFactoryAbi,
    functionName: 'createPriceFeed',
    args: [
      '0x50834f3163758fcc1df9973b6e91f0f0f0434ad3',
      '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
      10n ** 12n
    ]
  })
}
