import React, { useEffect } from 'react'
import pairStore from '../store/pair'
import positionStore from '../store/position'
import { BasePopup } from './common/BasePopup'
import { PAIR_INFOS, PairInfo } from '../constants/assets'
import { AiFillCaretDown } from 'react-icons/ai'
import { useTradeRoute } from '../hooks/useTradeRoute'
import { PairBadge } from './common/PairBadge'
import { useRealtimePrice } from '../hooks/price/useRealtimePrice'
import { use24HPriceSummary } from '../hooks/price/use24HPriceSummary'

export interface PairSelectorItem {
  id: number
  pairId?: number
  pairSymbol: string
}

interface Props {
  chainId: number
  items: PairSelectorItem[]
}

const PairSelectorItem = ({
  chainId,
  isSelected,
  pairInfo,
  onMouseDown
}: {
  chainId: number
  isSelected: boolean
  pairInfo: PairInfo
  onMouseDown: () => void
}) => {
  const price = useRealtimePrice(pairInfo)
  const summary = use24HPriceSummary(chainId, pairInfo)

  return (
    <div
      key={pairInfo.id}
      className={`px-4 mr-[1px] py-1 flex justify-between items-center cursor-pointer ${
        isSelected ? 'bg-white1' : 'hover:bg-white5'
      }`}
      onMouseDown={onMouseDown}
    >
      <div className="basis-[34%] text-sm font-semibold">
        {pairInfo.base.symbol}/{pairInfo.quote.symbol}
      </div>
      <div
        className={`text-right basis-[33%] ${
          price ? (price.now.isUp ? 'text-green' : 'text-red') : ''
        }`}
      >
        {price ? price.now.fmtPrice : '-'}
      </div>
      <div
        className={`text-right basis-[33%] ${
          summary ? (summary.change > 0 ? 'text-green' : 'text-red') : ''
        }`}
      >
        {summary ? summary.change : '-'}%
      </div>
    </div>
  )
}

export const PairSelector = ({ chainId, items }: Props) => {
  const tradeRoute = useTradeRoute()
  const { currentPair, selectPair } = pairStore()
  const { clearPosition } = positionStore()

  useEffect(() => {
    if (tradeRoute.pairTitle) {
      selectPair(tradeRoute.pairTitle)
    }
  }, [selectPair, tradeRoute.pairTitle])

  return (
    <BasePopup
      titleContent={
        <PairBadge
          pairInfo={PAIR_INFOS[chainId][currentPair]}
          className="cursor-pointer"
        >
          <AiFillCaretDown />
        </PairBadge>
      }
      isSmall={false}
    >
      <div className="w-full py-4 flex flex-col space-y-0">
        <div className="px-4 mb-4 text-xs flex justify-between items-center">
          <div className="text-left basis-[34%]">Symbol</div>
          <div className="text-right basis-[33%]">Price</div>
          <div className="text-right basis-[33%]">24h (%)</div>
        </div>
        {items.map(pairInfo => {
          return (
            <PairSelectorItem
              chainId={chainId}
              pairInfo={PAIR_INFOS[chainId][pairInfo.pairSymbol]}
              key={pairInfo.id}
              isSelected={tradeRoute.pairTitle == pairInfo.pairSymbol}
              onMouseDown={() => {
                clearPosition()
                selectPair(pairInfo.pairSymbol)
                tradeRoute.setPairTitle(pairInfo.pairSymbol)
              }}
            />
          )
        })}
      </div>
    </BasePopup>
  )
}

export const HorizontalPairSelector = ({ chainId }: { chainId: number }) => {
  const tradeRoute = useTradeRoute()
  const { selectPair } = pairStore()
  const { clearPosition } = positionStore()

  useEffect(() => {
    if (tradeRoute.pairTitle) {
      selectPair(tradeRoute.pairTitle)
    }
  }, [selectPair, tradeRoute.pairTitle])

  const pairs = PAIR_INFOS[chainId]

  return (
    <div className="h-14 flex justify-between items-center space-x-0">
      <HorizontalPairSelectorItem
        pairInfo={pairs['eth-usdc']}
        onClick={() => {
          clearPosition()
          selectPair('eth-usdc')
          tradeRoute.setPairTitle('eth-usdc')
        }}
      />
      <HorizontalPairSelectorItem
        pairInfo={pairs['wbtc-usdc']}
        onClick={() => {
          clearPosition()
          selectPair('wbtc-usdc')
          tradeRoute.setPairTitle('wbtc-usdc')
        }}
      />
      <HorizontalPairSelectorItem
        pairInfo={pairs['arb-usdc']}
        onClick={() => {
          clearPosition()
          selectPair('arb-usdc')
          tradeRoute.setPairTitle('arb-usdc')
        }}
      />
    </div>
  )
}

const HorizontalPairSelectorItem = ({
  pairInfo,
  onClick
}: {
  pairInfo: PairInfo
  onClick: () => void
}) => {
  const price = useRealtimePrice(pairInfo)

  if (!price) {
    return <></>
  }

  return (
    <div
      className="px-4 py-2 hover:bg-white1 flex justify-between items-center text-xs space-x-1 cursor-pointer"
      onClick={onClick}
    >
      <div className="text-fourth">
        {pairInfo.base.symbol + pairInfo.quote.symbol}
      </div>
      <div className={`${price.now.isUp ? 'text-green' : 'text-red'}`}>
        {price.now.fmtPrice}
      </div>
    </div>
  )
}
