import { gql } from '@apollo/client'

export const PERP_HISTORY_QUERY = gql`
  query ($address: String) {
    perpTradeHistoryItems(
      first: 20
      where: { trader: $address }
      orderBy: createdAt
      orderDirection: desc
    ) {
      id
      pair {
        id
      }
      trader
      action
      size
      entryValue
      payoff
      margin
      fee
      txHash
      createdAt
    }
  }
`

export type PerpHistoryItemEntities = {
  perpTradeHistoryItems: {
    id: string
    pair: {
      id: string
    }
    trader: string
    action: string
    size: string
    entryValue: string
    payoff: string
    margin: string
    fee: string
    txHash: string
    createdAt: string
  }[]
}
