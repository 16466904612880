import { useSimulateContract } from 'wagmi'
import { ADDRESS_MAP } from '../../constants/addresses'
import { predyPoolAbi } from '../../abis/PredyPool'

export function useWithdraw(
  chainId: number,
  pairId: number,
  withdrawAmount: bigint,
  isStable: boolean
) {
  return useSimulateContract({
    chainId,
    address: ADDRESS_MAP[chainId].PredyPool,
    abi: predyPoolAbi,
    functionName: 'withdraw',
    args: [BigInt(pairId), isStable, withdrawAmount]
  })
}
