import { SpotOrderV3 } from '@predy/js-sdk'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { calculateLogStep } from '../../utils/calculateLogStep'
import { apiGet } from '../../utils/fetch'
import { DEFAULT_STALE_TIME, REFETCH_INTERVAL } from '../../constants'

interface BestSpotQuote {
  quote: bigint
  routeIndex: number
  timestamp: number
}

function deserializeSpotQuote(spotQuote: {
  quote: string
  routeIndex: number
  timestamp: number
}): BestSpotQuote {
  return {
    quote: BigInt(spotQuote.quote),
    routeIndex: spotQuote.routeIndex,
    timestamp: spotQuote.timestamp
  }
}

function updateOrderAmount(order: SpotOrderV3, newBaseTokenAmount: bigint) {
  const witnessInfo = order.witnessInfo()

  witnessInfo.baseTokenAmount = newBaseTokenAmount

  return new SpotOrderV3(witnessInfo, order.chainId)
}

export interface SpotQuoteResult {
  data: {
    quoteAmount: bigint
    routeIndex: number
  } | null
  isLoading: boolean
  error: Error | null
}

export function useQuoteSpotTrade(
  chainId: number,
  order: SpotOrderV3
): SpotQuoteResult {
  const baseTokenAmount = order.witnessInfo().baseTokenAmount

  const step = calculateLogStep(baseTokenAmount)

  const query = useQuery<BestSpotQuote, Error>({
    queryKey: [
      'quote_spot',
      chainId,
      order.spotOrder.baseToken,
      step.toString()
    ],
    queryFn: async () => {
      if (step === 0n) {
        throw new Error('step must not be 0')
      }

      const query = new URLSearchParams({
        order: updateOrderAmount(order, step).serialize(),
        chainId: chainId.toString()
      })

      const result = await apiGet('order/v2/spot/quote', query)

      if (result.message && result.message.length > 0) {
        throw new Error(result.message)
      }

      const quoteResult = deserializeSpotQuote(result)

      if (quoteResult.routeIndex === -1) {
        throw new Error('no routes found')
      }

      return quoteResult
    },
    enabled: step != 0n,
    refetchInterval: REFETCH_INTERVAL,
    staleTime: DEFAULT_STALE_TIME
  })

  const price = query.data ? BigInt(query.data.quote) : 0n

  const quoteResult = useMemo(() => {
    if (baseTokenAmount !== 0n && query.data) {
      return {
        quoteAmount: (baseTokenAmount * price) / step,
        routeIndex: query.data.routeIndex
      }
    }

    return null
  }, [query.data, baseTokenAmount, price, step])

  return {
    data: quoteResult,
    isLoading: quoteResult === null,
    error: query.error
  }
}
