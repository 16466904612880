import { useCallback } from 'react'
import { useBalanceQuery } from './erc20/balance'
import { ADDRESS_MAP, PairInfo } from '../constants'
import { usePermitV1 } from './contracts/usePermitV1'
import { Address, maxUint256 } from 'viem'

export function usePermit(
  chainId: number,
  pairInfo: PairInfo,
  trader: Address,
  amount: bigint
) {
  const balance = useBalanceQuery(
    chainId,
    pairInfo.quote.address,
    trader,
    ADDRESS_MAP[chainId].Permit2
  )
  const { signTypedData, variables, error, isNonceLoading } = usePermitV1(
    chainId,
    trader,
    pairInfo.quote.address,
    maxUint256
  )

  const isEnoughAllowance =
    balance.data !== undefined &&
    balance.data.isLoaded &&
    balance.data.allowance >= amount
  const isEnoughBalance =
    balance.data !== undefined &&
    balance.data.isLoaded &&
    balance.data.balance >= amount

  const approve = useCallback(() => {
    if (!isEnoughAllowance) {
      if (signTypedData) {
        signTypedData({
          domain: variables.domain,
          types: variables.types,
          message: variables.message,
          primaryType: 'Permit'
        })
      }
    }
  }, [isEnoughAllowance, signTypedData, variables])

  return { isEnoughBalance, isEnoughAllowance, isNonceLoading, approve, error }
}
