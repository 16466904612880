import React from 'react'

export enum Direction {
  Long,
  Short
}

interface Props {
  direction: Direction
  setDirection: (direction: Direction) => void
}

export const DirectionSelector = ({ direction, setDirection }: Props) => {
  return (
    <div className="h-full flex justify-around items-center">
      <div
        onClick={() => {
          setDirection(Direction.Long)
        }}
        className={`p-2 w-full h-full flex justify-center items-center cursor-pointer ${
          direction === Direction.Long
            ? 'bg-green3 border-b-4 border-green2'
            : 'bg-background text-text-light hover:text-text-highlight'
        }`}
      >
        Buy
      </div>
      <div
        onClick={() => {
          setDirection(Direction.Short)
        }}
        className={`p-2 w-full h-full flex justify-center items-center cursor-pointer ${
          direction === Direction.Short
            ? 'bg-red3 border-b-4 border-red2'
            : 'bg-background text-text-light hover:text-text-highlight'
        }`}
      >
        Sell
      </div>
    </div>
  )
}
