import { useMemo, useState } from 'react'
import { Bytes, SpotOrderV3 } from '@predy/js-sdk'
import { useSignMessage } from 'wagmi'
import { FILLER_API_ENDPOINT } from '../../constants'
import { keccak256 } from 'viem'
import { useQueryClient } from '@tanstack/react-query'

export function useCancelSpotOrderV3(
  orderId: number,
  order: SpotOrderV3,
  callback?: (result: any) => void
) {
  const [isAPILoading, setIsAPILoading] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const queryClient = useQueryClient()

  const message = useMemo(() => {
    const orderHash = keccak256(order.serialize())
    const message = 'cancel:' + orderHash

    return message
  }, [order])

  const { signMessage, data, status } = useSignMessage({
    mutation: {
      onSuccess: async (data: Bytes) => {
        setIsAPILoading(true)
        setError(null)

        console.log('start to cancel order')

        const res = await fetch(`${FILLER_API_ENDPOINT}order/v2/spot`, {
          method: 'DELETE',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            orderId,
            signature: data
          })
        })

        const result = await res.json()

        if (result.error) {
          setError(JSON.stringify(result.error.reason))
        }

        await queryClient.invalidateQueries({
          queryKey: [
            'spot_orders',
            order.chainId,
            order.spotOrder.info.trader.toLowerCase(),
            'pending'
          ]
        })

        setIsAPILoading(false)

        if (callback) callback(result)
      }
    }
  })

  return {
    variables: message,
    signMessage,
    data,
    error,
    isLoading: status === 'pending' || isAPILoading
  }
}
