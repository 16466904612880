import { useState } from 'react'
import { Bytes, PerpOrderV3, PerpOrderV3Params } from '@predy/js-sdk'
import { useSignTypedData } from 'wagmi'
import { FILLER_API_ENDPOINT } from '../../constants'
import { reasonToErrorMessage } from '../../utils/error'
import { usePerpPosition } from './usePosition'
import { useQueryClient } from '@tanstack/react-query'
import { getPerpOrdersQueryKey } from './usePerpOrders'
import { usePermit2Nonce } from '../query/usePermit2Nonce'

function getOrderType(order: PerpOrderV3) {
  const params = order.perpOrder

  if (params.limitPrice === 0n && params.stopPrice === 0n) {
    return 'market'
  } else {
    if (params.reduceOnly || params.closePosition) {
      return 'tpsl'
    }

    if (params.limitPrice === 0n) {
      return 'stop'
    }

    return 'limit'
  }
}

export function usePerpSendOrder(
  order: PerpOrderV3,
  routeIndex: number,
  callback: (error: string | null, tradeResult: any) => void
) {
  const { domain, types, message } = order.permitData()
  const queryClient = useQueryClient()
  const position = usePerpPosition(
    order.chainId,
    order.perpOrder.info.trader,
    order.perpOrder.pairId
  )
  const nonce = usePermit2Nonce(
    order.chainId,
    order.perpOrder.info.trader,
    order.perpOrder.info.market
  )

  const [isAPILoading, setIsAPILoading] = useState(false)
  const [error, setError] = useState<null | string>(null)

  const { signTypedData, data, status } = useSignTypedData({
    mutation: {
      onSuccess: async (data: Bytes, variables) => {
        const signedOrder = new PerpOrderV3(
          variables.message.witness as PerpOrderV3Params,
          order.chainId
        )

        if (signedOrder.perpOrder.info.nonce === 0n) {
          const error = 'nonce is 0'
          setError(error)
          callback(error, null)
          return
        }

        setIsAPILoading(true)
        setError(null)

        console.log('start to send order', variables)

        const res = await fetch(`${FILLER_API_ENDPOINT}order/v2/perp`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            serializedOrder: signedOrder.serialize(),
            signature: data,
            routeIndex,
            orderType: getOrderType(signedOrder)
          })
        })
        const tradeResult = await res.json()

        await nonce.refetch()

        if (tradeResult.message && tradeResult.message.length > 0) {
          const message = tradeResult.message

          const errorMessage = reasonToErrorMessage(message)

          if (errorMessage) {
            setError(errorMessage)
            callback(errorMessage, null)
          } else {
            setError('unexpected error')
            callback(message, null)
            console.error(message)
          }

          setIsAPILoading(false)

          return
        }

        callback(null, tradeResult)

        setIsAPILoading(false)

        // refetch perp orders
        await queryClient.invalidateQueries({
          queryKey: getPerpOrdersQueryKey(
            order.chainId,
            order.perpOrder.info.trader,
            true
          )
        })

        // refetch position
        setTimeout(async () => {
          await queryClient.invalidateQueries({ queryKey: position.queryKey })
        }, 1000)
      }
    }
  })

  return {
    variables: {
      domain,
      types,
      message,
      primaryType: 'PermitWitnessTransferFrom'
    },
    signTypedData,
    data,
    error,
    isLoading: status === 'pending' || isAPILoading
  }
}
