import React, { useState } from 'react'
import { Tab, TabList } from '../../common/Tab'
import { OpenOrders } from './OpenOrders'
import { TradeHistory } from './TradeHistory'
import { useAccount } from 'wagmi'
import { ConnectLink } from '../../header/ConnectButton'
import { OrderHistory } from './OrderHistory'

enum PositionTabItems {
  OpenPositions,
  OpenOrders,
  OrderHistory,
  Trades
}

export const PositionTab = ({ chainId }: { chainId: number }) => {
  const [selectedTab, selectTab] = useState<PositionTabItems>(
    PositionTabItems.OpenOrders
  )
  const { address } = useAccount()

  return (
    <div className="h-full mb-6">
      <TabList
        onSelect={(tabId: number) => {
          selectTab(tabId)
        }}
      >
        <Tab
          tabId={PositionTabItems.OpenOrders}
          selected={selectedTab === PositionTabItems.OpenOrders}
        >
          Open Orders
        </Tab>
        <Tab
          tabId={PositionTabItems.OrderHistory}
          selected={selectedTab === PositionTabItems.OrderHistory}
        >
          Order History
        </Tab>
        <Tab
          tabId={PositionTabItems.Trades}
          selected={selectedTab === PositionTabItems.Trades}
        >
          Trades
        </Tab>
      </TabList>
      <div>
        {address ? (
          selectedTab === PositionTabItems.OpenOrders ? (
            <OpenOrders chainId={chainId} trader={address} />
          ) : selectedTab === PositionTabItems.OrderHistory ? (
            <OrderHistory chainId={chainId} trader={address} />
          ) : selectedTab === PositionTabItems.Trades ? (
            <TradeHistory chainId={chainId} trader={address} />
          ) : (
            <></>
          )
        ) : (
          <div className="h-40 flex justify-center items-center">
            <div className="w-80 h-10 text-sm">
              <ConnectLink>
                <span className="text-accent">Connect Wallet</span>
              </ConnectLink>{' '}
              to trade
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
