import { useSimulateContract } from 'wagmi'
import { PredyPoolABI } from '@predy/js-sdk'
import { ADDRESS_MAP } from '../../constants'

export function useUpdateFeeRatio(
  chainId: number,
  pairId: bigint,
  feeRatio: number
) {
  return useSimulateContract({
    address: ADDRESS_MAP[chainId].PredyPool,
    abi: PredyPoolABI,
    functionName: 'updateFeeRatio',
    args: [pairId, feeRatio]
  })
}
