import React, { useState } from 'react'
import {
  AmountForm,
  BaseModal,
  CancelButton,
  HorizontalRule,
  LoadingIndicator,
  PrimaryButton
} from '../../common'
import {
  usePerpOrder,
  usePerpOrders,
  usePerpPosition,
  usePerpSendOrder
} from '../../../hooks/perp'
import { PairInfo, Q96 } from '../../../constants'
import { Address } from 'viem'
import { toUnscaled } from '../../../utils/bn'
import { toScaledPrice, toUnscaledPrice } from '../../../utils'
import { PnLChange } from '../../common/PriceChange'
import { useModifyPerpOrder } from '../../../hooks/perp/useModifyPerpOrder'
import { PerpOrderV3 } from '@predy/js-sdk'

export const TPSLOrderFormModal = ({
  chainId,
  trader,
  pairInfo,
  children
}: {
  chainId: number
  trader: Address
  pairInfo: PairInfo
  children: React.ReactNode
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const perpPosition = usePerpPosition(
    chainId,
    trader,
    BigInt(pairInfo.pairId || 0)
  )

  const openOrders = usePerpOrders(chainId, trader, true)

  const tpslOrders = openOrders.data
    ? openOrders.data.filter(order => order.orderType === 'TP/SL')
    : []

  // The handler called when user try to close modal
  const onCloseModal = () => {
    setIsOpen(false)
  }

  if (openOrders.isLoading || perpPosition.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <div className="w-full text-sm md:text-xs">
      <div
        className="w-full pointer-cursor"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        {children}
      </div>

      <BaseModal
        isOpen={isOpen}
        onRequestClose={onCloseModal}
        maxWidth={428}
        height={538}
      >
        {tpslOrders.length > 0 ? (
          <ModifyTPSLOrderForm
            pairInfo={pairInfo}
            positionAmount={perpPosition.data.perpAmount}
            entryPrice={perpPosition.data.entryPrice}
            oraclePrice={perpPosition.data.oraclePrice}
            onCloseModal={onCloseModal}
            orderId={tpslOrders[0].orderId}
            originalPerpOrder={tpslOrders[0].perpOrder}
          />
        ) : (
          <NewTPSLOrderForm
            chainId={chainId}
            pairInfo={pairInfo}
            trader={trader}
            positionAmount={perpPosition.data.perpAmount}
            leverage={perpPosition.data.leverage}
            entryPrice={perpPosition.data.entryPrice}
            oraclePrice={perpPosition.data.oraclePrice}
            onCloseModal={onCloseModal}
            defaultTPPrice={0}
            defaultSLPrice={0}
          />
        )}
      </BaseModal>
    </div>
  )
}

export const NewTPSLOrderForm = ({
  chainId,
  pairInfo,
  trader,
  positionAmount,
  leverage,
  entryPrice,
  oraclePrice,
  defaultTPPrice,
  defaultSLPrice,
  onCloseModal
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  positionAmount: bigint
  leverage: number
  entryPrice: bigint
  oraclePrice: bigint
  defaultTPPrice: number
  defaultSLPrice: number
  onCloseModal: () => void
}) => {
  const [tpPrice, setTPPrice] = useState(defaultTPPrice)
  const [slPrice, setSLPrice] = useState(defaultSLPrice)

  const limitPrice = toScaledPrice(pairInfo, tpPrice)
  const stopPrice = toScaledPrice(pairInfo, slPrice)

  const order = usePerpOrder(
    chainId,
    pairInfo,
    trader,
    positionAmount < 0n,
    0n,
    leverage,
    {
      limitPrice,
      stopPrice,
      reduceOnly: true,
      closePosition: true
    }
  )

  const { signTypedData, variables, isLoading, error } = usePerpSendOrder(
    order,
    -1,
    error => {
      if (error === null) {
        onCloseModal()
      }
    }
  )

  return (
    <TPSLOrderForm
      pairInfo={pairInfo}
      positionAmount={positionAmount}
      entryPrice={entryPrice}
      oraclePrice={oraclePrice}
      tpPrice={tpPrice}
      slPrice={slPrice}
      isLoading={isLoading}
      error={error}
      setTPPrice={setTPPrice}
      setSLPrice={setSLPrice}
      onCloseModal={onCloseModal}
      onOK={() => {
        signTypedData({
          domain: variables.domain,
          types: variables.types,
          message: variables.message,
          primaryType: 'PermitWitnessTransferFrom'
        })
      }}
    />
  )
}

export const ModifyTPSLOrderForm = ({
  pairInfo,
  positionAmount,
  entryPrice,
  oraclePrice,
  orderId,
  originalPerpOrder,
  onCloseModal
}: {
  pairInfo: PairInfo
  positionAmount: bigint
  entryPrice: bigint
  oraclePrice: bigint
  orderId: string
  originalPerpOrder: PerpOrderV3
  onCloseModal: () => void
}) => {
  const [tpPrice, setTPPrice] = useState(
    toUnscaledPrice(pairInfo, originalPerpOrder.perpOrder.limitPrice)
  )
  const [slPrice, setSLPrice] = useState(
    toUnscaledPrice(pairInfo, originalPerpOrder.perpOrder.stopPrice)
  )

  const limitPrice = toScaledPrice(pairInfo, tpPrice)
  const stopPrice = toScaledPrice(pairInfo, slPrice)

  const modified = useModifyPerpOrder(
    orderId,
    originalPerpOrder,
    0n,
    limitPrice,
    stopPrice,
    () => {
      onCloseModal()
    }
  )

  return (
    <TPSLOrderForm
      pairInfo={pairInfo}
      positionAmount={positionAmount}
      entryPrice={entryPrice}
      oraclePrice={oraclePrice}
      tpPrice={tpPrice}
      slPrice={slPrice}
      isLoading={modified.isLoading}
      error={modified.error}
      setTPPrice={setTPPrice}
      setSLPrice={setSLPrice}
      onCloseModal={onCloseModal}
      onOK={() => {
        modified.signTypedData({
          domain: modified.variables.domain,
          types: modified.variables.types,
          message: modified.variables.message,
          primaryType: 'PermitWitnessTransferFrom'
        })
      }}
    />
  )
}

export const TPSLOrderForm = ({
  pairInfo,
  positionAmount,
  entryPrice,
  oraclePrice,
  tpPrice,
  slPrice,
  setTPPrice,
  setSLPrice,
  isLoading,
  error,
  onCloseModal,
  onOK
}: {
  pairInfo: PairInfo
  positionAmount: bigint
  entryPrice: bigint
  oraclePrice: bigint
  tpPrice: number
  slPrice: number
  isLoading: boolean
  error: string | null
  setTPPrice: (price: number) => void
  setSLPrice: (price: number) => void
  onCloseModal: () => void
  onOK: () => void
}) => {
  const limitPrice = toScaledPrice(pairInfo, tpPrice)
  const stopPrice = toScaledPrice(pairInfo, slPrice)

  const closeDirectionIsShort = positionAmount > 0n

  const estProfit = ((limitPrice - entryPrice) * positionAmount) / Q96
  let estLoss = ((stopPrice - entryPrice) * positionAmount) / Q96

  if (estLoss < 0n) {
    estLoss = (estLoss * 1003n) / 1000n
  }

  const estProfitFmt = toUnscaled(estProfit, pairInfo.quote.decimals)
  const estLossFmt = toUnscaled(estLoss, pairInfo.quote.decimals)

  const isAlertTp =
    (closeDirectionIsShort
      ? limitPrice <= oraclePrice
      : limitPrice >= oraclePrice) && tpPrice > 0
  const isAlertSl =
    (closeDirectionIsShort
      ? stopPrice >= oraclePrice
      : stopPrice <= oraclePrice) && slPrice > 0

  return (
    <div>
      <h1 className="text-base font-bold">TP/SL for entire position</h1>
      <div className="my-6 space-y-2">
        <Row label="Symbol" content={pairInfo.pairSymbol} />
        <Row
          label="Entry Price"
          content={toUnscaledPrice(pairInfo, entryPrice).toFixed(
            pairInfo.pricePrecision
          )}
        />
        <Row
          label="Oracle Price"
          content={toUnscaledPrice(pairInfo, oraclePrice).toFixed(
            pairInfo.pricePrecision
          )}
        />
      </div>
      <div className="mt-12 mb-6 space-y-6">
        <div className="space-y-3">
          <AmountForm
            amount={tpPrice}
            disabled={false}
            title="Take Profit Price"
            unit="USDC"
            digits={pairInfo.pricePrecision}
            onChange={setTPPrice}
            isAlert={isAlertTp}
            alertText={
              closeDirectionIsShort
                ? 'Take Profit price must be higher than entry price'
                : 'Take Profit price must be lower than entry price'
            }
          />
          <p className="text-fourth text-xs">
            Creates a Limit Order at {tpPrice} to close this position.
            <br />
            Estimated PNL shall be{' '}
            {tpPrice > 0 ? (
              <PnLChange value={estProfitFmt}>
                {estProfitFmt.toFixed(pairInfo.quote.precision)}
              </PnLChange>
            ) : (
              '-'
            )}{' '}
            USDC
          </p>
        </div>
        <HorizontalRule />
        <div className="space-y-3">
          <AmountForm
            amount={slPrice}
            disabled={false}
            title="Stop Loss Price"
            unit="USDC"
            digits={pairInfo.pricePrecision}
            onChange={setSLPrice}
            isAlert={isAlertSl}
            alertText={
              closeDirectionIsShort
                ? 'Stop Loss price must be lower than oracle price'
                : 'Stop Loss price must be higher than oracle price'
            }
          />
          <p className="text-fourth text-xs">
            When Oracle Price reaches {slPrice}, it shall trigger Stop Market
            order to close this position. Estimated PNL shall be{' '}
            {slPrice > 0 ? (
              <PnLChange value={estLossFmt}>
                {estLossFmt.toFixed(pairInfo.quote.precision)}
              </PnLChange>
            ) : (
              '-'
            )}{' '}
            USDC
          </p>
        </div>
      </div>
      <div className="text-fourth text-xs">
        This setting applies to the entire position. Take-profit and stop-loss
        are automatically cancelled after closing the position.
      </div>
      <div className="mt-4 mb-3 h-10 flex justify-between space-x-2">
        <CancelButton onClick={onCloseModal}>Cancel</CancelButton>
        <PrimaryButton
          disabled={isAlertTp || isAlertSl || (tpPrice === 0 && slPrice === 0)}
          onClick={() => {
            onOK()
          }}
        >
          {isLoading ? <LoadingIndicator /> : 'Confirm'}
        </PrimaryButton>
      </div>
      {error ? <div className="text-red text-xs">{error}</div> : <></>}
    </div>
  )
}

const Row = ({ label, content }: { label: string; content: string }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="text-fourth">{label}</div>
      <div>{content}</div>
    </div>
  )
}
