import React from 'react'
import { AmountForm, AmountFormProps } from './AmountForm'
import { roundDigit } from '../../../utils/number'

export const AmountButtonForm = (props: AmountFormProps) => {
  const IncrementButton = ({ step }: { step: number }) => {
    return (
      <button
        className="w-14 p-1 bg-secondary rounded"
        onClick={() => {
          const newAmount = props.amount ? props.amount + step : step
          props.onChange &&
            props.onChange(roundDigit(newAmount, props.digits || 2))
        }}
      >
        +{step}
      </button>
    )
  }

  return (
    <div className="space-y-1">
      <AmountForm {...props} />
      <div className="flex justify-between items-center text-xs">
        <IncrementButton step={0.01} />
        <IncrementButton step={0.1} />
        <IncrementButton step={1} />
        <IncrementButton step={10} />
      </div>
    </div>
  )
}
