export const ERROR_SIGNATURES_MAP: { [key: string]: string } = {
  '0x678487e1': 'Slippage too large',
  '0xfeca5487': 'Slippage too large',
  '0x756688fe': 'Invalid Nonce',
  TRANSFER_FROM_FAILED: 'Insufficient balance',
  S0: 'Low liquidity',
  'invalid signature': 'Invalid signature',
  'amount must not be 0': 'Amount must not be 0',
  'Invalid parameters': 'Invalid parameters',
  'expired order': 'The order is expired',
  'invalid order': 'Invalid order'
}
