import React from 'react'
import { PairInfo } from '../../constants'
import { StrongTitle } from './common/Title'
import { toTimeString } from '../../utils/string'
import { getStrategyName } from '../../utils/gamma'
import { DurationComponent } from './common/DurationComponent'

export const PositionHistoryCardSM = ({
  pairInfo,
  totalDeposit,
  totalWithdraw,
  hedgeInterval,
  closeType,
  openedAt,
  closedAt,
  leverage
}: {
  pairInfo: PairInfo
  totalDeposit: number
  totalWithdraw: number
  hedgeInterval: number
  isAutoClose: boolean
  isAutoHedge: boolean
  closeType: string
  openedAt: number
  closedAt: number
  leverage: number
}) => {
  const pnl = totalWithdraw - totalDeposit

  const isPlus = pnl >= 0

  const runtimeInSec = closedAt - openedAt
  const runtime = Math.ceil(runtimeInSec / 60) * 60000

  return (
    <div className="p-2 bg-panel border-panelborder border-[1px] space-y-2 rounded">
      <div className="flex justify-between space-x-2">
        <div className="flex space-x-1 justify-center items-center">
          <StrongTitle>
            {getStrategyName({
              leverage
            })}
          </StrongTitle>

          <div className="flex items-center space-x-1">
            <div className="text-xs text-fourth">
              {pairInfo.base.symbol}/{pairInfo.quote.symbol}
            </div>
            <div className="p-[2px] bg-secondary rounded flex items-center text-xs ">
              {leverage}x
            </div>
          </div>
        </div>

        <div className="text-xs text-fourth font-light">
          {toTimeString(closedAt)}
        </div>
      </div>

      <div className="flex justify-between space-x-2">
        <div className="space-y-1">
          <div className="flex justify-start text-xs text-white5 font-sm underline decoration-dotted">
            Value
          </div>
          <div className="text-base">{totalWithdraw} USDC</div>
        </div>
        <div className="space-y-1">
          <div className="flex justify-end text-xs text-white5 font-sm underline decoration-dotted">
            PNL(ROI)
          </div>
          <div
            className={`text-base ${
              pnl > 0 ? 'text-high-green' : 'text-high-red'
            }`}
          >
            {isPlus ? '+' : '-'}
            {Math.abs(pnl).toFixed(pairInfo.quote.precision)} USDC (
            {isPlus ? '+' : '-'}
            {(Math.abs(pnl * 100) / totalDeposit).toFixed(2)}%)
          </div>
        </div>
      </div>

      <div className="flex justify-between space-x-2">
        <div className="space-y-1">
          <div className="flex justify-start text-xs text-white5 font-sm underline decoration-dotted">
            Runtime
          </div>
          <div className="text-fourth">
            <DurationComponent time={runtime} />
          </div>
        </div>
        <div className="space-y-1">
          <div className="flex justify-start text-xs text-white5 font-sm underline decoration-dotted">
            Hedge interval
          </div>
          <div className="text-xs text-fourth">
            {hedgeInterval === 0
              ? '-'
              : `${Math.floor(hedgeInterval / 60 / 60)} h`}
          </div>
        </div>
        <div className="space-y-1">
          <div className="flex justify-end text-xs text-white5 font-sm underline decoration-dotted">
            Closed by
          </div>
          <div className="text-xs text-fourth">{closeType}</div>
        </div>
      </div>
    </div>
  )
}

export const PositionHistoryCardPC = ({
  pairInfo,
  totalDeposit,
  totalWithdraw,
  hedgeInterval,
  closeType,
  openedAt,
  closedAt,
  leverage,
  txHash
}: {
  pairInfo: PairInfo
  totalDeposit: number
  totalWithdraw: number
  hedgeInterval: number
  isAutoClose: boolean
  isAutoHedge: boolean
  closeType: string
  openedAt: number
  closedAt: number
  leverage: number
  txHash: string | null
}) => {
  const pnl = totalWithdraw - totalDeposit

  const isPlus = pnl >= 0

  const runtimeInSec = closedAt - openedAt
  const runtime = Math.ceil(runtimeInSec / 60) * 60000

  return (
    <tr>
      <td className="p-2 text-start align-middle text-xs text-fourth">
        {txHash ? (
          <a
            href={`https://arbiscan.io/tx/${txHash}`}
            target="_blank"
            rel="noreferrer"
          >
            {toTimeString(closedAt)}
          </a>
        ) : (
          toTimeString(closedAt)
        )}
      </td>

      <td className="p-3 flex flex-col justify-start items-start space-y-1">
        <div className="flex justify-start space-x-1">
          <StrongTitle>
            {getStrategyName({
              leverage
            })}
          </StrongTitle>
        </div>
        <div className="flex space-x-1">
          <div className="text-xs text-fourth">
            {pairInfo.base.symbol}/{pairInfo.quote.symbol}
          </div>
          <div className="px-1 bg-secondary rounded flex items-center text-xs">
            {leverage}x
          </div>
        </div>
      </td>

      <td className="p-2 text-end align-middle">
        <div className="text-sm">{totalWithdraw} USDC</div>
      </td>

      <td className="p-2 text-end align-middle">
        <div
          className={`text-sm ${pnl > 0 ? 'text-high-green' : 'text-high-red'}`}
        >
          {isPlus ? '+' : '-'}
          {Math.abs(pnl).toFixed(pairInfo.quote.precision)} USDC (
          {isPlus ? '+' : '-'}
          {(Math.abs(pnl * 100) / totalDeposit).toFixed(2)}%)
        </div>
      </td>

      <td className="p-2 text-end align-middle text-xs text-fourth">
        <DurationComponent time={runtime} />
      </td>

      <td className="p-2 text-end align-middle text-xs">
        <div className="text-xs text-fourth">
          {hedgeInterval === 0
            ? '-'
            : `${Math.floor(hedgeInterval / 60 / 60)} h`}
        </div>
      </td>

      <td className="p-2 text-end align-middle text-xs">
        <div className="text-xs text-fourth">{closeType}</div>
      </td>
    </tr>
  )
}
