import React, { useCallback, useState, useEffect } from 'react'
import { Address } from '@predy/js-sdk'
import { toScaled, toUnscaled } from '../../../utils/bn'
import { Direction, PairInfo } from '../../../constants'
import { AmountForm, SizeRatioForm, Slider } from '../../common'
import { useMaxTradable } from '../../../hooks/perp'
import { formatSize } from '../../../utils/size'

export const SizeForm = ({
  chainId,
  pairInfo,
  trader,
  amount,
  currentPositionAmount,
  leverage,
  limitPrice,
  setBuyAmount,
  setSellAmount
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  amount: number
  currentPositionAmount: bigint
  leverage: number
  limitPrice: number
  setBuyAmount: (amount: bigint) => void
  setSellAmount: (amount: bigint) => void
}) => {
  const [size, setSize] = useState<number>(amount)
  const [sizeRatio, setSizeRatio] = useState(0)
  const [isRatio, setIsRatio] = useState(false)

  const availableSize = useMaxTradable(
    chainId,
    pairInfo,
    trader,
    currentPositionAmount,
    leverage,
    limitPrice
  )
  const maxBuy = availableSize.isSuccess ? availableSize.data.maxBuy : 0n
  const maxSell = availableSize.isSuccess ? availableSize.data.maxSell : 0n

  useEffect(() => {
    if (isRatio) {
      setBuyAmount(formatSize((maxBuy * BigInt(sizeRatio)) / 100n, pairInfo))
      setSellAmount(formatSize((maxSell * BigInt(sizeRatio)) / 100n, pairInfo))
    } else {
      const amount = toScaled(size, pairInfo.base.decimals)

      setBuyAmount(formatSize(maxBuy < amount ? maxBuy : amount, pairInfo))
      setSellAmount(formatSize(maxSell < amount ? maxSell : amount, pairInfo))
    }
  }, [
    size,
    isRatio,
    sizeRatio,
    maxBuy,
    maxSell,
    setBuyAmount,
    setSellAmount,
    pairInfo
  ])

  const onSizeRatioChange = useCallback(
    (value: number) => {
      setSizeRatio(value)

      setBuyAmount(formatSize((maxBuy * BigInt(value)) / 100n, pairInfo))
      setSellAmount(formatSize((maxSell * BigInt(value)) / 100n, pairInfo))
    },
    [setSizeRatio, maxBuy, maxSell, setBuyAmount, setSellAmount, pairInfo]
  )

  const onAmountChange = useCallback(
    (value: number) => {
      setSize(value)

      const amount = toScaled(value || 0, pairInfo.base.decimals)

      setBuyAmount(formatSize(maxBuy < amount ? maxBuy : amount, pairInfo))
      setSellAmount(formatSize(maxSell < amount ? maxSell : amount, pairInfo))
    },
    [pairInfo, maxBuy, maxSell, setBuyAmount, setSellAmount]
  )

  const scaledSize = toScaled(size || 0, pairInfo.base.decimals)
  const maxTradeAmount = toScaled(100, pairInfo.base.decimals)

  const isExceed100 = scaledSize > maxTradeAmount
  //const isExceedMax = scaledSize > maxBuy || scaledSize > maxSell

  return (
    <>
      <SizeRatioForm
        unit={pairInfo.base.symbol}
        amount={size}
        ratio={sizeRatio}
        step={1 / 10 ** pairInfo.base.precision}
        digits={pairInfo.base.precision}
        isAlert={isExceed100}
        isRatio={isRatio}
        alertText={isExceed100 ? 'Max quantity is 100 ETH' : undefined}
        onSizeChange={onAmountChange}
        onRatioChange={onSizeRatioChange}
        setIsRatio={setIsRatio}
      />
    </>
  )
}

export const SizeFormSm = ({
  chainId,
  pairInfo,
  trader,
  amount,
  currentPositionAmount,
  leverage,
  limitPrice,
  direction,
  setBuyAmount,
  setSellAmount
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  amount: number
  currentPositionAmount: bigint
  leverage: number
  direction: Direction
  limitPrice: number
  setBuyAmount: (amount: bigint) => void
  setSellAmount: (amount: bigint) => void
}) => {
  const [size, setSize] = useState<number>(amount)

  const availableSize = useMaxTradable(
    chainId,
    pairInfo,
    trader,
    currentPositionAmount,
    leverage,
    limitPrice
  )
  const maxBuy = availableSize.isSuccess ? availableSize.data.maxBuy : 0n
  const maxSell = availableSize.isSuccess ? availableSize.data.maxSell : 0n

  const onAmountChange = useCallback(
    (value: number) => {
      setSize(value)

      const amount = toScaled(value || 0, pairInfo.base.decimals)

      setBuyAmount(formatSize(maxBuy < amount ? maxBuy : amount, pairInfo))
      setSellAmount(formatSize(maxSell < amount ? maxSell : amount, pairInfo))
    },
    [pairInfo, maxBuy, maxSell, setBuyAmount, setSellAmount]
  )

  const scaledSize = toScaled(size || 0, pairInfo.base.decimals)
  const maxTradeAmount = toScaled(100, pairInfo.base.decimals)

  const isExceed100 = scaledSize > maxTradeAmount
  //const isExceedMax = scaledSize > maxBuy || scaledSize > maxSell

  return (
    <div className="space-y-5">
      <AmountForm
        title={'Size'}
        unit={pairInfo.base.symbol}
        amount={size}
        step={1 / 10 ** (pairInfo.base.precision - 1)}
        digits={pairInfo.base.precision}
        isAlert={isExceed100}
        alertText={isExceed100 ? 'Max quantity is 100 ETH' : undefined}
        onChange={onAmountChange}
      />

      <Slider
        value={size}
        min={0}
        max={
          direction === Direction.Long
            ? toUnscaled(maxBuy, pairInfo.base.decimals)
            : toUnscaled(maxSell, pairInfo.base.decimals)
        }
        step={1 / 10 ** (pairInfo.base.precision - 1)}
        onValueChanged={onAmountChange}
      />
    </div>
  )
}
