import { useState } from 'react'
import { Bytes, GammaOrder, GammaOrderParams } from '@predy/js-sdk'
import { useSignTypedData } from 'wagmi'
import { FILLER_API_ENDPOINT, PAIR_INFOS } from '../../constants'
import { reasonToErrorMessage } from '../../utils/error'
import { usePermit2Nonce } from '../query/usePermit2Nonce'
import { useQueryClient } from '@tanstack/react-query'
import { useGammaPositions } from './useGammaPositions'
import { pairIdToSymbol } from '../../utils/pairs'

export function useSendGammaOrder(
  order: GammaOrder,
  routeIndex: number,
  callback: (error: string | null, tradeResult: any) => void
) {
  const { domain, types, message } = order.permitData()
  const queryClient = useQueryClient()
  const nonce = usePermit2Nonce(
    order.chainId,
    order.gammaOrder.info.trader,
    order.gammaOrder.info.market
  )
  const gammaPositions = useGammaPositions(
    order.chainId,
    order.gammaOrder.info.trader,
    PAIR_INFOS[order.chainId][pairIdToSymbol(Number(order.gammaOrder.pairId))]
  )

  const [isAPILoading, setIsAPILoading] = useState(false)
  const [error, setError] = useState<null | string>(null)

  const { signTypedData, data, status } = useSignTypedData({
    mutation: {
      onSuccess: async (data: Bytes, variables) => {
        const signedOrder = new GammaOrder(
          variables.message.witness as GammaOrderParams,
          order.chainId
        )

        if (signedOrder.gammaOrder.info.nonce === 0n) {
          const error = 'nonce is 0'
          setError(error)
          callback(error, null)
          return
        }

        setIsAPILoading(true)
        setError(null)

        console.log('start to send order', variables)

        const res = await fetch(`${FILLER_API_ENDPOINT}order/v2/gamma`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            serializedOrder: signedOrder.serialize(),
            signature: data,
            routeIndex
          })
        })
        const tradeResult = await res.json()

        // update nonce
        await nonce.refetch()
        await queryClient.invalidateQueries({
          queryKey: [
            'nonceBitmap',
            order.chainId,
            order.gammaOrder.info.trader,
            order.gammaOrder.info.market
          ]
        })

        if (tradeResult.message && tradeResult.message.length > 0) {
          const message = tradeResult.message

          const errorMessage = reasonToErrorMessage(message)

          if (errorMessage) {
            setError(errorMessage)
            callback(errorMessage, null)
          } else {
            setError('unexpected error')
            callback(message, null)
            console.error(message)
          }

          setIsAPILoading(false)

          return
        }

        callback(null, tradeResult)
        setIsAPILoading(false)

        // refetch position
        setTimeout(async () => {
          await queryClient.invalidateQueries({
            queryKey: gammaPositions.queryKey
          })
        }, 1000)
      }
    }
  })

  return {
    variables: {
      domain,
      types,
      message,
      primaryType: 'PermitWitnessTransferFrom'
    },
    signTypedData,
    data,
    error,
    isLoading: status === 'pending' || isAPILoading
  }
}
