import React, { useState } from 'react'
import {
  AmountForm,
  BaseModal,
  CancelButton,
  LoadingIndicator,
  PrimaryButton
} from '../../common'
import { AiFillEdit } from 'react-icons/ai'
import { useModifySpotOrder } from '../../../hooks/spot/useModifySpotOrder'
import { toScaled } from '../../../utils/bn'
import { SpotOrderV3 } from '@predy/js-sdk'
import { PairInfo } from '../../../constants'

export const ModifySpotOrderFormModal = ({
  amount,
  limitPrice,
  pair,
  orderId,
  spotOrder
}: {
  amount: number
  limitPrice: number
  pair: PairInfo
  orderId: number
  spotOrder: SpotOrderV3
}) => {
  const [isOpen, setIsOpen] = useState(false)

  // The handler called when user try to close modal
  const onCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <div className="text-sm md:text-xs">
      <button
        className="pointer-cursor"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <AiFillEdit />
      </button>

      <BaseModal isOpen={isOpen} onRequestClose={onCloseModal} height={224}>
        <ModifyOrderForm
          amount={amount}
          limitPrice={limitPrice}
          pairInfo={pair}
          orderId={orderId}
          spotOrder={spotOrder}
          onCloseModal={onCloseModal}
        />
      </BaseModal>
    </div>
  )
}

export const ModifyOrderForm = ({
  amount,
  limitPrice,
  pairInfo,
  orderId,
  spotOrder,
  onCloseModal
}: {
  amount: number
  limitPrice: number
  pairInfo: PairInfo
  orderId: number
  spotOrder: SpotOrderV3
  onCloseModal: () => void
}) => {
  const [newAmount, setNewAmount] = useState(amount)
  const [newLimitPrice, setNewLimitPrice] = useState(limitPrice)

  const quoteTokenAmount = newAmount * newLimitPrice

  const { signTypedData, variables, isLoading, error } = useModifySpotOrder(
    orderId,
    spotOrder,
    toScaled(newAmount, pairInfo.base.decimals),
    toScaled(quoteTokenAmount, pairInfo.quote.decimals),
    () => {
      onCloseModal()
    }
  )

  return (
    <div>
      <div className="space-y-3">
        <AmountForm
          amount={newLimitPrice}
          disabled={false}
          title="Price"
          unit="USDC"
          digits={pairInfo.pricePrecision}
          onChange={setNewLimitPrice}
        />
        <AmountForm
          amount={newAmount}
          disabled={false}
          title="Amount"
          unit="ETH"
          digits={pairInfo.base.precision}
          onChange={setNewAmount}
          isAlert={newAmount < 0}
          alertText="Amount must not be 0"
        />
      </div>
      <div className="mt-10 mb-4 h-10 flex justify-between space-x-2">
        <CancelButton onClick={onCloseModal}>Cancel</CancelButton>
        <PrimaryButton
          disabled={false}
          onClick={() => {
            signTypedData({
              domain: variables.domain,
              types: variables.types,
              message: variables.message,
              primaryType: 'PermitWitnessTransferFrom'
            })
          }}
        >
          {isLoading ? <LoadingIndicator /> : 'Confirm'}
        </PrimaryButton>
      </div>
      {error ? <div className="text-red text-xs">{error}</div> : <></>}
    </div>
  )
}
