import { useQuery } from '@tanstack/react-query'
import { Address, Hex, isAddressEqual, zeroAddress } from 'viem'
import {
  ADDRESS_MAP,
  DEFAULT_STALE_TIME,
  FILLER_API_ENDPOINT,
  OrderStatus,
  OrderType,
  PAIR_INFOS,
  PairInfo
} from '../../constants'
import { PerpOrderV3 } from '@predy/js-sdk'
import { toTimeString } from '../../utils/string'
import { abs, toUnscaled } from '../../utils/bn'
import { pairIdToSymbol } from '../../utils/pairs'
import { toUnscaledPrice } from '../../utils'

interface OrderResponseRow {
  id: string
  positionId: number
  chainId: number
  trader: Address
  deadline: number
  nonce: string
  pairId: number
  orderStatus: string
  orderType: string
  direction: number
  maxMarginAmount: string
  quantity: string
  executedAmount: string
  leverage: number
  price?: string
  stopPrice?: string
  reduceOnly: boolean
  closePosition: boolean
  auctionParams?: string
  createdAt: string
  updatedAt: string
}

interface OrderResponse {
  id: string
  positionId: number
  chainId: number
  trader: Address
  deadline: number
  nonce: bigint
  pairId: number
  orderStatus: OrderStatus
  orderType: string
  direction: number
  maxMarginAmount: bigint
  quantity: bigint
  executedAmount?: bigint
  leverage: number
  price?: bigint
  stopPrice?: bigint
  reduceOnly: boolean
  closePosition: boolean
  auctionParams?: Hex
  createdAt: Date
  updatedAt: Date
}

function decodeOrderResponseRow(row: OrderResponseRow): OrderResponse {
  return {
    id: row.id,
    positionId: row.positionId,
    chainId: row.chainId,
    trader: row.trader,
    deadline: row.deadline,
    nonce: BigInt(row.nonce),
    pairId: row.pairId,
    orderStatus: row.orderStatus as OrderStatus,
    orderType: row.orderType as OrderType,
    direction: row.direction,
    maxMarginAmount: BigInt(row.maxMarginAmount),
    quantity: BigInt(row.quantity),
    executedAmount: row.executedAmount ? BigInt(row.executedAmount) : undefined,
    leverage: row.leverage,
    price: row.price ? BigInt(row.price) : undefined,
    stopPrice: row.stopPrice ? BigInt(row.stopPrice) : undefined,
    reduceOnly: row.reduceOnly,
    closePosition: row.closePosition,
    auctionParams: row.auctionParams as Hex,
    createdAt: new Date(row.createdAt),
    updatedAt: new Date(row.updatedAt)
  }
}
export function decodedToPerpOrder(decoded: OrderResponse) {
  const pair = PAIR_INFOS[decoded.chainId][pairIdToSymbol(decoded.pairId)]
  return new PerpOrderV3(
    {
      info: {
        market: ADDRESS_MAP[decoded.chainId]
          ? ADDRESS_MAP[decoded.chainId].PerpMarket
          : zeroAddress,
        trader: decoded.trader,
        nonce: decoded.nonce,
        deadline: BigInt(decoded.deadline)
      },
      pairId: BigInt(decoded.pairId),
      side: decoded.direction === 0 ? 'Buy' : 'Sell',
      quantity: decoded.quantity,
      marginAmount: decoded.maxMarginAmount,
      entryTokenAddress: pair.quote.address,
      limitPrice: decoded.price || 0n,
      stopPrice: decoded.stopPrice || 0n,
      leverage: decoded.leverage,
      reduceOnly: decoded.reduceOnly,
      closePosition: decoded.closePosition,
      auctionData: decoded.auctionParams || '0x'
    },
    decoded.chainId
  )
}

export interface OpenOrder {
  orderId: string
  params: {
    side: 'Buy' | 'Sell'
    quantity: bigint
  }
  side: 'Buy' | 'Sell'
  perpOrder: PerpOrderV3
  pairInfo: PairInfo
  orderStatus: OrderStatus
  orderType: string
  limitPrice: number
  stopPrice: number
  quantity: number
  executed: {
    side: 'Buy' | 'Sell'
    quantity: number
  }
  createdAt: string
}

export function useQuoteAmountsInPerpOrders(chainId: number, trader: Address) {
  const perpOrders = usePerpOrders(chainId, trader, true)

  return perpOrders.data
    ? perpOrders.data.reduce((acc, order) => {
        const usedQuoteAmount =
          order.params.marginAmount > 0n ? order.params.marginAmount : 0n
        return acc + usedQuoteAmount
      }, 0n)
    : 0n
}

export function getPerpOrdersQueryKey(
  chainId: number,
  trader: Address,
  isPending: boolean
) {
  return ['perp_orders', chainId, trader.toLowerCase(), isPending]
}

export function usePerpOrders(
  chainId: number,
  trader: Address,
  isPending: boolean
) {
  return useQuery({
    queryKey: getPerpOrdersQueryKey(chainId, trader, isPending),
    queryFn: async () => {
      const query = new URLSearchParams({
        market: ADDRESS_MAP[chainId].PerpMarket.toLowerCase(),
        account: trader.toLowerCase(),
        isPending: isPending.toString(),
        offset: '0',
        limit: '100'
      })

      const res = await fetch(`${FILLER_API_ENDPOINT}order/v2/perp?${query}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      })

      const result = await res.json()

      const orders = result as OrderResponseRow[]

      return orders.map(decodeOrderResponseRow).map(order => {
        const perpOrder = decodedToPerpOrder(order)

        const witnessInfo = perpOrder.witnessInfo()

        const pairInfo =
          PAIR_INFOS[chainId][pairIdToSymbol(Number(witnessInfo.pairId))]

        const limitPrice = order.price
          ? toUnscaledPrice(pairInfo, order.price)
          : 0

        const stopPrice = order.stopPrice
          ? toUnscaledPrice(pairInfo, order.stopPrice)
          : 0

        const quantity = toUnscaled(
          witnessInfo.quantity,
          pairInfo.base.decimals
        )

        let executed = null

        if (order.executedAmount) {
          executed = {
            side: perpOrder.perpOrder.side,
            quantity: toUnscaled(
              abs(order.executedAmount),
              pairInfo.base.decimals
            )
          }
        } else {
          executed = {
            side: perpOrder.perpOrder.side,
            quantity
          }
        }

        const orderType = order.orderType as OrderType

        return {
          orderId: order.id,
          params: perpOrder.perpOrder,
          executed,
          side: perpOrder.perpOrder.side,
          perpOrder,
          pairInfo,
          orderStatus: order.orderStatus as OrderStatus,
          status: orderStatusToString(order.orderStatus as OrderStatus),
          orderType: orderTypeToString(orderType),
          limitPrice,
          stopPrice,
          quantity,
          createdAt: toTimeString(order.createdAt.getTime() / 1000)
        }
      })
    },
    enabled: !isAddressEqual(trader, zeroAddress),
    staleTime: DEFAULT_STALE_TIME,
    refetchInterval: 30000
  })
}

function orderTypeToString(orderType: OrderType) {
  switch (orderType) {
    case OrderType.LIMIT:
      return 'Limit'
    case OrderType.MARKET:
      return 'Market'
    case OrderType.STOP:
      return 'Stop'
    case OrderType.STOP_LIMIT:
      return 'Stop Limit'
    case OrderType.TPSL:
      return 'TP/SL'
  }
}

function orderStatusToString(orderStatus: OrderStatus) {
  switch (orderStatus) {
    case OrderStatus.CANCELLED:
      return 'Cancel'
    case OrderStatus.FAILED:
      return 'Failed'
    case OrderStatus.FILLED:
      return 'Filled'
    case OrderStatus.PENDING:
      return 'Pending'
    case OrderStatus.EXPIRED:
      return 'Expired'
    case OrderStatus.NOT_ACTIVATED:
      return 'Not Activated'
  }
}
