import React from 'react'

const Maintenance = () => {
  return (
    <div className="h-[840px] flex justify-center items-center">
      <div>
        <div className="text-lg text-white text-center">Under maintenance</div>
      </div>
    </div>
  )
}

export default Maintenance
