import React, { useCallback, useMemo, useRef, useState } from 'react'
import { PairInfo } from '../../../constants'
import {
  AccentButton,
  AmountForm,
  BaseModal,
  CancelButton,
  Slider
} from '../../common'
import { GammaPnLChart } from '../GammaPnLChart'
import { toScaled, toUnscaled } from '../../../utils/bn'
import { useAvbl } from '../../../hooks/perp/useAvbl'
import { Address } from 'viem'
import { AiFillEdit, AiOutlineLineChart } from 'react-icons/ai'
import { STRATEGY_TEMPLATE } from '../../../constants/strategy'
import { StrongTitle, Title } from '../common/Title'
import { calculateGammaPosition } from '../../../utils/gamma'
import { useGammaOrder } from '../../../hooks/gamma/useGammaOrder'
import { useSendGammaOrder } from '../../../hooks/gamma/useSendGammaOrder'
import { useQuoteGammaTrade } from '../../../hooks/gamma/useQuoteGammaTrade'
import { DepositLimit } from '../common/DepositLimit'
import { useDepositLimit } from '../../../hooks/gamma/useDepositLimit'
import { APRComponent } from '../common/APRComponent'
import { DepositAmountForm } from '../common/DepositAmountForm'
import { usePermit } from '../../../hooks/usePermit'

interface Props {
  chainId: number
  pairInfo: PairInfo
  price: number
  trader: Address
  templateId: number
  onCancel?: () => void
}

function isPriceTriggerInvalid(priceTrigger: number) {
  return 0 < priceTrigger && priceTrigger < 1
}

function isTimeIntervalInvalid(timeInterval: number) {
  return 0 < timeInterval && timeInterval < 1
}

const AutoClose = ({
  duration,
  setDuration
}: {
  duration: number
  setDuration: (duration: number) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="space-y-2">
      <AiFillEdit className="cursor-pointer" onClick={() => setIsOpen(true)} />
      <BaseModal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false)
        }}
        height={280}
      >
        <div className="flex flex-col space-y-6">
          <Title>Auto Close</Title>
          <div className="space-y-4">
            <p className="text-xs text-fourth">
              Set the duration, in hours, from opening a position to automatic
              closure.
            </p>
            <AmountForm
              title="Time"
              unit="H"
              amount={duration}
              step={1}
              max={72}
              onChange={setDuration}
            />
            <Slider
              value={duration}
              step={1}
              min={0}
              max={72}
              onValueChanged={setDuration}
            />
            <div className="h-10">
              <AccentButton onClick={() => setIsOpen(false)}>OK</AccentButton>
            </div>
          </div>
        </div>
      </BaseModal>
    </div>
  )
}

const AutoDeltaHedge = ({
  leverage,
  timeInterval,
  priceTrigger,
  setTimeInterval,
  setPriceTrigger
}: {
  leverage: number
  timeInterval: number
  priceTrigger: number
  setTimeInterval: (timeInterval: number) => void
  setPriceTrigger: (priceTrigger: number) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="space-y-2">
      <AiFillEdit className="cursor-pointer" onClick={() => setIsOpen(true)} />
      <BaseModal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false)
        }}
        height={300}
      >
        <div className="flex flex-col space-y-6">
          <Title>Auto Delta Hedging</Title>
          <div className="space-y-4">
            <AmountForm
              title="Interval"
              unit="H"
              amount={timeInterval}
              step={1}
              max={48}
              isAlert={isTimeIntervalInvalid(timeInterval)}
              alertText="Time interval must be greater than 1 hour"
              onChange={setTimeInterval}
            />
            <Slider
              value={timeInterval}
              step={1}
              min={0}
              max={48}
              onValueChanged={setTimeInterval}
            />
          </div>

          <div className="space-y-4">
            <AmountForm
              title="Price Trigger"
              unit="%"
              amount={priceTrigger}
              max={22 / leverage}
              isAlert={isPriceTriggerInvalid(priceTrigger)}
              alertText="Price trigger must be greater than 1%"
              step={0.1}
              onChange={setPriceTrigger}
            />
            <Slider
              value={priceTrigger}
              min={0}
              max={22 / leverage}
              step={0.1}
              onValueChanged={setPriceTrigger}
            />
          </div>
        </div>
      </BaseModal>
    </div>
  )
}

export const CreateForm = ({
  chainId,
  pairInfo,
  price,
  trader,
  templateId,
  onCancel
}: Props) => {
  const strategy = STRATEGY_TEMPLATE[templateId - 1]
  const leverage = strategy.leverage

  const [value, setValue] = useState(100)
  const [isChartOpen, setIsChartOpen] = useState(true)
  const available = useAvbl(chainId, pairInfo, trader)

  const animatedTextRef = useRef<HTMLDivElement | null>(null)

  const [duration, setDuration] = useState(strategy.duration)
  const [timeInterval, setTimeInterval] = useState(strategy.timeInterval)
  const [priceTrigger, setPriceTrigger] = useState(strategy.priceTrigger)

  const position = useMemo(() => {
    return calculateGammaPosition(value, price, leverage, strategy.isGammaLong)
  }, [price, value, leverage, strategy.isGammaLong])

  const order = useGammaOrder(
    chainId,
    pairInfo,
    trader,
    position.underlying,
    position.squart,
    toScaled(value, pairInfo.quote.decimals, pairInfo.quote.precision),
    leverage,
    price,
    {
      isEnabled: true,
      duration: duration === 0 ? undefined : duration * 60 * 60,
      hedgeInterval: timeInterval * 60 * 60,
      priceTrigger: priceTrigger
    }
  )
  const quote = useQuoteGammaTrade(chainId, order)

  const routeIndex = quote.data ? quote.data.routeIndex : -1

  const { signTypedData, variables, error } = useSendGammaOrder(
    order,
    routeIndex,
    error => {
      if (error === null && onCancel) {
        onCancel()
      }
    }
  )

  const permit = usePermit(
    chainId,
    pairInfo,
    trader,
    toScaled(value, pairInfo.quote.decimals, pairInfo.quote.precision)
  )

  const openPositionHandler = useCallback(() => {
    if (permit.isEnoughAllowance) {
      signTypedData({
        domain: variables.domain,
        types: variables.types,
        message: variables.message,
        primaryType: 'PermitWitnessTransferFrom'
      })
    } else {
      permit.approve()
    }
  }, [permit, signTypedData, variables])

  const depositLimit = useDepositLimit(
    chainId,
    pairInfo.pairId || 0,
    leverage,
    price,
    value
  )

  const availableAmount =
    available === null
      ? null
      : toUnscaled(available, pairInfo.quote.decimals, pairInfo.quote.precision)
  const availableInPool = depositLimit
    ? depositLimit.maxValue - depositLimit.currentValue
    : null

  return (
    <div className="space-y-3 animate-slide-up" ref={animatedTextRef}>
      <div className="p-2 space-y-3 bg-panel border-panelborder border-[1px] rounded">
        <div className="flex justify-between items-center space-x-2">
          <div className="flex items-center space-x-1">
            <StrongTitle>{STRATEGY_TEMPLATE[templateId - 1].title}</StrongTitle>
            <div className="flex items-center text-xs text-third">ETH/USDC</div>
            <div className="p-1 bg-secondary rounded flex items-center text-xs">
              {STRATEGY_TEMPLATE[templateId - 1].leverage}x
            </div>
          </div>
          <div className="flex space-x-1">
            <div className="font-semibold text-high-green">Fee APR</div>
            <APRComponent
              chainId={chainId}
              pairInfo={pairInfo}
              price={price}
              leverage={leverage}
            />
          </div>
        </div>

        <DepositLimit
          chainId={chainId}
          pairInfo={pairInfo}
          leverage={leverage}
          price={price}
        />

        <div className="space-y-3">
          <div>
            <div className="mb-1">
              <Title>Auto Close</Title>
            </div>
            <div className="flex justify-between space-x-2 text-xs">
              <div className="text-white5">Close After</div>
              <div className="flex justify-between items-center space-x-1">
                <div>{duration} H</div>
                <AutoClose duration={duration} setDuration={setDuration} />
              </div>
            </div>
          </div>

          {strategy.autoHedge ? (
            <div>
              <div className="mb-1">
                <Title>Auto Delta Hedge</Title>
              </div>
              <div className="flex justify-between space-x-2 text-xs">
                <div className="text-white5">Time Interval</div>
                <div className="flex justify-between items-center space-x-1">
                  <div>{timeInterval} H</div>
                  <AutoDeltaHedge
                    leverage={leverage}
                    timeInterval={timeInterval}
                    priceTrigger={priceTrigger}
                    setTimeInterval={setTimeInterval}
                    setPriceTrigger={setPriceTrigger}
                  />
                </div>
              </div>

              <div className="flex justify-between space-x-2 text-xs">
                <div className="text-white5">Price Trigger</div>
                <div className="flex justify-between items-center space-x-1">
                  <div>{priceTrigger} %</div>
                  <AutoDeltaHedge
                    leverage={leverage}
                    timeInterval={timeInterval}
                    priceTrigger={priceTrigger}
                    setTimeInterval={setTimeInterval}
                    setPriceTrigger={setPriceTrigger}
                  />
                </div>
              </div>
            </div>
          ) : (
            <> </>
          )}
        </div>
      </div>

      <div className="p-2 space-y-3 bg-panel border-panelborder border-[1px] rounded">
        <div className="h-full space-y-2">
          <StrongTitle>Deposit</StrongTitle>
          <div className="flex text-xs space-x-1">
            <div className="text-fourth">Avbl</div>
            <div className="text-fourth">{availableAmount} USDC</div>
          </div>

          <DepositAmountForm
            value={value}
            setValue={setValue}
            availableAmount={availableAmount || 0}
            availableInPool={availableInPool || 0}
            isLoading={availableAmount === null || availableInPool === null}
          />
        </div>
      </div>

      <div
        className={`p-2 w-full ${
          isChartOpen ? 'h-[348px]' : ''
        } bg-panel border-panelborder border-[1px] rounded`}
      >
        <div className="flex justify-between">
          <div className="mb-2">
            <StrongTitle>PnL Simulation</StrongTitle>
          </div>
          <AiOutlineLineChart
            className="md:hidden w-[18px] h-[18px]"
            color={isChartOpen ? '#FFF' : '#777'}
            onClick={() => {
              setIsChartOpen(!isChartOpen)
            }}
          />
        </div>
        {isChartOpen ? (
          <div className="w-full h-full">
            <GammaPnLChart
              pair={pairInfo}
              price={price}
              position={position}
              lastPrice={price}
              priceTrigger={priceTrigger}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="z-10 sticky bottom-6 left-0 p-2 bg-background border-panelborder border-[1px]">
        {error ? <div className="pb-1 text-red text-xs">{error}</div> : <></>}
        {permit.error ? (
          <div className="pb-1 text-red text-xs">{permit.error}</div>
        ) : (
          <></>
        )}
        <div className="h-10 flex space-x-2">
          <CancelButton
            onClick={() => {
              if (animatedTextRef.current) {
                animatedTextRef.current.classList.remove('animate-slide-up')
                animatedTextRef.current.classList.add('animate-slide-down')
              }
              setTimeout(() => {
                if (onCancel) onCancel()
              }, 100)
            }}
          >
            Cancel
          </CancelButton>
          <AccentButton
            size="lg"
            onClick={openPositionHandler}
            disabled={
              permit.isNonceLoading ||
              routeIndex < 0 ||
              value < 10 ||
              isTimeIntervalInvalid(timeInterval) ||
              isPriceTriggerInvalid(priceTrigger)
            }
          >
            {permit.isEnoughAllowance ? 'Create Position' : 'Approve'}
          </AccentButton>
        </div>
      </div>
    </div>
  )
}
