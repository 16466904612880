import { Address } from 'viem'
import ethIcon from '../assets/assets/eth.svg'
import arbIcon from '../assets/assets/arb.png'
import wbtcIcon from '../assets/assets/wbtc.svg'
import usdcIcon from '../assets/assets/usdc.svg'
//import gyenIcon from '../assets/assets/gyen.png'
// import lusdIcon from '../assets/assets/lusd.png'
// import linkIcon from '../assets/assets/link.png'

export type PairInfo = {
  id: string
  pairSymbol: string
  pairId?: number
  base: AssetInfo
  quote: AssetInfo
  uniswapPoolAddress: string
  pricePrecision: number
  endpoint: string
  slippageTolerance: number
}

export type AssetInfo = {
  symbol: string
  description: string
  icon: string
  decimals: number
  precision: number
  address: Address
}

const ASSET_INFO_ETH: AssetInfo = {
  symbol: 'WETH',
  description: 'Ethereum',
  icon: ethIcon,
  decimals: 18,
  precision: 3,
  address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'
}

const ASSET_INFO_ARB: AssetInfo = {
  symbol: 'ARB',
  description: 'Arbitrum',
  icon: arbIcon,
  decimals: 18,
  precision: 2,
  address: '0x912CE59144191C1204E64559FE8253a0e49E6548'
}

const ASSET_INFO_WBTC: AssetInfo = {
  symbol: 'WBTC',
  description: 'Wrapped Bitcoin',
  icon: wbtcIcon,
  decimals: 8,
  precision: 4,
  address: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f'
}

const ASSET_INFO_USDC: AssetInfo = {
  symbol: 'USDC',
  description: 'USD Coin',
  icon: usdcIcon,
  decimals: 6,
  precision: 2,
  address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'
}

const ASSET_INFO_USDCE: AssetInfo = {
  symbol: 'USDCe',
  description: 'USD Coin (Arb1)',
  icon: usdcIcon,
  decimals: 6,
  precision: 2,
  address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8'
}

/*
const ASSET_INFO_GYEN: AssetInfo = {
  symbol: 'GYEN',
  description: 'GMO JPY',
  icon: gyenIcon,
  decimals: 6,
  address: '0x589d35656641d6aB57A545F08cf473eCD9B6D5F7'
}
*/

const PAIR_INFOS_ARBITRUM = {
  'eth-usdce': {
    id: 'eth-usdce',
    pairSymbol: 'ETH-USDCe',
    pairId: 1,
    base: ASSET_INFO_ETH,
    quote: ASSET_INFO_USDCE,
    pricePrecision: 2,
    uniswapPoolAddress: '0xC31E54c7a869B9FcBEcc14363CF510d1c41fa443',
    endpoint:
      'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USDC',
    slippageTolerance: 25
  },
  'eth-usdc': {
    id: 'eth-usdc',
    pairSymbol: 'ETH-USDC',
    pairId: 2,
    base: ASSET_INFO_ETH,
    quote: ASSET_INFO_USDC,
    pricePrecision: 2,
    uniswapPoolAddress: '0xC6962004f452bE9203591991D15f6b388e09E8D0',
    endpoint:
      'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USDC',
    slippageTolerance: 25
  },
  'arb-usdc': {
    id: 'arb-usdc',
    pairSymbol: 'ARB-USDC',
    pairId: undefined,
    base: ASSET_INFO_ARB,
    quote: ASSET_INFO_USDC,
    pricePrecision: 4,
    uniswapPoolAddress: '0xb0f6cA40411360c03d41C5fFc5F179b8403CdcF8',
    endpoint:
      'https://min-api.cryptocompare.com/data/price?fsym=ARB&tsyms=USDC',
    slippageTolerance: 30
  },
  'wbtc-usdc': {
    id: 'wbtc-usdc',
    pairSymbol: 'WBTC-USDC',
    pairId: undefined,
    base: ASSET_INFO_WBTC,
    quote: ASSET_INFO_USDC,
    pricePrecision: 1,
    uniswapPoolAddress: '0x0E4831319A50228B9e450861297aB92dee15B44F',
    endpoint:
      'https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USDC',
    slippageTolerance: 40
  }
}

export const PAIR_INFOS: {
  [key: number]: { [key: string]: PairInfo }
} = {
  1337: PAIR_INFOS_ARBITRUM,
  42161: PAIR_INFOS_ARBITRUM
}
