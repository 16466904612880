import { maxUint256 } from 'viem'

export const abs = (n: bigint) => (n < 0n ? -n : n)

export function toUnscaled(
  n: bigint,
  decimal: number,
  precision = 6,
  roundUp = false
): number {
  const p = BigInt(10 ** precision)

  if (roundUp) {
    const divider = pow10(decimal - 1)
    return Math.round(Number((n * p) / divider) / 10) / Number(p)
  }

  return Number((n * p) / pow10(decimal)) / Number(p)
}

export function toScaled(
  n: number | string,
  decimal: number,
  precision = 6
): bigint {
  // handle decimal
  const str = n.toString()
  if (str.indexOf('.') !== -1) {
    // calc decimal part length
    const decimalLength = str.split('.')[1].length
    const splittedStrs = str.split('.')
    if (decimalLength <= decimal) {
      return round(
        BigInt(splittedStrs[0] + splittedStrs[1]) *
          pow10(decimal - decimalLength)
      )
    } else {
      return round(BigInt(splittedStrs[0] + splittedStrs[1].slice(0, decimal)))
    }
  }

  return round(BigInt(n) * pow10(decimal))

  function round(n: bigint) {
    const divider = pow10(decimal - precision)
    return (n / divider) * divider
  }
}

export function pow10(d: number): bigint {
  return 10n ** BigInt(d)
}

export function sqrt(x: bigint) {
  let z = (x + 1n) / 2n
  let y = x
  while (z - y < 0) {
    y = z
    z = (x / z + z) / 2n
  }
  return y
}

export function max(bns: bigint[]) {
  return bns.reduce((max, bn) => (bn > max ? bn : max), 0n)
}

export function min(bns: bigint[]) {
  return bns.reduce((min, bn) => (bn < min ? bn : min), maxUint256)
}
