import React, { useState } from 'react'
import { useLocation, Link } from 'wouter'
import Logo from '../../assets/logo.svg'
import LogoSmall from '../../assets/logo_small.svg'
import { HamburgerMenu } from '../common/HamburgerMenu'
import { useTradeRoute } from '../../hooks/useTradeRoute'
import { ConnectButton } from './ConnectButton'

enum SelectableMenu {
  Swap,
  Perp,
  Gamma,
  Strategy,
  Lending
}

function getSelectableMenu(path: string) {
  if (path.indexOf('swap') >= 0) {
    return SelectableMenu.Swap
  } else if (path.indexOf('perp') >= 0) {
    return SelectableMenu.Perp
  } else if (path.indexOf('predict') >= 0) {
    return SelectableMenu.Gamma
  } else if (path.indexOf('gamma') >= 0) {
    return SelectableMenu.Gamma
  } else if (path.indexOf('lending') >= 0) {
    return SelectableMenu.Lending
  }

  return SelectableMenu.Swap
}

const Header = () => {
  const { chainName, pairTitle } = useTradeRoute()

  const [path] = useLocation()
  const selected = getSelectableMenu(path)
  const [isOpenMobileMenu, setOpenMobileMenu] = useState(false)

  const currentChainName = chainName || 'arbitrum'
  const currentPairTitle = pairTitle || 'eth-usdc'

  return (
    <div className="relative top-0 left-0 box-border z-50 w-full bg-background">
      <div className="flex items-center justify-between h-[64px] px-4 font-normal">
        <div className="flex items-center">
          <div className="hidden md:block">
            <img className="min-w-[80px]" src={Logo} alt="Logo"></img>
          </div>
          <div className="block md:hidden">
            <img
              className="min-w-[36px]"
              src={LogoSmall}
              alt="Logo"
              width={20}
              height={20}
            ></img>
          </div>
        </div>
        <div>
          <div className="hidden md:block">
            <ul className="flex flex-row items-center space-x-5 text-sm font-bold">
              <li
                className={`flex justify-center items-center space-x-1 rounded-3xl px-4 py-1 ${
                  selected === SelectableMenu.Swap
                    ? 'text-accent'
                    : 'text-white hover:text-accent'
                }`}
              >
                <Link
                  href={`/${currentChainName || 'local'}/swap/${
                    currentPairTitle || 'eth-usdc'
                  }`}
                >
                  Swap
                </Link>
              </li>
              <li
                className={`flex justify-center items-center space-x-1 rounded-3xl px-4 py-1 ${
                  selected === SelectableMenu.Perp
                    ? 'text-accent'
                    : 'text-white hover:text-accent'
                }`}
              >
                <Link href={`/${currentChainName}/perp/${currentPairTitle}`}>
                  Perp
                </Link>
              </li>
              <li
                className={`flex justify-center items-center space-x-1 rounded-3xl px-4 py-1 ${
                  selected === SelectableMenu.Gamma
                    ? 'text-accent'
                    : 'text-white hover:text-accent'
                }`}
              >
                <Link href={`/${currentChainName}/gamma/${currentPairTitle}`}>
                  Gamma
                </Link>
              </li>
              {/*

              <li
                className={`rounded-3xl px-4 py-1 ${
                  selected === SelectableMenu.Gamma
                    ? 'bg-primary text-white'
                    : 'text-third'
                }`}
              >
                <Link
                  href={`/${currentChainName}/predict/${currentPairTitle}`}
                  className="whitespace-nowrap"
                >
                  Predict
                </Link>
              </li>
              <li
                className={`rounded-3xl px-4 py-1 ${
                  selected === SelectableMenu.Strategy
                    ? 'bg-primary text-white'
                    : 'text-third'
                }`}
              >
                <Link
                  href={`/${currentChainName}/gamma/${currentPairTitle}`}
                  className="whitespace-nowrap"
                >
                  Strategy
                </Link>
              </li>
              <li
                className={`rounded-3xl px-4 py-1 ${
                  selected === SelectableMenu.Lending
                    ? 'text-accent'
                    : 'text-white hover:text-accent'
                }`}
              >
                <Link
                  href={`/${currentChainName}/lending/${currentPairTitle}`}
                  className="whitespace-nowrap"
                >
                  Lending
                </Link>
              </li>
              */}
              <li className={`rounded-3xl px-4 py-1 text-white`}>
                <a
                  href={`https://docs.predy.finance/predy-v6`}
                  target="_blank"
                  rel="noreferrer"
                  className="whitespace-nowrap"
                >
                  Doc
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex items-center">
          <div>
            <div className="px-1">
              <div className="flex justify-center space-x-2">
                <div className="w-44 md:w-40 h-8 rounded-sm hover:bg-white1 border-[1px] border-fourth flex justify-center items-center">
                  <ConnectButton>Connect Wallet</ConnectButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block md:hidden">
          <HamburgerMenu
            onClick={() => {
              setOpenMobileMenu(!isOpenMobileMenu)
            }}
          />
        </div>
      </div>
      {isOpenMobileMenu ? (
        <div className="block md:hidden">
          <ul className="py-2 flex flex-col items-center space-y-6 text-base bg-background">
            <li
              className={`px-4 py-1 ${
                selected === SelectableMenu.Swap
                  ? 'text-accent'
                  : 'text-third hover:text-accent'
              }`}
            >
              <Link href={`/${currentChainName}/swap/${currentPairTitle}`}>
                Swap
              </Link>
            </li>
            <li
              className={`px-4 py-1 ${
                selected === SelectableMenu.Perp
                  ? 'text-accent'
                  : 'text-third hover:text-accent'
              }`}
            >
              <Link href={`/${currentChainName}/perp/${currentPairTitle}`}>
                Perp
              </Link>
            </li>
            <li
              className={`px-4 py-1 ${
                selected === SelectableMenu.Gamma
                  ? 'text-accent'
                  : 'text-third hover:text-accent'
              }`}
            >
              <Link href={`/${currentChainName}/gamma/${currentPairTitle}`}>
                Gamma
              </Link>
            </li>
            <li className={`px-4 py-1 text-third`}>
              <a
                href={`https://docs.predy.finance/predy-v6`}
                target="_blank"
                rel="noreferrer"
                className="whitespace-nowrap"
              >
                Doc
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Header
