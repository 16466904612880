import React, { useMemo } from 'react'
import { PnLChart } from '../chart/PnLChart'
import { Position, Q96 } from '@predy/js-sdk'
import { toUnscaled } from '../../utils/bn'
import { PairInfo } from '../../constants'
import { toUnscaledPrice } from '../../utils'

type Props = {
  position: Position
  price: number
  lastPrice: number
  priceTrigger: number
  pair: PairInfo
}

const TICKS = 100

function calculatePnL(
  price: number,
  position: Position,
  liqPrice1: number,
  liqPrice2: number
) {
  const base = Math.floor(price / 10) * 10

  const prices = Array.from(Array(TICKS).keys())
    .map(i => i * 10 + base - (10 * TICKS) / 2)
    .concat([price, liqPrice1, liqPrice2])
    .filter(price => price > 0)

  prices.sort((a, b) => a - b)

  return prices
    .map(price => {
      const sqrtPriceX96 =
        (BigInt(Math.floor(Math.sqrt(price * 1000000))) * Q96) / 10n ** 9n

      const value = position.calculateValue(sqrtPriceX96)

      return {
        price,
        value: toUnscaled(value, 6)
      }
    })
    .filter(item => item.value >= 0)
}

export const GammaPnLChart = ({
  pair,
  price,
  position,
  lastPrice,
  priceTrigger
}: Props) => {
  const liqPrice1 = useMemo(() => {
    return toUnscaledPrice(pair, position.calculateLiquidationPrice1(0n))
  }, [pair, position])

  const liqPrice2 = useMemo(() => {
    return toUnscaledPrice(pair, position.calculateLiquidationPrice2(0n))
  }, [pair, position])

  const data = useMemo(() => {
    return calculatePnL(price, position, liqPrice1, liqPrice2)
  }, [price, position, liqPrice1, liqPrice2])

  return (
    <PnLChart
      current={price}
      data={data}
      liq1={liqPrice1}
      liq2={liqPrice2}
      hedgeLower={
        priceTrigger > 0 ? lastPrice / ((priceTrigger + 100) / 100) : 0
      }
      hedgeUpper={
        priceTrigger > 0 ? lastPrice * ((priceTrigger + 100) / 100) : 0
      }
    />
  )
}
