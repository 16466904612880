import { useSimulateContract } from 'wagmi'
import { ADDRESS_MAP } from '../../constants/addresses'
import { PerpMarketABI } from '@predy/js-sdk'

export function useUpdateQuoteTokenMap(chainId: number, pairId: number) {
  return useSimulateContract({
    chainId,
    address: ADDRESS_MAP[chainId].PerpMarket,
    abi: PerpMarketABI,
    functionName: 'updateQuoteTokenMap',
    args: [BigInt(pairId)]
  })
}
