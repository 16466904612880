import React, { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { Address, zeroAddress } from 'viem'
import { AmountForm, HorizontalRule, LoadingIndicator } from '../../common'
import { OrderType, OrderTypeSelector } from './OrderTypeSelector'
import { PriceResult, usePrice } from '../../../hooks/query/usePrice'
import { toUnscaled } from '../../../utils/bn'
import {
  PerpPosition,
  getZeroPerpPosition,
  usePerpPosition
} from '../../../hooks/perp'
import { LeverageModal } from '../LeverageModal'
import { SizeForm, SizeFormSm } from './SizeForm'
import { Direction, PairInfo } from '../../../constants'
import { TradeResult } from './TradeResult'
import { toUnscaledPrice } from '../../../utils'
import { useAvbl } from '../../../hooks/perp/useAvbl'
import { DirectionSelector } from './DirectionSelector'

const AvblBalance = ({
  chainId,
  pairInfo,
  trader
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
}) => {
  const balance = useAvbl(chainId, pairInfo, trader)

  return (
    <div className="flex justify-between text-xs space-x-2">
      <div className="text-white5">Avbl</div>
      <div>
        {balance !== null
          ? toUnscaled(
              balance,
              pairInfo.quote.decimals,
              pairInfo.quote.precision
            )
          : '-'}{' '}
        USDC
      </div>
    </div>
  )
}

export const TradeForm = ({
  chainId,
  pairInfo
}: {
  chainId: number
  pairInfo: PairInfo
}) => {
  const price = usePrice(chainId, pairInfo.pairId || 0)
  const { address } = useAccount()
  const position = usePerpPosition(
    chainId,
    address || zeroAddress,
    BigInt(pairInfo.pairId || 0)
  )

  if (pairInfo.pairId === undefined || pairInfo.pairId === 1)
    return (
      <div className="p-4 flex justify-center">
        <div>
          Pair not found. Please return to{' '}
          <a className="text-accent underline" href="/arbitrum/perp/eth-usdc">
            home
          </a>
          .
        </div>
      </div>
    )

  if (price.isSuccess && price.data) {
    if (address) {
      if (position.isSuccess) {
        return (
          <TradeFormWithAccount
            chainId={chainId}
            pairInfo={pairInfo}
            price={price.data}
            trader={address}
            perpPosition={position.data}
            isLoggedIn={true}
          />
        )
      }
    } else {
      return (
        <TradeFormWithAccount
          chainId={chainId}
          pairInfo={pairInfo}
          price={price.data}
          trader={zeroAddress}
          perpPosition={getZeroPerpPosition(BigInt(pairInfo.pairId))}
          isLoggedIn={false}
        />
      )
    }
  }

  return (
    <div>
      <LoadingIndicator />
    </div>
  )
}

export const TradeFormWithAccount = ({
  chainId,
  pairInfo,
  price,
  trader,
  perpPosition,
  isLoggedIn
}: {
  chainId: number
  pairInfo: PairInfo
  price: PriceResult
  trader: Address
  perpPosition: PerpPosition
  isLoggedIn: boolean
}) => {
  const [direction, setDirection] = useState<Direction>(Direction.Long)
  const [orderType, setOrderType] = useState<OrderType>(OrderType.Market)

  const [buyAmount, setBuyAmount] = useState(0n)
  const [sellAmount, setSellAmount] = useState(0n)

  const [leverage, setLeverage] = useState(20)
  const [limitPrice, setLimitPrice] = useState(0)

  const mediaQuery = window.matchMedia('(width < 768px)')
  const isMobile = mediaQuery.matches

  useEffect(() => {
    const limitPrice = toUnscaledPrice(pairInfo, price.price)

    setLimitPrice(Math.abs(limitPrice))
  }, []) // eslint-disable-line

  useEffect(() => {
    if (perpPosition.leverage > 0) {
      setLeverage(perpPosition.leverage)
    }
  }, []) // eslint-disable-line

  return (
    <div className="h-full bg-panel border-[1px] border-panelborder">
      {isMobile ? (
        <div className="h-10">
          <DirectionSelector
            direction={direction}
            setDirection={setDirection}
          />
        </div>
      ) : (
        <></>
      )}

      <div className="m-3 flex justify-around items-center text-sm md:text-xs font-medium">
        <div>Leverage</div>
        <LeverageModal leverage={leverage} setLeverage={setLeverage} />
      </div>

      <div className="mx-3">
        <HorizontalRule />
      </div>

      <div className="mx-3 my-2 md:my-3">
        <OrderTypeSelector orderType={orderType} setOrderType={setOrderType} />
      </div>

      <div className="mx-3 py-1">
        <div className="flex justify-start">
          <AvblBalance chainId={chainId} pairInfo={pairInfo} trader={trader} />
        </div>
      </div>

      <div className="mx-3 my-2 md:my-3 flex justify-between">
        <AmountForm
          amount={limitPrice}
          disabled={false}
          title="Price"
          unit="USDC"
          step={1 / 10 ** pairInfo.pricePrecision}
          digits={pairInfo.pricePrecision}
          onChange={setLimitPrice}
          disabledText={orderType === OrderType.Market ? 'Market' : undefined}
        />
      </div>

      <div className="mx-3 py-1">
        {isMobile ? (
          <SizeFormSm
            chainId={chainId}
            pairInfo={pairInfo}
            trader={trader}
            amount={0}
            currentPositionAmount={perpPosition.perpAmount}
            leverage={leverage}
            direction={direction}
            setBuyAmount={setBuyAmount}
            setSellAmount={setSellAmount}
            limitPrice={orderType === OrderType.Market ? 0 : limitPrice}
          />
        ) : (
          <SizeForm
            chainId={chainId}
            pairInfo={pairInfo}
            trader={trader}
            amount={0}
            currentPositionAmount={perpPosition.perpAmount}
            leverage={leverage}
            setBuyAmount={setBuyAmount}
            setSellAmount={setSellAmount}
            limitPrice={orderType === OrderType.Market ? 0 : limitPrice}
          />
        )}
      </div>

      <div className="mx-3">
        <HorizontalRule />
      </div>

      {isLoggedIn ? (
        <div className="mb-3 mx-3 flex justify-between space-x-3">
          {!isMobile || direction === Direction.Long ? (
            <TradeResult
              chainId={chainId}
              pairInfo={pairInfo}
              trader={trader}
              isLong={true}
              price={price.price}
              currentPositionAmount={perpPosition.perpAmount}
              leverage={leverage}
              tradeAmount={buyAmount}
              limitPrice={
                orderType === OrderType.Limit ? limitPrice : undefined
              }
            />
          ) : (
            <></>
          )}
          {!isMobile || direction === Direction.Short ? (
            <TradeResult
              chainId={chainId}
              pairInfo={pairInfo}
              trader={trader}
              isLong={false}
              price={price.price}
              currentPositionAmount={perpPosition.perpAmount}
              leverage={leverage}
              tradeAmount={sellAmount}
              limitPrice={
                orderType === OrderType.Limit ? limitPrice : undefined
              }
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="mx-3 my-2 flex justify-center">
          Please connect to trade
        </div>
      )}
    </div>
  )
}
