import React, { useMemo } from 'react'
import { Switch, Router, Route, Redirect } from 'wouter'
import LendingView from './pages/LendingView'
import SwapView from './pages/SwapView'
import PerpView from './pages/PerpView'
import GammaView from './pages/GammaView'
import PairListView from './pages/PairListView'
import GalleryView from './pages/GalleryView'
import NetworkErrorLabel from './components/common/NetworkErrorLabel'
import Header from './components/header/Header'
import { ApolloProvider } from '@apollo/client'
import { PredyClient } from './utils/apollo-client'
import { DEFAULT_CHAIN, FILLER_WS_ENDPOINT, PAIR_INFOS } from './constants'
import { useChainId, useIsSupportedChain } from './hooks/network'
import UnsupportedNetwork from './components/UnsupportedNetwork'
import NotFound from './components/NotFound'
import Footer from './components/common/Footer'
import Modal from 'react-modal'
import { WebSocketProvider } from './hooks/price/WebSocketProvider'
import { getSymbolName } from './utils'
import Maintenance from './components/Maintenance'

Modal.setAppElement('#root')

const App = () => {
  const chainId = useChainId()
  const supported = useIsSupportedChain()

  const client = useMemo(() => PredyClient[chainId || DEFAULT_CHAIN], [chainId])

  return (
    <ApolloProvider client={client || PredyClient[DEFAULT_CHAIN]}>
      <WebSocketProvider
        endpoint={FILLER_WS_ENDPOINT}
        initialMessage={{
          action: 'subscribe',
          symbolName: getSymbolName(PAIR_INFOS[DEFAULT_CHAIN]['eth-usdc']),
          resolution: 3600
        }}
      >
        <NetworkErrorLabel />
        <Router>
          <Header />
          {supported ? (
            <div className="mx-auto mt-[0px]">
              <Switch>
                <Route path="/">
                  <Redirect to="/maintenance" />
                </Route>
                <Route path="/:chain/perp/:pair">
                  <Redirect to="/maintenance" />
                </Route>
                <Route path="/:chain/gamma/:pair">
                  <Redirect to="/maintenance" />
                </Route>
                <Route path="/gallery" component={GalleryView} />
                <Route path="/:chain/pairs" component={PairListView} />
                <Route path="/:chain/lending" component={LendingView} />
                <Route path="/:chain/swap/:pair" component={SwapView} />
                <Route path="/:chain/perp/:pair" component={PerpView} />
                <Route path="/:chain/gamma/:pair" component={GammaView} />
                <Route
                  path="/:chain/gamma/:pair/create/:templateId"
                  component={GammaView}
                />
                <Route
                  path="/:chain/gamma/:pair/positions/:positionId"
                  component={GammaView}
                />
                <Route path="/maintenance">
                  <Maintenance />
                </Route>
                <Route path="/:rest*">
                  <NotFound />
                </Route>
              </Switch>
            </div>
          ) : (
            <UnsupportedNetwork />
          )}
          <Footer />
        </Router>
      </WebSocketProvider>
    </ApolloProvider>
  )
}

export default App
