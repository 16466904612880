import { useMemo } from 'react'
import { Address, isAddressEqual, zeroAddress } from 'viem'
import { useSimulateContract } from 'wagmi'
import { Position, PerpMarketABI } from '@predy/js-sdk'
import { ADDRESS_MAP, PAIR_INFOS, Q96 } from '../../constants'
import { abs, toUnscaled } from '../../utils/bn'
import { toUnscaledPrice, formatPrice } from '../../utils'
import { pairIdToSymbol } from '../../utils/pairs'

export interface PerpPosition {
  pairId: number
  isLoaded: boolean
  leverage: number
  perpAmount: bigint
  currentMargin: bigint
  entryPrice: bigint
  oraclePrice: bigint
  entryValue: bigint
  position: Position
  unrealizedFee: {
    feeAmountBase: bigint
    feeAmountQuote: bigint
  }
  marginBn: bigint
  vaultValue: bigint
  formattedData: {
    pairId: bigint
    isLong: boolean
    entryPrice: number
    quantity: number
    margin: number
    markPrice: number
    liquidationPrice: number
  }
}

export function getZeroPerpPosition(pairId: bigint): PerpPosition {
  return {
    pairId: Number(pairId),
    isLoaded: false,
    leverage: 0,
    perpAmount: 0n,
    currentMargin: 0n,
    entryValue: 0n,
    entryPrice: 0n,
    oraclePrice: 0n,
    position: new Position(0n, 0n, 0n),
    unrealizedFee: {
      feeAmountBase: 0n,
      feeAmountQuote: 0n
    },
    marginBn: 0n,
    vaultValue: 0n,
    formattedData: {
      pairId,
      isLong: false,
      entryPrice: 0,
      quantity: 0,
      margin: 0,
      markPrice: 0,
      liquidationPrice: 0
    }
  }
}

export function usePerpPosition(
  chainId: number,
  account: Address,
  pairId: bigint
) {
  const result = useSimulateContract({
    address: ADDRESS_MAP[chainId].PerpMarket,
    abi: PerpMarketABI,
    functionName: 'getUserPosition',
    args: [account, pairId],
    chainId,
    query: {
      refetchInterval: 30000,
      enabled: pairId >= 1 && !isAddressEqual(account, zeroAddress)
    }
  })

  const data = useMemo(() => {
    if (!result.data) {
      return getZeroPerpPosition(pairId)
    }

    const pairInfo = PAIR_INFOS[chainId][pairIdToSymbol(Number(pairId))]

    const perpPosition = result.data.result[0]
    const vaultStatus = result.data.result[1]
    const vault = result.data.result[2]
    const perp = vault.openPosition.perp

    const perpAmount = perp.amount
    const currentMargin = vault.margin

    const isLong = perp.amount >= 0n

    const entryPriceBn =
      perp.amount !== 0n ? abs((-perp.entryValue * Q96) / perp.amount) : 0n

    const entryPrice = toUnscaledPrice(pairInfo, entryPriceBn)

    const quantity = toUnscaled(abs(perpAmount), pairInfo.base.decimals, 3)

    const markPriceBn =
      (vaultStatus.oraclePrice * vaultStatus.oraclePrice) / 2n ** 96n

    const markPrice = toUnscaledPrice(pairInfo, markPriceBn)

    const unrealizedFee =
      vaultStatus.feeAmount.feeAmountQuote +
      (vaultStatus.feeAmount.feeAmountBase * markPriceBn) / 2n ** 96n

    const marginBn = currentMargin + unrealizedFee

    const margin = toUnscaled(marginBn, 6, pairInfo.quote.precision)

    const position = new Position(
      perp.entryValue + vaultStatus.feeAmount.feeAmountQuote,
      0n,
      perpAmount + vaultStatus.feeAmount.feeAmountBase
    )

    const liquidationPrice1 = toUnscaledPrice(
      pairInfo,
      position.calculateLiquidationPrice1(currentMargin)
    )

    const liquidationPrice2 = toUnscaledPrice(
      pairInfo,
      position.calculateLiquidationPrice2(currentMargin)
    )

    return {
      pairId: Number(pairId),
      isLoaded: perpAmount !== 0n,
      leverage: perpPosition.lastLeverage,
      perpAmount,
      currentMargin,
      entryPrice: entryPriceBn,
      entryValue: perp.entryValue,
      oraclePrice: markPriceBn,
      position,
      unrealizedFee: vaultStatus.feeAmount,
      marginBn,
      vaultValue: vaultStatus.vaultValue,
      formattedData: {
        pairId,
        isLong,
        entryPrice,
        quantity,
        margin,
        markPrice: formatPrice(pairInfo, markPrice),
        liquidationPrice: isLong ? liquidationPrice1 : liquidationPrice2
      }
    } as PerpPosition
  }, [chainId, pairId, result.data])

  return {
    ...result,
    data
  }
}
