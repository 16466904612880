import React from 'react'
import { TimeFilter } from '../../../constants'

export const TimeSelector = ({
  time,
  onSelectTime
}: {
  time: TimeFilter
  onSelectTime: (time: TimeFilter) => void
}) => {
  return (
    <div className="flex text-xs text-fourth space-x-1">
      <div
        className={`p-1 rounded cursor-pointer ${
          time === 'd' ? 'bg-secondary text-white' : ''
        }`}
        onClick={() => {
          onSelectTime('d')
        }}
      >
        1 Day
      </div>
      <div
        className={`p-1 rounded cursor-pointer ${
          time === 'w' ? 'bg-secondary text-white' : ''
        }`}
        onClick={() => {
          onSelectTime('w')
        }}
      >
        1 Week
      </div>
      <div
        className={`p-1 rounded cursor-pointer ${
          time === 'm' ? 'bg-secondary text-white' : ''
        }`}
        onClick={() => {
          onSelectTime('m')
        }}
      >
        1 Month
      </div>
      <div
        className={`p-1 rounded cursor-pointer ${
          time === 'all' ? 'bg-secondary text-white' : ''
        }`}
        onClick={() => {
          onSelectTime('all')
        }}
      >
        All
      </div>
    </div>
  )
}
