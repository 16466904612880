import { useState } from 'react'
import { Bytes, SpotOrderV3 } from '@predy/js-sdk'
import { useSignTypedData } from 'wagmi'
import { FILLER_API_ENDPOINT } from '../../constants'
import { reasonToErrorMessage } from '../../utils/error'
import { SpotOrderV3Params } from '@predy/js-sdk/dist/order/types'
import { useQueryClient } from '@tanstack/react-query'
import { useSpotOrderWithLimitPrice } from './useSpotOrder'

export function useModifySpotOrder(
  orderId: number,
  order: SpotOrderV3,
  baseTokenAmount: bigint,
  quoteTokenAmount: bigint,
  cb: (error: string | null, tradeResult: any) => void
) {
  const queryClient = useQueryClient()
  const newOrder = useSpotOrderWithLimitPrice(
    order,
    baseTokenAmount,
    quoteTokenAmount
  )
  const { domain, types, message } = newOrder.permitData()

  const [isAPILoading, setIsAPILoading] = useState(false)
  const [error, setError] = useState<null | string>(null)

  const { signTypedData, data, status } = useSignTypedData({
    mutation: {
      onSuccess: async (data: Bytes, variables) => {
        console.log('aaa', variables)

        const signedOrder = new SpotOrderV3(
          variables.message.witness as SpotOrderV3Params,
          order.chainId
        )

        setIsAPILoading(true)
        setError(null)

        console.log('start to put order/v2/spot')

        const res = await fetch(`${FILLER_API_ENDPOINT}order/v2/spot`, {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            orderId,
            baseTokenAmount: signedOrder.spotOrder.baseTokenAmount.toString(),
            limitQuoteTokenAmount:
              signedOrder.spotOrder.limitQuoteTokenAmount.toString(),
            signature: data
          })
        })

        console.log('end put order/v2/perp', res)

        const tradeResult = await res.json()

        if (tradeResult.message && tradeResult.message.length > 0) {
          const message = tradeResult.message

          const errorMessage = reasonToErrorMessage(message)

          if (errorMessage) {
            setError(errorMessage)
            cb(errorMessage, null)
          } else {
            setError('unexpected error')
            console.error(message)
            cb(message, null)
          }

          setIsAPILoading(false)

          return
        }

        setIsAPILoading(false)

        await queryClient.invalidateQueries({
          queryKey: [
            'spot_orders',
            order.chainId,
            order.spotOrder.info.trader.toLowerCase(),
            'pending'
          ]
        })

        cb(null, tradeResult)
      }
    }
  })

  return {
    variables: {
      domain,
      types,
      message,
      primaryType: 'PermitWitnessTransferFrom'
    },
    signTypedData,
    data,
    error,
    isLoading: status === 'pending' || isAPILoading
  }
}
