import React, { useState } from 'react'
import { useAccount } from 'wagmi'
import { Tab, TabList } from '../../common/Tab'
import { OpenPositions } from './OpenPositions'
import { OpenOrders } from './OpenOrders'
import { TradeHistory } from './TradeHistory'
import { usePrice } from '../../../hooks/query/usePrice'
import { PairInfo } from '../../../constants'
import { ConnectLink } from '../../header/ConnectButton'
import { OrderHistory } from './OrderHistory'
import { usePerpOrders } from '../../../hooks/perp'
import { zeroAddress } from 'viem'

enum PositionTabItems {
  OpenPositions,
  OpenOrders,
  OrderHistory,
  Trades
}

export const PositionTab = ({
  chainId,
  pairInfo
}: {
  chainId: number
  pairInfo: PairInfo
}) => {
  const [selectedTab, selectTab] = useState<PositionTabItems>(
    PositionTabItems.OpenPositions
  )
  const { address } = useAccount()
  const price = usePrice(chainId, pairInfo.pairId || 0)
  const openOrders = usePerpOrders(chainId, address || zeroAddress, true)

  if (address === undefined || !price.isSuccess || !price.data)
    return (
      <div>
        <ConnectLink>Please connect to trade</ConnectLink>
      </div>
    )

  return (
    <div>
      <TabList
        onSelect={(tabId: number) => {
          selectTab(tabId)
        }}
      >
        <Tab
          tabId={PositionTabItems.OpenPositions}
          selected={selectedTab === PositionTabItems.OpenPositions}
        >
          Positions
        </Tab>
        <Tab
          tabId={PositionTabItems.OpenOrders}
          selected={selectedTab === PositionTabItems.OpenOrders}
        >
          Open Orders{openOrders.data ? `(${openOrders.data.length})` : ''}
        </Tab>
        <Tab
          className="hidden md:block"
          tabId={PositionTabItems.OrderHistory}
          selected={selectedTab === PositionTabItems.OrderHistory}
        >
          Order History
        </Tab>
        <Tab
          tabId={PositionTabItems.Trades}
          selected={selectedTab === PositionTabItems.Trades}
        >
          Trades
        </Tab>
      </TabList>
      {selectedTab === PositionTabItems.OpenPositions ? (
        <OpenPositions chainId={chainId} account={address} price={price.data} />
      ) : selectedTab === PositionTabItems.OpenOrders ? (
        <OpenOrders chainId={chainId} trader={address} />
      ) : selectedTab === PositionTabItems.OrderHistory ? (
        <OrderHistory chainId={chainId} trader={address} />
      ) : selectedTab === PositionTabItems.Trades ? (
        <TradeHistory chainId={chainId} trader={address} />
      ) : (
        <></>
      )}
    </div>
  )
}
