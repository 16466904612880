import { PerpOrderV3, Position } from '@predy/js-sdk'
import { useQuery } from '@tanstack/react-query'
import { DEFAULT_STALE_TIME, REFETCH_INTERVAL } from '../../constants'
import { abs, max } from '../../utils/bn'
import { useMemo } from 'react'
import { usePerpPosition } from './usePosition'
import { calculateLogStep } from '../../utils/calculateLogStep'
import { Address } from 'viem'
import { apiGet } from '../../utils/fetch'
import { calculateEntry, calculateRequiredMargin } from '../../utils/im'

interface PerpQuote {
  // if long averagePrice is negative
  averagePrice: bigint
  fee: bigint
}

interface BestPerpQuote {
  quote: PerpQuote
  routeIndex: number
}

interface TradeResult {
  payoff: {
    perpEntryUpdate: bigint
    sqrtEntryUpdate: bigint
    sqrtRebalanceEntryUpdateUnderlying: bigint
    sqrtRebalanceEntryUpdateStable: bigint
    perpPayoff: bigint
    sqrtPayoff: bigint
  }
  vaultId: bigint
  fee: bigint
  minMargin: bigint
  averagePrice: bigint
  sqrtTwap: bigint
  sqrtPrice: bigint
}

export function updateOrderAmount(
  order: PerpOrderV3,
  newBaseTokenAmount: bigint
) {
  const witnessInfo = order.witnessInfo()

  witnessInfo.quantity = newBaseTokenAmount

  return new PerpOrderV3(witnessInfo, order.chainId)
}

export function useQuotePerpTrade(
  chainId: number,
  order: PerpOrderV3,
  refetchInterval = REFETCH_INTERVAL
) {
  const tradeAmount = order.perpOrder.quantity

  // const [step, setStep] = useState(0n)

  const step = calculateLogStep(tradeAmount)

  /*
  const [,] = useDebounce(
    () => {
      setStep(calculateLogStep(tradeAmount))
    },
    250,
    [tradeAmount]
  )
  */

  const query = useQuery<
    { error: null; result: BestPerpQuote } | { error: string; result: null }
  >({
    queryKey: [
      'quote_perp',
      chainId,
      order.perpOrder.pairId.toString(),
      order.perpOrder.side,
      step.toString()
    ],
    queryFn: async () => {
      const query = new URLSearchParams({
        order: updateOrderAmount(order, step).serialize(),
        chainId: chainId.toString()
      })

      const result = await apiGet('order/v2/perp/quote', query)

      if (result.message && result.message.length > 0) {
        return {
          error: result.message,
          result: null
        }
      }

      const quoteResult = deserializePerpQuote(result)

      return {
        error: null,
        result: quoteResult
      }
    },
    enabled: tradeAmount != 0n,
    // 20s
    refetchInterval: refetchInterval,
    staleTime: DEFAULT_STALE_TIME
  })

  return useMemo(() => {
    if (query.data) {
      if (tradeAmount === 0n) {
        return {
          isLoading: false,
          error: null,
          data: {
            routeIndex: -1,
            averagePrice: 0n
          }
        }
      }
      if (query.data.error || query.data.result === null) {
        return {
          isLoading: false,
          error: query.data.error,
          data: null
        }
      }

      const averagePrice = query.data
        ? BigInt(query.data.result.quote.averagePrice)
        : 0n

      return {
        isLoading: false,
        error: null,
        data: {
          routeIndex: query.data.result.routeIndex,
          averagePrice
        }
      }
    }

    return {
      isLoading: true,
      error: null,
      data: null
    }
  }, [query.data, tradeAmount])
}

export function useAfterPosition(
  chainId: number,
  trader: Address,
  pairId: bigint,
  averagePrice: bigint,
  tradeAmount: bigint,
  leverage: number,
  limitPriceX96: bigint = 0n
) {
  const perpPosition = usePerpPosition(chainId, trader, pairId)

  return useMemo(() => {
    if (perpPosition.data && tradeAmount !== 0n) {
      const valueUpdate = (-abs(averagePrice) * tradeAmount) / 2n ** 96n

      const [deltaEntry, payoff] = calculateEntry(
        perpPosition.data.perpAmount,
        perpPosition.data.entryValue,
        tradeAmount,
        valueUpdate
      )

      const realizedFee =
        (perpPosition.data.unrealizedFee.feeAmountBase * averagePrice) /
          2n ** 96n +
        perpPosition.data.unrealizedFee.feeAmountQuote

      // Required margin is the margin amount required to the position after trade
      // Current margin is the margin amount of the current position

      const actualCurrentMargin =
        perpPosition.data.currentMargin + realizedFee + payoff

      const nextRequiredMargin = max(
        [perpPosition.data.oraclePrice, limitPriceX96].map(price => {
          return calculateRequiredMargin(
            perpPosition.data.perpAmount,
            perpPosition.data.entryValue,
            tradeAmount,
            price,
            perpPosition.data.unrealizedFee.feeAmountQuote,
            perpPosition.data.unrealizedFee.feeAmountBase,
            perpPosition.data.currentMargin,
            leverage
          )
        })
      )

      // additional margin required
      const nextMarginUpdate = nextRequiredMargin - actualCurrentMargin

      const nextPerpPositionAmount = perpPosition.data.perpAmount + tradeAmount

      return {
        nextRequiredMargin,
        nextMarginUpdate,
        realizedFee,
        payoff,
        nextPerpPositionAmount,
        position: new Position(
          perpPosition.data.position.stable + deltaEntry + payoff + realizedFee,
          0n,
          perpPosition.data.position.underlying + tradeAmount
        )
      }
    }

    return null
  }, [perpPosition.data, averagePrice, tradeAmount, leverage, limitPriceX96])
}

function deserializePerpQuote(perpQuote: {
  quote: {
    averagePrice: string
    fee: string
  }
  routeIndex: number
}) {
  return {
    quote: {
      averagePrice: BigInt(perpQuote.quote.averagePrice),
      fee: BigInt(perpQuote.quote.fee)
    },
    routeIndex: perpQuote.routeIndex
  }
}

export function deserializeTradeResult(tradeResult: {
  payoff: {
    perpEntryUpdate: string
    sqrtEntryUpdate: string
    sqrtRebalanceEntryUpdateUnderlying: string
    sqrtRebalanceEntryUpdateStable: string
    perpPayoff: string
    sqrtPayoff: string
  }
  vaultId: string
  fee: string
  minMargin: string
  averagePrice: string
  sqrtTwap: string
  sqrtPrice: string
}): TradeResult {
  return {
    payoff: {
      perpEntryUpdate: BigInt(tradeResult.payoff.perpEntryUpdate),
      sqrtEntryUpdate: BigInt(tradeResult.payoff.sqrtEntryUpdate),
      sqrtRebalanceEntryUpdateUnderlying: BigInt(
        tradeResult.payoff.sqrtRebalanceEntryUpdateUnderlying
      ),
      sqrtRebalanceEntryUpdateStable: BigInt(
        tradeResult.payoff.sqrtRebalanceEntryUpdateStable
      ),
      perpPayoff: BigInt(tradeResult.payoff.perpPayoff),
      sqrtPayoff: BigInt(tradeResult.payoff.sqrtPayoff)
    },
    vaultId: BigInt(tradeResult.vaultId),
    fee: BigInt(tradeResult.fee),
    minMargin: BigInt(tradeResult.minMargin),
    averagePrice: BigInt(tradeResult.averagePrice),
    sqrtTwap: BigInt(tradeResult.sqrtTwap),
    sqrtPrice: BigInt(tradeResult.sqrtPrice)
  }
}
