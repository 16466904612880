import React, { useState } from 'react'
import { useAccount } from 'wagmi'
import { PairInfo } from '../../constants/assets'
import { SupplyForm } from './SupplyForm'
import { HorizontalRule } from '../common/HorizontalRule'
import { WithdrawForm } from './WithdrawForm'
import { usePairStatus } from '../../hooks/pool/usePairStatus'
import { AiFillCaretDown } from 'react-icons/ai'
import { useBorrowingFee } from '../../hooks/pool/useBorrowingFee'
import { toUnscaled } from '../../utils/bn'
import { useBalanceQuery } from '../../hooks/erc20/balance'
import { ADDRESS_MAP } from '../../constants'
import { useSuppliedAmount } from '../../hooks/lending/useSuppliedAmount'
import { Address } from 'viem'

export const SupplyPoolPanel = ({
  chainId,
  trader,
  pairInfo,
  isQuote
}: {
  chainId: number
  trader: Address
  pairInfo: PairInfo
  isQuote: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const apy = useBorrowingFee(chainId, pairInfo.pairId || 0)
  const balance = useBalanceQuery(
    chainId,
    isQuote ? pairInfo.quote.address : pairInfo.base.address,
    trader,
    ADDRESS_MAP[chainId].PredyPool
  )
  return (
    <div className="p-4 bg-panel border-[1px] border-panelborder">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className="my-2 basis-2/6 space-y-1">
          <h1>{isQuote ? pairInfo.quote.symbol : pairInfo.base.symbol}</h1>

          <span className="text-xs">({pairInfo.pairSymbol})</span>
        </div>
        <div className="basis-2/6 flex justify-end">
          {toUnscaled(
            balance.data ? balance.data.balance : 0n,
            isQuote ? pairInfo.quote.decimals : pairInfo.base.decimals,
            4
          )}
        </div>
        <div className="basis-1/6 flex justify-end">
          {toUnscaled(
            (isQuote
              ? apy.data?.quote.supplyInterestRate
              : apy.data?.base.supplyInterestRate) || 0n,
            16,
            4
          )}
          %
        </div>
        <div className="basis-1/6 flex justify-end">
          <AiFillCaretDown />
        </div>
      </div>
      {isOpen ? (
        <>
          <HorizontalRule />
          <div className="my-4 flex justify-center items-center space-x-8">
            <SupplyForm
              chainId={chainId}
              account={trader}
              pairInfo={pairInfo}
              isQuote={isQuote}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export const WithdrawPoolPanel = ({
  chainId,
  pairInfo,
  isQuote
}: {
  chainId: number
  pairInfo: PairInfo
  isQuote: boolean
}) => {
  const { address } = useAccount()
  const [isOpen, setIsOpen] = useState(false)
  const apy = useBorrowingFee(chainId, pairInfo.pairId || 0)

  const pairStatus = usePairStatus(chainId, pairInfo.pairId || 0)

  if (!pairStatus.data || !address) {
    return <></>
  }

  const supplyTokenAddress = isQuote
    ? pairStatus.data.result.quotePool.supplyTokenAddress
    : pairStatus.data.result.basePool.supplyTokenAddress

  return (
    <div className="p-4 bg-panel border-[1px] border-panelborder">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className="my-2 basis-2/6 space-y-1">
          <h1>{isQuote ? pairInfo.quote.symbol : pairInfo.base.symbol}</h1>

          <span className="text-xs">({pairInfo.pairSymbol})</span>
        </div>
        <div className="basis-2/6 flex justify-end">
          <SuppliedAmount
            chainId={chainId}
            pairInfo={pairInfo}
            account={address}
            supplyTokenAddress={supplyTokenAddress}
            isQuote={isQuote}
          />
        </div>
        <div className="basis-1/6 flex justify-end">
          {toUnscaled(
            (isQuote
              ? apy.data?.quote.supplyInterestRate
              : apy.data?.base.supplyInterestRate) || 0n,
            16,
            4
          )}
          %
        </div>
        <div className="basis-1/6 flex justify-end">
          <AiFillCaretDown />
        </div>
      </div>
      {isOpen ? (
        <>
          <HorizontalRule />
          <div className="my-4 flex justify-center items-center space-x-8">
            <WithdrawForm
              chainId={chainId}
              pairInfo={pairInfo}
              supplyTokenAddress={supplyTokenAddress}
              account={address}
              isQuote={isQuote}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export const SuppliedAmount = ({
  chainId,
  pairInfo,
  supplyTokenAddress,
  account,
  isQuote
}: {
  chainId: number
  pairInfo: PairInfo
  supplyTokenAddress: Address
  account: Address
  isQuote: boolean
}) => {
  const suppliedAmount = useSuppliedAmount(
    chainId,
    pairInfo.pairId || 0,
    supplyTokenAddress,
    account,
    isQuote
  )

  console.log(1, suppliedAmount.data)

  return <div>{suppliedAmount.data}</div>
}
