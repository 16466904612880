import { useQuery } from '@tanstack/react-query'
import { toUnscaled } from '../../utils/bn'
import { usePairStatus } from '../pool/usePairStatus'
import { PAIR_INFOS } from '../../constants'
import { pairIdToSymbol } from '../../utils/pairs'
import { Address } from 'viem'
import { useERC20Balance } from '../erc20/balance'

export function useSuppliedAmount(
  chainId: number,
  pairId: number,
  tokenAddress: Address,
  account: Address,
  isQuote: boolean
) {
  const balance = useERC20Balance(chainId, tokenAddress, account)
  const pairStatus = usePairStatus(chainId, pairId)

  return useQuery({
    queryKey: [
      'supplied-amounts',
      chainId,
      pairId,
      account,
      tokenAddress,
      isQuote,
      balance.data?.toString()
    ],
    queryFn: () => {
      if (!pairStatus.data) throw new Error('result not loaded')
      if (balance.data === undefined) throw new Error('balance not loaded')

      const pairInfo = PAIR_INFOS[chainId][pairIdToSymbol(pairId)]

      let assetScaler = 0n

      if (isQuote) {
        assetScaler = pairStatus.data.result.quotePool.tokenStatus.assetScaler
      } else {
        assetScaler = pairStatus.data.result.basePool.tokenStatus.assetScaler
      }

      return toUnscaled(
        (assetScaler * balance.data) / 10n ** 18n,
        isQuote ? pairInfo.quote.decimals : pairInfo.base.decimals,
        isQuote ? pairInfo.quote.precision : pairInfo.base.precision,
        true
      )
    },
    enabled:
      pairStatus.isSuccess &&
      !!pairStatus.data &&
      balance.isSuccess &&
      balance.data !== undefined
  })
}
