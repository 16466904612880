import { Q96 } from '../constants'
import { abs } from './bn'

export function mulLev(n: bigint, leverage: number) {
  return (n * (1000n + BigInt(leverage) * 10n)) / 1000n
}
export function calculateRequiredMargin(
  perpAmount: bigint,
  entryValue: bigint,
  tradeAmount: bigint,
  oraclePrice: bigint,
  feeAmountQuote: bigint,
  feeAmountBase: bigint,
  marginAmount: bigint,
  leverage: number
) {
  if (oraclePrice === 0n) return 0n

  const im = calculateInitialMargin(
    perpAmount,
    tradeAmount,
    leverage,
    oraclePrice
  )

  const value = calculateCurrentValue(
    perpAmount,
    entryValue,
    tradeAmount,
    oraclePrice,
    feeAmountQuote,
    feeAmountBase,
    marginAmount
  )

  return mulLev(im, leverage) - value
}

function calculateInitialMargin(
  perpAmount: bigint,
  tradeAmount: bigint,
  leverage: number,
  price: bigint
) {
  return (abs(perpAmount + tradeAmount) * price) / Q96 / BigInt(leverage)
}

function calculateCurrentValue(
  perpAmount: bigint,
  entryValue: bigint,
  tradeAmount: bigint,
  tradePrice: bigint,
  feeAmountQuote: bigint,
  feeAmountBase: bigint,
  marginAmount: bigint
) {
  const valueUpdate = (-abs(tradePrice) * tradeAmount) / Q96

  const [deltaEntry, payoff] = calculateEntry(
    perpAmount,
    entryValue,
    tradeAmount,
    valueUpdate
  )

  const quoteValue = entryValue + deltaEntry + payoff + feeAmountQuote
  const baseValue = perpAmount + tradeAmount + feeAmountBase

  return marginAmount + quoteValue + (baseValue * tradePrice) / Q96
}

export function calculateEntry(
  positionAmount: bigint,
  entryValue: bigint,
  tradeAmount: bigint,
  valueUpdate: bigint
) {
  let deltaEntry = 0n
  let payoff = 0n

  if (tradeAmount === 0n) {
    return [0n, 0n]
  }

  if (positionAmount * tradeAmount >= 0n) {
    // open position
    deltaEntry = valueUpdate
  } else {
    if (abs(positionAmount) >= abs(tradeAmount)) {
      // close position

      const closeStableAmount = (entryValue * tradeAmount) / positionAmount

      deltaEntry = closeStableAmount
      payoff = valueUpdate - closeStableAmount
    } else {
      // close full and open position

      const closeStableAmount = -entryValue
      const openStableAmount =
        (valueUpdate * (positionAmount + tradeAmount)) / tradeAmount

      deltaEntry = closeStableAmount + openStableAmount
      payoff = valueUpdate - closeStableAmount - openStableAmount
    }
  }

  return [deltaEntry, payoff]
}
