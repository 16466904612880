import { SpotOrderV3 } from '@predy/js-sdk'
import { Bytes } from '@predy/js-sdk/dist/types'
import { useSignTypedData } from 'wagmi'
import { useState } from 'react'
import { FILLER_API_ENDPOINT } from '../../constants'
import { reasonToErrorMessage } from '../../utils/error'
import { SpotOrderV3Params } from '@predy/js-sdk/dist/order/types'

export function useSpotSendOrder(
  order: SpotOrderV3,
  routeIndex: number,
  callback: (tradeResult: any) => void
) {
  const { domain, types, message } = order.permitData()

  const [isAPILoading, setIsAPILoading] = useState(false)
  const [error, setError] = useState<null | string>(null)

  const { signTypedData, data, status } = useSignTypedData({
    mutation: {
      onSuccess: async (data: Bytes, variables: any) => {
        setIsAPILoading(true)
        setError(null)

        const signedOrder = new SpotOrderV3(
          variables.message.witness as SpotOrderV3Params,
          order.chainId
        )

        console.log('start to send order')

        const res = await fetch(`${FILLER_API_ENDPOINT}order/v2/spot`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            serializedOrder: signedOrder.serialize(),
            signature: data,
            routeIndex
          })
        })
        const tradeResult = await res.json()

        if (tradeResult.message && tradeResult.message.length > 0) {
          const message = tradeResult.message

          const errorMessage = reasonToErrorMessage(message)

          if (errorMessage) {
            setError(errorMessage)
          } else {
            setError('unexpected error')
            console.error(message)
          }
        }

        setIsAPILoading(false)

        callback(tradeResult)
      }
    }
  })

  return {
    variables: {
      domain,
      types,
      primaryType: 'PermitWitnessTransferFrom',
      message
    },
    signTypedData,
    data,
    error,
    isLoading: status === 'pending' || isAPILoading
  }
}
