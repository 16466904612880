import { gql } from '@apollo/client'

export const SPOT_HISTORY_QUERY = gql`
  query ($address: String) {
    spotTradeHistoryItems(
      first: 100
      where: { trader: $address }
      orderBy: createdAt
      orderDirection: desc
    ) {
      id
      trader
      baseAmount
      baseToken
      quoteAmount
      quoteToken
      txHash
      createdAt
      validatorAddress
    }
  }
`

export type SpotHistoryItemEntities = {
  spotTradeHistoryItems: {
    id: string
    trader: string
    baseAmount: string
    baseToken: string
    quoteAmount: string
    quoteToken: string
    txHash: string
    createdAt: string
    validatorAddress: string
  }[]
}
