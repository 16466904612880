import React, { useCallback, useState } from 'react'
import { PairInfo, SLIPPAGE_TOLERANCE_FROM_ORACLE } from '../../../constants'
import { Address, zeroAddress } from 'viem'
import { Direction } from './DirectionSelector'
import { AmountForm, BasePopup, PrimaryButton } from '../../common'
import { AiFillCaretDown } from 'react-icons/ai'
import { TradeResult } from './TradeResult'
import { useSpotMarketOrder } from '../../../hooks/spot/useSpotOrder'
import { toScaled, toUnscaled } from '../../../utils/bn'
import { AmountSlider } from './AmountSlider'
import { useBalanceQuery } from '../../../hooks/erc20/balance'
import { useUniswapPrice } from '../../../hooks/useUniswapPrice'
import { toUnscaledPrice } from '../../../utils'
import { roundDigit } from '../../../utils/number'
import InfoTooltip from '../../common/InfoTooltip'

export function MarketOrderForm({
  chainId,
  pairInfo,
  trader,
  direction
}: {
  chainId: number
  pairInfo: PairInfo
  trader: Address
  direction: Direction
}) {
  const [amount, setAmount] = useState<number | null>(null)
  const [total, setTotal] = useState(0)
  const [isAmount, setIsAmount] = useState(true)

  const price = useUniswapPrice(chainId, pairInfo.id)

  const uniPrice = price.data ? toUnscaledPrice(pairInfo, price.data) : 0

  const quoteBalance = useBalanceQuery(
    chainId,
    pairInfo.quote.address,
    trader,
    zeroAddress
  )
  const baseBalance = useBalanceQuery(
    chainId,
    pairInfo.base.address,
    trader,
    zeroAddress
  )

  const maxTotalBuy = quoteBalance.data
    ? toUnscaled(
        quoteBalance.data.balance,
        pairInfo.quote.decimals,
        pairInfo.quote.precision
      )
    : 0

  const maxAmountBuy = quoteBalance.data ? maxTotalBuy / (uniPrice * 1.004) : 0

  const maxAmountSell = baseBalance.data
    ? toUnscaled(
        baseBalance.data?.balance,
        pairInfo.base.decimals,
        pairInfo.base.precision
      )
    : 0
  const maxTotalSell = maxAmountSell * uniPrice

  const maxAmount = roundDigit(
    direction === Direction.Long ? maxAmountBuy : maxAmountSell,
    pairInfo.base.precision
  )
  const maxTotal = roundDigit(
    direction === Direction.Long ? maxTotalBuy : maxTotalSell,
    pairInfo.quote.precision
  )

  const onAmountChanged = useCallback(
    (amount: number) => {
      if (isAmount) {
        setAmount(amount)
      } else {
        setTotal(amount)
      }
    },
    [isAmount, setAmount, setTotal]
  )

  const order = useSpotMarketOrder(
    chainId,
    pairInfo,
    trader,
    direction === Direction.Long,
    isAmount
      ? toScaled(amount || 0, pairInfo.base.decimals)
      : toScaled(total, pairInfo.quote.decimals),
    isAmount
      ? toScaled((amount || 0) * uniPrice, pairInfo.quote.decimals)
      : toScaled(total / uniPrice, pairInfo.base.decimals),
    SLIPPAGE_TOLERANCE_FROM_ORACLE,
    isAmount
  )

  return (
    <div>
      <div className="space-y-3">
        {direction === Direction.Long ? (
          <AvblBalance
            balance={
              (quoteBalance.data
                ? toUnscaled(quoteBalance.data.balance, pairInfo.quote.decimals)
                : 0) +
              ' ' +
              pairInfo.quote.symbol
            }
          />
        ) : (
          <AvblBalance
            balance={
              (baseBalance.data
                ? toUnscaled(baseBalance.data.balance, pairInfo.base.decimals)
                : 0) +
              ' ' +
              pairInfo.base.symbol
            }
          />
        )}

        <div>
          <AmountForm
            amount={0}
            disabledText="Market"
            step={1 / 10 ** pairInfo.pricePrecision}
            digits={pairInfo.pricePrecision}
            title="Price"
            unit="USDC"
          />
        </div>

        <div className="space-y-4">
          <AmountForm
            amount={isAmount ? amount : total}
            max={isAmount ? maxAmount : maxTotal}
            unit={isAmount ? pairInfo.base.symbol : pairInfo.quote.symbol}
            step={
              isAmount
                ? 1 / 10 ** pairInfo.base.precision
                : 1 / 10 ** pairInfo.quote.precision
            }
            digits={
              isAmount ? pairInfo.base.precision : pairInfo.quote.precision
            }
            onChange={onAmountChanged}
            isAlert={
              isAmount
                ? amount === null
                  ? false
                  : amount > maxAmount
                : total > maxTotal
            }
            title={
              <BasePopup
                isSmall={true}
                titleContent={
                  <div className="flex justify-center items-center space-x-1">
                    <span>{isAmount ? 'Amount' : 'Total'}</span>
                    <AiFillCaretDown width={12} className="w-3" />
                  </div>
                }
              >
                <div className="w-full flex flex-col justify-center items-center">
                  <div
                    className="w-full rounded-lg px-3 py-2 hover:bg-white1 cursor-pointer text-left"
                    onMouseDown={() => setIsAmount(true)}
                  >
                    Amount
                  </div>
                  <div
                    className="w-full rounded-lg px-3 py-2 hover:bg-white1 cursor-pointer text-left flex justify-between items-center"
                    onMouseDown={() => setIsAmount(false)}
                  >
                    Total
                    <InfoTooltip placement="top">
                      {direction === Direction.Long
                        ? 'Sends market order based on the amount you spend.'
                        : 'Sends market order based on the amount you receive.'}
                    </InfoTooltip>
                  </div>
                </div>
              </BasePopup>
            }
          />

          <AmountSlider
            amount={isAmount ? amount || 0 : total}
            maxAmount={isAmount ? maxAmount : maxTotal}
            setAmount={onAmountChanged}
            precision={
              isAmount ? pairInfo.base.precision : pairInfo.quote.precision
            }
          />
        </div>
      </div>

      <div className="mt-6">
        {order ? (
          <TradeResult
            chainId={chainId}
            pairInfo={pairInfo}
            trader={trader}
            direction={direction}
            maxAmount={direction === Direction.Long ? maxAmount : maxTotal}
            order={order}
            isLimitOrder={false}
            disabled={
              isAmount
                ? amount === null
                  ? true
                  : amount > maxAmount
                : total > maxTotal
            }
          />
        ) : (
          <div className="h-10">
            <PrimaryButton
              type={direction === Direction.Long ? 'green' : 'red'}
              size="md"
              disabled={true}
            >
              {direction === Direction.Long ? 'Buy' : 'Sell'}{' '}
              {pairInfo.base.symbol}
            </PrimaryButton>
          </div>
        )}
      </div>
    </div>
  )
}

const AvblBalance = ({ balance }: { balance: string }) => {
  return (
    <div className="flex justify-start text-xs space-x-2">
      <div className="text-white5">Avbl</div>
      <div>{balance}</div>
    </div>
  )
}
