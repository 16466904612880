import { useReadContract, useReadContracts } from 'wagmi'
import { Address } from '@predy/js-sdk'
import { erc20ABI } from '../../abis/ERC20'
import { DEFAULT_STALE_TIME, REFETCH_INTERVAL } from '../../constants'

export function useERC20Balance(
  chainId: number,
  address: Address,
  account: Address
) {
  return useReadContract({
    address,
    abi: erc20ABI,
    functionName: 'balanceOf',
    args: [account],
    chainId
  })
}

export function useBalanceQuery(
  chainId: number,
  address: Address,
  account: Address,
  spender: Address
) {
  const contractRead = useReadContracts({
    contracts: [
      {
        address,
        abi: erc20ABI,
        functionName: 'balanceOf',
        args: [account],
        chainId
      },
      {
        address,
        abi: erc20ABI,
        functionName: 'allowance',
        args: [account, spender]
      }
    ],
    query: {
      refetchInterval: REFETCH_INTERVAL,
      staleTime: DEFAULT_STALE_TIME
    }
  })

  const format = (data: any) => {
    const isLoaded =
      data[0].result !== undefined && data[1].result !== undefined
    return {
      isLoaded,
      balance: data[0].result || 0n,
      allowance: data[1].result || 0n
    }
  }

  return {
    ...contractRead,
    data: contractRead.data ? format(contractRead.data) : undefined
  }
}
