import React, { useState } from 'react'
import { Direction } from './DirectionSelector'
import { OrderType, OrderTypeSelector } from './OrderTypeSelector'
import { PairInfo } from '../../../constants/assets'
import { useAccount } from 'wagmi'
import { TradeDirectionForm } from './TradeDirectionForm'
import { zeroAddress } from 'viem'
import InfoTooltip from '../../common/InfoTooltip'

export const TradeForm = ({
  chainId,
  pairInfo
}: {
  chainId: number
  pairInfo: PairInfo
}) => {
  const { address } = useAccount()
  const [orderType, setOrderType] = useState<OrderType>(OrderType.Market)

  return (
    <div className="h-full bg-panel border-[1px] border-panelborder">
      <div className="mx-3 my-5 flex justify-start items-center space-x-4">
        <OrderTypeSelector orderType={orderType} setOrderType={setOrderType} />
        <InfoTooltip placement="top">
          {orderType === OrderType.Market
            ? 'A market order is immediately executed by the best price.'
            : 'A limit order is an order to buy or sell at a specific price or better.'}
        </InfoTooltip>
      </div>
      <div className="mx-3 flex justify-between items-center space-x-4">
        <TradeDirectionForm
          chainId={chainId}
          pairInfo={pairInfo}
          direction={Direction.Long}
          orderType={orderType}
          trader={address || zeroAddress}
        />
        <TradeDirectionForm
          chainId={chainId}
          pairInfo={pairInfo}
          direction={Direction.Short}
          orderType={orderType}
          trader={address || zeroAddress}
        />
      </div>
    </div>
  )
}
