import { useState, useRef, useEffect, useCallback } from 'react'
import ReconnectingWebSocket, { Message } from 'reconnecting-websocket'

class PingWebSocket {
  websocket: ReconnectingWebSocket
  timer: number
  isChecking: boolean

  constructor(endpoint: string) {
    this.websocket = new ReconnectingWebSocket(endpoint)
    this.timer = 0
    this.isChecking = false

    this.websocket.addEventListener('open', () => {
      setTimeout(() => {
        this.websocket.send(JSON.stringify({ action: 'ping' }))
      }, 30000)
    })

    this.websocket.addEventListener('message', event => {
      const message = JSON.parse(event.data)

      if (this.timer > 0) {
        clearTimeout(this.timer)
        this.timer = 0
      }

      if (message.length === 0 && this.isChecking === false) {
        setTimeout(() => {
          if (this.timer === 0) {
            this.websocket.send(JSON.stringify({ action: 'ping' }))
            this.timer = setTimeout(() => {
              this.websocket.reconnect()
            }, 10000)
          }

          this.isChecking = false
        }, 30000)
        this.isChecking = true
      }
    })
  }

  onMessage(messageHandler: (e: MessageEvent<string>) => void) {
    this.websocket.addEventListener('message', messageHandler)
  }

  onOpen(openHandler: () => void) {
    this.websocket.addEventListener('open', openHandler)
  }

  close() {
    this.websocket.close()
  }

  offMessage(messageHandler: (e: MessageEvent<string>) => void) {
    this.websocket.removeEventListener('message', messageHandler)
  }

  send(data: Message) {
    this.websocket.send(data)
  }

  getReadyState() {
    return this.websocket.readyState
  }
}

export const useWebSocketCore = (endpoint: string, initialMessage: any) => {
  const [lastMessage, setLastMessage] = useState(null)
  const [messageHandler, setMessageHandler] = useState<null | {
    fn: (event: MessageEvent<string>) => void
  }>(null)

  const socketRef = useRef<PingWebSocket>()

  const onMessage = useCallback(
    (event: MessageEvent<string>) => {
      const message = JSON.parse(event.data)

      // TODO: avoid pong message
      if (message.length > 0) {
        setLastMessage(message)
      }

      if (messageHandler) {
        messageHandler.fn(message)
      }
    },
    [setLastMessage, messageHandler]
  )

  useEffect(() => {
    const websocket = new PingWebSocket(endpoint)

    socketRef.current = websocket

    if (!socketRef.current) {
      throw new Error('WebSocket failed to be created')
    }

    websocket.onMessage(onMessage)

    websocket.onOpen(() => {
      websocket.send(JSON.stringify(initialMessage))
    })

    return () => {
      websocket.close()
      websocket.offMessage(onMessage)

      setLastMessage(null)
    }
  }, []) // eslint-disable-line

  const sendMessage = useCallback((message: any) => {
    if (socketRef.current?.getReadyState() !== WebSocket.OPEN) return
    socketRef.current?.send(JSON.stringify(message))
  }, [])

  return [
    { lastMessage, messageHandler },
    { sendMessage, setMessageHandler }
  ] as [WebSocketContextState, WebSocketContextAction]
}

export type WebSocketContextState = {
  lastMessage: any
  messageHandler: { fn: (event: MessageEvent<string>) => void }
}

export type WebSocketContextAction = {
  sendMessage: (message: any) => void
  setMessageHandler: (messageHandler: {
    fn: (event: MessageEvent<string>) => void
  }) => void
}
