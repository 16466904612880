import { useMemo, useState } from 'react'
import { useWebSocketState } from './useWebSocket'
import { formatPrice, getSymbolName } from '../../utils'
import { PairInfo } from '../../constants'

export interface PriceBar {
  symbolName: string
  resolution: number
  high: number
  low: number
  open: number
  close: number
  openAt: number
  closeAt: number
}

export interface RealtimePrice {
  day: {
    price: PriceBar
    fmtPrice: string
    isUp: boolean
  }
  now: {
    price: PriceBar
    fmtPrice: string
    isUp: boolean
  }
  oraclePrice?: number
}

const RES_24H = 24 * 60 * 60
const RES_NOW = 1

const convert = (pairInfo: PairInfo, bar: any) => ({
  symbolName: bar[0],
  resolution: bar[1],
  high: formatPrice(pairInfo, bar[2]),
  low: formatPrice(pairInfo, bar[3]),
  open: formatPrice(pairInfo, bar[4]),
  close: formatPrice(pairInfo, bar[5]),
  openAt: bar[6],
  closeAt: bar[7]
})

export function useRealtimePriceWithResolution(
  pair: PairInfo,
  resolution: number
) {
  const symbolName = getSymbolName(pair)
  const { lastMessage } = useWebSocketState()

  const latestPrice = useMemo(() => {
    if (lastMessage === null) {
      return null
    }

    if (lastMessage.length === 0) {
      return null
    }

    if (lastMessage[0] === undefined) {
      console.error('lastMessage error', lastMessage)
      return null
    }

    try {
      const priceBars = lastMessage[0].map((bar: any) =>
        convert(pair, bar)
      ) as PriceBar[]

      const prices = priceBars.filter(
        (bar: any) =>
          bar.symbolName === symbolName && bar.resolution === resolution
      )

      if (prices.length === 0) {
        return null
      }

      return prices[0]
    } catch (e) {
      console.error(e)

      return null
    }
  }, [pair, symbolName, resolution, lastMessage])

  return latestPrice
}

export function useRealtimePrice(pair: PairInfo) {
  const symbolName = getSymbolName(pair)
  const { lastMessage } = useWebSocketState()

  const [lastPrice, setLastPrice] = useState(new Map<string, RealtimePrice>())

  useMemo(() => {
    if (lastMessage === null) {
      return null
    }

    if (lastMessage.length === 0) {
      return null
    }

    if (lastMessage[0] === undefined) {
      console.error('lastMessage error', lastMessage)
      return null
    }

    try {
      const priceBars = lastMessage[0].map((bar: any) =>
        convert(pair, bar)
      ) as PriceBar[]

      const prices24h = priceBars.filter(
        (bar: any) =>
          bar.symbolName === symbolName && bar.resolution === RES_24H
      )

      const pricesNow = priceBars.filter(
        (bar: any) =>
          bar.symbolName === symbolName && bar.resolution === RES_NOW
      )

      if (prices24h.length === 0 || pricesNow.length === 0) {
        return
      }

      const price24h = prices24h[0]
      const priceNow = pricesNow[0]

      setLastPrice(
        lastPrice.set(symbolName, {
          day: {
            price: price24h,
            fmtPrice: price24h.close.toFixed(pair.pricePrecision),
            isUp: price24h.close >= price24h.open
          },
          now: {
            price: priceNow,
            fmtPrice: priceNow.close.toFixed(pair.pricePrecision),
            isUp: priceNow.close >= priceNow.open
          },
          oraclePrice: pair.pairId === 2 ? lastMessage[1][0] : undefined
        })
      )
    } catch (e) {
      console.error(lastMessage, e)
    }
  }, [pair, symbolName, lastMessage, lastPrice])

  return lastPrice.get(symbolName)
}
