import React, { useCallback, useEffect, useState } from 'react'
import { PairInfo, TimeFilter } from '../../../constants'
import { useAccount } from 'wagmi'
import { CreateForm } from './CreateForm'
import { PositionCardList } from '../PositionCardList'
import { GammaModifyForm } from './ModifyForm'
import { PositionHistoryList } from '../PositionHistoryList'
import { StrategyTemplateList } from '../StrategyTemplateList'
import { useTradeRoute } from '../../../hooks/useTradeRoute'
import { TimeSelector } from '../common/TimeSelector'

interface Props {
  chainId: number
  pairInfo: PairInfo
  price: number
}

export const GammaMarketSM = ({ chainId, pairInfo, price }: Props) => {
  const { templateId, positionId, setTemplateId, setPositionId } =
    useTradeRoute()
  const { address } = useAccount()

  useEffect(() => {
    document.title = `Gamma | Predy V6`
  }, [])

  const onTemplateClick = useCallback(
    (templateId: number) => {
      setTemplateId(templateId)
    },
    [setTemplateId]
  )

  const [filterTime, setFilterTime] = useState<TimeFilter>('w')

  if (templateId !== null && address) {
    return (
      <div className="container mx-auto max-w-3xl p-3">
        <CreateForm
          chainId={chainId}
          pairInfo={pairInfo}
          templateId={templateId}
          price={price}
          trader={address}
          onCancel={() => {
            setTemplateId(0)
          }}
        />
      </div>
    )
  }

  if (positionId !== null && address) {
    return (
      <div className="container mx-auto max-w-3xl p-3">
        <GammaModifyForm
          chainId={chainId}
          pairInfo={pairInfo}
          price={price}
          trader={address}
          templateId={1}
          positionId={positionId}
          onCancel={() => {
            setPositionId(0)
          }}
        />
      </div>
    )
  }

  return (
    <div className="container mx-auto max-w-5xl flex justify-center flex-col">
      <div className="p-3">
        <div className="my-2 text-xs text-fourth">
          Create new position from strategy templates.
        </div>

        <div className="space-y-3">
          <StrategyTemplateList
            chainId={chainId}
            pairInfo={pairInfo}
            price={price}
            onSelect={onTemplateClick}
          />
        </div>
      </div>

      {address && (
        <div className="p-3 space-y-3">
          <div className="my-2 text-xs text-fourth">My positions</div>

          <div className="space-y-3">
            <PositionCardList
              chainId={chainId}
              pairInfo={pairInfo}
              price={price}
              address={address}
              onSelect={setPositionId}
            />
          </div>
        </div>
      )}

      {address && (
        <div className="p-3 space-y-3">
          <div className="flex justify-between md:justify-center md:flex-col items-center md:items-start">
            <div className="my-2 text-xs text-fourth">Position history</div>
            <TimeSelector time={filterTime} onSelectTime={setFilterTime} />
          </div>

          <div className="space-y-3">
            <PositionHistoryList
              chainId={chainId}
              pairInfo={pairInfo}
              address={address}
              filterTime={filterTime}
            />
          </div>
        </div>
      )}
    </div>
  )
}
