import { useSimulateContract } from 'wagmi'
import { PredyPoolABI } from '@predy/js-sdk'
import { ADDRESS_MAP } from '../../constants'
import { Address } from 'viem'

export function useWithdrawCreatorFee(
  chainId: number,
  account: Address,
  pairId: bigint,
  isQuoteToken: boolean
) {
  return useSimulateContract({
    account,
    address: ADDRESS_MAP[chainId].PredyPool,
    abi: PredyPoolABI,
    functionName: 'withdrawCreatorRevenue',
    args: [pairId, isQuoteToken]
  })
}

export function useWithdrawProtocolFee(
  chainId: number,
  account: Address,
  pairId: bigint,
  isQuoteToken: boolean
) {
  return useSimulateContract({
    account,
    address: ADDRESS_MAP[chainId].PredyPool,
    abi: PredyPoolABI,
    functionName: 'withdrawProtocolRevenue',
    args: [pairId, isQuoteToken]
  })
}
