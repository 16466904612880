import { useQuery } from '@tanstack/react-query'
import { FILLER_API_ENDPOINT, PairInfo } from '../../constants'
import { getNow, useDeltaTimestamps } from '../../utils/time'
import { calculateChange, getSymbolName } from '../../utils'
import { useRealtimePrice } from './useRealtimePrice'

export function use24HPriceSummary(chainId: number, pair: PairInfo) {
  const now = getNow()
  const [t1, , ,] = useDeltaTimestamps()
  const realtimePrice = useRealtimePrice(pair)

  const query = useQuery({
    queryKey: ['24h-price', chainId, pair.pairSymbol],
    queryFn: async () => {
      if (realtimePrice === undefined) throw new Error('Price is undefined')

      const symbolName = getSymbolName(pair)
      const resolution = 60 * 15

      const data = await fetch(
        `${FILLER_API_ENDPOINT}prices?symbolName=${symbolName}&resolution=${resolution}&from=${t1}&to=${now}&countBack=${96}`
      )
      const result = await data.json()

      let low = Infinity
      let high = 0

      if (result.prices.length === 0) throw new Error('No prices found')

      result.prices.forEach((price: any) => {
        if (price.low < low) {
          low = price.low
        }
        if (price.high > high) {
          high = price.high
        }
      })

      if (high < realtimePrice.now.price.close)
        high = realtimePrice.now.price.close
      if (low > realtimePrice.now.price.close)
        low = realtimePrice.now.price.close

      return {
        open: result.prices[0].open,
        low,
        high
      }
    },
    enabled: !!realtimePrice,
    refetchInterval: 1000 * 60 * 5
  })

  return query.data && !!realtimePrice
    ? {
        change: calculateChange(query.data.open, realtimePrice.now.price.close),
        low: query.data.low,
        high: query.data.high
      }
    : null
}
