import React from 'react'
import { PairInfo } from '../../constants'
import { STRATEGY_TEMPLATE } from '../../constants/strategy'
import { TemplateCardPC, TemplateCardSM } from './StrategyTemplate'
import { InfoTooltip2 } from '../common'

export const StrategyTemplateList = ({
  chainId,
  pairInfo,
  price,
  onSelect
}: {
  chainId: number
  pairInfo: PairInfo
  price: number
  onSelect: (templateId: number) => void
}) => {
  const SM = () => {
    return (
      <>
        {STRATEGY_TEMPLATE.map((template, index) => {
          return (
            <TemplateCardSM
              key={index}
              chainId={chainId}
              pairInfo={pairInfo}
              price={price}
              symbol="ETH/USDC"
              title={template.title}
              leverage={template.leverage}
              isAutoHedge={template.autoHedge}
              isAutoClose={template.duration > 0}
              templateId={template.id}
              onClick={onSelect}
            />
          )
        })}
      </>
    )
  }

  const PC = () => {
    return (
      <div className="hidden md:block border-collapse w-full bg-panel border-panelborder border-[1px] rounded">
        <table className="w-[99%] m-auto">
          <thead className="m-1 border-b-[1px] border-white1">
            <tr className="text-xs text-white5 font-sm underline decoration-dotted">
              <th className="p-3 text-start">Symbol</th>
              <th className="p-3 text-end">
                <InfoTooltip2
                  placement="bottom"
                  description="The annualized rate of fee income generated from Uniswap liquidity provider positions. Yearly APR is indicative and should not be used as a performance measure."
                >
                  Fee APR
                </InfoTooltip2>
              </th>
              <th className="p-3 text-end">Auto Close & Hedge</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {STRATEGY_TEMPLATE.map((template, index) => {
              return (
                <TemplateCardPC
                  key={index}
                  chainId={chainId}
                  pairInfo={pairInfo}
                  price={price}
                  symbol="ETH/USDC"
                  title={template.title}
                  leverage={template.leverage}
                  isAutoHedge={template.autoHedge}
                  isAutoClose={template.duration > 0}
                  templateId={template.id}
                  onClick={onSelect}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      <PC />
      <div className="block md:hidden space-y-3">
        <SM />
      </div>
    </>
  )
}
