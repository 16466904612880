import React from 'react'
import { Address } from 'viem'
import { PairInfo } from '../../constants'
import { useGammaPositions } from '../../hooks/gamma/useGammaPositions'
import { PositionCardPC, PositionCardSM } from './PositionCard'
import { toUnscaled } from '../../utils/bn'
import { useIsMobile } from '../../hooks/useIsMobile'

export const PositionCardList = ({
  chainId,
  pairInfo,
  address,
  price,
  onSelect
}: {
  chainId: number
  pairInfo: PairInfo
  address: Address
  price: number
  onSelect: (positionId: number) => void
}) => {
  const isMobile = useIsMobile()

  const positions = useGammaPositions(chainId, address, pairInfo)

  if (isMobile) {
    return (
      <>
        {positions.data?.map((gammaPosition, index) => {
          const historyItem = gammaPosition.historyItem

          const totalDeposit = historyItem
            ? historyItem.totalDeposit - historyItem.totalWithdraw
            : gammaPosition.vault.margin

          const isAutoHedge =
            gammaPosition.userPosition.hedgeInterval > 0n ||
            gammaPosition.userPosition.sqrtPriceTrigger > 0n

          return (
            <PositionCardSM
              key={index}
              chainId={chainId}
              pairInfo={pairInfo}
              trader={address}
              positionId={BigInt(gammaPosition.userPosition.vaultId)}
              price={price}
              leverage={gammaPosition.userPosition.leverage}
              expiration={Number(gammaPosition.userPosition.expiration)}
              lastHedgeTime={Number(gammaPosition.userPosition.lastHedgedTime)}
              hedgeInterval={Number(gammaPosition.userPosition.hedgeInterval)}
              lastHedgePrice={gammaPosition.lastHedgePrice}
              isAutoHedge={isAutoHedge}
              pnl={
                gammaPosition.valueBn
                  ? toUnscaled(gammaPosition.valueBn - totalDeposit, 6, 2)
                  : undefined
              }
              totalDeposit={toUnscaled(totalDeposit, 6, 2)}
              value={
                gammaPosition.valueBn
                  ? toUnscaled(gammaPosition.valueBn, 6, 2)
                  : undefined
              }
              onClick={() => {
                onSelect(Number(gammaPosition.userPosition.vaultId))
              }}
            />
          )
        })}
      </>
    )
  }

  if (!positions.data || positions.data.length === 0)
    return <div>No positions</div>

  return (
    <div className="border-collapse w-full bg-panel border-panelborder border-[1px] rounded">
      <table className="w-[99%] m-auto">
        <thead className="m-1 border-b-[1px] border-white1">
          <tr className="text-xs text-white5 font-sm underline decoration-dotted">
            <th className="p-3 text-start">Symbol</th>
            <th className="p-2 text-end">Value</th>
            <th className="p-2 text-end">PNL(ROI)</th>
            <th className="p-2 text-end">Last hedge price</th>
            <th className="p-2 text-end">Next Hedge in</th>
            <th className="p-2 text-end">Close in</th>
            <th className="p-2 text-end"> </th>
          </tr>
        </thead>
        <tbody>
          {positions.data?.map((gammaPosition, index) => {
            const historyItem = gammaPosition.historyItem

            const totalDeposit = historyItem
              ? historyItem.totalDeposit - historyItem.totalWithdraw
              : gammaPosition.vault.margin

            const isAutoHedge =
              gammaPosition.userPosition.hedgeInterval > 0n ||
              gammaPosition.userPosition.sqrtPriceTrigger > 0n

            return (
              <PositionCardPC
                key={index}
                chainId={chainId}
                pairInfo={pairInfo}
                trader={address}
                positionId={BigInt(gammaPosition.userPosition.vaultId)}
                price={price}
                leverage={gammaPosition.userPosition.leverage}
                expiration={Number(gammaPosition.userPosition.expiration)}
                lastHedgeTime={Number(
                  gammaPosition.userPosition.lastHedgedTime
                )}
                hedgeInterval={Number(gammaPosition.userPosition.hedgeInterval)}
                lastHedgePrice={gammaPosition.lastHedgePrice}
                isAutoHedge={isAutoHedge}
                pnl={
                  gammaPosition.valueBn
                    ? toUnscaled(gammaPosition.valueBn - totalDeposit, 6, 2)
                    : undefined
                }
                totalDeposit={toUnscaled(totalDeposit, 6, 2)}
                value={
                  gammaPosition.valueBn
                    ? toUnscaled(gammaPosition.valueBn, 6, 2)
                    : undefined
                }
                onClick={() => {
                  onSelect(Number(gammaPosition.userPosition.vaultId))
                }}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
