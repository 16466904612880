import { Address, Bytes } from '@predy/js-sdk'
import { useState } from 'react'
import { useSignTypedData } from 'wagmi'
import { useDetails } from '../erc20/details'
import { ADDRESS_MAP } from '../../constants/addresses'
import { useDeadline } from '../useBlockTimestamp'
import { usePermitNonce } from '../query/usePermitNonce'
import { FILLER_API_ENDPOINT } from '../../constants'
import { isAddressEqual } from 'viem'
import { TrackJS } from 'trackjs'

function getVersion(address: Address) {
  // Native USDC in Arbitrum
  if (isAddressEqual(address, '0xaf88d065e77c8cc2239327c5edb3a432268e5831')) {
    return '2'
  }

  return '1'
}

export function usePermitV1(
  chainId: number,
  owner: Address,
  address: Address,
  value: bigint
) {
  const [isAPILoading, setIsAPILoading] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const details = useDetails(chainId, address)
  const deadlineQuery = useDeadline()
  const nonceQuery = usePermitNonce(chainId, address, owner)

  const domain = {
    name: details.data ? details.data : 'USD Coin',
    version: getVersion(address),
    chainId: chainId,
    verifyingContract: address
  } as const

  const types = {
    Permit: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' }
    ]
  }

  const spender = ADDRESS_MAP[chainId].Permit2
  const deadline = deadlineQuery
  const nonce = nonceQuery.data || 0n

  const message = {
    owner,
    spender,
    value,
    nonce,
    deadline
  } as const

  const { signTypedData, data, status } = useSignTypedData({
    mutation: {
      onSuccess: async (data: Bytes, variables: any) => {
        setIsAPILoading(true)
        setError(null)

        const message = variables.message as {
          owner: Address
          spender: Address
          value: bigint
          deadline: bigint
        }

        console.log('start to send approve')
        const res = await fetch(`${FILLER_API_ENDPOINT}permit`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            address,
            owner: message.owner,
            spender: message.spender,
            value: message.value.toString(),
            deadline: message.deadline.toString(),
            signature: data
          })
        })
        const result = await res.json()

        if (result.error) {
          const error = JSON.stringify(result.error.reason)
          setError(error)

          TrackJS.track(error)
        }

        setIsAPILoading(false)
      }
    }
  })

  return {
    isNonceLoading: nonceQuery.data === undefined,
    variables: {
      domain,
      types,
      message,
      primaryType: 'Permit'
    },
    signTypedData,
    data,
    error,
    isLoading: status === 'pending' || isAPILoading
  }
}
