import { gql } from '@apollo/client'

export const GAMMA_POSITION_HISTORY_QUERY = gql`
  query ($address: String, $startTime: BigInt) {
    gammaPositionEntities(
      first: 100
      where: { trader: $address, createdAt_gt: $startTime }
      orderBy: updatedAt
      orderDirection: desc
    ) {
      id
      trader
      vault {
        id
      }
      pair {
        id
      }
      isClosed
      lastAction
      totalDeposit
      totalWithdraw
      leverage
      expiration
      hedgeInterval
      sqrtPriceTrigger
      createdAt
      updatedAt
      history {
        txHash
      }
    }
  }
`

export type GammaPositionHistoryEntities = {
  gammaPositionEntities: {
    id: string
    trader: string
    vault: {
      id: string
    }
    pair: {
      id: string
    }
    isClosed: boolean
    lastAction: string
    totalDeposit: string
    totalWithdraw: string
    leverage: number
    expiration: string
    hedgeInterval: string
    sqrtPriceTrigger: string
    createdAt: string
    updatedAt: string
    history: {
      txHash: string
    }[]
  }[]
}
