import create from 'zustand'
import { Position } from '@predy/js-sdk'

interface State {
  position: Position
  setPosition: (position: Position) => void
  clearPosition: () => void
}

const useStore = create<State>(set => ({
  position: new Position(0n, 0n, 0n),
  setPosition: (position: Position) =>
    set({
      position
    }),
  clearPosition: () =>
    set({
      position: new Position(0n, 0n, 0n)
    })
}))

export default useStore
