export type Addresses = {
  DutchOrderValidator: `0x${string}`
  PredyPool: `0x${string}`
  PredyPoolQuoter: `0x${string}`
  GammaTradeMarket: `0x${string}`
  GammaTradeMarketQuoter: `0x${string}`
  PredictMarket: `0x${string}`
  PerpMarket: `0x${string}`
  PerpMarketQuoter: `0x${string}`
  SpotMarket: `0x${string}`
  SpotMarketQuoter: `0x${string}`
  UniswapSettlement: `0x${string}`
  PriceFeedFactory: `0x${string}`
  Permit2: `0x${string}`
  Multicall2: string
  filler: `0x${string}`
}

export const arbitrum: Addresses = {
  DutchOrderValidator: '0x1Df9b0A328A41E2b01C439fF9C90C481B3170c5e',
  PredyPool: '0x9215748657319B17fecb2b5D086A3147BFBC8613',
  PredyPoolQuoter: '0x04EF21442e5f1cef6B4d5B32BB9BbF356631F8d9',
  GammaTradeMarket: '0x92027Eb7caa12EC06f9Ba149c9521A1A48921514',
  GammaTradeMarketQuoter: '0xB3bDFb59f663BeFFCa59099E9fd195367dDD2aE4',
  PredictMarket: '0x642e4A237998e0C25f83C40158c553A816FA9318',
  PerpMarket: '0x02C9Ad1Aa219BCF221C3f915c45595f1d24928a1',
  PerpMarketQuoter: '0xd0BdaEe7Ca74A48B9B82C82bAF13123438fc0424',
  SpotMarket: '0x12Fea3F9185539562eC23F44f3cc1AD5EbB4AdC9',
  SpotMarketQuoter: '0xF015a0B2bC7F9fA126b22c0C892D3256d4062623',
  UniswapSettlement: '0x5f1E0379D04f77d221fbFa4c4D6105d097Fd690C',
  PriceFeedFactory: '0x14606e833eC195b4f591f9970c52F4Bc5aDA6191',
  Permit2: '0x000000000022d473030f116ddee9f6b43ac78ba3',
  Multicall2: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
  filler: '0xE24dF0e38F0Fc044A6d6497793fea0d9A27D10F5'
}

export const baseMainnet: Addresses = {
  DutchOrderValidator: '0x0000000000000000000000000000000000000000',
  PredyPool: '0x0000000000000000000000000000000000000000',
  PredyPoolQuoter: '0x0000000000000000000000000000000000000000',
  GammaTradeMarket: '0x0000000000000000000000000000000000000000',
  GammaTradeMarketQuoter: '0x0000000000000000000000000000000000000000',
  PredictMarket: '0x0000000000000000000000000000000000000000',
  PerpMarket: '0x0000000000000000000000000000000000000000',
  PerpMarketQuoter: '0x0000000000000000000000000000000000000000',
  SpotMarket: '0x0000000000000000000000000000000000000000',
  SpotMarketQuoter: '0x0000000000000000000000000000000000000000',
  UniswapSettlement: '0x0000000000000000000000000000000000000000',
  PriceFeedFactory: '0x0000000000000000000000000000000000000000',
  Permit2: '0x000000000022D473030F116dDEE9F6B43aC78BA3',
  Multicall2: '0xcD03fFA0550815cCdAf08b793016e2E1Ee0e316d',
  filler: '0x0000000000000000000000000000000000000000'
}

export const ADDRESS_MAP: { [key: number]: Addresses } = {
  [1337]: arbitrum,
  [42161]: arbitrum,
  [8453]: baseMainnet
}
