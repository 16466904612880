import React, { ReactNode } from 'react'
import { PairInfo } from '../../constants/assets'

export const PairBadge = ({
  pairInfo,
  children,
  className
}: {
  pairInfo: PairInfo
  children?: ReactNode
  className?: string
}) => {
  return (
    <div
      className={
        'flex justify-center items-center space-x-1 ' + (className || '')
      }
    >
      <div className="flex justify-center items-center space-x-0">
        <img src={pairInfo.base.icon} width={24} className="rounded-full" />
        <img src={pairInfo.quote.icon} width={24} />
      </div>
      <span className="text-lg font-bold">
        {pairInfo.base.symbol}/{pairInfo.quote.symbol}
      </span>
      {children}
    </div>
  )
}
