import React from 'react'
import { Address } from 'viem'
import { PerpOrderV3 } from '@predy/js-sdk'
import { AlertTooltip, LoadingIndicator } from '../../common'
import { OpenOrder, usePerpOrders } from '../../../hooks/perp/usePerpOrders'
import { useCancelPerpOrderV3 } from '../../../hooks/perp/useCancelPerpOrderV3'
import { ADDRESS_MAP, OrderStatus } from '../../../constants'
import { useIsValidNonce } from '../../../hooks/query/usePermit2Nonce'
import { useBalanceQuery } from '../../../hooks/erc20/balance'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import { ModifyOrderFormModal } from './ModifyOrderForm'
import { TPSLOrderFormModal } from './TPSLOrderForm'

const CancelOrderButton = ({
  orderId,
  order
}: {
  orderId: string
  order: PerpOrderV3
}) => {
  const { signMessage, variables } = useCancelPerpOrderV3(orderId, order)

  return (
    <button
      onClick={() => {
        signMessage({
          message: variables
        })
      }}
    >
      <AiFillDelete size={16} />
    </button>
  )
}

const OpenOrderItem = ({ openOrder }: { openOrder: OpenOrder }) => {
  const isValidNonce = useIsValidNonce(
    openOrder.perpOrder.chainId,
    openOrder.perpOrder.perpOrder.info.trader,
    openOrder.perpOrder.perpOrder.info.nonce
  )
  const balance = useBalanceQuery(
    openOrder.perpOrder.chainId,
    openOrder.perpOrder.perpOrder.entryTokenAddress,
    openOrder.perpOrder.perpOrder.info.trader,
    ADDRESS_MAP[openOrder.perpOrder.chainId].Permit2
  )

  const OpenOrderAlert = () => {
    if (balance.data && isValidNonce.isSuccess) {
      const marginAmount = openOrder.perpOrder.perpOrder.marginAmount

      if (balance.data.balance < marginAmount) {
        return (
          <AlertTooltip placement="top">
            The order may fail because balance is not enough.
          </AlertTooltip>
        )
      }

      if (balance.data.allowance < marginAmount) {
        return (
          <AlertTooltip placement="top">
            The order may fail because allowance is not enough.
          </AlertTooltip>
        )
      }

      if (isValidNonce.data === false) {
        return (
          <AlertTooltip placement="top">
            The order may fail because nonce is invalid. You should cancel the
            order and open new order again.
          </AlertTooltip>
        )
      }
      return <></>
    } else {
      return <></>
    }
  }

  const mediaQuery = window.matchMedia('(width < 768px)')
  const isMobile = mediaQuery.matches

  if (isMobile) {
    return (
      <div className="w-full bg-panel border-panelborder border-[1px] p-4 rounded my-1 text-white space-y-2">
        <div className="flex justify-between">
          <div className="space-y-1">
            <div className="text-base font-bold">
              {openOrder.pairInfo.base.symbol}
            </div>

            <div className="flex justify-between space-x-1 text-xs">
              <div>{openOrder.orderType}</div>
              {openOrder.params.side === 'Buy' ? (
                <div className="text-green">Long</div>
              ) : (
                <div className="text-red">Short</div>
              )}
              <div>{openOrder.createdAt}</div>
            </div>
          </div>
          <CancelOrderButton
            orderId={openOrder.orderId}
            order={openOrder.perpOrder}
          />
        </div>
        <div className="flex justify-between">
          <div>Quantity</div>
          <div className="flex justify-between">
            {openOrder.orderType === 'Limit' ? (
              <>
                <div>{openOrder.quantity}</div>
                <ModifyOrderFormModal
                  amount={openOrder.quantity}
                  limitPrice={openOrder.limitPrice}
                  openOrder={openOrder}
                />
              </>
            ) : openOrder.perpOrder.perpOrder.closePosition ? (
              <>Close Position</>
            ) : (
              <>-</>
            )}
          </div>
        </div>

        <div className="flex justify-between">
          <div>Limit Price</div>
          <div className="flex justify-between">
            <div>{openOrder.limitPrice === 0 ? '-' : openOrder.limitPrice}</div>
            {openOrder.orderType === 'Limit' ? (
              <ModifyOrderFormModal
                amount={openOrder.quantity}
                limitPrice={openOrder.limitPrice}
                openOrder={openOrder}
              />
            ) : (
              <TPSLOrderFormModal
                chainId={openOrder.perpOrder.chainId}
                trader={openOrder.perpOrder.perpOrder.info.trader}
                pairInfo={openOrder.pairInfo}
              >
                <AiFillEdit className="cursor-pointer" />
              </TPSLOrderFormModal>
            )}
          </div>
        </div>

        <div className="flex justify-between">
          <div>Stop Price</div>
          <div className="flex justify-between">
            <div>{openOrder.stopPrice === 0 ? '-' : openOrder.stopPrice}</div>
            {openOrder.orderType === 'TP/SL' ? (
              <TPSLOrderFormModal
                chainId={openOrder.perpOrder.chainId}
                trader={openOrder.perpOrder.perpOrder.info.trader}
                pairInfo={openOrder.pairInfo}
              >
                <AiFillEdit className="cursor-pointer" />
              </TPSLOrderFormModal>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      className={`h-10 my-0 text-white flex justify-around items-center hover:bg-white1 border-b-[1px] border-panelborder ${
        openOrder.orderStatus === OrderStatus.FAILED ||
        openOrder.orderStatus === OrderStatus.CANCELLED
          ? 'text-white5'
          : 'text-white'
      }`}
    >
      <div className="basis-[4%]">
        <OpenOrderAlert />
      </div>
      <div className="basis-[18%]">{openOrder.createdAt}</div>
      <div className="basis-[12%]">{openOrder.pairInfo.base.symbol}</div>
      <div className="basis-[12%]">
        {openOrder.params.side === 'Buy' ? (
          <div className="text-green">Long</div>
        ) : (
          <div className="text-red">Short</div>
        )}
      </div>
      <div className="basis-[12%]">{openOrder.orderType}</div>
      <div className="basis-[15%] flex justify-start items-start space-x-1">
        {openOrder.orderType === 'Limit' ? (
          <>
            <div>{openOrder.quantity}</div>
            <ModifyOrderFormModal
              amount={openOrder.quantity}
              limitPrice={openOrder.limitPrice}
              openOrder={openOrder}
            />
          </>
        ) : openOrder.perpOrder.perpOrder.closePosition ? (
          <>Close Position</>
        ) : (
          <>-</>
        )}
      </div>
      <div className="basis-[15%] flex justify-start items-start space-x-1">
        <div>{openOrder.limitPrice === 0 ? '-' : openOrder.limitPrice}</div>
        {openOrder.orderType === 'Limit' ? (
          <ModifyOrderFormModal
            amount={openOrder.quantity}
            limitPrice={openOrder.limitPrice}
            openOrder={openOrder}
          />
        ) : (
          <TPSLOrderFormModal
            chainId={openOrder.perpOrder.chainId}
            trader={openOrder.perpOrder.perpOrder.info.trader}
            pairInfo={openOrder.pairInfo}
          >
            <AiFillEdit />
          </TPSLOrderFormModal>
        )}
      </div>
      <div className="basis-[15%] flex justify-start items-start space-x-1">
        <div>{openOrder.stopPrice === 0 ? '-' : openOrder.stopPrice}</div>
        {openOrder.orderType === 'TP/SL' ? (
          <TPSLOrderFormModal
            chainId={openOrder.perpOrder.chainId}
            trader={openOrder.perpOrder.perpOrder.info.trader}
            pairInfo={openOrder.pairInfo}
          >
            <AiFillEdit />
          </TPSLOrderFormModal>
        ) : (
          <></>
        )}
      </div>

      <div className="basis-[12%]">
        <CancelOrderButton
          orderId={openOrder.orderId}
          order={openOrder.perpOrder}
        />
      </div>
    </div>
  )
}
export const OpenOrders = ({
  chainId,
  trader
}: {
  chainId: number
  trader: Address
}) => {
  const openOrders = usePerpOrders(chainId, trader, true)

  return (
    <div className="pb-5 px-4 text-xs">
      {openOrders.isLoading ? (
        <LoadingIndicator />
      ) : openOrders.isSuccess && openOrders.data.length === 0 ? (
        <div className="flex justify-center items-center">No Orders</div>
      ) : (
        <div className="hidden pb-4 mb-4 text-third md:flex justify-around border-b-[1px] border-panelborder">
          <div className="basis-[4%]"></div>
          <div className="basis-[18%]">Time</div>
          <div className="basis-[12%]">Symbol</div>
          <div className="basis-[12%]">Side</div>
          <div className="basis-[12%]">Type</div>
          <div className="basis-[15%]">Quantity</div>
          <div className="basis-[15%]">Limit Price</div>
          <div className="basis-[15%]">Stop Price</div>
          <div className="basis-[12%]">Cancel</div>
        </div>
      )}
      {openOrders.data ? (
        openOrders.data.map((openOrder, index) => (
          <OpenOrderItem openOrder={openOrder} key={index} />
        ))
      ) : (
        <></>
      )}
    </div>
  )
}
