import React, { useEffect, useRef } from 'react'
import usePairStore from '../../store/pair'
import { PAIR_INFOS, DEFAULT_CHAIN, FILLER_API_ENDPOINT } from '../../constants'
import { DataFeed } from '../../utils/DataFeed'
import { useWebSocketAction } from '../../hooks/price/useWebSocket'
import { useRealtimePriceWithResolution } from '../../hooks/price/useRealtimePrice'
import { formatPrice, getSymbolName } from '../../utils'

export function PriceChart({ isChartOpen }: { isChartOpen: boolean }) {
  return (
    <div className={`w-full`}>
      {isChartOpen && (
        <div className="h-[348px]">
          <TradingViewWidget />
        </div>
      )}
    </div>
  )
}

function TradingViewWidget() {
  const onLoadScriptRef = useRef()
  const { currentPair } = usePairStore()

  const datafeed = useRef<DataFeed>()

  const interval = useRef(60 * 60)

  const pair = PAIR_INFOS[DEFAULT_CHAIN][currentPair]
  const symbolName = getSymbolName(pair)
  const { sendMessage, setMessageHandler } = useWebSocketAction()
  const price = useRealtimePriceWithResolution(pair, interval.current)

  useEffect(() => {
    if (price) {
      datafeed.current?.updateBar({
        open: formatPrice(pair, price.open),
        high: formatPrice(pair, price.high),
        low: formatPrice(pair, price.low),
        close: formatPrice(pair, price.close),
        time: Number(price.openAt) * 1000
      })
    }
  }, [pair, price])

  useEffect(() => {
    const getBars = async (
      symbolInfo: TradingView.LibrarySymbolInfo,
      resolutionStr: TradingView.ResolutionString,
      periodParams: TradingView.PeriodParams,
      onResult: TradingView.HistoryCallback,
      onError: TradingView.ErrorCallback
    ) => {
      function getResolution(resolutionStr: TradingView.ResolutionString) {
        if (resolutionStr === '1D') {
          return 24 * 60 * 60
        } else {
          return Number(resolutionStr) * 60
        }
      }

      const resolution = getResolution(resolutionStr)

      try {
        const data = await fetch(
          `${FILLER_API_ENDPOINT}prices?symbolName=${symbolName}&resolution=${resolution}&from=${periodParams.from}&to=${periodParams.to}&countBack=${periodParams.countBack}`
        )
        const result = await data.json()

        interval.current = resolution

        if (result.prices.length === 0) {
          onResult([], { noData: true })
        } else {
          onResult(
            result.prices.map((price: any) => ({
              open: formatPrice(pair, price.open),
              high: formatPrice(pair, price.high),
              low: formatPrice(pair, price.low),
              close: formatPrice(pair, price.close),
              time: Number(price.openAt) * 1000
            }))
          )
        }
      } catch (e: any) {
        onError(e.message)
      }
    }

    initDataFeed()
    onLoadScriptRef.current = createWidget()

    function initDataFeed() {
      datafeed.current = new DataFeed({
        getBars: getBars,
        onSubscribeStarted: (symbolInfo: any, resolution: number) => {
          sendMessage({
            action: 'subscribe',
            symbolName,
            resolution: resolution * 60
          })
        }
      })
    }

    function createWidget() {
      if (
        document.getElementById('tradingview_e0c71') &&
        'TradingView' in window
      ) {
        const widget = new window.TradingView.widget({
          debug: false,
          autosize: true,
          symbol: pair.pairSymbol,
          fullscreen: false,
          library_path: '/charting_library/',
          interval: 'H',
          timeframe: '2D',
          timezone: getTimezone(),
          theme: 'dark',
          style: '1',
          locale: 'en',
          disabled_features: [
            'header_compare',
            'header_symbol_search',
            'use_localstorage_for_settings',
            'studies_symbol_search_spread_operators',
            'timeframes_toolbar'
          ],
          enabled_features: [
            // for the compatibility with the Metamask mobile
            'iframe_loading_compatibility_mode'
          ],
          enable_publishing: false,
          hide_legend: true,
          container: 'tradingview_e0c71',
          datafeed: datafeed.current!,
          overrides: {
            'mainSeriesProperties.candleStyle.upColor': '#00C06B',
            'mainSeriesProperties.candleStyle.borderUpColor': '#00C06B',
            'mainSeriesProperties.candleStyle.downColor': '#D64B4B',
            'mainSeriesProperties.candleStyle.borderDownColor': '#D64B4B'
          }
        })

        widget.onChartReady(function () {
          widget
            .activeChart()
            .createStudy(
              'Moving Average',
              false,
              false,
              { length: 7, source: 'close', offset: 0, smoothing: 'SMA 9' },
              { 'plot.color': '#BEB728', showLabelsOnPriceScale: false }
            )
          widget
            .activeChart()
            .createStudy(
              'Moving Average',
              false,
              false,
              { length: 25, source: 'close', offset: 0, smoothing: 'SMA 9' },
              { 'plot.color': '#FE62AE', showLabelsOnPriceScale: false }
            )
          widget
            .activeChart()
            .createStudy(
              'Moving Average',
              false,
              false,
              { length: 99, source: 'close', offset: 0, smoothing: 'SMA 9' },
              { 'plot.color': '#4ACAFD', showLabelsOnPriceScale: false }
            )
        })

        return widget
      }
    }
  }, [pair, currentPair, symbolName, sendMessage, setMessageHandler])

  return (
    <div
      className="tradingview-widget-container"
      style={{ height: '100%', width: '100%' }}
    >
      <div id="tradingview_e0c71" style={{ height: '100%', width: '100%' }} />
    </div>
  )
}

function getTimezone() {
  const date = new Date()
  const offset = date.getTimezoneOffset()

  if (offset === 0) {
    return 'Europe/London'
  } else if (offset === 240) {
    return 'America/Santiago'
  } else if (offset === 300) {
    return 'America/New_York'
  } else if (offset === 360) {
    return 'America/Chicago'
  } else if (offset === 420) {
    return 'America/Phoenix'
  } else if (offset === 480) {
    return 'America/Los_Angeles'
  } else if (offset === -600) {
    return 'Australia/Sydney'
  } else if (offset === -540) {
    return 'Asia/Tokyo'
  } else if (offset === -480) {
    return 'Asia/Singapore'
  } else if (offset === -420) {
    return 'Asia/Jakarta'
  } else if (offset === -360) {
    return 'Asia/Dhaka'
  } else if (offset === -300) {
    return 'Asia/Karachi'
  } else if (offset === -240) {
    return 'Asia/Dubai'
  } else if (offset === -180) {
    return 'Asia/Kuwait'
  } else if (offset === -120) {
    return 'Africa/Cairo'
  } else if (offset === -60) {
    return 'Europe/Berlin'
  } else {
    return 'Etc/UTC'
  }
}
