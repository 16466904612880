import React, { useCallback } from 'react'
import { Slider } from '../Slider'
import { AmountForm } from './AmountForm'

export const SizeRatioForm = ({
  amount,
  ratio,
  unit,
  isAlert = false,
  alertText,
  max = 100,
  step = 1,
  digits = 2,
  isRatio,
  onSizeChange,
  onRatioChange,
  setIsRatio
}: {
  amount: number
  ratio: number
  unit: string
  isAlert?: boolean
  alertText?: string
  max?: number
  step?: number
  digits?: number
  isRatio: boolean
  onSizeChange: (amount: number) => void
  onRatioChange: (amount: number) => void
  setIsRatio: (isRatio: boolean) => void
}) => {
  const onSizeRatioChange = useCallback(
    (value: number) => {
      onRatioChange(value)
      setIsRatio(true)
    },
    [onRatioChange, setIsRatio]
  )

  const onAmountChange = useCallback(
    (value: number) => {
      if (isRatio) {
        onRatioChange(value)
      } else {
        onSizeChange(value)
      }
    },
    [isRatio, onRatioChange, onSizeChange]
  )

  return (
    <div className="space-y-5">
      <AmountForm
        title={'Size'}
        unit={unit}
        amount={isRatio ? ratio : amount}
        isRatio={isRatio}
        isAlert={isAlert}
        alertText={alertText}
        max={max}
        step={step}
        digits={digits}
        onChange={onAmountChange}
        onFocus={() => {
          if (isRatio) {
            onSizeChange(0)
            setIsRatio(false)
          }
        }}
      />

      <Slider
        value={ratio}
        min={0}
        max={100}
        step={1}
        onValueChanged={onSizeRatioChange}
      />
    </div>
  )
}
