import React from 'react'
import { useDepositLimit } from '../../../hooks/gamma/useDepositLimit'
import { PairInfo } from '../../../constants'
import { Title } from './Title'

export const DepositLimit = ({
  chainId,
  pairInfo,
  price,
  leverage
}: {
  chainId: number
  pairInfo: PairInfo
  price: number
  leverage: number
}) => {
  const depositLimit = useDepositLimit(
    chainId,
    pairInfo.pairId || 0,
    leverage,
    price
  )

  if (!depositLimit) {
    return null
  }

  const ur = Math.floor(
    (depositLimit.currentValue * 100) / depositLimit.maxValue
  )

  return (
    <div className="space-y-1">
      <Title>Deposit Limit</Title>
      <div className="rounded h-1 bg-secondary">
        <div
          className="rounded h-1 bg-accent"
          style={{ width: ur + '%' }}
        ></div>
      </div>
      <div className="text-xs text-white5 font-normal">
        {depositLimit.currentValue.toFixed(pairInfo.quote.precision)} USDC /{' '}
        {depositLimit.maxValue.toFixed(pairInfo.quote.precision)} USDC
      </div>
    </div>
  )
}
