import { useState } from 'react'
import { Bytes, PerpOrderV3, PerpOrderV3Params } from '@predy/js-sdk'
import { useSignTypedData } from 'wagmi'
import { FILLER_API_ENDPOINT } from '../../constants'
import { reasonToErrorMessage } from '../../utils/error'
import { useQueryClient } from '@tanstack/react-query'
import { useModifiedPerpOrder } from './usePerpOrder'
import { getPerpOrdersQueryKey } from './usePerpOrders'

export function useModifyPerpOrder(
  orderId: string,
  order: PerpOrderV3,
  tradeAmount: bigint,
  price: bigint,
  stopPrice: bigint,
  cb: (error: string | null, tradeResult: any) => void
) {
  const queryClient = useQueryClient()
  const newOrder = useModifiedPerpOrder(order, tradeAmount, price, stopPrice)
  const { domain, types, message } = newOrder.permitData()

  const [isAPILoading, setIsAPILoading] = useState(false)
  const [error, setError] = useState<null | string>(null)

  const { signTypedData, data, status } = useSignTypedData({
    mutation: {
      onSuccess: async (data: Bytes, variables) => {
        console.log('aaa', variables)

        const signedOrder = new PerpOrderV3(
          variables.message.witness as PerpOrderV3Params,
          order.chainId
        )

        const params = signedOrder.perpOrder

        setIsAPILoading(true)
        setError(null)

        console.log('start to put order/v2/perp')

        const res = await fetch(`${FILLER_API_ENDPOINT}order/v2/perp`, {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            orderId,
            quantity: params.quantity.toString(),
            price: params.limitPrice.toString(),
            stopPrice: params.stopPrice.toString(),
            marginAmount: params.marginAmount.toString(),
            signature: data
          })
        })

        console.log('end put order/v2/perp', res)

        const tradeResult = await res.json()

        if (tradeResult.message && tradeResult.message.length > 0) {
          const message = tradeResult.message

          const errorMessage = reasonToErrorMessage(message)

          if (errorMessage) {
            setError(errorMessage)
            cb(errorMessage, null)
          } else {
            setError('unexpected error')
            console.error(message)
            cb(message, null)
          }

          setIsAPILoading(false)

          return
        }

        setIsAPILoading(false)

        // refetch perp orders
        await queryClient.invalidateQueries({
          queryKey: getPerpOrdersQueryKey(
            order.chainId,
            order.perpOrder.info.trader,
            true
          )
        })

        cb(null, tradeResult)
      }
    }
  })

  return {
    variables: {
      domain,
      types,
      message,
      primaryType: 'PermitWitnessTransferFrom'
    },
    signTypedData,
    data,
    error,
    isLoading: status === 'pending' || isAPILoading
  }
}
