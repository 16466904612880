import React from 'react'
import { AddNewPairForm } from '../components/pairs/AddNewPairForm'
import { useRoute } from 'wouter'
import { chainNameToId } from '../hooks/useTradeRoute'
import { PairListComponent } from '../components/pairs/PairList'

const PairListView = () => {
  const route = useRoute('/:chain/pairs')
  const params = route[1]
  const chainId = params ? chainNameToId(params.chain) : null

  if (!chainId) return <div>Not Found</div>

  return (
    <div className="p-10 flex justify-center flex-col">
      <AddNewPairForm chainId={chainId} />
      <PairListComponent chainId={chainId} />
    </div>
  )
}

export default PairListView
