import React from 'react'
import { usePerpTradeHistory } from '../../../hooks/perp/usePerpTradeHistory'
import { Address } from 'viem'
import { toTimeString } from '../../../utils/string'
import LoadingIndicator from '../../common/LoadingIndicator'
import { PairInfo } from '../../../constants'

const TradeHistoryItem = ({
  action,
  pair,
  link,
  createdAt,
  side,
  quantity,
  fmtPayoff,
  payoff,
  price
}: {
  action: string
  pair: PairInfo
  link: string
  createdAt: number
  side: string
  quantity: string
  fmtPayoff: number
  payoff: string
  price: string
}) => {
  return (
    <div className="w-full bg-panel border-panelborder border-[1px] p-4 rounded text-white space-y-1">
      <div className="flex justify-between">
        <div className="flex space-x-1">
          <div>{action}</div>
          <div>
            {pair.base.symbol}/{pair.quote.symbol}
          </div>
        </div>

        <a
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
          href={link}
        >
          {toTimeString(createdAt)}
        </a>
      </div>

      {side.length === 0 ? (
        <></>
      ) : (
        <div className="flex justify-between">
          <div className="text-xs text-fourth">Side</div>
          <div>
            {side === 'Long' ? (
              <div className="text-green">Long</div>
            ) : side === 'Short' ? (
              <div className="text-red">Short</div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      {quantity.length === 0 ? (
        <></>
      ) : (
        <div className="flex justify-between">
          <div className="text-xs text-fourth">Size</div>
          <div>{quantity}</div>
        </div>
      )}

      <div className="flex justify-between">
        <div className="text-xs text-fourth">PNL</div>
        <div
          className={
            fmtPayoff > 0 ? 'text-green' : fmtPayoff < 0 ? 'text-red' : ''
          }
        >
          {payoff}
        </div>
      </div>

      {price.length === 0 ? (
        <></>
      ) : (
        <div className="flex justify-between">
          <div className="text-xs text-fourth">Price</div>
          <div>{price}</div>
        </div>
      )}
    </div>
  )
}

export const TradeHistory = ({
  chainId,
  trader
}: {
  chainId: number
  trader: Address
}) => {
  const tradeHistory = usePerpTradeHistory(chainId, trader)

  if (tradeHistory.loading) {
    return <LoadingIndicator />
  }

  const mediaQuery = window.matchMedia('(width < 768px)')
  const isMobile = mediaQuery.matches

  if (isMobile) {
    return (
      <div className="px-4 pb-5 text-xs w-full overflow-x-scroll overflow-y-scroll space-y-1">
        {tradeHistory.data === null || tradeHistory.data.length === 0 ? (
          <div className="flex justify-center items-center text-third">
            No History
          </div>
        ) : (
          <></>
        )}
        {tradeHistory.data ? (
          tradeHistory.data.map((item, index) => (
            <TradeHistoryItem
              key={index}
              action={item.action}
              pair={item.pair}
              link={item.link}
              createdAt={item.createdAt}
              side={item.side}
              quantity={item.quantity}
              fmtPayoff={item.fmtPayoff}
              payoff={item.payoff}
              price={item.price}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    )
  }

  return (
    <div className="px-4 text-xs w-full overflow-x-scroll overflow-y-scroll">
      {tradeHistory.data === null || tradeHistory.data.length === 0 ? (
        <div className="flex justify-center items-center text-third">
          No History
        </div>
      ) : (
        <div className="pb-4 mb-4 text-fourth flex justify-around border-b-[1px] border-panelborder">
          <div className="basis-[16%]">Time</div>
          <div className="basis-[13%]">Symbol</div>
          <div className="basis-[13%]">Action</div>
          <div className="basis-[13%]">Side</div>
          <div className="basis-[15%]">Price</div>
          <div className="basis-[15%]">Quantity</div>
          <div className="basis-[15%]">PnL</div>
        </div>
      )}
      {tradeHistory.data ? (
        tradeHistory.data.map((item, index) => (
          <div className="my-2 text-white flex justify-around" key={index}>
            <a
              className="basis-[16%] hover:underline"
              target="_blank"
              rel="noreferrer"
              href={item.link}
            >
              {toTimeString(item.createdAt)}
            </a>
            <div className="basis-[13%]">{`${item.pair.base.symbol}/${item.pair.quote.symbol}`}</div>
            <div className="basis-[13%]">{item.action}</div>
            <div className="basis-[13%]">
              {item.side === 'Long' ? (
                <div className="text-green">Long</div>
              ) : item.side === 'Short' ? (
                <div className="text-red">Short</div>
              ) : (
                <></>
              )}
            </div>
            <div className="basis-[15%]">{item.price}</div>
            <div className="basis-[15%]">{item.quantity}</div>
            <div
              className={
                'basis-[15%] ' +
                (item.fmtPayoff > 0
                  ? 'text-green'
                  : item.fmtPayoff < 0
                  ? 'text-red'
                  : '')
              }
            >
              {item.payoff}
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  )
}
