import { useQuery } from '@tanstack/react-query'
import { toUnscaled } from '../../utils/bn'
import { usePairStatus } from './usePairStatus'
import { IRMParams, calculateInterestRate } from '../../utils/irm'
import { ONE } from '../../constants'

interface ScaledAssetStatus {
  totalCompoundDeposited: bigint
  totalNormalDeposited: bigint
  totalNormalBorrowed: bigint
  assetScaler: bigint
  assetGrowth: bigint
  debtGrowth: bigint
}

export function useBorrowingFee(chainId: number, pairId: number) {
  const result = usePairStatus(chainId, pairId)

  const calculateBorrowInterestRate = (
    params: IRMParams,
    pool: ScaledAssetStatus
  ) => {
    if (
      pool.totalCompoundDeposited === 0n &&
      pool.totalNormalDeposited === 0n
    ) {
      return {
        borrowInterestRate: 0n,
        supplyInterestRate: 0n
      }
    }

    const ur =
      (pool.totalNormalBorrowed * ONE) /
      ((pool.assetScaler * pool.totalCompoundDeposited) / ONE +
        pool.totalNormalDeposited)
    const borrowInterestRate = calculateInterestRate(params, ur)
    const supplyInterestRate = (borrowInterestRate * ur) / ONE

    return {
      borrowInterestRate,
      supplyInterestRate
    }
  }

  const calculateAvailable = (assetStatus: ScaledAssetStatus) => {
    const totalDeposit =
      (assetStatus.assetScaler * assetStatus.totalCompoundDeposited) / ONE +
      assetStatus.totalNormalDeposited
    const totalBorrow = assetStatus.totalNormalBorrowed

    return totalDeposit - totalBorrow
  }

  return useQuery({
    queryKey: ['borrowing-fee', chainId, pairId],
    queryFn: () => {
      if (!result.data) throw new Error('result not loaded')

      const pairStatus = result.data.result

      const interestRateBase = calculateBorrowInterestRate(
        pairStatus.basePool.irmParams,
        pairStatus.basePool.tokenStatus
      )
      const interestRateQuote = calculateBorrowInterestRate(
        pairStatus.quotePool.irmParams,
        pairStatus.quotePool.tokenStatus
      )

      return {
        openLong: pairStatus.basePool.tokenStatus.totalNormalDeposited,
        openShort: pairStatus.basePool.tokenStatus.totalNormalBorrowed,
        availableBaseAmount: calculateAvailable(
          pairStatus.basePool.tokenStatus
        ),
        availableQuoteAmount: calculateAvailable(
          pairStatus.quotePool.tokenStatus
        ),
        base: interestRateBase,
        quote: interestRateQuote,
        borrowingFeeLong: toUnscaled(
          (interestRateBase.supplyInterestRate -
            interestRateQuote.borrowInterestRate) /
            365n,
          16,
          4
        ),
        borrowingFeeShort: toUnscaled(
          (interestRateQuote.supplyInterestRate -
            interestRateBase.borrowInterestRate) /
            365n,
          16,
          4
        )
      }
    },
    enabled: pairId > 0 && result.isSuccess && !!result.data
  })
}
