import React, { useCallback, useEffect, useState } from 'react'

type Props = {
  type: 'number' | 'text'
  value: string
  className?: string
  disabled?: boolean
  step?: number
  max?: number
  // input delay in ms
  delay?: number
  onChange?: (amount: string) => void
  onFocus?: () => void
}

export const InputForm = ({
  type,
  value,
  className = '',
  disabled = false,
  step = 1,
  max = 100,
  onChange,
  onFocus
}: Props) => {
  const [innerValue, setInnerValue] = useState(value.toString())

  useEffect(() => {
    setInnerValue(value)
  }, [value])

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInnerValue(e.target.value)
      if (onChange) onChange(e.target.value)
    },
    [setInnerValue, onChange]
  )

  return (
    <input
      autoComplete={'off'}
      type={type}
      className={className}
      value={innerValue}
      step={step}
      max={max}
      disabled={disabled}
      onChange={onChangeHandler}
      onBlur={onChangeHandler}
      onFocus={() => {
        if (onFocus) onFocus()
      }}
    />
  )
}
