import React, { useState } from 'react'
import { PrimaryButton } from '../common/Button'
import { useAccount, useWriteContract } from 'wagmi'
import { PairInfo } from '../../constants/assets'
import { toScaled } from '../../utils/bn'
import { useSuppliedAmount } from '../../hooks/lending/useSuppliedAmount'
import { SupplyAmountForm } from './SupplyForm'
import { useWithdraw } from '../../hooks/lending/useWithdraw'
import { Address } from 'viem'

export const WithdrawForm = ({
  chainId,
  pairInfo,
  supplyTokenAddress,
  account,
  isQuote
}: {
  chainId: number
  pairInfo: PairInfo
  supplyTokenAddress: Address
  account: Address
  isQuote: boolean
}) => {
  const [amount, serAmount] = useState<number>(0)

  const { isConnected } = useAccount()
  const suppliedAmount = useSuppliedAmount(
    chainId,
    pairInfo.pairId || 0,
    supplyTokenAddress,
    account,
    isQuote
  )
  const amountBn = toScaled(
    amount,
    isQuote ? pairInfo.quote.decimals : pairInfo.base.decimals
  )
  const withdraw = useWithdraw(chainId, pairInfo.pairId || 0, amountBn, isQuote)
  const write = useWriteContract()

  const maxAmount = suppliedAmount.data || 0
  console.log(2, suppliedAmount.data)

  return (
    <div className="space-y-2">
      <div className="flex justify-between text-xs">
        <div className="text-fourth">Supplied</div>
        <div>{suppliedAmount.data}</div>
      </div>
      <SupplyAmountForm
        symbol={isQuote ? pairInfo.quote.symbol : pairInfo.base.symbol}
        maxAmount={maxAmount}
        amount={amount}
        setAmount={serAmount}
      />

      <div className="my-10 h-10">
        <PrimaryButton
          disabled={false}
          onClick={() => {
            if (withdraw.data) {
              write.writeContract(withdraw.data.request)
            }
          }}
        >
          {isConnected
            ? 'Withdraw' +
              (isQuote
                ? ' ' + pairInfo.quote.symbol
                : ' ' + pairInfo.base.symbol)
            : 'Connect Wallet'}
        </PrimaryButton>
      </div>
    </div>
  )
}
