import React, { ReactElement, useState } from 'react'
import { BaseModal } from './BaseModal'

export const BaseCloseButton = ({
  title,
  children,
  height = 320
}: {
  title: ReactElement
  children: ReactElement
  height?: number
}) => {
  const [isOpen, setIsOpen] = useState(false)

  // The handler called when user try to close modal
  const onCloseModal = () => {
    setIsOpen(false)
  }

  const newChildren = React.cloneElement(children, { onCloseModal })

  return (
    <div className="w-18 md:w-20 text-xs">
      <a
        className="cursor-pointer"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        {title}
      </a>

      <BaseModal isOpen={isOpen} onRequestClose={onCloseModal} height={height}>
        {newChildren}
      </BaseModal>
    </div>
  )
}
