import { useMemo } from 'react'
import { PairInfo } from '../../constants'
import { useFeeEst } from './useFeeEst'
import { calculateGammaPosition } from '../../utils/gamma'
import { toScaled } from '../../utils/bn'

export function useAPR(
  chainId: number,
  pairInfo: PairInfo,
  pairId: number,
  price: number,
  leverage: number,
  isGammaLong = false
) {
  const marginAmount = 100

  const position = useMemo(() => {
    return calculateGammaPosition(marginAmount, price, leverage, isGammaLong)
  }, [marginAmount, price, leverage, isGammaLong])

  const feeEst = useFeeEst(
    chainId,
    pairInfo,
    pairId,
    position.underlying,
    position.squart
  )

  return useMemo(() => {
    if (feeEst.data?.total) {
      const baseApr =
        Number(
          (feeEst.data.total * 3650000n) /
            toScaled(marginAmount, pairInfo.quote.decimals)
        ) / 100

      const apr = baseApr * 0.995

      return apr
    }

    return null
  }, [pairInfo.quote.decimals, feeEst, marginAmount])
}
