import React from 'react'
import { useSpotOrders } from '../../../hooks/spot/useSpotOrders'
import { Address } from 'viem'
import { LoadingIndicator } from '../../common/LoadingIndicator'
import { OrderStatus } from '../../../constants'
import { HorizontalRule } from '../../common/HorizontalRule'

export const OrderHistory = ({
  chainId,
  trader
}: {
  chainId: number
  trader: Address
}) => {
  const orders = useSpotOrders(chainId, trader, false)

  if (orders.isLoading) {
    return <LoadingIndicator />
  }

  if (!orders.isSuccess) {
    return <div>Fetch error</div>
  }

  return (
    <div className="px-4 text-third text-xs overflow-x-scroll overflow-y-scroll">
      {orders.data.length === 0 ? (
        <div className="flex justify-center items-center">No Orders</div>
      ) : (
        <div className="flex justify-around">
          <div className="basis-[14.2%]">Time</div>
          <div className="basis-[14.2%]">Symbol</div>
          <div className="basis-[14.2%]">Side</div>
          <div className="basis-[14.2%]">Type</div>
          <div className="basis-[14.2%]">Price</div>
          <div className="basis-[14.2%]">Quantity</div>
          <div className="basis-[14.2%]">Status</div>
        </div>
      )}
      <HorizontalRule />
      {orders.data ? (
        orders.data.map((openOrder, index) => (
          <div
            className={`my-2 flex justify-around ${
              openOrder.orderStatus === OrderStatus.FAILED ||
              openOrder.orderStatus === OrderStatus.CANCELLED
                ? 'text-white5'
                : 'text-white'
            }`}
            key={index}
          >
            <div className="basis-[14.2%]">{openOrder.createdAt}</div>
            <div className="basis-[14.2%]">
              {openOrder.pair?.base.symbol}/{openOrder.pair?.quote.symbol}
            </div>
            <div className="basis-[14.2%]">
              {openOrder.isBuy ? 'Buy' : 'Sell'}
            </div>
            <div className="basis-[14.2%]">
              {openOrder.limitPrice ? 'Limit' : 'Market'}
            </div>
            <div className="basis-[14.2%]">
              {openOrder.limitPrice ? openOrder.limitPrice : '-'}
            </div>
            <div className="basis-[14.2%]">{openOrder.quantity}</div>
            <div className="basis-[14.2%]">{openOrder.orderStatus}</div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  )
}
