import React from 'react'
import { useSpotOrders } from '../../../hooks/spot/useSpotOrders'
import { Address } from 'viem'
import { abs, toUnscaled } from '../../../utils/bn'
import { LoadingIndicator } from '../../common/LoadingIndicator'
import { SpotOrderV3 } from '@predy/js-sdk'
import { useCancelSpotOrderV3 } from '../../../hooks/spot/useCancelSpotOrderV3'
import { HorizontalRule } from '../../common/HorizontalRule'
import { AiFillDelete } from 'react-icons/ai'
import { ModifySpotOrderFormModal } from './ModifyOrderForm'

const CancelOrderButton = ({
  orderId,
  order
}: {
  orderId: number
  order: SpotOrderV3
}) => {
  const { signMessage, variables } = useCancelSpotOrderV3(orderId, order)

  return (
    <button
      onClick={() => {
        signMessage({
          message: variables
        })
      }}
    >
      <AiFillDelete size={16} />
    </button>
  )
}

export const OpenOrders = ({
  chainId,
  trader
}: {
  chainId: number
  trader: Address
}) => {
  const orders = useSpotOrders(chainId, trader, true)

  if (orders.isLoading) {
    return <LoadingIndicator />
  }

  if (!orders.isSuccess) {
    return <div>Fetch error</div>
  }

  return (
    <div className="px-4 text-third text-xs overflow-x-scroll overflow-y-scroll">
      {orders.data.length === 0 ? (
        <div className="flex justify-center items-center">No Orders</div>
      ) : (
        <div className="flex justify-around">
          <div className="basis-[14.2%]">Time</div>
          <div className="basis-[14.2%]">Symbol</div>
          <div className="basis-[14.2%]">Side</div>
          <div className="basis-[14.2%]">Type</div>
          <div className="basis-[14.2%]">Price</div>
          <div className="basis-[14.2%]">Quantity</div>
          <div className="basis-[14.2%]"></div>
        </div>
      )}
      <HorizontalRule />
      {orders.data ? (
        orders.data.map((openOrder, index) => (
          <div
            className="h-10 my-0 flex justify-around items-center hover:bg-white1 border-b-[1px] border-panelborder"
            key={index}
          >
            <div className="basis-[14.2%]">{openOrder.createdAt}</div>
            <div className="basis-[14.2%]">
              {openOrder.pair?.base.symbol}/{openOrder.pair?.quote.symbol}
            </div>
            <div className="basis-[14.2%]">
              {openOrder.baseTokenAmount > 0n ? (
                <div className="text-green">Buy</div>
              ) : (
                <div className="text-red">Sell</div>
              )}
            </div>
            <div className="basis-[14.2%]">
              {openOrder.limitPrice ? 'Limit' : 'Market'}
            </div>
            <div className="basis-[14.2%] flex justify-start items-start space-x-1">
              <div>{openOrder.limitPrice ? openOrder.limitPrice : '-'}</div>
              <ModifySpotOrderFormModal
                amount={openOrder.quantity}
                pair={openOrder.pair}
                orderId={openOrder.orderId}
                spotOrder={openOrder.spotOrder}
                limitPrice={openOrder.limitPrice || 0}
              />
            </div>
            <div className="basis-[14.2%] flex justify-start items-start space-x-1">
              <div>
                {toUnscaled(
                  abs(openOrder.baseTokenAmount),
                  openOrder.pair?.base.decimals || 18,
                  3
                )}
              </div>
              <ModifySpotOrderFormModal
                amount={openOrder.quantity}
                pair={openOrder.pair}
                orderId={openOrder.orderId}
                spotOrder={openOrder.spotOrder}
                limitPrice={openOrder.limitPrice || 0}
              />
            </div>
            <div className="basis-[14.2%]">
              <CancelOrderButton
                orderId={openOrder.orderId}
                order={openOrder.spotOrder}
              />
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  )
}
