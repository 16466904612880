import { useReadContract } from 'wagmi'
import { erc20ABI } from '../../abis/ERC20'

export function useDetails(chainId: number, address: `0x${string}`) {
  return useReadContract({
    address,
    abi: erc20ABI,
    functionName: 'name',
    chainId
  })
}
