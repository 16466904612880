import { useReadContract } from 'wagmi'
import { uniswapV3PoolAbi } from '../abis/UniswapV3Pool'
import { PAIR_INFOS } from '../constants'
import { Address } from '@predy/js-sdk'

export interface PriceResult {
  price: bigint
  sqrtPrice: bigint
  indexPrice: bigint
  sqrtIndexPrice: bigint
}

export function useUniswapPrice(chainId: number, pairSymbol: string) {
  const result = useReadContract({
    address: PAIR_INFOS[chainId][pairSymbol].uniswapPoolAddress as Address,
    abi: uniswapV3PoolAbi,
    functionName: 'slot0',
    chainId
  })

  if (result.data) {
    const sqrtPrice = result.data[0]

    const price = (sqrtPrice * sqrtPrice) / 2n ** 96n

    return {
      isSuccess: true,
      data: price
    }
  } else {
    return {
      isSuccess: false
    }
  }
}
