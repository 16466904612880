import React, { useState } from 'react'
import {
  AmountForm,
  BaseModal,
  CancelButton,
  LoadingIndicator,
  PrimaryButton
} from '../../common'
import { OpenOrder } from '../../../hooks/perp'
import { toScaledPrice } from '../../../utils'
import { AiFillEdit } from 'react-icons/ai'
import { useModifyPerpOrder } from '../../../hooks/perp/useModifyPerpOrder'
import { toScaled } from '../../../utils/bn'

export const ModifyOrderFormModal = ({
  amount,
  limitPrice,
  openOrder
}: {
  amount: number
  limitPrice: number
  openOrder: OpenOrder
}) => {
  const [isOpen, setIsOpen] = useState(false)

  // The handler called when user try to close modal
  const onCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <div className="text-sm md:text-xs">
      <button
        className="pointer-cursor"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <AiFillEdit />
      </button>

      <BaseModal isOpen={isOpen} onRequestClose={onCloseModal} height={224}>
        <ModifyOrderForm
          amount={amount}
          limitPrice={limitPrice}
          openOrder={openOrder}
          onCloseModal={onCloseModal}
        />
      </BaseModal>
    </div>
  )
}

export const ModifyOrderForm = ({
  amount,
  limitPrice,
  openOrder,
  onCloseModal
}: {
  amount: number
  limitPrice: number
  openOrder: OpenOrder
  onCloseModal: () => void
}) => {
  const [newAmount, setNewAmount] = useState(amount)
  const [newLimitPrice, setNewLimitPrice] = useState(limitPrice)

  const { signTypedData, variables, isLoading, error } = useModifyPerpOrder(
    openOrder.orderId,
    openOrder.perpOrder,
    toScaled(newAmount, openOrder.pairInfo.base.decimals),
    toScaledPrice(openOrder.pairInfo, newLimitPrice),
    0n,
    () => {
      onCloseModal()
    }
  )

  return (
    <div>
      <div className="space-y-3">
        <AmountForm
          amount={newLimitPrice}
          disabled={false}
          title="Price"
          unit="USDC"
          step={1 / 10 ** openOrder.pairInfo.pricePrecision}
          digits={openOrder.pairInfo.pricePrecision}
          onChange={setNewLimitPrice}
        />
        <AmountForm
          amount={newAmount}
          disabled={false}
          title="Amount"
          unit="ETH"
          step={1 / 10 ** openOrder.pairInfo.base.precision}
          digits={openOrder.pairInfo.base.precision}
          onChange={setNewAmount}
          isAlert={newAmount < 0}
          alertText="Amount must not be 0"
        />
      </div>
      <div className="mt-10 mb-4 h-10 flex justify-between space-x-2">
        <CancelButton onClick={onCloseModal}>Cancel</CancelButton>
        <PrimaryButton
          disabled={false}
          onClick={() => {
            signTypedData({
              domain: variables.domain,
              types: variables.types,
              message: variables.message,
              primaryType: 'PermitWitnessTransferFrom'
            })
          }}
        >
          {isLoading ? <LoadingIndicator /> : 'Confirm'}
        </PrimaryButton>
      </div>
      {error ? <div className="text-red text-xs">{error}</div> : <></>}
    </div>
  )
}
