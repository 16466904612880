import { gql } from '@apollo/client'

export const LENDING_HISTORY_ITEM_QUERY = gql`
  query ($owner: String, $first: Int, $skip: Int) {
    lendingUserHistoryItems(
      first: $first
      skip: $skip
      where: { account: $owner }
      orderBy: createdAt
      orderDirection: desc
    ) {
      id
      address
      pairId
      isStable
      action
      account
      assetAmount
      txHash
      createdAt
    }
  }
`

export type LendingUserHistoryItems = {
  lendingUserHistoryItems: {
    id: string
    address: string
    pairId: string
    isStable: boolean
    action: string
    account: string
    assetAmount: string
    txHash: string
    createdAt: string
  }[]
}
