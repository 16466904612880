import { TickMath } from '@uniswap/v3-sdk'
import { Q96 } from '../constants'

export function calculateAmount0Offset(tick: number, liquidity: bigint) {
  const sqrtPriceJSBI = TickMath.getSqrtRatioAtTick(tick)
  const sqrtPrice = BigInt(sqrtPriceJSBI.toString())

  return (liquidity * Q96) / sqrtPrice
}

export function calculateAmount1Offset(tick: number, liquidity: bigint) {
  const sqrtPriceJSBI = TickMath.getSqrtRatioAtTick(tick)
  const sqrtPrice = BigInt(sqrtPriceJSBI.toString())

  return (liquidity * sqrtPrice) / Q96
}

export function getAmount0ForLiquidity(
  sqrtPriceA: bigint,
  sqrtPriceB: bigint,
  liquidity: bigint
) {
  if (sqrtPriceA > sqrtPriceB) {
    const t = sqrtPriceA
    sqrtPriceA = sqrtPriceB
    sqrtPriceB = t
  }

  return (liquidity * Q96 * (sqrtPriceB - sqrtPriceA)) / sqrtPriceB / sqrtPriceA
}

export function getAmount1ForLiquidity(
  sqrtPriceA: bigint,
  sqrtPriceB: bigint,
  liquidity: bigint
) {
  if (sqrtPriceA > sqrtPriceB) {
    const t = sqrtPriceA
    sqrtPriceA = sqrtPriceB
    sqrtPriceB = t
  }

  return (liquidity * (sqrtPriceB - sqrtPriceA)) / Q96
}

export function getAmountsForLiquidityWithTicks(
  sqrtPrice: bigint,
  tickA: number,
  tickB: number,
  liquidity: bigint
) {
  const sqrtPriceAJSBI = TickMath.getSqrtRatioAtTick(tickA)
  const sqrtPriceA = BigInt(sqrtPriceAJSBI.toString())

  const sqrtPriceBJSBI = TickMath.getSqrtRatioAtTick(tickB)
  const sqrtPriceB = BigInt(sqrtPriceBJSBI.toString())

  return getAmountsForLiquidity(sqrtPrice, sqrtPriceA, sqrtPriceB, liquidity)
}

export function getAmountsForLiquidity(
  sqrtPrice: bigint,
  sqrtPriceA: bigint,
  sqrtPriceB: bigint,
  liquidity: bigint
) {
  let amount0 = BigInt(0)
  let amount1 = BigInt(0)

  if (sqrtPriceA > sqrtPriceB) {
    const t = sqrtPriceA
    sqrtPriceA = sqrtPriceB
    sqrtPriceB = t
  }

  if (sqrtPrice <= sqrtPriceA) {
    amount0 = getAmount0ForLiquidity(sqrtPriceA, sqrtPriceB, liquidity)
  } else if (sqrtPrice < sqrtPriceB) {
    amount0 = getAmount0ForLiquidity(sqrtPrice, sqrtPriceB, liquidity)
    amount1 = getAmount1ForLiquidity(sqrtPriceA, sqrtPrice, liquidity)
  } else {
    amount1 = getAmount1ForLiquidity(sqrtPriceA, sqrtPriceB, liquidity)
  }

  return [amount0, amount1]
}
