import React, { useState } from 'react'
import { Direction, DirectionSelector } from './DirectionSelector'
import { OrderType, OrderTypeSelector } from './OrderTypeSelector'
import { PairInfo } from '../../../constants/assets'
import { useAccount } from 'wagmi'
import { TradeDirectionForm } from './TradeDirectionForm'
import { zeroAddress } from 'viem'

export const TradeFormSM = ({
  chainId,
  pairInfo
}: {
  chainId: number
  pairInfo: PairInfo
}) => {
  const [direction, setDirection] = useState<Direction>(Direction.Long)
  const { address } = useAccount()
  const [orderType, setOrderType] = useState<OrderType>(OrderType.Market)

  return (
    <div className="h-full bg-panel border-[1px] border-panelborder">
      <div className="h-10 mb-4">
        <DirectionSelector direction={direction} setDirection={setDirection} />
      </div>
      <div className="mx-3 my-4">
        <OrderTypeSelector orderType={orderType} setOrderType={setOrderType} />
      </div>
      <div className="mx-3 mb-3">
        {direction === Direction.Long ? (
          <TradeDirectionForm
            chainId={chainId}
            pairInfo={pairInfo}
            direction={Direction.Long}
            orderType={orderType}
            trader={address || zeroAddress}
          />
        ) : (
          <TradeDirectionForm
            chainId={chainId}
            pairInfo={pairInfo}
            direction={Direction.Short}
            orderType={orderType}
            trader={address || zeroAddress}
          />
        )}
      </div>
    </div>
  )
}
