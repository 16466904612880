const PAIR_ITEMS = [
  {
    id: 1,
    pairId: 2,
    pairSymbol: 'eth-usdc'
  },
  {
    id: 2,
    pairSymbol: 'arb-usdc'
  },
  {
    id: 3,
    pairSymbol: 'wbtc-usdc'
  }
]

export function usePairSelectorItems(isSpotMarket: boolean = true) {
  return isSpotMarket ? PAIR_ITEMS : PAIR_ITEMS.filter(item => !!item.pairId)
}
