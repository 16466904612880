import { ERROR_SIGNATURES_MAP } from '../constants/errors'

export function reasonToErrorMessage(reason: string) {
  const errorCode = Object.keys(ERROR_SIGNATURES_MAP).find(
    errorCode => reason.indexOf(errorCode) >= 0
  )

  if (errorCode === undefined) {
    return null
  }

  return ERROR_SIGNATURES_MAP[errorCode]
}
