import { useWriteContract, useSimulateContract } from 'wagmi'
import { ADDRESS_MAP } from '../../constants/addresses'
import { predyPoolAbi } from '../../abis/PredyPool'
import { Address } from 'viem'

export function useUpdatePriceOracle(
  chainId: number,
  pairId: number,
  priceOracle: Address
) {
  const { data } = useSimulateContract({
    chainId,
    address: ADDRESS_MAP[chainId].PredyPool,
    abi: predyPoolAbi,
    functionName: 'updatePriceOracle',
    args: [BigInt(pairId), priceOracle]
  })

  return {
    ...useWriteContract(),
    data
  }
}
