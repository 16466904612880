import { useQuery } from 'react-query'
import { ONE } from '../../constants'
import {
  IRMParams,
  calculateInterestRate,
  calculatePremiumCurve
} from '../../utils/irm'
import {
  PairStatus,
  ScaledAssetStatus,
  usePairStatus
} from '../pool/usePairStatus'
import { abs } from '../../utils/bn'

function calculateSupply(assetStatus: ScaledAssetStatus) {
  const totalDeposited =
    (assetStatus.totalCompoundDeposited * assetStatus.assetScaler) / ONE +
    assetStatus.totalNormalDeposited

  return totalDeposited
}

function calculateBorrow(assetStatus: ScaledAssetStatus) {
  const totalBorrowed = assetStatus.totalNormalBorrowed

  return totalBorrowed
}

export function calculateAssetInterest(
  tokenStatus: ScaledAssetStatus,
  irmParams: IRMParams,
  positionAmount: bigint,
  tradeAmount?: bigint
) {
  let supplyAddiction = 0n
  let borrowAddiction = 0n

  if (tradeAmount) {
    const openAndCloseAmounts = calculateOpenAndCloseAmounts(
      positionAmount,
      tradeAmount
    )

    if (openAndCloseAmounts.openAmount > 0n) {
      supplyAddiction = openAndCloseAmounts.openAmount
    } else {
      borrowAddiction = openAndCloseAmounts.openAmount * -1n
    }

    if (openAndCloseAmounts.closeAmount > 0n) {
      borrowAddiction = openAndCloseAmounts.closeAmount * -1n
    } else {
      supplyAddiction = openAndCloseAmounts.closeAmount
    }
  }

  const supply = calculateSupply(tokenStatus) + supplyAddiction
  const borrow = calculateBorrow(tokenStatus) + borrowAddiction

  const ur = supply === 0n ? 0n : (borrow * ONE) / supply
  const borrowInterest = calculateInterestRate(irmParams, ur)
  const supplyInterest =
    supply === 0n ? 0n : (borrowInterest * borrow * (100n - 1n)) / supply / 100n

  return {
    supplyInterest,
    borrowInterest
  }
}

export function useAssetInterest(
  chainId: number,
  assetId: number,
  isStable: boolean
) {
  const asset = usePairStatus(chainId, assetId)

  return useQuery(
    ['perp_interest', chainId, assetId, isStable],

    async () => {
      if (!asset.isSuccess) throw new Error('asset not set')

      const pool = isStable
        ? asset.data.result.quotePool
        : asset.data.result.basePool

      return calculateAssetInterest(pool.tokenStatus, pool.irmParams, 0n)
    },
    {
      enabled: asset.isSuccess,
      staleTime: 1000
    }
  )
}

// interest for 2 * sqrt(x)
export function calculateSquartInterest(
  pairStatus: PairStatus,
  positionAmount: bigint,
  tradeAmount?: bigint
) {
  let supplyAddiction = 0n
  let borrowAddiction = 0n

  if (tradeAmount) {
    const openAndCloseAmounts = calculateOpenAndCloseAmounts(
      positionAmount,
      tradeAmount
    )

    if (openAndCloseAmounts.openAmount > 0n) {
      supplyAddiction = openAndCloseAmounts.openAmount
    } else {
      borrowAddiction = openAndCloseAmounts.openAmount * -1n
    }

    if (openAndCloseAmounts.closeAmount > 0n) {
      borrowAddiction = openAndCloseAmounts.closeAmount * -1n
    } else {
      supplyAddiction = openAndCloseAmounts.closeAmount
    }
  }

  const supply = pairStatus.sqrtAssetStatus.totalAmount + supplyAddiction
  const borrow = pairStatus.sqrtAssetStatus.borrowedAmount + borrowAddiction

  const ur = supply === 0n ? 0n : (borrow * ONE) / supply

  const spread = calculatePremiumCurve(ur)

  return {
    supply,
    borrow,
    spread
  }
}

function calculateOpenAndCloseAmounts(
  positionAmount: bigint,
  tradeAmount: bigint
) {
  let openAmount = 0n
  let closeAmount = 0n

  if (positionAmount < 0n === tradeAmount < 0n) {
    openAmount = tradeAmount
  } else {
    if (abs(positionAmount) >= abs(tradeAmount)) {
      closeAmount = tradeAmount
    } else {
      openAmount = positionAmount + tradeAmount
      closeAmount = positionAmount * -1n
    }
  }

  return {
    openAmount,
    closeAmount
  }
}
