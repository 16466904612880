import React, { useCallback } from 'react'
import { AmountForm, SizeRatioForm } from '../components/common'
import { Panel } from '../components/common/Panel'
import { AmountButtonForm } from '../components/common/form/AmountButtonForm'

const GalleryView = () => {
  return (
    <div className="p-4 flex justify-center flex-col">
      <Panel>
        <div className="w-80 flex justify-center flex-col space-y-6">
          <div className="space-y-3">
            <h3>Leverage Form</h3>
            <LeverageForm />
          </div>

          <div className="space-y-3">
            <h3>Price Form</h3>
            <PriceForm />
          </div>

          <div className="space-y-3">
            <h3>Market Price Form</h3>
            <MarketPriceForm />
          </div>

          <div className="space-y-3">
            <h3>Amount Form</h3>
            <SizeForm />
          </div>

          <div className="space-y-3">
            <h3>Amount Range Form</h3>
            <SizeRatioArea maxBuy={100} maxSell={50} />
          </div>

          <div className="space-y-3">
            <h3>Amount Button Form</h3>
            <SizeButtonForm />
          </div>
        </div>
      </Panel>
    </div>
  )
}

const LeverageForm = () => {
  const [value, setValue] = React.useState(20)

  return <AmountForm title="Leverage" amount={value} onChange={setValue} />
}

const PriceForm = () => {
  const [price, setPrice] = React.useState(3205.01)

  return (
    <AmountForm
      title="Price"
      unit="USDC"
      amount={price}
      onChange={setPrice}
      step={0.01}
    />
  )
}

const MarketPriceForm = () => {
  const [price, setPrice] = React.useState(0)

  return (
    <AmountForm
      title="Price"
      unit="USDC"
      disabledText="Market"
      amount={price}
      onChange={setPrice}
    />
  )
}

const SizeForm = () => {
  const [size, setSize] = React.useState(0)

  return (
    <AmountForm
      title="Size"
      unit="ETH"
      amount={size}
      onChange={setSize}
      step={0.001}
      digits={3}
    />
  )
}

const SizeRatioArea = ({
  maxBuy,
  maxSell
}: {
  maxBuy: number
  maxSell: number
}) => {
  const [size, setSize] = React.useState(0)
  const [ratio, setRatio] = React.useState(0)
  const [isRatio, setIsRatio] = React.useState(false)

  const [buyAmount, setBuyAmount] = React.useState(0)
  const [sellAmount, setSellAmount] = React.useState(0)

  const onRatioChange = useCallback(
    (value: number) => {
      setRatio(value)

      setBuyAmount((value * maxBuy) / 100)
      setSellAmount((value * maxSell) / 100)
    },
    [maxBuy, maxSell, setRatio, setBuyAmount, setSellAmount]
  )

  const onSizeChange = useCallback(
    (value: number) => {
      setSize(value)

      setBuyAmount(maxBuy < value ? maxBuy : value)
      setSellAmount(maxSell < value ? maxSell : value)
    },
    [maxBuy, maxSell, setSize, setBuyAmount, setSellAmount]
  )

  return (
    <div className="space-y-2">
      <SizeRatioForm
        amount={size}
        ratio={ratio}
        unit={'ETH'}
        isRatio={isRatio}
        onSizeChange={onSizeChange}
        onRatioChange={onRatioChange}
        setIsRatio={setIsRatio}
      />
      <div className="flex justify-between text-xs">
        <div>Buy: {buyAmount}</div>
        <div>Sell: {sellAmount}</div>
      </div>
    </div>
  )
}

const SizeButtonForm = () => {
  const [size, setSize] = React.useState(0)

  return (
    <AmountButtonForm
      title="Size"
      unit="ETH"
      amount={size}
      onChange={setSize}
    />
  )
}

export default GalleryView
