import { Position } from '@predy/js-sdk'
import { toScaled, toUnscaled } from './bn'
import { Q96 } from '../constants'
import { STRATEGY_TEMPLATE } from '../constants/strategy'

function getRatio(price: number, isGammaLong: boolean) {
  const squart2 = 100 * (isGammaLong ? -1 : 1)
  const perp = -squart2 / Math.sqrt(price)

  const position = new Position(0n, toScaled(squart2, 12), toScaled(perp, 18))

  const sqrtPriceX96 =
    (BigInt(Math.floor(Math.sqrt(price * 1000000))) * Q96) / 10n ** 9n
  // const priceX96 = (sqrtPriceX96 * sqrtPriceX96) / Q96

  // console.log(price, sqrtPriceX96, (priceX96 * 10n ** 18n) / Q96)

  const minDeposit = position.calculateMinDeposit(sqrtPriceX96)

  // console.log(minDeposit)

  return Math.abs(squart2) / toUnscaled(minDeposit, 6)
}

export function calculateGammaPosition(
  value: number,
  price: number,
  leverage: number,
  isGammaLong = false
) {
  const sqrtPriceX96 =
    (BigInt(Math.floor(Math.sqrt(price * 1000000))) * Q96) / 10n ** 9n

  const ratio = getRatio(price, isGammaLong)

  const squart2 = ((isGammaLong ? -1 : 1) * (value * ratio * leverage)) / 50
  const perp = -squart2 / Math.sqrt(price)

  const adjustedPerp = perp * 1.0002

  const position = new Position(
    0n,
    toScaled(squart2, 12),
    toScaled(adjustedPerp, 18)
  )

  const quote = position.calculateValue(sqrtPriceX96)

  return new Position(
    toScaled(value, 6) - quote,
    toScaled(squart2, 12),
    toScaled(adjustedPerp, 18)
  )
}

export function getStrategyName({ leverage }: { leverage: number }) {
  const templates = STRATEGY_TEMPLATE.filter(template => {
    return template.leverage === leverage || template.leverage === leverage - 1
  })

  if (templates.length === 0) {
    return 'Custom'
  }

  return templates[0].title
}
