import { PairInfo, Q96 } from '../constants'
import { toScaled, toUnscaled } from './bn'

export function convertNotionalToString(value: number) {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(2) + 'm'
  } else if (value >= 1000) {
    return (value / 1000).toFixed(2) + 'k'
  } else {
    return value.toFixed(2)
  }
}

export const getSymbolName = (pair: PairInfo) => {
  const symbolName = `${pair.base.address}-${pair.quote.address}`.toLowerCase()

  return symbolName
}

export function calculateChange(open: number, close: number) {
  if (open === 0) return 0

  return parseFloat(((close / open - 1) * 100).toFixed(3))
}

export function formatPrice(pairInfo: PairInfo, price: number) {
  const precision = Math.pow(10, pairInfo.pricePrecision)

  return Math.round(price * precision) / precision
}

export const toUnscaledPrice = (pairInfo: PairInfo, price: bigint) => {
  return formatPrice(
    pairInfo,
    toUnscaled(
      (price * 10n ** BigInt(pairInfo.base.decimals)) / Q96,
      pairInfo.quote.decimals
    )
  )
}

export const toScaledPrice = (pairInfo: PairInfo, price: number) => {
  return (
    (toScaled(price, pairInfo.quote.decimals) * Q96) /
    10n ** BigInt(pairInfo.base.decimals)
  )
}
