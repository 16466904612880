import { useQuery } from '@tanstack/react-query'
import { Address, Hex, isAddressEqual, zeroAddress } from 'viem'
import {
  ADDRESS_MAP,
  DEFAULT_STALE_TIME,
  REFETCH_INTERVAL
} from '../../constants'
import { SpotOrderV3 } from '@predy/js-sdk'
import { toTimeString } from '../../utils/string'
import { baseTokenAddressToPair } from '../../utils/pairs'
import { abs, toUnscaled } from '../../utils/bn'
import { apiGet } from '../../utils/fetch'

interface SpotOrderResponse {
  id: number
  chainId: number
  trader: Hex
  nonce: string
  deadline: number
  direction: number
  quoteToken: Hex
  baseToken: Hex
  baseTokenAmount: string
  quoteTokenAmount: string
  limitQuoteTokenAmount: string
  auctionParams: Hex
  orderStatus: string
  orderType: string
  createdAt: string
  updatedAt: string
}

export interface OpenSpotOrder {
  id: number
  chainId: number
  trader: Hex
  nonce: string
  deadline: number
  direction: number
  quoteToken: Hex
  baseToken: Hex
  baseTokenAmount: string
  quoteTokenAmount: string
  limitQuoteTokenAmount: string
  auctionParams: Hex
  orderStatus: string
  orderType: string
  createdAt: string
  updatedAt: string
}

export function decodedToSpotOrder(decoded: SpotOrderResponse) {
  return new SpotOrderV3(
    {
      info: {
        market: ADDRESS_MAP[decoded.chainId]
          ? ADDRESS_MAP[decoded.chainId].SpotMarket
          : zeroAddress,
        trader: decoded.trader,
        nonce: BigInt(decoded.nonce),
        deadline: BigInt(decoded.deadline)
      },
      baseToken: decoded.baseToken,
      quoteToken: decoded.quoteToken,
      baseTokenAmount: BigInt(decoded.baseTokenAmount),
      quoteTokenAmount: BigInt(decoded.quoteTokenAmount),
      limitQuoteTokenAmount: decoded.limitQuoteTokenAmount
        ? BigInt(decoded.limitQuoteTokenAmount)
        : 0n,
      auctionData: decoded.auctionParams || '0x'
    },
    decoded.chainId
  )
}

export function useQuoteAmountsInSpotOrders(chainId: number, trader: Address) {
  const spotOrders = useSpotOrders(chainId, trader, true)

  return spotOrders.data
    ? spotOrders.data.reduce((acc, order) => {
        const usedQuoteAmount =
          order.baseTokenAmount > 0n ? order.quoteTokenAmount : 0n
        return acc + usedQuoteAmount
      }, 0n)
    : 0n
}

export function useSpotOrders(
  chainId: number,
  trader: Address,
  isPending: boolean
) {
  return useQuery({
    queryKey: ['spot_orders', chainId, trader.toLowerCase(), isPending],
    queryFn: async () => {
      const query = new URLSearchParams({
        market: ADDRESS_MAP[chainId].SpotMarket.toLowerCase(),
        account: trader.toLowerCase(),
        isPending: isPending.toString(),
        offset: '0',
        limit: '100'
      })

      const result = await apiGet('order/v2/spot', query)

      const orders = result as SpotOrderResponse[]

      return orders.map(order => {
        const spotOrder = decodedToSpotOrder(order)

        const witnessInfo = spotOrder.witnessInfo()

        const canonicalPair = baseTokenAddressToPair(
          chainId,
          witnessInfo.baseToken
        )
        const reversePair = baseTokenAddressToPair(
          chainId,
          witnessInfo.quoteToken
        )

        const pair = canonicalPair || reversePair

        if (pair === null) {
          throw new Error('Pair not found')
        }

        let limitPrice: number | undefined

        if (pair && spotOrder.spotOrder.limitQuoteTokenAmount > 0n) {
          limitPrice = toUnscaled(
            (spotOrder.spotOrder.limitQuoteTokenAmount *
              10n ** BigInt(pair.base.decimals)) /
              abs(spotOrder.spotOrder.baseTokenAmount),
            pair.quote.decimals,
            pair.pricePrecision
          )
        }

        const quantity = toUnscaled(
          canonicalPair
            ? spotOrder.spotOrder.baseTokenAmount
            : spotOrder.spotOrder.quoteTokenAmount,
          pair.base.decimals,
          pair.base.precision
        )

        const isBuy =
          !!canonicalPair === spotOrder.spotOrder.baseTokenAmount > 0n

        return {
          orderId: order.id,
          ...witnessInfo,
          spotOrder,
          pair: pair || reversePair,
          orderStatus: order.orderStatus,
          isBuy,
          quantity,
          limitPrice,
          createdAt: toTimeString(new Date(order.createdAt).getTime() / 1000)
        }
      })
    },
    enabled: !isAddressEqual(trader, zeroAddress),
    staleTime: DEFAULT_STALE_TIME,
    refetchInterval: REFETCH_INTERVAL
  })
}
