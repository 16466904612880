import React from 'react'
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ReferenceLine
} from 'recharts'

type Props = {
  data: { price: number; value: number }[]
  current: number
  liq1: number
  liq2: number
  hedgeLower: number
  hedgeUpper: number
}

function createTrianglePath(x: number, y: number) {
  return `${x},${y + 8} ${x - 5},${y} ${x + 5},${y}`
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const dataDefs = (
      payload as { dataKey: string; value: number; payload: any }[]
    ).map(p => ({
      name: p.dataKey,
      value: p.value,
      price: p.payload.price
    }))

    // sort by value
    dataDefs.sort((a: any, b: any) => b.value - a.value)

    return (
      <div className="text-xs rounded-lg bg-white border-none p-1">
        {dataDefs.map((dataDef, i) => {
          return (
            <div key={i} className="text-[#009bf2] text-xs p-1">
              <p>Price: {dataDef.price}</p>
              <p>Value: {dataDef.value}</p>
            </div>
          )
        })}
      </div>
    )
  }
  return null
}

export const PnLChart = (props: Props) => {
  const { current, data, liq1, liq2, hedgeLower, hedgeUpper } = props

  const width = window.screen.width

  const LiqLabel = (params: any) => {
    const x = params.viewBox.x
    const y = params.viewBox.y - params.yy

    const points = createTrianglePath(x, y)

    return (
      <g>
        <text
          x={x}
          y={y - 3}
          textAnchor="middle"
          fill="#BAC5D0"
          transform="rotate(0)"
          fontSize={11}
        >
          {params.children} {params.price.toFixed(2)}
        </text>
        <polygon points={points} fill={params.content.props.fill} />
      </g>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={width}
        height={248}
        data={data}
        margin={{ top: 32, right: 20, left: 12, bottom: 28 }}
      >
        <XAxis
          type="number"
          dataKey="price"
          domain={['dataMin', 'dataMax']}
          tick={{
            stroke: '#BAC5D0',
            fill: '#BAC5D0',
            fontWeight: 100,
            fontSize: 11
          }}
        />
        <YAxis
          type="number"
          tick={{
            stroke: '#BAC5D0',
            fill: '#BAC5D0',
            fontWeight: 100,
            fontSize: 11
          }}
        />
        <Tooltip content={CustomTooltip} />
        <Legend />

        <ReferenceLine
          x={liq1}
          stroke="red"
          label={
            <LiqLabel price={liq1} fill="#7279FA" yy={0}>
              Liq. Price
            </LiqLabel>
          }
        />

        {hedgeLower > 0 ? (
          <ReferenceLine x={hedgeLower} stroke="white" />
        ) : (
          <></>
        )}

        <ReferenceLine
          x={current}
          stroke="green"
          label={
            <LiqLabel price={current} fill="#7279FA" yy={20}>
              Current Price
            </LiqLabel>
          }
        />

        {hedgeUpper > 0 ? (
          <ReferenceLine x={hedgeUpper} stroke="white" />
        ) : (
          <></>
        )}

        <ReferenceLine
          x={liq2}
          stroke="red"
          label={
            <LiqLabel price={liq2} fill="#7279FA" yy={0}>
              Liq. Price
            </LiqLabel>
          }
        />

        <Line
          type="monotone"
          dataKey="value"
          stroke="#009bf2"
          dot={false}
          strokeWidth={2}
          label="PnL"
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
