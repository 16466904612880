import { BigNumber } from 'ethers'

export * from './addresses'
export * from './assets'
export * from './enum'
export * from './messages'

export const SUPPORTED_CHAIN_IDS = [1337, 42161, 8453, 421613]
export const DEFAULT_CHAIN = SUPPORTED_CHAIN_IDS[1]

// slippage tolerance from oracle price is 0.75%
export const SLIPPAGE_TOLERANCE_FROM_ORACLE = 75

// 12 * 240 = 2880 seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 4

export const ONE = 10n ** 18n
export const ZERO = BigNumber.from(0)

export const DEFAULT_STALE_TIME = 5000
export const REFETCH_INTERVAL = 15000

export const Q96 = 2n ** 96n
export const Q128 = 2n ** 128n

// scaled by 100
export const TOKEN_RESERVE_FACTOR = 1n // 1%

//const FILLER_WS_ENDPOINT_DEV =
//  'wss://s8zy70cil0.execute-api.ap-northeast-1.amazonaws.com/dev/'
//const FILLER_API_ENDPOINT_DEV =
//  'https://0omsrjfp2l.execute-api.ap-northeast-1.amazonaws.com/dev/'

const FILLER_WS_ENDPOINT_PROD =
  'wss://zvfkglujgh.execute-api.ap-northeast-1.amazonaws.com/prod/'
const FILLER_API_ENDPOINT_PROD =
  'https://zsphfa23xa.execute-api.ap-northeast-1.amazonaws.com/prod/'

export const FILLER_WS_ENDPOINT = FILLER_WS_ENDPOINT_PROD
export const FILLER_API_ENDPOINT = FILLER_API_ENDPOINT_PROD

export const DEFAULT_CONFIRMATION: {
  [key: number]: number
} = {
  1337: 1,
  42161: 50
}

export const DEFAULT_REFRESH_INTERVAL: {
  [key: number]: number
} = {
  1337: 1,
  42161: 500
}

export * from './addresses'
export * from './assets'
export * from './enum'
