import React from 'react'
import { Address, PerpOrderV3 } from '@predy/js-sdk'
import {
  PrimaryButton,
  BaseCloseButton,
  HorizontalRule,
  ModalTitle,
  LoadingIndicator,
  InfoTooltip2
} from '../../common'
import { usePerpPosition } from '../../../hooks/perp/usePosition'
import {
  usePerpOrder,
  usePerpOrderWithSlippage
} from '../../../hooks/perp/usePerpOrder'
import { PAIR_INFOS, PairInfo } from '../../../constants/assets'
import { PriceResult } from '../../../hooks/query/usePrice'
import { pairIdToSymbol } from '../../../utils/pairs'
import { abs, toUnscaled } from '../../../utils/bn'
import {
  useAfterPosition,
  useQuotePerpTrade
} from '../../../hooks/perp/useQuotePerpTrade'
import { usePerpSendOrder } from '../../../hooks/perp/usePerpSendOrder'
import { SLIPPAGE_TOLERANCE_FROM_ORACLE } from '../../../constants'
import { toUnscaledPrice } from '../../../utils'
import { Direction } from '../../../constants/enum'
import { PnLChange } from '../../common/PriceChange'
import { TPSLOrderFormModal } from './TPSLOrderForm'
import { usePerpOrders } from '../../../hooks/perp'
import { AiFillEdit } from 'react-icons/ai'

const CloseModal = ({
  pairInfo,
  order,
  closeDirection,
  quantity,
  estPrice,
  payoff,
  routeIndex,
  onCloseModal
}: {
  pairInfo: PairInfo
  order: PerpOrderV3
  closeDirection: Direction
  quantity: number
  estPrice: bigint
  payoff?: bigint
  routeIndex: number
  onCloseModal?: () => void
}) => {
  const orderWithSlippage = usePerpOrderWithSlippage(
    order,
    estPrice,
    pairInfo.slippageTolerance
  )

  const { variables, signTypedData, error } = usePerpSendOrder(
    orderWithSlippage,
    routeIndex,
    error => {
      if (error === null) {
        if (onCloseModal) onCloseModal()
      }
    }
  )
  return (
    <div className="p-2">
      <ModalTitle>Close Position</ModalTitle>
      <HorizontalRule />
      <div className="my-4 text-sm space-y-1">
        <div className="flex justify-between">
          <div>Direction</div>
          <div>
            {closeDirection === Direction.Long ? 'Close Short' : 'Close Long'}
          </div>
        </div>
        <div className="flex justify-between">
          <div>Quantity</div>
          <div>{quantity}</div>
        </div>
        <div className="flex justify-between">
          <div>Price</div>
          <div>{toUnscaledPrice(pairInfo, estPrice)}</div>
        </div>
        <div className="flex justify-between">
          <div>Est. Profit</div>
          <Payoff pair={pairInfo} payoff={payoff} />
        </div>
      </div>
      <div className="my-4 h-10">
        <PrimaryButton
          disabled={false}
          onClick={() => {
            signTypedData({
              domain: variables.domain,
              types: variables.types,
              message: variables.message,
              primaryType: 'PermitWitnessTransferFrom'
            })
          }}
        >
          Close Position
        </PrimaryButton>
        {error ? <span className="mx-2 text-xs text-red">{error}</span> : <></>}
      </div>
    </div>
  )
}

const CloseButton = ({
  pairInfo,
  closeDirection,
  order,
  estPrice,
  payoff,
  routeIndex,
  perpAmount
}: {
  pairInfo: PairInfo
  closeDirection: Direction
  order: PerpOrderV3
  estPrice: bigint
  payoff?: bigint
  routeIndex: number
  perpAmount: bigint
}) => {
  return (
    <BaseCloseButton
      title={<span className="text-accent">Close</span>}
      height={260}
    >
      <CloseModal
        pairInfo={pairInfo}
        closeDirection={closeDirection}
        order={order}
        estPrice={estPrice}
        payoff={payoff}
        routeIndex={routeIndex}
        quantity={toUnscaled(abs(perpAmount), pairInfo.base.decimals, 3)}
      />
    </BaseCloseButton>
  )
}

const Payoff = ({ pair, payoff }: { payoff?: bigint; pair: PairInfo }) => {
  if (payoff === undefined) {
    return <div className="text-white">...</div>
  }

  const value = toUnscaled(
    payoff || 0n,
    pair.quote.decimals,
    pair.quote.precision
  )

  return (
    <div>
      <PnLChange value={value}>
        {payoff >= 0n ? '+' : ''}
        {value.toFixed(pair.quote.precision)}
      </PnLChange>
    </div>
  )
}

const ROIValue = ({ roi }: { roi: bigint }) => {
  if (roi === undefined) {
    return <div className="text-white">...</div>
  }

  const value = toUnscaled(roi, 4, 2)

  return (
    <PnLChange value={value}>
      ({roi >= 0n ? '+' : ''}
      {value.toFixed(2)}
      %)
    </PnLChange>
  )
}

const OpenPositionItem = ({
  chainId,
  account,
  price,
  isLong,
  entryPrice,
  quantity,
  margin,
  liquidationPrice,
  pair,
  perpAmount,
  leverage
}: {
  chainId: number
  account: Address
  price: PriceResult
  isLong: boolean
  entryPrice: bigint
  quantity: number
  margin: bigint
  liquidationPrice: string
  pair: PairInfo
  perpAmount: bigint
  leverage: number
}) => {
  const openOrders = usePerpOrders(chainId, account, true)

  const tpslOrders = openOrders.data
    ? openOrders.data.filter(order => order.orderType === 'TP/SL')
    : []

  const order = usePerpOrder(
    chainId,
    pair,
    account,
    !isLong,
    abs(perpAmount),
    leverage,
    {
      price: price.price,
      slippage: SLIPPAGE_TOLERANCE_FROM_ORACLE,
      closePosition: true
    }
  )

  const quoter = useQuotePerpTrade(chainId, order)
  const afterPosition = useAfterPosition(
    chainId,
    account,
    BigInt(pair.pairId || 0),
    quoter.data?.averagePrice || 0n,
    -perpAmount,
    leverage
  )

  const PriceOrHyphen = ({ price }: { price: number }) => {
    return <>{price > 0 ? price : '-'}</>
  }

  const mediaQuery = window.matchMedia('(width < 768px)')
  const isMobile = mediaQuery.matches

  if (isMobile) {
    return (
      <div className="w-full bg-panel border-panelborder border-[1px] p-4 rounded my-1 text-white space-y-2">
        <div>
          <div className="text-base font-bold space-x-2">
            <span>{pair.base.symbol}</span>
            <span className={'text-sm ' + (isLong ? 'text-green' : 'text-red')}>
              {isLong ? 'Long' : 'Short'}
            </span>
            <span className="text-sm text-fourth">{leverage}X</span>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="text-xs text-fourth">Size</div>
          <div className="text-xs text-fourth">PNL(ROI)</div>
        </div>

        <div className="flex justify-between">
          <div className="text-base">
            {quantity.toFixed(pair.base.precision)} {pair.base.symbol}
          </div>
          <div>
            {quoter.data ? (
              <>
                <Payoff pair={pair} payoff={afterPosition?.payoff || 0n} />
                <ROIValue
                  roi={((afterPosition?.payoff || 0n) * 1000000n) / margin}
                />
              </>
            ) : (
              <LoadingIndicator />
            )}
          </div>
        </div>

        <div className="flex justify-between">
          <div className="space-y-1">
            <div className="text-xs text-fourth">Entry Price</div>
            <div className="text-sm">{toUnscaledPrice(pair, entryPrice)}</div>
          </div>
          <div className="space-y-1">
            <div className="text-xs text-fourth">Est. Price</div>
            <div className="text-sm">
              {quoter.data
                ? toUnscaledPrice(pair, quoter.data?.averagePrice)
                : '...'}
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-xs text-fourth">Liq. Price</div>
            <div className="text-sm">{liquidationPrice}</div>
          </div>
        </div>

        <div className="flex justify-center space-x-2">
          <div className="w-1/2">
            {quoter.data ? (
              <BaseCloseButton
                title={
                  <div className="h-8">
                    <PrimaryButton size="sm">Market Close</PrimaryButton>
                  </div>
                }
                height={260}
              >
                <CloseModal
                  pairInfo={pair}
                  closeDirection={isLong ? Direction.Short : Direction.Long}
                  order={order}
                  estPrice={quoter.data.averagePrice}
                  payoff={afterPosition?.payoff}
                  routeIndex={quoter.data.routeIndex}
                  quantity={toUnscaled(abs(perpAmount), pair.base.decimals, 3)}
                />
              </BaseCloseButton>
            ) : (
              <LoadingIndicator />
            )}
          </div>
          <div className="w-1/2 flex justify-start items-start space-x-1">
            <TPSLOrderFormModal
              chainId={chainId}
              trader={account}
              pairInfo={pair}
            >
              <div className="h-8">
                <PrimaryButton size="sm">TP/SL</PrimaryButton>
              </div>
            </TPSLOrderFormModal>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-[1000px] h-8 my-1 flex justify-start items-center text-white">
      <div className="w-20">{pair.base.symbol}</div>
      <div className="w-20">{isLong ? 'Long' : 'Short'}</div>
      <div className={`w-24 ${isLong ? 'text-green' : 'text-red'}`}>
        {quantity.toFixed(pair.base.precision)} {pair.base.symbol}
      </div>
      <div className="w-24">{toUnscaledPrice(pair, entryPrice)}</div>
      <div className="w-28">{liquidationPrice}</div>
      <div className="w-24">
        {toUnscaled(margin, pair.quote.decimals, pair.quote.precision)}
      </div>
      <div className="w-24">
        {quoter.data ? toUnscaledPrice(pair, quoter.data?.averagePrice) : '...'}
      </div>
      <div className="w-24">
        {quoter.data ? (
          <>
            <Payoff pair={pair} payoff={afterPosition?.payoff || 0n} />
            <ROIValue
              roi={((afterPosition?.payoff || 0n) * 1000000n) / margin}
            />
          </>
        ) : (
          <LoadingIndicator />
        )}
      </div>
      <div className="w-28">
        {quoter.data ? (
          <CloseButton
            pairInfo={pair}
            closeDirection={isLong ? Direction.Short : Direction.Long}
            order={order}
            estPrice={quoter.data.averagePrice}
            payoff={afterPosition?.payoff}
            routeIndex={quoter.data.routeIndex}
            perpAmount={perpAmount}
          />
        ) : (
          <LoadingIndicator />
        )}
      </div>
      <div className="w-28 flex justify-start items-center space-x-1">
        <div>
          {tpslOrders.length > 0 ? (
            <>
              <PriceOrHyphen price={tpslOrders[0].limitPrice} />/
              <PriceOrHyphen price={tpslOrders[0].stopPrice} />
            </>
          ) : (
            'TP/SL'
          )}
        </div>
        <TPSLOrderFormModal chainId={chainId} trader={account} pairInfo={pair}>
          <AiFillEdit className="cursor-pointer" />
        </TPSLOrderFormModal>
      </div>
    </div>
  )
}

export const OpenPositions = ({
  chainId,
  account,
  price
}: {
  chainId: number
  account: Address
  price: PriceResult
}) => {
  const position = usePerpPosition(chainId, account, 2n)
  const openPositions =
    position.isSuccess && position.data.isLoaded ? [position.data] : []

  return (
    <div className="max-w-[680px] lg:max-w-[760px] xl:max-w-full px-4 pb-4 text-xs overflow-x-auto">
      {openPositions.length === 0 ? (
        <div className="flex justify-center items-center">No Positions</div>
      ) : (
        <div className="hidden w-[1000px] pb-4 mb-4 text-fourth md:flex justify-start border-b-[1px] border-panelborder">
          <div className="w-20">Symbol</div>
          <div className="w-20">Side</div>
          <div className="w-24">Quantity</div>
          <div className="w-24">Entry Price</div>
          <div className="w-28">Liquidation Price</div>
          <div className="w-24">
            <InfoTooltip2
              placement="top"
              description="The margin allocated to the current position.
              "
            >
              Margin
            </InfoTooltip2>
          </div>
          <div className="w-24">Est. Price</div>
          <div className="w-24">PNL(ROI%)</div>
          <div className="w-28">Close All Positions</div>
          <div className="w-28">
            <InfoTooltip2
              placement="top"
              description="This setting applies to the entire position. Take-profit and stop-loss are automatically cancelled after closing the position."
            >
              TP/SL for position
            </InfoTooltip2>
          </div>
        </div>
      )}

      {openPositions.map((openPosition, index) => (
        <OpenPositionItem
          chainId={chainId}
          account={account}
          price={price}
          isLong={openPosition.formattedData.isLong}
          entryPrice={openPosition.entryPrice}
          quantity={openPosition.formattedData.quantity}
          margin={openPosition.marginBn}
          liquidationPrice={openPosition.formattedData.liquidationPrice.toString()}
          pair={PAIR_INFOS[chainId][pairIdToSymbol(openPosition.pairId)]}
          perpAmount={openPosition.perpAmount}
          leverage={openPosition.leverage}
          key={index}
        />
      ))}
    </div>
  )
}
