import React from 'react'

interface Props {
  value: number
  min: number
  max: number
  step: number
  onValueChanged: (value: number) => void
}

export const Slider = ({ value, min, max, step, onValueChanged }: Props) => {
  const handler = (value: string) => {
    onValueChanged(Number(value))
  }

  return (
    <input
      type={'range'}
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={e => handler(e.target.value)}
      onFocus={e => handler(e.target.value)}
      onBlur={e => handler(e.target.value)}
      className="w-full h-2 bg-black5 rounded-lg select-none appearance-none cursor-pointer dark:bg-gray-700 accent-[#96dbfa]"
    />
  )
}
