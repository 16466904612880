import TradingView from '../../types/charting_library'

export interface DataFeedOptions {
  getBars?: TradingView.IDatafeedChartApi['getBars']
  onSubscribeStarted?: (symbolInfo: any, resolution: any) => void
}

export interface DataSubscriber {
  symbolInfo: TradingView.LibrarySymbolInfo
  resolution: string
  lastBarTime: number | null
  listener: TradingView.SubscribeBarsCallback
}

const SYMBOL_PRICE_SCALES: { [key: string]: number } = {
  'ETH-USDC': 100,
  'WBTC-USDC': 10,
  'ARB-USDC': 10000
}

export class DataFeed {
  constructor(
    private options: DataFeedOptions,
    private subscribers: Record<string, DataSubscriber> = {}
  ) {}

  public async onReady(callback: any) {
    setTimeout(
      () =>
        callback({
          supported_resolutions: ['D', 'H', '15', '5', '1'],
          supports_marks: false,
          supports_timescale_marks: false,
          supports_time: true,
          reset_cache_timeout: 100
        }),
      0
    )
  }

  public async searchSymbols(
    serInput: string,
    exchange: string,
    symbolType: string,
    onResult: any
  ) {
    console.log(serInput, exchange, symbolType)
    onResult([])
  }

  public async resolveSymbol(
    symbolName: string,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: any
  ) {
    const symbolInfo = {
      debug: false,
      name: symbolName,
      type: 'crypto',
      description: symbolName,
      ticker: symbolName,
      session: '24x7',
      minmov: 1,
      exchange: 'Predy V6 Perp',
      pricescale: SYMBOL_PRICE_SCALES[symbolName] || 2,
      timezone: 'Etc/UTC',
      has_intraday: true,
      has_daily: true,
      currency_code: 'USD',
      visible_plots_set: 'ohlc',
      data_status: 'streaming',
      isStable: false
    }

    return new Promise(resolve => resolve(void 0))
      .then(() => {
        onSymbolResolvedCallback(symbolInfo)
      })
      .catch(onResolveErrorCallback)
  }

  async getBars(
    symbolInfo: any,
    resolution: any,
    periodParams: any,
    onResult: any,
    onError: any
  ) {
    if (!this.options.getBars) {
      return new Promise(resolve => resolve(void 0)).then(() => {
        onResult([])
      })
    }

    this.options.getBars(
      symbolInfo,
      resolution,
      periodParams,
      onResult,
      onError
    )
  }

  async subscribeBars(
    symbolInfo: any,
    resolution: any,
    onRealtimeCallback: any,
    subscriberUID: string,
    onResetCacheNeededCallback: () => void
  ) {
    try {
      if (this.options.onSubscribeStarted) {
        this.options.onSubscribeStarted(symbolInfo, resolution)
      }

      if (this.subscribers[subscriberUID]) {
        return
      }

      this.subscribers[subscriberUID] = {
        lastBarTime: null,
        listener: onRealtimeCallback,
        resolution: resolution,
        symbolInfo: symbolInfo
      }
    } catch (e) {
      onResetCacheNeededCallback()
    }
  }

  async unsubscribeBars(subscriberUID: string) {
    if (!this.subscribers[subscriberUID]) {
      return
    }
    delete this.subscribers[subscriberUID]
  }

  public updateBar(bar: TradingView.Bar) {
    for (const listenerGuid in this.subscribers) {
      const subscriptionRecord = this.subscribers[listenerGuid]
      if (
        subscriptionRecord.lastBarTime !== null &&
        bar.time < subscriptionRecord.lastBarTime
      ) {
        continue
      }
      subscriptionRecord.lastBarTime = bar.time
      subscriptionRecord.listener(bar)
    }
  }
}
