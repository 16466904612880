import React from 'react'
import { usePerpOrders } from '../../../hooks/perp/usePerpOrders'
import { Address } from 'viem'
import { LoadingIndicator } from '../../common/LoadingIndicator'
import { OrderStatus } from '../../../constants'

export const OrderHistory = ({
  chainId,
  trader
}: {
  chainId: number
  trader: Address
}) => {
  const openOrders = usePerpOrders(chainId, trader, false)

  return (
    <div className="px-4 text-xs">
      {openOrders.isLoading ? (
        <LoadingIndicator />
      ) : openOrders.isSuccess && openOrders.data.length === 0 ? (
        <div className="flex justify-center items-center">No Orders</div>
      ) : (
        <div className="pb-4 mb-4 text-third flex justify-around border-b-[1px] border-panelborder">
          <div className="basis-[14%]">Time</div>
          <div className="basis-[12%]">Symbol</div>
          <div className="basis-[12%]">Side</div>
          <div className="basis-[12%]">Type</div>
          <div className="basis-[12%]">Status</div>
          <div className="basis-[12%]">Executed</div>
          <div className="basis-[12%]">Limit Price</div>
          <div className="basis-[12%]">Stop Price</div>
        </div>
      )}
      {openOrders.data ? (
        openOrders.data.map((openOrder, index) => (
          <div
            className={`my-2 text-white flex justify-around ${
              openOrder.orderStatus === OrderStatus.FAILED ||
              openOrder.orderStatus === OrderStatus.CANCELLED
                ? 'text-white5'
                : 'text-white'
            }`}
            key={index}
          >
            <div className="basis-[14%]">{openOrder.createdAt}</div>
            <div className="basis-[12%]">{openOrder.pairInfo.base.symbol}</div>
            <div className="basis-[12%]">
              {openOrder.executed.side === 'Buy' ? (
                <div className="text-green">Long</div>
              ) : (
                <div className="text-red">Short</div>
              )}
            </div>
            <div className="basis-[12%]">{openOrder.orderType}</div>
            <div className="basis-[12%]">{openOrder.status}</div>
            <div className="basis-[12%]">
              {openOrder.perpOrder.perpOrder.closePosition &&
              openOrder.executed.quantity === 0 ? (
                <>Close Position</>
              ) : (
                <>
                  {openOrder.executed.quantity.toFixed(
                    openOrder.pairInfo.base.precision
                  )}
                </>
              )}
            </div>
            <div className="basis-[12%]">
              {openOrder.limitPrice === 0 ? '-' : openOrder.limitPrice}
            </div>
            <div className="basis-[12%]">
              {openOrder.stopPrice === 0 ? '-' : openOrder.stopPrice}
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  )
}
